import { useParams } from "react-router-dom"
import { format, parseISO } from "date-fns"
import { FC, useEffect, useRef, useState } from "react"
import { formatNumberToCurrency } from "../../../lib/currency"
import useReceiptPublic, {
  OrderedProduct,
} from "../../../hooks/api/use-receipt-public"
import { Trans } from "react-i18next"
import axios from "axios"
import { BACKEND_API } from "../../../constants/Config"
import Button from "../../../components/Button"
import LoadingSpinner from "../../../components/LoadingSpinner"
import useIncrementDownloadCount from "../../../hooks/api/use-increment-download-count"
import estore_logo_default from "../../../assets/estore_logo_default.png"
import slugify from "slugify"
import { t } from "i18next"
import { Tooltip } from "@mui/material"
import { jsPDF } from "jspdf"
import html2canvas from "html2canvas"

interface Params {
  orderId: string
  businessId: string
}

const PublicReceipt = () => {
  const { orderId } = useParams<Params>()
  const { data: receipt, mutate } = useReceiptPublic(orderId)
  const ref = useRef(null)
  const business = receipt?.business
  const settings = receipt?.settings
  const cartTransaction = receipt?.cartTransaction
  const logoUrl =
    business?.logoUrl && business?.logoUrl !== ""
      ? business.logoUrl
      : estore_logo_default

  const [logoBase64, setLogoBase64] = useState<any>(null)
  const taxPIN = receipt?.settings?.taxPIN

  useEffect(() => {
    if (logoUrl) {
      fetch(logoUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const reader = new FileReader()
          reader.onloadend = () => {
            setLogoBase64(reader.result) // Set the Base64 string
          }
          reader.readAsDataURL(blob)
        })
    }
  }, [logoUrl])

  const address = (
    <div className={"mt-8"}>
      <p className={"m-0 p-0 text-center"}>
        {cartTransaction?.location?.addressLine1}
      </p>
      <p className={"m-0 p-0 text-center"}>
        {cartTransaction?.location?.addressLine2}
      </p>
    </div>
  )
  const phone = <p className={"m-0 mt-4 p-0 text-center"}>{business?.phone}</p>
  const barcode = (
    <p className={"m-0 mt-4 p-0 text-center"}>
      Receipt #{cartTransaction?.orderNumber}
    </p>
  )
  const note = (
    <p className={"m-0 mt-4 mb-4 p-0 text-center"}>{settings?.message}</p>
  )

  // const receiptThermal = (
  //   <Printer type="epson" width={42} characterSet="korea">
  //     <Text size={{ width: 2, height: 2 }}>9,500원</Text>
  //     <Text bold={true}>결제 완료</Text>
  //     <Br />
  //     <Line />
  //     <Row left="결제방법" right="체크카드" />
  //     <Row left="카드번호" right="123456**********" />
  //     <Row left="할부기간" right="일시불" />
  //     <Row left="결제금액" right="9,500" />
  //     <Row left="부가세액" right="863" />
  //     <Row left="공급가액" right="8,637" />
  //     <Line />
  //     <Row left="맛있는 옥수수수염차 X 2" right="11,000" />
  //     <Text>옵션1(500)/옵션2/메모</Text>
  //     <Row left="(-) 할인" right="- 500" />
  //     <Br />
  //     <Line />
  //     <Row left="합계" right="9,500" />
  //     <Row left="(-) 할인 2%" right="- 1,000" />
  //     <Line />
  //     <Row left="대표" right="김대표" />
  //     <Row left="사업자등록번호" right="000-00-00000" />
  //     <Row left="대표번호" right="0000-0000" />
  //     <Row left="주소" right="어디시 어디구 어디동 몇동몇호" />
  //     <Line />
  //     <Br />
  //     <Text align="center">Wifi: some-wifi / PW: 123123</Text>
  //     <Cut />
  //   </Printer>
  // )

  const handleClickPrint = async () => {
    // const data: Uint8Array = await render(receiptThermal)
    // console.log(data)
  }

  const handleDownload = async () => {
    // Ensure the reference exists
    if (!ref.current) return

    try {
      // Capture the HTML element as a canvas
      const canvas = await html2canvas(ref.current, {
        scale: 2, // Increase the resolution
        useCORS: true, // Enables CORS to avoid cross-origin issues
      })

      // Convert canvas to image data
      const imgData = canvas.toDataURL("image/png")

      // Initialize jsPDF
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "px",
        format: [canvas.width, canvas.height], // Adjust PDF size to match canvas
      })

      // Add the image to the PDF
      pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height)

      // Save the PDF
      pdf.save("receipt.pdf")
    } catch (error) {
      console.error("Error generating PDF:", error)
    }
  }

  const activeReceiptCustomerLocation =
    cartTransaction?.customer?.customerLocations.find(
      (el) => el.isPrimaryLocation
    ) || null

  return (
    <div className={"flex h-full flex-col bg-primary-700"}>
      <div className="flex justify-end px-8 pt-6">
        <Tooltip title={"Download this receipt"}>
          <Button
            variant={"text"}
            onClick={handleDownload}
            className="text-white"
          >
            Download PDF
          </Button>
        </Tooltip>
      </div>
      <div className="flex flex-col items-center justify-center bg-primary-700 p-8 lg:py-16">
        <div
          className="w-full max-w-xl rounded-2xl border border-solid border-primary-800 bg-white p-4 shadow-xl shadow-primary-900/40"
          ref={ref}
        >
          <div className={"mt-8 flex items-center justify-center"}>
            <img
              src={logoBase64 || estore_logo_default}
              className={"h-20 w-20 rounded-lg"}
              alt={business?.name + " logo"}
            />
          </div>

          {cartTransaction?.buxPaymentStatus === "PAID" && (
            <p className="m-0 mt-8 p-0 text-center font-bold text-green-700">
              <Trans>Payment Successful!</Trans>
            </p>
          )}

          {cartTransaction?.buxPaymentStatus === "PENDING" && (
            <p className="m-0 mt-8 p-0 text-center font-bold text-orange-700">
              <Trans>Payment Pending</Trans>
            </p>
          )}

          {cartTransaction?.buxPaymentStatus === "FAILED" && (
            <p className="m-0 mt-8 p-0 text-center font-bold text-red-700">
              <Trans>Payment Pending</Trans>
            </p>
          )}

          <p className={"m-0 mt-2 mb-8 p-0 text-center text-2xl font-bold"}>
            {business?.name}
          </p>

          {settings?.showLocation === "Header" && address}
          {settings?.showPhone === "Header" && phone}
          <p className={"m-0 p-0 text-center"}>
            {format(
              parseISO(cartTransaction?.createdAt || ""),
              "dd MMM yyyy HH:mm a"
            )}
          </p>
          {taxPIN && <p className={"text-center"}>Tax PIN: {taxPIN}</p>}
          {settings?.showBarcode === "Header" && barcode}
          {settings?.showOrderNote === "Header" && note}

          <div className="flex flex-col border-t border-gray-200 px-8 py-6">
            <span>
              <Trans>Cashier</Trans>: {cartTransaction?.createdBy || ""}
            </span>
            <span>
              <Trans>Customer</Trans>:{" "}
              {cartTransaction?.customer
                ? cartTransaction?.customer?.email
                : "Walk In"}
            </span>
            <span>
              <Trans>Phone</Trans>:{" "}
              {cartTransaction?.customer &&
              cartTransaction?.customer?.phone != ""
                ? cartTransaction.customer.phone
                : "N/A"}
            </span>
            {activeReceiptCustomerLocation == null ? (
              <span>
                <Trans>Address</Trans>: N/A
              </span>
            ) : (
              <div className="mt-2 flex flex-col">
                <span className="">
                  <Trans>Address Line 1</Trans>:{" "}
                  {activeReceiptCustomerLocation.addressLine1}
                </span>
                <span className="">
                  <Trans>City</Trans>:{" "}
                  {activeReceiptCustomerLocation.city || "N/A"}
                </span>
                <span className="">
                  <Trans>Province</Trans>:{" "}
                  {activeReceiptCustomerLocation.province || "N/A"}
                </span>
                <span className="">
                  <Trans>Zip</Trans>:{" "}
                  {activeReceiptCustomerLocation.zip || "N/A"}
                </span>
              </div>
            )}
          </div>

          <div className="border border-x-0 border-b-0 border-solid border-gray-200 py-2">
            {cartTransaction?.orderedProducts.map((product) => {
              const isRefunded = cartTransaction?.refund?.refundItem?.find(
                (r: any) => r.orderedProduct?.id === product.id
              )
              return (
                <Product
                  key={product.id}
                  product={product}
                  currency={business?.businessCurrency || "USD"}
                  onDownloaded={async () => {
                    await mutate()
                  }}
                  isRefunded={isRefunded}
                />
              )
            })}
          </div>

          <div className="border border-x-0 border-b-0 border-solid border-gray-200 p-8">
            <div className={"mb-4 flex"}>
              <p className={"m-0 p-0 text-gray-500"}>Discount</p>
              <p className={"m-0 ml-auto p-0"}>
                {formatNumberToCurrency(
                  business?.businessCurrency || "USD",
                  cartTransaction?.discount
                )}
              </p>
            </div>

            <div className={"mb-4 flex"}>
              <p className={"m-0 p-0 text-gray-500"}>Tax</p>
              <p className={"m-0 ml-auto p-0"}>
                {formatNumberToCurrency(
                  business?.businessCurrency || "USD",
                  cartTransaction?.tax
                )}
              </p>
            </div>
            <ul className="list-disc pl-8 ">
              {cartTransaction?.taxes?.map((el: any) => {
                return (
                  <li className="mb-4">
                    <div className="flex flex-1 items-end">
                      <div className="flex flex-1 text-gray-500">
                        <span className="">
                          {el.name} {el.rate}% ({el.type})
                        </span>
                      </div>
                      <div className="flex flex-1 justify-end">
                        <span className="">
                          {formatNumberToCurrency(
                            business?.businessCurrency || "USD",
                            el.amount
                          )}
                        </span>
                      </div>
                    </div>
                  </li>
                )
              })}
            </ul>

            <div className={"mb-4 flex "}>
              <p className={"m-0 p-0 text-gray-500"}>Service Charge</p>
              <p className={"m-0 ml-auto p-0"}>
                {formatNumberToCurrency(
                  business?.businessCurrency || "USD",
                  cartTransaction?.serviceCharge
                )}
              </p>
            </div>

            {cartTransaction?.orderType === "DELIVERY" && (
              <div className={"mb-4 flex"}>
                <p className={"m-0 p-0 text-gray-500"}>Delivery Fee</p>
                <p className={"m-0 ml-auto p-0"}>
                  {formatNumberToCurrency(
                    business?.businessCurrency || "USD",
                    cartTransaction?.shippingCost
                  )}
                </p>
              </div>
            )}

            {cartTransaction?.outstanding &&
            cartTransaction?.outstanding > 0 ? (
              <>
                <div className={"mb-4 flex"}>
                  <p className={"m-0 p-0 text-gray-500"}>
                    {t("Partial Amount")}
                  </p>
                  <p className={"m-0 ml-auto p-0"}>
                    {formatNumberToCurrency(
                      business?.businessCurrency || "USD",
                      cartTransaction?.grandTotal ??
                        0 - cartTransaction?.outstanding ??
                        0
                    )}
                  </p>
                </div>

                <div className={"mb-4 flex"}>
                  <p className={"m-0 p-0 text-gray-500"}>
                    {t("Total Outstanding")}
                  </p>
                  <p className={"m-0 ml-auto p-0"}>
                    {formatNumberToCurrency(
                      business?.businessCurrency || "USD",
                      cartTransaction.outstanding
                    )}
                  </p>
                </div>
              </>
            ) : null}
            <div className={"flex"}>
              <p className={"m-0 p-0 text-gray-500"}>Total</p>
              <p className={"m-0 ml-auto p-0"}>
                {formatNumberToCurrency(
                  business?.businessCurrency || "USD",
                  cartTransaction?.grandTotal
                )}
              </p>
            </div>
          </div>

          <div className="border border-x-0 border-b-0 border-solid border-gray-200 px-16">
            {settings?.showLocation === "Footer" && address}
            {settings?.showPhone === "Footer" && phone}
            {settings?.showBarcode === "Footer" && barcode}
            {settings?.showOrderNote === "Footer" && note}

            <p className={"m-0 my-8 p-0 text-center text-gray-500"}>
              Powered by DizLog
            </p>
          </div>
        </div>
        {/* <div className="mt-4 flex w-full items-center justify-center">
          <button
            onClick={() => handleClickPrint()}
            className="w-56 rounded-full border-2  bg-white p-4 font-[Roboto] text-xl text-primary-900 outline-none"
          >
            Print
          </button>
        </div> */}
      </div>
    </div>
  )
}

const Product: FC<{
  currency: string
  product: OrderedProduct
  onDownloaded: () => void
  isRefunded: boolean
}> = ({ product, currency, onDownloaded, isRefunded }) => {
  const [progress, setProgress] = useState(0)
  const [isDownloading, setIsDownloading] = useState(false)

  const incrementDownloadCount = useIncrementDownloadCount(product.id)

  const handleDownload = async () => {
    setProgress(0)
    setIsDownloading(true)

    const result = await axios.get<{ url: string }>(
      `${BACKEND_API}/products/download-link`,
      {
        params: { key: product.attachment },
      }
    )

    const file = await axios.get(result.data.url, {
      responseType: "blob",
      onDownloadProgress: (e) => {
        const currentProgress = e.total
          ? Math.round((e.loaded * 100) / e.total)
          : 0
        setProgress(currentProgress)
      },
    })
    await incrementDownloadCount.trigger()
    setIsDownloading(false)
    onDownloaded()

    // download file to downloads folder
    const url = window.URL.createObjectURL(new Blob([file.data]))
    const link = document.createElement("a")
    const ext = product.attachment.split(".").pop()
    const name = slugify(product.name, { lower: true })
    link.href = url
    link.setAttribute("download", `${name}.${ext}`)
    document.body.appendChild(link)
    link.click()
  }

  const downloadsLeft = product.quantity - product.downloadCount

  let totalPrice = product.sellingPrice * product.quantity
  if (product.orderedProductAddOns?.length) {
    product.orderedProductAddOns.forEach((addOn: any) => {
      totalPrice += addOn.sellingPrice * addOn.quantity
    })
  }

  return (
    <div className={"px-8 py-6"}>
      <div className={"mb-2 flex"}>
        <p className={"m-0 p-0 font-bold"}>{product.name}</p>
        <p className={"m-0 ml-auto p-0 text-gray-500"}>SKU {product.SKU}</p>
      </div>

      <div className={"flex"}>
        <p className={"m-0 p-0 text-gray-500"}>
          {product.quantity} Item(s) @{" "}
          {formatNumberToCurrency(currency, product.sellingPrice)}
        </p>

        <p className={"m-0 ml-auto p-0"}>
          {formatNumberToCurrency(currency, totalPrice)}
        </p>
      </div>
      {product.orderedProductAddOns.length > 0 ? (
        <div className="flex flex-1 items-start pl-4">
          <span className="font-bold">AdOns</span>
        </div>
      ) : null}

      {product.orderedProductAddOns.length > 0
        ? product.orderedProductAddOns.map((i: any) => (
            <div className="flex flex-1 items-start pl-4">
              {i.quantity} x {i.groupName} : {i.name} -{" "}
              {i.sellingPrice.toFixed(2)}
            </div>
          ))
        : null}
      {product.productOptionValues.length > 0 ? (
        <div className="flex flex-1 items-start pl-4">
          <span className="font-bold">Variants</span>
        </div>
      ) : null}

      {product.productOptionValues.length > 0
        ? product.productOptionValues.map((i: any) => (
            <div className="flex flex-1 items-start pl-4">
              {i.productOptionName} : {i.name}
            </div>
          ))
        : null}
      {product.quantityReturned > 0 && (
        <div className="flex flex-1 items-start pl-4">
          <span className="text-red-500">Voided</span>
        </div>
      )}
      {!!product.quantityReturned && product.quantityReturned > 0 && (
        <div className="flex flex-1 items-start pl-4">
          <span className="text-red-500">
            {isRefunded ? "Refunded" : "Voided"}
          </span>
        </div>
      )}
      {isRefunded && !product.quantityReturned && (
        <div className="flex flex-1 items-start pl-4">
          <span className="text-red-500">Refunded</span>
        </div>
      )}

      {product.productType === "DIGITAL" && (
        <>
          <div className={"mt-2 flex items-center"}>
            <Button
              variant={"small"}
              className={"w-auto"}
              onClick={handleDownload}
              disabled={
                isDownloading || product.quantity - product.downloadCount < 1
              }
            >
              {isDownloading ? <LoadingSpinner /> : <Trans>Download</Trans>}
            </Button>

            <p className={"m-0 ml-3 p-0 text-sm text-gray-500"}>
              {downloadsLeft > 0 && (
                <Trans>{{ downloadsLeft }} Download left</Trans>
              )}
              {downloadsLeft < 1 && <Trans>No downloads left.</Trans>}
            </p>
          </div>

          {progress > 0 && (
            <div className="relative mt-3 h-8 w-full overflow-hidden rounded-xl border border-solid border-gray-200 bg-gray-100 shadow-sm">
              <div
                className={"h-8 bg-green-400 text-center"}
                style={{ width: progress + "%" }}
              />
              <p className="absolute inset-0 m-0 flex w-full items-center justify-center p-0 text-sm font-bold">
                {isDownloading ? (
                  <Trans>Downloading</Trans>
                ) : (
                  <Trans>Finished</Trans>
                )}
              </p>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default PublicReceipt
