import useAPI from "./use-api"

export interface Earnings {
  id: string
  calculationBasis: string
  createdAt: string
  default: boolean
  isMandatory: boolean
  name: string
  overtimeType: string
  payPeriod: string
  percentageBasis: string
  updatedAt: string
  value: string
}

const useEarnings = () => {
  return useAPI<Earnings[]>(`/earning`)
}

export default useEarnings
