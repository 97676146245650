import axios from "axios"
import authProvider from "../utils/authProvider"
import { deleteAPI, patchAPI, postAPI } from "./api"
import { UnitOfMeasure } from "./models"
import { BACKEND_API } from "../constants/Config"

export const postNewUnitOfMeasure = async (unitOfMeasure: UnitOfMeasure) => {
  return postAPI(`/unit-of-measure`, unitOfMeasure)
}

// export const patchUnitOfMeasureToggle = async (unitOfMeasureList: UnitOfMeasure[]) => {
//   return patchAPI(`/unit-of-measure/toggle`, "", unitOfMeasureList)
// }

export const patchUnitOfMeasureToggle = async (
  unitOfMeasureList: UnitOfMeasure[]
) => {
  const token = await authProvider.getToken()
  return await axios.patch(
    `${BACKEND_API}/unit-of-measure/toggle`,
    { data: unitOfMeasureList },
    {
      headers: { id_token: token },
    }
  )
}

export const patchUnitOfMeasure = async (unitOfMeasure: any, id: string) => {
  const token = await authProvider.getToken()
  return await axios.patch(
    `${BACKEND_API}/unit-of-measure/${id}`,
    unitOfMeasure,
    {
      headers: { id_token: token },
    }
  )
}

export const deleteUnitOfMeasure = async (id: string) => {
  const token = await authProvider.getToken()
  return await axios.delete(`${BACKEND_API}/unit-of-measure/${id}`, {
    headers: { id_token: token },
  })
}
