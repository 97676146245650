import ThermalPrinterEncoder, {
  ThermalPrinterEncoderOption,
} from "thermal-printer-encoder"

export const tableQRCCodes = (
  details: {
    tableName: string
    url: string
    expiryDate: string
    isStatic: boolean
  },
  encoderOptions: ThermalPrinterEncoderOption = {
    language: "esc-pos",
    width: 32,
  }
) => {
  let result = new ThermalPrinterEncoder(encoderOptions)
    .initialize()
    .align("center")
    .bold(true)
    .line(`Table: ${details.tableName}`)
    .qrcode(details.url)
    .newline()
    .line("Step 1: Scan QR code using your mobile phone camera")
    .line("Step 2: Browse our menu and select your food order")
    .line("Step 3: Confirm your order and wait for the food to be served")
    .newline()
    .line("Note: Android users may have to download a QR scanner.")
    .newline()

  if (!details.isStatic) {
    result = result.line(`Valid until: ${details.expiryDate}`)
  }

  return result.newline().newline().newline().cut().encode()
}
