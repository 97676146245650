import ThermalPrinterEncoder, {
  ThermalPrinterEncoderOption,
} from "thermal-printer-encoder"
import jsBarcode from "jsbarcode"
import { printBorder } from "./helper"

type Item = {
  name: string
  price: string
  barcode: string
  quantity: number
}

export const encodeItemLabels = async (
  items: Item[],
  printName: boolean = true,
  printPrice: boolean = true,
  encoderOptions: ThermalPrinterEncoderOption = {
    language: "esc-pos",
    width: 32,
  }
): Promise<Uint8Array> => {
  const width = encoderOptions.width || 32

  let encoder = new ThermalPrinterEncoder({
    ...encoderOptions,
    imageMode: "raster",
  }).initialize()

  /**
   * Generates a barcode image using jsBarcode.
   * @param barcode - The barcode string to encode.
   * @returns A promise that resolves to an HTMLImageElement containing the barcode.
   */
  const generateBarcodeImage = (barcode: string): Promise<HTMLImageElement> => {
    return new Promise<HTMLImageElement>((resolve, reject) => {
      try {
        const canvas = document.createElement("canvas") // Create a canvas for the barcode
        jsBarcode(canvas, barcode, {
          format: "CODE128", // Specify the barcode format
          width: 2,
          height: 60,
          displayValue: true,
          fontSize: 30, // Adjust font size for better readability
        })

        const img = new Image()
        img.src = canvas.toDataURL("image/png") // Convert canvas to a base64 PNG
        img.onload = () => resolve(img)
        img.onerror = (err) => reject(err)
      } catch (err) {
        reject(err)
      }
    })
  }

  // Loop through items and encode their labels
  for (const item of items) {
    // Print item times quantity
    for (let i = 0; i < item.quantity; i++) {
      // Add item details to the encoder
      if (printName && printPrice) {
        encoder = encoder.table(
          [
            { width: 20 - 1, marginRight: 2, align: "left" },
            { width: width - 20 - 1, align: "right" },
          ],
          [[item.name, item.price]]
        )
      } else {
        if (printName) encoder = encoder.align("left").line(item.name)
        if (printPrice) encoder = encoder.align("left").line(item.price)
      }

      // Generate and add barcode if available
      if (item.barcode) {
        try {
          const barcodeImage = await generateBarcodeImage(item.barcode)
          encoder = encoder
            .align("center")
            .image(barcodeImage, 160, 80, "threshold") // Adjust dimensions as needed
            .newline()
        } catch (err: any) {
          console.error("Failed to generate barcode:", err.message || err)
        }
      }

      // Add spacing and border
      encoder = encoder.newline()
      encoder = printBorder(encoder, width, { noSpacing: true })
    }
  }

  // Finalize encoding
  return encoder.newline().newline().newline().cut().encode()
}
