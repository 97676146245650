import DigLogLogo from "../../../assets/Logo_DigLog.png"
import { Link, useParams } from "react-router-dom"
import { Trans } from "react-i18next"
import { formatNumberToCurrency } from "../../../lib/currency"
import usePublicPODetails from "../../../hooks/use-public-po-details"
import PurchaseOrderPreview from "../../../components/PurchaseOrderPreview"
import Button from "../../../components/Button"
import {
  acceptPurchaseOrder,
  rejectPurchaseOrder,
} from "../../../lib/purchase-order"
import clsx from "clsx"
import { PurchaseOrderStatus } from "../../../lib/models"
import AuthorizationControl from "../../../components/AuthorizationControl"

const ShareablePurchaseOrder = () => {
  const { id } = useParams<{ id: string }>()
  const { data: po, mutate } = usePublicPODetails(id)

  if (!po) return null

  return (
    <div className="bg-slate-100 min-h-screen overflow-auto">
      <div className="sticky top-0 z-20 border-x-0 border-b border-t-0 border-solid border-gray-200 bg-white py-4">
        <div className={"mx-auto flex max-w-[1200px] items-center px-6"}>
          <Link to="/" className={"flex"}>
            <img
              src={DigLogLogo}
              alt="DizLog"
              className={"h-8 w-auto object-cover"}
            />
          </Link>

          {/* <LanguageSelect
              hideLabel
              containerClassName={"ml-auto"}
              selectClassName={"!bg-gray-50 font-bold"}
            /> */}
        </div>
      </div>

      <div className="w-full mt-8">
        <div className="px-b mx-auto mb-20 box-border flex w-full max-w-[1200px] flex-col px-4">
          <div className="mb-4 flex flex-wrap items-center px-2 pt-4 lg:items-end">
            <p className={"m-0 p-0 text-2xl font-medium"}>
              Purchase Order from {po.business.name}
              {/* Quotation from {estimate.customer.fullName} */}
            </p>

            {/* <button
                className="ml-auto cursor-pointer rounded-md border-none bg-orange-400
          p-3 font-semibold text-white hover:bg-orange-500"
              >
                CREATE INVOICE
              </button> */}
            {/*<a*/}
            {/*  href={`${BACKEND_API}/estimate/${estimate.id}/share/pdf`}*/}
            {/*  className="ml-auto font-bold text-primary-900 no-underline hover:underline"*/}
            {/*  target={"_blank"}*/}
            {/*  rel={"noopener noreferrer"}*/}
            {/*>*/}
            {/*  Download PDF*/}
            {/*</a>*/}
          </div>

          <div className="main_content flex flex-col-reverse md:flex-row gap-4 flex-1">
            <PurchaseOrderPreview
              business={po.business}
              location={po.location}
              po={po}
              currency={
                po.business.businessCurrency || po.business.country.currency
              }
            />

            <div className="left-content-main-div h-52 md:w-3/12">
              <div className="flex h-full flex-1 flex-col rounded-2xl bg-white p-4 shadow-sm border">
                <div
                  className="flex flex-1 items-center justify-center"
                  style={{ borderBottom: "1px solid #cbd2d6" }}
                >
                  <p className="text-md flex w-full flex-1 font-medium">
                    <Trans> Total Amount</Trans>:
                  </p>
                  <p className="w-full flex-1 text-2xl font-semibold text-right">
                    {formatNumberToCurrency(
                      po.business.businessCurrency,
                      po.totalPrice
                    )}
                  </p>
                </div>
                <div className="mt-3 flex items-center justify-center">
                  {po.status === "NEW" ? (
                    <div className={"w-full"}>
                      <AuthorizationControl
                        permission={[
                          "inventory:purchaseOrder:approvePurchaseOrder",
                        ]}
                        customNotAuthorizedView={
                          <Button className={"w-full mb-2"} disabled>
                            Confirm Purchase Order (No permission)
                          </Button>
                        }
                      >
                        <Button
                          onClick={async () => {
                            if (!po) return
                            console.log("called")

                            await acceptPurchaseOrder(po.id)
                            await mutate()
                          }}
                          className={"w-full mb-2"}
                        >
                          Confirm Purchase Order
                        </Button>
                      </AuthorizationControl>

                      <Button
                        onClick={async () => {
                          if (!po) return

                          await rejectPurchaseOrder(po.id)
                          await mutate()
                        }}
                        className="w-full !text-red-600 !border-red-600 hover:!text-white "
                        variant={"outlined"}
                      >
                        Reject Purchase Order
                      </Button>
                    </div>
                  ) : (
                    <Button
                      className={clsx(
                        "w-full capitalize",
                        po.status === PurchaseOrderStatus.CONFIRMED &&
                          "!bg-green-600 !text-white",
                        po.status === PurchaseOrderStatus.REJECTED &&
                          "!bg-red-600 !text-white"
                      )}
                      disabled
                    >
                      Purchase Order {po.status.toLowerCase()}
                    </Button>
                  )}
                </div>
                {/* <div className="flex flex-1 items-center justify-center">
                    {estimate.status == "ACCEPTED" ||
                    estimate.status == "INVOICED" ? (
                      <span className="cursor-pointer font-semibold text-orange-400 hover:text-orange-500">
                        {" "}
                      </span>
                    ) : (
                      <span className="cursor-pointer font-semibold text-orange-400 hover:text-orange-500">
                        Request changes
                      </span>
                    )}
                  </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShareablePurchaseOrder
