import useEmployeeMe from "./api/use-employee-me"
import { RolePermissions } from "../lib/models"
import useSubscription from "./api/use-subscription"
import { useCallback } from "react"

// These are permissions that also exist in the subscription plans,
// only this needs to be verified on merchant's subscription.
export const SUBSCRIPTION_RELATED_PERMISSIONS: RolePermissions[] = [
  "inventory:inventory:transferStock",
  "inventory:inventory:receiveStock",
  "inventory:inventory:ingredient",
  "inventory:inventory:inventoryCount",
  "inventory:inventory:adjustStock",
  "pos:kitchenRegister:kitchenRegister",
  "setting:onlineStoreSetting:changeSetting",
  // "inventory:itemMaintenance:manageIngredients",
  "inventory:itemMaintenance:printLabels",
  "inventory:itemMaintenance:manageUnitOfMeasure",
  "inventory:itemMaintenance:managePaymentTerms",
  "inventory:itemMaintenance:managePriceList",
  // "setting:printers:printerSetup",
  // "setting:customerDisplay:manageCustomerDisplay",
  "invoice:setting:invoiceSetting",
  "pos:kitchenDisplay:kitchenDisplay",
  "inventory:inventory:manageUom",
  "pos:customerDisplay:customerDisplay",
  "cashbook:cashbook:manageCashbook",
  "creditbook:creditbook:manageCreditbook",
  "pos:report:viewReceipts",
  "pos:checkout:applyDiscount",
  "pos:checkout:changeTax",
  "pos:checkout:acceptPayment",
  "pos:orderManager:performReturn",
  "pos:openOrders:manageOpenTicket",
  "pos:orderManager:voidOpenTicket",
  "pos:cashRegister:viewShiftReport",
  "pos:cashRegister:openCashDrawer",
  "pos:orderManager:printReceipt",
  "inventory:itemMaintenance:manageItem",
  // "setting:posSetting:changeSetting",
  "getHelp:getHelp:liveChatSupport",
  // "pos:report:viewSalesReport",
  "pos:orderManager:cancelReceipt",
  "inventory:itemMaintenance:manageItemInventory",
  "inventory:itemMaintenance:viewCostItem",
  "hrms:employee:manageEmployee",
  "pos:customer:manageCustomer",
  "setting:general:editGeneralSetting",
  "setting:subscription:manageBilling",
  "setting:payment:managePayment",
  "setting:loyalty:manageLoyalty",
  "setting:tax:manageTax",
  "setting:cashRegister:managePOSDevice",
  "inventory:dashboard:viewDashboard",
  "pos:dashboard:viewDashboard",
  "inventory:report:viewReport",
  "inventory:report:viewAdvancedReport",
  "pos:salesOverview:viewSalesOverview",
  "pos:cashRegister:payInOut",
  "hrms:shift:manageShift",
  "payroll:payroll:viewPayroll",
  "payroll:payroll:managePayroll",
  "hrms:loan:manageLoan",
  "hrms:approval:requestApproval",
  "hrms:approval:manageApproval",
  "inventory:supplier:manageSupplier",
  "invoice:invoice:manageInvoice",
  "hrms:hrms:dashboard",
  "payroll:payroll:dashboard",
  // "hrms:hrms:manageSetting",
  "payroll:payroll:manageSetting",
  "pos:orderManager:kitchenPrinter",
  "inventory:report:viewSalesReport",
  "inventory:report:viewReceipts",
  "pos:cashback:manageCashback",
  "inventory:purchaseOrder:managePurchaseOrder",
  "inventory:xero:xeroIntegration",
  "pos:pos:multiStoreRegister",
  "setting:onlineStoreSetting:changeCustomTheme",
  "setting:b2b:manageSupplierAccount",
  "setting:discount:manageDiscount",
  "accounting:accounting:manageAccounting",
  "invoice:invoice:manageInvoice",
]

// These are permissions that are currently only being used on subscription plans.
export const SUBSCRIPTION_ONLY_PERMISSIONS: RolePermissions[] = [
  "pos:pos:multiStoreRegister",
  "setting:b2b:manageSupplierAccount",
  "accounting:accounting:manageAccounting",
]

const useAuthorizationControl = () => {
  const { data: subscription } = useSubscription()
  const { data: me } = useEmployeeMe()

  const isBusinessOwner = localStorage.getItem("isBusinessOwner")

  const hasSubscriptionPermissions = useCallback(
    (permission: RolePermissions) => {
      const isPartOfSubscription =
        SUBSCRIPTION_RELATED_PERMISSIONS.includes(permission)

      if (isPartOfSubscription && !subscription?.isLegacyPlan) {
        const hasSubscriptionAccess = subscription?.features?.find(
          (f) => f.id === permission
        )
        if (!hasSubscriptionAccess) {
          return false
        }
      }

      return true
    },
    [subscription]
  )

  const hasRolePermission = useCallback(
    (permission: RolePermissions) => {
      const notEmployeePermission =
        SUBSCRIPTION_ONLY_PERMISSIONS.includes(permission)
      if (
        !me?.permission ||
        isBusinessOwner === "true" ||
        notEmployeePermission
      ) {
        return true
      }
      return !!me?.permission.find((p) => p.permission === permission)
    },
    [isBusinessOwner, me?.permission]
  )

  const hasFullPermission = useCallback(
    (permission: RolePermissions) => {
      const isPartOfSubscription =
        SUBSCRIPTION_RELATED_PERMISSIONS.includes(permission)

      if (isPartOfSubscription && !subscription?.isLegacyPlan) {
        const hasSubscriptionAccess = subscription?.features?.find(
          (f) => f.id === permission
        )
        if (!hasSubscriptionAccess) {
          return false
        }
      }

      const notEmployeePermission =
        SUBSCRIPTION_ONLY_PERMISSIONS.includes(permission)
      if (
        !me?.permission ||
        isBusinessOwner === "true" ||
        notEmployeePermission
      ) {
        return true
      }
      return !!me?.permission.find((p) => p.permission === permission)
    },
    [
      isBusinessOwner,
      me?.permission,
      subscription?.features,
      subscription?.isLegacyPlan,
    ]
  )

  const hasFeatureAccess = useCallback(
    (
      feature:
        | "pos"
        | "pos:checkout"
        | "inventory"
        | "setting"
        | "payroll"
        | "hrms"
        | "agriculture"
        | "invoice"
        | "financialReport"
    ) => {
      const isSubscriptionRelated = SUBSCRIPTION_RELATED_PERMISSIONS.find((p) =>
        p.startsWith(feature)
      )

      if (!subscription?.isLegacyPlan && isSubscriptionRelated) {
        const hasSubscriptionAccess = subscription?.features?.find((f) =>
          f.id.startsWith(feature)
        )
        return !!hasSubscriptionAccess
      }

      if (!me?.permission || isBusinessOwner === "true") {
        return true
      }
      return !!me?.permission.find((p) => p.permission.startsWith(feature))
    },
    [
      isBusinessOwner,
      me?.permission,
      subscription?.features,
      subscription?.isLegacyPlan,
    ]
  )

  return {
    hasSubscriptionPermissions,
    /** Only checks employee permissions. * */
    hasRolePermission,
    /** Checks both subscription and role permissions. * */
    hasFullPermission,
    hasFeatureAccess,
  }
}

export default useAuthorizationControl
