/*!
 * imin-printer v2.0.3
 * (c) 2024 archiesong
 * @license MIT
 */
let e
const t = (s) => {
  if ("object" != typeof s) {
    if (t.installed && e === s) return
    ;(t.installed = !0), (e = s)
    const i = (e) => void 0 !== e,
      r = (e, t) => {
        let s = e.$options._parentVnode
        i(s) && i((s = s.data)) && i((s = s.registerPrinterInstance)) && s(e, t)
      }
    return (
      s.mixin({
        beforeCreate() {
          this.print || (this.print = {}),
            i(this.$options.printer)
              ? ((this._printerRoot = this),
                (this._printer = this.$options.printer))
              : (this._printerRoot =
                  (this.$parent && this.$parent._printerRoot) || this),
            r(this, this)
        },
        destroyed() {
          r(this, void 0)
        },
      }),
      void Object.defineProperty(s.prototype, "$printer", {
        get() {
          return this._printerRoot._printer
        },
      })
    )
  }
  const i = (e) => void 0 !== e,
    r = (e, t) => {
      let s = e.$options._parentVnode
      i(s) && i((s = s.data)) && i((s = s.registerPrinterInstance)) && s(e, t)
    }
  s.mixin({
    beforeCreate() {
      this.print || (this.print = {}),
        i(this.$options.printer)
          ? ((this._printerRoot = this),
            (this._printer = this.$options.printer))
          : (this._printerRoot =
              (this.$parent && this.$parent._printerRoot) || this),
        r(this, this)
    },
    destroyed() {
      r(this, void 0)
    },
  })
}
var s, i, r, n
!(function (e) {
  ;(e.USB = "USB"), (e.SPI = "SPI"), (e.Bluetooth = "Bluetooth")
})(s || (s = {})),
  (function (e) {
    ;(e.NORMAL = "0"),
      (e.OPEN = "3"),
      (e.NOPAPERFEED = "7"),
      (e.PAPERRUNNINGOUT = "8"),
      (e.NOTCONNECTED = "-1"),
      (e.NOTPOWEREDON = "1"),
      (e.OTHERERRORS = "99")
  })(i || (i = {})),
  (function (e) {
    ;(e.WEBSOCKET_WS = "ws://"),
      (e.WEBSOCKET_WSS = "wss://"),
      (e.HTTP = "http://"),
      (e.HTTPS = "https://")
  })(r || (r = {})),
  (function (e) {
    ;(e.WEBSOCKET = "/websocket"), (e.HTTP = "/upload")
  })(n || (n = {}))
const a = (e) => JSON.parse(e),
  o = (e) => {
    let t = ""
    t =
      e.split(",")[0].indexOf("base64") >= 0
        ? window.atob(e.split(",")[1])
        : decodeURIComponent(e.split(",")[1])
    const s = e.split(",")[0].split(":")[1].split(";")[0],
      i = new Uint8Array(t.length)
    for (let e = 0; e < t.length; e++) i[e] = t.charCodeAt(e)
    return new window.Blob([i], { type: s })
  },
  h = (e) => {
    let t = "The printer is not connected or powered on"
    switch (e.toString()) {
      case "0":
        t = "The printer is normal"
        break
      case "3":
        t = "Print head open"
        break
      case "7":
        t = "No Paper Feed"
        break
      case "8":
        t = "Paper Running Out"
        break
      case "99":
        t = "Other errors"
        break
      default:
        t = "The printer is not connected or powered on"
    }
    return t
  },
  d = "undefined" != typeof window

class c extends class {
  constructor(e) {
    ;(this.address = e || "127.0.0.1"),
      (this.lock_time = 4e3),
      (this.check_time = 3e3),
      (this.heart_time = 3e3),
      (this.port = 8081),
      (this.isLock = !1),
      (this.callback = () => {}),
      (this.prefix = n.WEBSOCKET),
      (this.protocol = r.WEBSOCKET_WS)
  }

  connect() {
    return new Promise((e, t) => {
      const s = window.MozWebSocket || window.WebSocket
      s ||
        t(
          (function (e, t) {
            if (!e) throw new Error("[imin-printer] " + t)
          })(s, "Browser does not support Websocket!")
        )
      try {
        const i = new s(
          `${this.protocol}${this.address}:${this.port}${this.prefix}`
        )
        ;(i.onopen = (s) => {
          this.heartCheck(), i.readyState === i.OPEN ? e(!0) : t()
        }),
          (i.onclose = (e) => {
            this.reconnect()
          }),
          (i.onerror = () => {
            this.reconnect()
          }),
          (i.onmessage = (e) => {
            "request" === e.data || (a(e.data) && 0 === a(e.data).type)
              ? this.heartCheck()
              : this.callback(a(e.data))
          }),
          (this.ws = i)
      } catch (e) {
        this.reconnect(), t(e)
      }
    })
  }

  sendParameter(e, t, s, i) {
    return ((e) => JSON.stringify(e))({
      data: Object.assign(
        {},
        {
          text: void 0 !== e ? e : "",
          value: void 0 !== s ? s : -1,
        },
        i || {}
      ),
      type: void 0 !== t ? t : 0,
    })
  }

  heartCheck() {
    this.h_timer && clearTimeout(this.h_timer),
      this.c_timer && clearTimeout(this.c_timer),
      (this.h_timer = setTimeout(() => {
        this.send(this.sendParameter("ping")),
          (this.c_timer = setTimeout(() => {
            1 !== this.ws.readyState && this.close()
          }, this.check_time))
      }, this.heart_time))
  }

  reconnect() {
    this.isLock ||
      ((this.isLock = !0),
      this.l_timer && clearTimeout(this.l_timer),
      (this.l_timer = setTimeout(() => {
        this.connect(), (this.isLock = !1)
      }, this.lock_time)))
  }

  send(e) {
    this.ws.send(e)
  }

  close() {
    this.ws && (this.ws.close(), (this.ws = null))
  }
} {
  constructor(e) {
    super(e), (this.connect_type = s.SPI)
  }

  initPrinter(e = s.SPI) {
    ;(this.connect_type = e), this.send(this.sendParameter(e, 1))
  }

  getPrinterStatus(e = s.SPI) {
    return new Promise((t) => {
      ;(this.connect_type = e),
        this.send(this.sendParameter(e, 2)),
        (this.callback = (e) => {
          2 === e.type &&
            t(Object.assign({}, e.data, { text: h(e.data.value) }))
        })
    })
  }

  printAndLineFeed(e) {
    this.send(this.sendParameter("", 3))
  }

  partialCut() {
    this.send(this.sendParameter("", 5))
  }

  printAndFeedPaper(e) {
    this.send(this.sendParameter("", 4, e <= 0 ? 0 : e >= 255 ? 255 : e))
  }

  setAlignment(e) {
    this.send(this.sendParameter("", 6, e <= 0 ? 0 : e >= 2 ? 2 : e))
  }

  setTextSize(e) {
    this.send(this.sendParameter("", 7, e))
  }

  setTextTypeface(e) {
    this.send(this.sendParameter("", 8, e))
  }

  setTextStyle(e) {
    this.send(this.sendParameter("", 9, e <= 0 ? 0 : e >= 3 ? 3 : e))
  }

  setTextLineSpacing(e) {
    this.send(this.sendParameter("", 10, e))
  }

  setTextWidth(e) {
    this.send(this.sendParameter("", 11, e <= 0 ? 0 : e >= 576 ? 576 : e))
  }

  printText(e, t) {
    this.send(
      this.sendParameter(
        void 0 === t || t || "n" !== e.charAt(e.length - 1)
          ? void 0 !== t
            ? e
            : e + "\n"
          : e.slice(0, e.length - 1) + "\n",
        void 0 !== t ? 13 : 12,
        void 0 !== t ? (t <= 0 ? 0 : t >= 1 ? 1 : t) : void 0
      )
    )
  }

  printColumnsText(e, t, s, i, r) {
    this.send(
      this.sendParameter("", 14, r < 0 ? 0 : r > 576 ? 576 : r, {
        colTextArr: e,
        colWidthArr: t,
        colAlign: s.map((e) => (e <= 0 ? 0 : e >= 2 ? 2 : e)),
        size: i,
      })
    )
  }

  setBarCodeWidth(e) {
    this.send(
      this.sendParameter(
        "",
        15,
        void 0 !== e ? (e <= 2 ? 2 : e >= 6 ? 6 : e) : 3
      )
    )
  }

  setBarCodeHeight(e) {
    this.send(this.sendParameter("", 16, e <= 1 ? 1 : e >= 255 ? 255 : e))
  }

  setBarCodeContentPrintPos(e) {
    this.send(this.sendParameter("", 17, e <= 0 ? 0 : e >= 3 ? 3 : e))
  }

  printBarCode(e, t, s) {
    this.send(
      this.sendParameter(
        t,
        void 0 !== s ? 19 : 18,
        Number(e) <= 0
          ? 0
          : Number(e) >= 6 && 73 !== Number(e) && 8 !== Number(e)
            ? 6
            : Number(e),
        void 0 !== s ? { alignmentMode: s <= 0 ? 0 : s >= 2 ? 2 : s } : {}
      )
    )
  }

  setQrCodeSize(e) {
    this.send(this.sendParameter("", 20, e <= 1 ? 1 : e >= 13 ? 13 : e))
  }

  setQrCodeErrorCorrectionLev(e) {
    this.send(this.sendParameter("", 21, e <= 48 ? 48 : e >= 51 ? 51 : e))
  }

  setLeftMargin(e) {
    this.send(this.sendParameter("", 22, e <= 0 ? 0 : e >= 576 ? 576 : e))
  }

  printQrCode(e, t) {
    this.send(
      this.sendParameter(
        e,
        void 0 !== t ? 24 : 23,
        void 0 !== t ? (t <= 0 ? 0 : t >= 2 ? 2 : t) : void 0
      )
    )
  }

  setPageFormat(e) {
    this.send(this.sendParameter("", 25, e >= 1 ? 1 : 0))
  }

  openCashBox() {
    this.send(this.sendParameter("", 100))
  }

  printSingleBitmap(e, t) {
    return new Promise((s) => {
      if ("[object Object]" !== Object.prototype.toString.call(e)) {
        const s = new window.Image()
        ;/^\s*data:([a-z]+\/[a-z0-9-+.]+(;[a-z-]+=[a-z0-9-]+)?)?(;base64)?,([a-z0-9!$&',()*+;=\-._~:@\/?%\s]*?)\s*$/i.test(
          e
        )
          ? (s.src = e)
          : ((s.crossOrigin = "*"), (s.src = `${e}?v=${new Date().getTime()}`)),
          (s.onload = () => {
            const i = new window.FormData()
            i.append(
              "file",
              o(
                ((e, t) => {
                  const s = document.createElement("canvas"),
                    i = s.getContext("2d"),
                    r = e.width,
                    n = e.height
                  return (
                    (s.width = r),
                    (s.height = n),
                    i.clearRect(0, 0, r, n),
                    i.drawImage(e, 0, 0, r, n),
                    s.toDataURL(t || "image/png")
                  )
                })(s, o(e).type)
              )
            )
            let a = null
            ;(a = window.XMLHttpRequest
              ? new XMLHttpRequest()
              : window.ActiveXObject
                ? new window.ActiveXObject("Microsoft.XMLHTTP")
                : null),
              a &&
                (a.open(
                  "POST",
                  `${r.HTTP}${this.address}:${this.port}${n.HTTP}`
                ),
                (a.onreadystatechange = () => {
                  if (4 === a.readyState && 200 === a.status) {
                    a.responseText &&
                      this.send(
                        this.sendParameter(
                          "",
                          void 0 !== t ? 27 : 26,
                          void 0 !== t ? t : void 0
                        )
                      ),
                      (a = null)
                  }
                }),
                a.send(i))
          })
      } else {
        var i = new window.FormData()
        i.append("file", e)
        var a = null
        ;(a = window.XMLHttpRequest
          ? new XMLHttpRequest()
          : window.ActiveXObject
            ? new window.ActiveXObject("Microsoft.XMLHTTP")
            : null) &&
          (a.open("POST", `${r.HTTP}${this.address}:${this.port}${n.HTTP}`),
          (a.onreadystatechange = () => {
            if (4 === a.readyState && 200 === a.status) {
              a.responseText &&
                this.send(
                  this.sendParameter(
                    "",
                    void 0 !== t ? 27 : 26,
                    void 0 !== t ? t : void 0
                  )
                ),
                (a = null)
            }
          }),
          a.send(i))
      }
      this.callback = (e) => {
        ;[27, 26].includes(e.type) && s()
      }
    })
  }

  setDoubleQRSize(e) {
    this.send(this.sendParameter("", 28, e))
  }

  setDoubleQR1Level(e) {
    this.send(this.sendParameter("", 29, e <= 1 ? 1 : e >= 3 ? 3 : e))
  }

  setDoubleQR2Level(e) {
    this.send(this.sendParameter("", 30, e <= 1 ? 1 : e >= 3 ? 3 : e))
  }

  setDoubleQR1MarginLeft(e) {
    this.send(this.sendParameter("", 31, e <= 0 ? 0 : e >= 200 ? 200 : e))
  }

  setDoubleQR2MarginLeft(e) {
    this.send(this.sendParameter("", 32, e <= 0 ? 0 : e >= 200 ? 200 : e))
  }

  setDoubleQR1Version(e) {
    this.send(this.sendParameter("", 33, e <= 0 ? 0 : e >= 40 ? 40 : e))
  }

  setDoubleQR2Version(e) {
    this.send(this.sendParameter("", 34, e <= 0 ? 0 : e >= 40 ? 40 : e))
  }

  printDoubleQR(e) {
    this.send(this.sendParameter("", 35, void 0, { colTextArr: e }))
  }

  electronicScaleRemovePeel() {
    this.send(this.sendParameter("", 1001))
  }

  electronicScaleTurnZero() {
    this.send(this.sendParameter("", 1002))
  }

  electronicScaleGetWeight(e) {
    this.send(this.sendParameter("", 1003)),
      (this.callback = (t) => {
        1003 === t.type && e && e(Object.assign({}, t.data))
      })
  }

  closeElectronicScale() {
    this.send(this.sendParameter("", 1004))
  }

  getPrinterPaperDistance() {
    return new Promise((e) => {
      this.send(this.sendParameter("", 90)),
        (this.callback = (t) => {
          90 === t.type && e(Object.assign({}, t.data))
        })
    })
  }

  getPrinterCutTimes() {
    return new Promise((e) => {
      this.send(this.sendParameter("", 91)),
        (this.callback = (t) => {
          91 === t.type && e(Object.assign({}, t.data))
        })
    })
  }

  partialCutPaper() {
    this.send(this.sendParameter("", 36))
  }
}

;(c.install = t), (c.version = "2.0.3"), d && window.Vue && window.Vue.use(c)
export { c as default }
