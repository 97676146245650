import { FC, useEffect } from "react"
import { Redirect } from "react-router-dom"
import { useSWRConfig } from "swr"
import { AxiosError } from "axios"
import * as Sentry from "@sentry/react"
import Button from "./Button"
import { useIntercom } from "react-use-intercom"

const TopLevelErrorBoundary: FC = ({ children }) => {
  const { mutate } = useSWRConfig()
  return (
    <Sentry.ErrorBoundary
      fallback={FallbackComponent}
      onError={async (error) => {
        if (!(error instanceof AxiosError)) {
          await mutate(() => true, undefined, false)
        }
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}

const FallbackComponent = ({
  resetError,
  error,
}: {
  error: unknown
  componentStack: string
  eventId: string
  resetError(): void
}) => {
  console.log(error)

  useEffect(() => {
    if (error === "No current user") {
      resetError()
    }
  }, [error, resetError])

  if (error === "No current user") {
    return <Redirect to={"/"} />
  }

  // TODO: show generic error page
  return (
    <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <p className="text-xl font-semibold text-primary-900">500</p>
        <h1 className="mt-4 text-2xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          Oops, something went wrong
        </h1>
        <p className="mt-6 text-base leading-7 text-gray-600">
          Sorry, looks like something went wrong. Please reload the page.
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <Button onClick={() => window.location.reload()}>
            Reload this page
          </Button>
        </div>
      </div>
    </main>
  )
}

export default TopLevelErrorBoundary
