import { patchAPI, postAPI, getAPI, uploadFile, deleteAPI } from "./api"
import axios from "axios"

export const patchBusiness = (
  id: string,
  data: Partial<{
    affiliateCode?: string
    onlyCashPayment?: boolean
    isAttendancePresentByDefault?: boolean
    isFaceRecognitionEnable?: boolean
    isGPSLocationTrackingEnable?: boolean
    isQRcodeEnabled?: boolean
    workingDays?: number | null
    weekWorkDays?: number
    salaryCycle?: string
    multiWalletEnabled?: boolean
  }>
) => {
  return patchAPI(`/businesses`, id, data)
}

export const createBusinessProfileImageUploadURL = async (
  businessId: string
) => {
  return postAPI<{
    url: string
    uploadURL: string
  }>(`/businesses/logo?ext=jpg&businessId=${businessId}`)
}

export const uploadBusinessProfileImage = async (
  file: File,
  businessId: string
) => {
  const uploadURL = await createBusinessProfileImageUploadURL(businessId)
  //console.log('uploadURL>>',uploadURL)
  const result = await axios.put<{ url: string }>(
    uploadURL.data.uploadURL,
    file,
    {
      headers: {
        "Content-Type": file.type,
      },
    }
  )

  if (result.status !== 200) {
    throw new Error("Upload failed")
  }

  return { url: uploadURL.data.url }
}

export const uploadBusinessLogo = async (file: File, businessId: string) => {
  return uploadFile<{ url: string; uploadUrl: string; key: string }>(
    `/businesses/logo?ext=jpg&businessId=${businessId}`,
    file,
    {
      headers: {
        "Content-Type": file.type,
      },
    }
  )
}

export const deleteLocation = async (id: string) => {
  return deleteAPI("/locations", id)
}
