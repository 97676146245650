import { FC, useState } from "react"
import { useShowSnackbar } from "./Snackbar"
import { subscribeWithGcash } from "../lib/subscriptions"
import { t } from "i18next"
import clsx from "clsx"

const BuxSubscribeButton: FC<{
  planId: string
  className?: string
}> = ({ planId, className }) => {
  const showSnackBar = useShowSnackbar()
  const [loading, setLoading] = useState(false)

  const handleSubscribeGCash = async () => {
    setLoading(true)
    try {
      const result = await subscribeWithGcash(planId)
      if (result.data.paymentUrl) {
        window.location.href = result.data.paymentUrl
      }
    } catch (e: any) {
      if (e.response?.data?.message) {
        showSnackBar(e.response.data.message, "error")
      } else {
        showSnackBar(
          t("Oops! Something went wrong. Please try again."),
          "error"
        )
      }
      setLoading(false)
    }
  }

  return (
    <button
      className={clsx(
        "w-xl mx-0 mt-1 block w-full rounded-xl border border-solid border-primary-800 bg-primary-800 py-3 text-center text-sm font-semibold text-white no-underline hover:bg-primary-900 disabled:cursor-not-allowed disabled:opacity-50",
        className
      )}
      onClick={handleSubscribeGCash}
      disabled={loading}
    >
      {/* {loading ? t("Loading...") : t("Checkout with GCash")} */}
      {loading ? t("Loading...") : t("Subscribe")}
    </button>
  )
}

export default BuxSubscribeButton
