import useAPI from "./use-api"
import useQueryString from "../use-query-string"

enum DefaultPaymentMethodOptions {
  STRIPE = "stripe",
  PAYPAL = "paypal",
  BUX = "bux",
  IPAY = "ipay",
  DIGLOG_PAYMENT = "diglogPayment",
}

export type GetPaymentMethodResponse = {
  id: string
  createdAt: string
  updatedAt: string
  stripe: {
    id: string
    status: "PENDING" | "VERIFIED" | "FAILED_VERIFICATION"
    stripeAccountId: string
    updatedAt: string
    createdAt: string
  }
  paypal: any
  ipay?: {
    country: "ID" | "MY"
    merchantKeyOTP: string
    merchantCodeOTP: string
    merchantKeyQR: string
    merchantCodeQR: string
    merchantKeyEstore: string
    merchantCodeEstore: string
  }
  diglogPayment?: {
    registeredCompanyName?: string
    companyRegistrationNumber?: string
    monthlyRevenue?: string
    businessDocumentUrl?: string
    bankAccountNumber?: string
    bankName?: string
    bankSwiftCode?: string
    bankRoutingNumber?: string
    bankBranchCode?: string
    isEnabled?: boolean
    paymentAccountMethods?: Array<{
      id: string
      externalId: string
      paymentGatewayType: string
      status: string
      isEnabled: boolean
      createdAt: string
      updatedAt: string
    }>
    paymentDetails: Array<{
      walletName: string
      email: string
      phone: string
      accountNumber: string
    }> | null
  }
  bux?: {
    email: string
  }

  defaultPOSPayment: DefaultPaymentMethodOptions
  defaultEstorePayment: DefaultPaymentMethodOptions

  customPaymentMethod?: Array<CustomPaymentMethod>
}

export type CustomPaymentMethod = {
  key: string
  value: string
  isEnabled: boolean
  locations: string[]
}

const usePaymentMethod = () => {
  // query strings given by PayPal on redirection after merchant on-boarding
  const merchantIdInPayPal = useQueryString("merchantIdInPayPal")
  const merchantId = useQueryString("merchantId")

  return useAPI<GetPaymentMethodResponse>("/businesses/payment-method", {
    merchantId: merchantIdInPayPal || undefined,
    trackingId: merchantId || undefined,
  })
}

export default usePaymentMethod
