import useSWR from "swr"
import { getPublicAPI } from "../../lib/api"

interface PublicReceiptDetails {
  settings: Settings
  cartTransaction: CartTransaction
  business: Business
}

interface Settings {
  showBarcode: string
  showOrderNote: string
  showCustomerInfo: string
  showLocation: string
  showPhone: string
  message: string
  taxPIN?: string
}

interface CartTransaction {
  orderedProducts: OrderedProduct[]
  location: Location
  orderNumber: number
  createdAt: string
  discount: number
  tax: number
  outstanding: number
  serviceCharge: number
  shippingCost: number
  grandTotal: number
  buxPaymentStatus: "PAID" | "FAILED" | "PENDING"
  refund: {
    refundItem: any[]
  }
  createdBy: string
  customer: {
    email: string
    phone: string
    customerLocations: CustomerLocation[]
  }
  taxes: any[]
  orderType: string
}

export interface CustomerLocation {
  isPrimaryLocation: boolean
  addressLine1: string
  city: string
  province: string
  zip: string
}

export interface OrderedProduct {
  id: string
  created_at: string
  updated_at: string
  cartTransactionsId: string
  productId: string
  totalPrice: number
  quantity: number
  discount: number
  orderDate: string
  businessId: string
  purchaseOrderId: any
  name: string
  SKU: string
  sellingPrice: number
  unitOfMeasure: string
  productType: string
  attachment: string

  downloadCount: number
  orderedProductAddOns: any[]
  productOptionValues: any[]
  quantityReturned: number
}

interface Location {
  city: string
  state: string
  zip: string
  addressLine1: string
  addressLine2: string
}

interface Business {
  name: string
  logoUrl: string
  phone: string
  businessCurrency: string
}

const useReceiptPublic = (id: string) => {
  return useSWR<PublicReceiptDetails>(
    `/cartTransaction/receipt/${id}`,
    getPublicAPI
  )
}

export default useReceiptPublic
