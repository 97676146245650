import React, { FormEventHandler, useState } from "react"
import { Redirect, useHistory, useLocation } from "react-router-dom"
import "./index.css"
import Square_Blue_WhiteLogo from "../../../assets/loginPage/Square_Blue&WhiteLoo.png"
import Button from "../../../components/Button"
import { Auth } from "@aws-amplify/auth"
import { login } from "../../../utils/authProvider"
import { getCurrentSubscription } from "../../../lib/subscriptions"
import { t } from "i18next"
import { Trans } from "react-i18next"
import useToggle from "../../../hooks/use-toggle"
import IconButton from "../../../components/IconButton"
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material"
import useClearAllCache from "../../../hooks/use-clear-all-cache"
import Input from "../../../components/Input"
import { HOMEPAGE_URL } from "../../../lib/models"

function NewPassword(): React.ReactElement {
  const location = useLocation<{ username: string; password: string }>()
  const history = useHistory()
  const clearAllCache = useClearAllCache()
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [error, setError] = useState("")
  const [showPassword, toggleShowPassword] = useToggle()
  const [showConfirmPassword, toggleShowConfirmPassword] = useToggle()

  const resetPassword: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault()
    try {
      const user = await login(location.state.username, location.state.password)
      await Auth.completeNewPassword(user, password)
      const res: any = await login(location.state.username, password)
      if (res.attributes["custom:businessId"]) {
        await clearAllCache()
        const currentSub = await getCurrentSubscription()
        if (currentSub?.status === "ACTIVE") {
          history.push(HOMEPAGE_URL)
        } else {
          history.push("/subscriptions")
        }
      }
    } catch (e: any) {
      console.log(e)
      setError(e.message)
    }
  }

  if (!location.state) {
    return <Redirect to={"/"} />
  }

  return (
    <div className="flex w-[80%] flex-col items-center justify-center md:w-[45rem] md:flex-row">
      <div className="flex w-full flex-col items-center justify-center rounded-t-3xl bg-primary-900 p-6 md:h-full md:w-1/2 md:rounded-l-3xl md:rounded-tr-none">
        <img
          src={Square_Blue_WhiteLogo}
          className="h-56 w-auto"
          alt="dizlog logo"
        />
      </div>
      <div className="flex w-full flex-col items-center justify-center gap-4 rounded-b-3xl bg-white p-6 md:w-1/2 md:rounded-r-3xl md:rounded-bl-none">
        <h1 className={"text-2xl"}>
          <Trans>Set New Password</Trans>
        </h1>
        <p className={"text-center text-sm text-gray-500"}>
          <Trans>
            Welcome to DizLog. Please choose a new password to replace your
            temporary password.
          </Trans>
        </p>

        <form
          onSubmit={resetPassword}
          className={"mt-0 box-border flex w-full flex-col gap-4"}
        >
          <Input
            required
            value={password}
            type={showPassword ? "text" : "password"}
            onChange={(event) => setPassword(event.target.value)}
            placeholder={t("Password")}
            label={t("New Password")}
            trailingElement={
              <IconButton
                onClick={toggleShowPassword}
                onMouseDown={toggleShowPassword}
              >
                {showPassword ? (
                  <VisibilityOffOutlined />
                ) : (
                  <VisibilityOutlined />
                )}
              </IconButton>
            }
          />
          <Input
            required
            value={confirmPassword}
            type={showConfirmPassword ? "text" : "password"}
            onChange={(event) => setConfirmPassword(event.target.value)}
            placeholder={t("Confirm Password")}
            label={t("Confirm New Password")}
            trailingElement={
              <IconButton
                onClick={toggleShowConfirmPassword}
                onMouseDown={toggleShowConfirmPassword}
              >
                {showConfirmPassword ? (
                  <VisibilityOffOutlined />
                ) : (
                  <VisibilityOutlined />
                )}
              </IconButton>
            }
          />
          {error && <div className="italic text-red-300">{error}</div>}
          <Button type="submit">
            <Trans>Submit</Trans>
          </Button>
          <Button onClick={() => history.push("/")} variant="text">
            <Trans>Back to Login</Trans>
          </Button>
        </form>
      </div>
    </div>
  )
}

export default NewPassword
