import useAPI from "./use-api"

export type XReportResponse = Array<{
  openedAt: string
  closedAt: string
  tenderBreakdown: Array<{
    paymentMethodCode: string
    sum: number
  }>
  beginningOR: string
  endingOR: string
  openingFund: number
  paymentReceived: number
  withdrawal: null | number
  lessWithdrawal: null | number
  voidAmount: number
  refundAmount: null | number
  openedBy: string
  cashRegisterLog: any
}>

const useXReport = (cashRegisterId: string, logId: string) => {
  const swr = useAPI<XReportResponse>(
    `/cashRegister/${cashRegisterId}/x-report`,
    { logId }
  )

  return {
    ...swr,
    data: swr.data?.[0],
  }
}

export default useXReport
