import CloseIcon from "@mui/icons-material/Close"
import React, { FC, ReactElement } from "react"
import { Modal as MuiModal } from "@mui/material"
import clsx from "clsx"
import ToolTipComponent from "./ToolTipComponent"

const Modal: FC<{
  open?: boolean
  onClose: () => void
  title?: string | ReactElement
  className?: string
  titleClassName?: string
  toolTipContent?: any
  toolTipPosition?: any
  hideCloseButton?: boolean
  overrideWidth?: any
  customTitle?: ReactElement
}> = ({
  onClose,
  className,
  open = true,
  title,
  children,
  titleClassName,
  hideCloseButton,
  toolTipContent,
  toolTipPosition,
  overrideWidth,
  customTitle,
}) => (
  <MuiModal
    open={open}
    onClose={() => onClose()}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    className={"flex h-full w-full items-center justify-center bg-black/60"}
  >
    <div
      className={clsx(
        className,
        `${
          overrideWidth ? overrideWidth : `max-w-2xl`
        } rounded-2xl bg-white p-8 shadow-xl  max-h-[calc(100vh-16px)] overflow-auto`
      )}
    >
      {customTitle ? (
        customTitle
      ) : (
        <div className={clsx(titleClassName, "flex items-center")}>
          {/* {title && !toolTipContent && (
          <h2 className={"mr-8 text-2xl font-bold text-primary-900"}>
            {title}
          </h2>
        )} */}

          <div className="flex items-center justify-left w-full">
            <h2
              className={
                "mr-2 cursor-pointer text-2xl font-bold text-primary-900 truncate"
              }
            >
              {title}
            </h2>
            {toolTipContent && (
              <ToolTipComponent
                toolTipContent={toolTipContent}
                toolTipPosition={toolTipPosition}
              />
            )}
          </div>

          {!hideCloseButton && (
            <button
              className="ml-auto flex w-auto items-center rounded-full border-solid border-black p-1 "
              onClick={onClose}
            >
              <CloseIcon className={"!h-4 !w-4"} />
            </button>
          )}
        </div>
      )}

      {children}
    </div>
  </MuiModal>
)

export default Modal
