import { deleteAPI, getAPI, patchAPI, postAPI, postPublicAPI } from "./api"
import {
  InvoiceReminderSettings,
  InvoiceSettings,
  Tax,
  TERM_TYPE,
} from "./models"
import { nanoid } from "nanoid"
import { combine } from "zustand/middleware"
import { v4 } from "uuid"
import Big from "big.js"
import { InstallmentDetail } from "../hooks/api/use-invoice-details"
import axios from "axios"

export const createInvoicePayPalOrder = (invoiceId: string) => {
  return postPublicAPI<{
    orderId: string
  }>(`/invoice/${invoiceId}/pay/paypal-checkout`)
}

export const capturePayPalPayment = (invoiceId: string, orderId: string) => {
  return postPublicAPI<{
    orderId: string
  }>(`/invoice/${invoiceId}/pay/paypal-capture/${orderId}`)
}

export const DEFAULT_INVOICE_SETTINGS = {
  message: "Thank You.",
  showBarcode: "Footer",
  showDueDate: "Disabled",
  showMessage: "Footer",
  showCustomerInfo: "Header",
  showLocation: "Header",
  showOrderNote: "Header",
  disclaimer: "",
  showDisclaimer: "Footer",
  showPhone: "Header",
  width: 57,
  showTaxPIN: false,
  taxPIN: "",
  transactionType: "Disabled",
  storeName: "Disabled",
  showSKU: false,
  showBusinessHours: false,
  showLogo: false,
  printLogo: undefined as string | undefined,
  showOrderType: "Header",
  showReferenceNumber: false,
}

export const postNewInvoiceSettings = (
  invoice: Omit<InvoiceSettings, "id">
) => {
  return postAPI<{
    id: string
    message: string
    showBarcode: string
    showCustomerInfo: string
    showLocation: string
    showOrderNote: string
    showPhone: string
    location: string
    taxPIN: string
    showTaxPIN: any
    transactionType: string
    storeName: string
    showDueDate: string
  }>("/invoice-setting", invoice)
}

export const patchInvoiceSettings = (
  id: string,
  body: Partial<Omit<InvoiceSettings, "id">>
) => {
  return patchAPI<{
    id: string
    message: string
    showBarcode: string
    showCustomerInfo: string
    showLocation: string
    showOrderNote: string
    showPhone: string
    location: string
    taxPIN: string
    showTaxPIN: any
    transactionType: string
    storeName: string
    showDueDate: string
  }>(`/invoice-setting`, id, body)
}

export const postSendEmailReceipt = (id: string, email: string) => {
  return postAPI(`/cartTransaction/send-email-receipt/${id}`, { to: email })
}

export const postNewInvoice = (data: {
  invoiceNumber: number
  customer?: string | null
  shippingCost: number
  otherCost: number
  otherCostDetails: string
  status: string
  products: Array<{
    product: string // product id
    quantity: number
    // quantityReceived: number
    // discount: number
    description?: string
    productVariant?: string
  }>
  referenceNumber: string
  currency: string
  termsAndCondition: string
  memo: string
  fixedDiscount: number
  percentageDiscount: number
  paymentMethod?: string
  termType?: string
  dueDate?: Date
  invoiceDate?: Date
  notes?: string
  tax: number
  location: string
  cartTransaction?: string
  taxes?: Array<Tax>
  taxDetails?: Array<{
    name: string
    rate: number
    amount?: number
    type: string
  }>
  project?: string
  installmentDetail?: {
    frequency?: string
    term?: number
    description?: string
    amount?: number
  }
  employee?: {
    id: string
  } | null
}) => {
  return postAPI<{ id: string }>("/invoice", data)
}

export const getInvoiceNumber = () => {
  return getAPI<{
    invoiceNumber: number
  }>("/invoice/invoice-number")
}

export const deleteInvoice = (id: string) => {
  return deleteAPI(`/invoice`, id)
}

export const uploadInvoiceFile = async (file: File) => {
  const ext = file.name.split(".").pop() || "png"

  const uploadURL = await postAPI<{
    key: string
    url: string
    uploadURL: string
  }>(`/invoice/upload-attachment?ext=${ext}`)

  const result = await axios.put<{ url: string }>(
    uploadURL.data.uploadURL,
    file,
    {
      headers: { "Content-Type": file.type },
    }
  )

  if (result.status !== 200) {
    throw new Error("Upload failed")
  }

  return {
    url: uploadURL.data.url,
    key: uploadURL.data.key,
  }
}

export const patchInvoice = (
  id: string,
  data: Partial<{
    invoiceNumber: number
    customer: string | null
    shippingCost: number
    otherCost: number
    otherCostDetails: string
    status: string
    receiptUrl?: string
    products: Array<{
      id?: string
      product: string
      sellingPrice: number
      quantity: number
      // quantityReceived: number
      // discount: number
      description?: string
      productVariant?: string
    }>
    referenceNumber: string
    currency: string
    termsAndCondition: string
    memo: string
    fixedDiscount: number
    percentageDiscount: number
    paymentMethod?: string
    termType?: string
    dueDate?: Date
    invoiceDate?: Date
    notes?: string
    tax: number
    taxDetails?: Array<{
      name: string
      rate: number
      amount?: number
      type: string
    }>
    location: string
    amountReceived?: number
    lastPaymentDate?: Date
    taxes?: Array<Tax>
    project?: string
    installmentDetail?: InstallmentDetail
    employee?: {
      id: string
    } | null
  }>
) => {
  return patchAPI<{ id: string }>("/invoice", id, data)
}

const baseUrl =
  process.env.NODE_ENV === "production"
    ? `https://${window.location.hostname}`
    : `http://${window.location.hostname}:${window.location.port}`

export const generateInvoiceShareableLink = (invoiceId: string) => {
  return `${baseUrl}/share/invoice/${invoiceId}`
}

const INVOICE_FORM_DEFAULT_STATE = {
  invoiceNumber: 1,
  shippingCost: 0,
  otherCost: 0,
  otherCostDetails: "",
  notes: "",
  currency: "USD",
  memo: "",
  termsAndCondition: "",
  referenceNumber: "",
  discount: undefined as
    | {
        type: "PERCENTAGE" | "FIXED"
        amount: number
      }
    | undefined,
  invoiceDate: new Date(),
  customer: null as null | undefined | string,
  termType: TERM_TYPE.ON_RECEIPT,
  items: [
    {
      id: undefined as string | undefined,
      tempId: nanoid(),
      product: {
        id: "",
        name: "",
        productVariantId: undefined as string | undefined,
      },
      sellingPrice: 0,
      quantity: 1,
    },
  ],
  dueDate: new Date(),
  taxes: [] as Array<Tax & { amount?: number }>,
  location: "",
  project: "",
  installmentDetail: undefined as undefined | InstallmentDetail,
  employee: null as null | undefined | string,
}

export const createInvoiceFormState = combine(
  INVOICE_FORM_DEFAULT_STATE,
  (set, get) => ({
    setInvoiceNumber: (value: number) => set({ invoiceNumber: value }),
    setShippingCost: (value: number) => set({ shippingCost: value }),
    setOtherCost: (value: number) => set({ otherCost: value }),
    setNotes: (value: string) => set({ notes: value }),
    setMemo: (value: string) => set({ memo: value }),
    setTermsAndCondition: (value: string) => set({ termsAndCondition: value }),
    setReferenceNumber: (value: string) => set({ referenceNumber: value }),
    setDiscount: (
      value: undefined | { type: "PERCENTAGE" | "FIXED"; amount: number }
    ) => set({ discount: value }),
    setCustomer: (value?: string | null) => set({ customer: value }),
    setEmployee: (value?: string | null) => set({ employee: value }),
    setInvoiceDate: (value: Date) => set({ invoiceDate: value }),
    setTermType: (termType: TERM_TYPE) => set({ termType: termType }),
    setOtherCostDetails: (value: string) => set({ otherCostDetails: value }),
    setDueDate: (value: Date) => set({ dueDate: value }),
    setCurrency: (value: string) => set({ currency: value }),
    setTax: (value: Array<Tax & { amount?: number }>) => set({ taxes: value }),
    setLocation: (value: string) => {
      set({
        location: value,
      })
    },
    setProject: (value: string) => {
      set({
        project: value,
      })
    },
    addItem: () => {
      const items = get().items
      set({
        items: [
          ...items,
          {
            id: undefined,
            tempId: nanoid(), // ID only used by react
            product: { id: "", name: "", productVariantId: undefined },
            sellingPrice: 0,
            quantity: 1,
          },
        ],
      })
    },
    removeItem: (id: string) => {
      const items = get().items
      set({ items: items.filter((item) => item.tempId !== id) })
    },
    setItemQuantity: (id: string, value: number) => {
      const items = get().items
      const item = items.find((item) => item.tempId === id)
      if (item) {
        item.quantity = value
        set({ items })
      }
    },
    setItemPrice: (id: string, value: number) => {
      const items = get().items
      const item = items.find((item) => item.tempId === id)
      if (item) {
        item.sellingPrice = value
        set({ items })
      }
    },
    setItemProduct: (
      id: string,
      product: {
        id: string
        name: string
        sellingPrice: number
        productVariantId: string | undefined
      }
    ) => {
      const items = get().items
      set({
        items: items.map((item) => {
          if (item.tempId === id) {
            return { ...item, sellingPrice: product.sellingPrice, product }
          }
          return item
        }),
      })
    },
    calculateTotalPrice: () => {
      let subtotal = 0
      const { discount, items, shippingCost, otherCost, taxes } = get()
      items.forEach((item) => {
        subtotal += item.quantity * item.sellingPrice
      })

      if (discount) {
        if (discount.type === "PERCENTAGE") {
          subtotal = subtotal - (subtotal * discount.amount) / 100
        } else {
          subtotal = subtotal - discount.amount
        }
      }

      let totalWithoutTax = subtotal + shippingCost + otherCost
      // let totalTax = (totalWithoutTax * (tax?.rate || 0)) / 100
      let totalTax = 0
      taxes.forEach((tax) => {
        if (tax.type === "ADDED") {
          totalTax += tax.amount || 0
        }
      })
      return subtotal + shippingCost + otherCost + totalTax
      // return subtotal + shippingCost + otherCost + tax
    },
    calculateSubTotal: () => {
      let subTotal = Big(0)
      const { items } = get()
      items.forEach((item) => {
        subTotal = subTotal.add(Big(item.quantity).mul(item.sellingPrice))
      })
      return subTotal.round(2).toNumber()
    },
    calculateTotalTax: () => {
      const { taxes } = get()

      let totalTax = 0
      taxes.forEach((tax) => {
        totalTax += tax.amount || 0
      })

      return totalTax
    },
    clear: () => set(INVOICE_FORM_DEFAULT_STATE),
    setState: (state: typeof INVOICE_FORM_DEFAULT_STATE) => set(state),
    setDeductionFrequency: (value: string) =>
      set({
        installmentDetail: {
          ...get().installmentDetail,
          frequency: value,
        },
      }),
    setLoanTerm: (value: number) =>
      set({
        installmentDetail: {
          ...get().installmentDetail,
          term: value,
        },
      }),
    setLoanDescription: (value: string) =>
      set({
        installmentDetail: {
          ...get().installmentDetail,
          description: value,
        },
      }),
  })
)

export const putInvoiceReminderSettings = (
  id: string,
  data: Partial<Omit<InvoiceReminderSettings, "id">>
) => {
  return patchAPI<{ id: string }>("/invoice-reminder-setting", id || v4(), data)
}
