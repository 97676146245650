/**
 * @deprecated please use the swr based use-api.ts and the other api hooks such
 * as use-products.ts instead of manually calling API using useEffect.
 *
 * useApi provides global caching, deduplication, automatic retries, automatic
 * revalidation, offline caching, and more. For more info see {@link https://swr.vercel.app/ | SWR docs}.
 *
 * @see useAPI
 * @see useProducts
 * */

import axios, { AxiosResponse } from "axios"
import authProvider from "./authProvider"
//import { config } from '../config';
import { BACKEND_API, BUX_API } from "../constants/Config"
import { Cart, Customer, PRODUCT_TYPE } from "../lib/models"
import { uuidv4 } from "@firebase/util"
import {
  calculateItemDiscountAmount,
  cartSummarySelector,
  cartTypeSelector,
} from "../lib/cart"
import { createServiceAppointment } from "../lib/service"
import { GetSinglePromotionResponse } from "../hooks/api/use-single-promotion"
import { PostCheckoutResponseBody } from "../hooks/api/use-checkout"
import moment from "moment"

async function checkEmailIFExist(
  params: Record<string, unknown>
): Promise<AxiosResponse> {
  // const token = await authProvider.getToken()
  const request = await axios.post(
    `${BACKEND_API}/auth/is-username-unique`,
    params
  )
  return request
}

async function forgotPassword(
  params: Record<string, unknown>
): Promise<AxiosResponse> {
  // const token = await authProvider.getToken()
  const request = await axios.post(
    `${BACKEND_API}/auth/forgot-password`,
    params
  )
  return request
}

async function forgotPasswordSubmit(
  params: Record<string, unknown>
): Promise<AxiosResponse> {
  // const token = await authProvider.getToken()
  const request = await axios.post(
    `${BACKEND_API}/auth/confirm-forgot-password`,
    params
  )
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
const getAppointment = async (
  params: Record<string, unknown>,
  additionalQuery?: string
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.get(
    `${BACKEND_API}/appointment${additionalQuery || ""}`,
    {
      headers: { id_token: token },
      params,
    }
  )
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
const getDeductionSummary = async (
  params: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.get(
    `${BACKEND_API}/businesses/reportDeductionSummary`,
    {
      headers: { id_token: token },
      params,
    }
  )
  return request
}

/**
 * @deprecated use use-cart-transaction.ts (plural, to get many transactions) instead.
 *
 * @see useCartTransactions
 * */
const getCartTransaction = async (
  params: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const finalParams = params || {}
  if (!finalParams?.startDate) {
    finalParams.startDate = moment().format("yyyy-MM-DDT00:00:00")
  }
  const request = await axios.get(`${BACKEND_API}/cartTransaction`, {
    headers: { id_token: token },
    params: finalParams,
  })
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
const getCartTransactionAllOrders = async (
  params: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.get(`${BACKEND_API}/cartTransaction/all-orders`, {
    headers: { id_token: token },
    params,
  })
  return request
}

/**
 * @deprecated use use-cart-transaction.ts (singular, to get one transaction) instead.
 *
 * @see useCartTransaction
 * */
const getSingleCartTransaction = async (
  id: string,
  params?: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.get(`${BACKEND_API}/cartTransaction/${id}`, {
    headers: { id_token: token },
    params,
  })
  return request
}

const updateCartTransactionPaymentStatus = async (
  id: string,
  params: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.patch(
    `${BACKEND_API}/cartTransaction/${id}`,
    params,
    {
      headers: { id_token: token },
    }
  )
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
const getPayrun = async (
  params: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.get(`${BACKEND_API}/businesses/payrun`, {
    headers: { id_token: token },
    params,
  })
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
const getPayslips = async (
  params: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.get(`${BACKEND_API}/pay`, {
    headers: { id_token: token },
    params,
  })
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
const getEmployeePaySummary = async (
  params: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.get(
    `${BACKEND_API}/businesses/reportPaymentSummary`,
    {
      headers: { id_token: token },
      params,
    }
  )
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
const getEmployeeLoan = async (
  params?: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.get(`${BACKEND_API}/loan`, {
    headers: { id_token: token },
    params,
  })
  return request
}

async function createEmployeeLoan(
  params: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.post(`${BACKEND_API}/loan`, params, {
    headers: { id_token: token },
  })
  return request
}

async function updateEmployeeLoan(
  id: any,
  params: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.patch(`${BACKEND_API}/loan/${id}`, params, {
    headers: { id_token: token },
  })
  return request
}

async function deleteEmployeeLoan(id: string): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.delete(`${BACKEND_API}/loan/${id}`, {
    headers: { id_token: token },
  })
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
const getApprovedClaim = async (
  params: Record<string, unknown>,
  additionalQuery?: string
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.get(
    `${BACKEND_API}/claim-approval${additionalQuery || ""}`,
    {
      headers: { id_token: token },
      params,
    }
  )
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
const getLeaveSummary = async (
  params: Record<string, unknown>,
  additionalQuery?: string
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.get(
    `${BACKEND_API}/leave-approval${additionalQuery || ""}`,
    {
      headers: { id_token: token },
      params,
    }
  )
  return request
}

async function createLeaveApproval(
  params: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.post(`${BACKEND_API}/leave-approval`, params, {
    headers: { id_token: token },
  })
  return request
}

async function createLeaveApprovalAttachment(
  params: any
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.post(
    `${BACKEND_API}/leave-approval/upload-link`,
    params,
    {
      headers: { id_token: token },
    }
  )
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
async function LeaveApprovalAttachment(params: any): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.get(`${BACKEND_API}/leave-approval/upload-link`, {
    headers: { id_token: token },
    params,
  })
  return request
}

async function updateLeaveApproval(
  id: string,
  params?: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.patch(
    `${BACKEND_API}/leave-approval/${id}`,
    params,
    {
      headers: { id_token: token },
    }
  )
  return request
}

async function acceptOrRejectLeaveApproval(
  id: string,
  type: string, //approve , reject
  params?: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.patch(
    `${BACKEND_API}/leave-approval/${type}/${id}`,
    params,
    {
      headers: { id_token: token },
    }
  )
  return request
}

async function deleteLeaveApproval(id: string): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.delete(`${BACKEND_API}/leave-approval/${id}`, {
    headers: { id_token: token },
  })
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
const getProduct = async (
  params: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.get(`${BACKEND_API}/products`, {
    headers: { id_token: token },
    params,
  })
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
const getMpos = async (
  params: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.get(`${BACKEND_API}/mpossetting`, {
    headers: { id_token: token },
    params,
  })
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
const getInventory = async (
  params: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.get(`${BACKEND_API}/inventory?name=`, {
    headers: { id_token: token },
    params,
  })
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
const getInventoryDetail = async (
  id: string,
  params: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.get(`${BACKEND_API}/inventory/${id}`, {
    headers: { id_token: token },
    params,
  })
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
const getInventorySummary = async (
  params?: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.get(
    `${BACKEND_API}/reporting/inventory/summary`,
    {
      headers: { id_token: token },
      params,
    }
  )
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
const getInventoryDashboard = async (
  params?: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.get(
    `${BACKEND_API}/reporting/inventory/dashboard`,
    {
      headers: { id_token: token },
      params,
    }
  )
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
const getInventoryReport = async (
  params: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.get(`${BACKEND_API}/reporting/inventory/report`, {
    headers: { id_token: token },
    params,
  })
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
async function getTimesheet(
  params: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.get(`${BACKEND_API}/businesses/reportTimeSheet`, {
    headers: { id_token: token },
    params,
  })
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
async function getBusiness(
  params: Record<string, unknown>,
  businessId: string
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.get(`${BACKEND_API}/businesses/${businessId}`, {
    headers: { id_token: token },
    params,
  })
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
async function getLocations(
  params: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.get(
    `${BACKEND_API}/locations?join=pickupLocation`,
    {
      headers: { id_token: token },
      params,
    }
  )
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
async function getEmployee(
  params?: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.get(`${BACKEND_API}/employee`, {
    headers: { id_token: token },
    params,
  })
  return request
}

async function patchEmployee(id: any, params: Object): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.patch(`${BACKEND_API}/employee/${id}`, params, {
    headers: { id_token: token },
  })
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
async function getPayroll(
  params: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.get(`${BACKEND_API}/payroll`, {
    headers: { id_token: token },
    params,
  })
  return request
}

async function createPayroll(
  params: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.post(`${BACKEND_API}/payroll`, params, {
    headers: { id_token: token },
  })
  return request
}

async function updatePayroll(
  id: any,
  params: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.patch(`${BACKEND_API}/payroll/${id}`, params, {
    headers: { id_token: token },
  })
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
async function getBulkTemplate(
  params: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.get(
    `${BACKEND_API}/inventory/bulk-template-link`,
    {
      headers: { id_token: token },
      params,
    }
  )
  return request
}

/** @deprecated use use-inventories-bulk-upload.ts instead. */
async function bulkCreate(
  products: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.post(
    `${BACKEND_API}/inventory/bulk-create`,
    products,
    {
      headers: { id_token: token },
    }
  )
  return request
}

async function bulkImages(image: any): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.post(
    `${BACKEND_API}/inventory/bulk-upload-images`,
    image,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        id_token: token,
      },
    }
  )
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
async function getCustomers(
  params?: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.get(`${BACKEND_API}/customer`, {
    headers: { id_token: token },
    params,
  })
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
async function getCustomer(
  id: string,
  params?: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.get(`${BACKEND_API}/customer/${id}`, {
    headers: { id_token: token },
    params,
  })
  return request
}

async function updateCustomerLocation(
  id: string,
  params?: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.patch(
    `${BACKEND_API}/customerLocation/${id}`,
    params,
    {
      headers: { id_token: token },
    }
  )
  return request
}

async function deleteCustomerLocation(id: string): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.delete(`${BACKEND_API}/customerLocation/${id}`, {
    headers: { id_token: token },
  })
  return request
}

async function createCustomerLocation(params?: Record<string, unknown>) {
  const token = await authProvider.getToken()
  const request = await axios.post<{
    customer: string
    addressLine1: string
    city: string
    isPrimaryLocation: boolean
    phone: string
    name: string
    province: string
    zip: string
    enableCashback: boolean
    cashbackPercentage: number
    isBillingAddress: boolean
    addressLine2: any
    latitude: any
    longitude: any
    id: string
  }>(`${BACKEND_API}/customerLocation/firebase`, params, {
    headers: { id_token: token },
  })
  return request
}

async function createBusinessProfile(
  business: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.post(
    `${BACKEND_API}/businesses?origin=web`,
    business,
    {
      headers: { id_token: token },
    }
  )
  return request
}

async function createCartTransaction(
  cart: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.post(`${BACKEND_API}/cartTransaction`, cart, {
    headers: { id_token: token },
  })
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
const getCartTransactionBuxToken = async (
  params?: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.get(`${BACKEND_API}/cartTransaction/buxToken`, {
    headers: { id_token: token },
    params,
  })
  return request
}

async function generatePaymentLink(
  params: Record<string, unknown>,
  buxToken: string
): Promise<AxiosResponse> {
  const request = await axios.post(`${BUX_API}/wallets/topup`, params, {
    headers: { Authorization: `Bearer ${buxToken}` },
  })
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
async function checkPaymentStatus(
  id: string,
  buxToken: string
): Promise<AxiosResponse> {
  const request = await axios.get(`${BUX_API}/wallets/topup/?req_id=${id}`, {
    headers: { Authorization: `Bearer ${buxToken}` },
  })
  return request
}

async function createAppointment(
  appointment: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.post(`${BACKEND_API}/appointment`, appointment, {
    headers: { id_token: token },
  })
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
const getSchedules = async (
  id: string,
  params?: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.get(
    `${BACKEND_API}/products/services/${id}/schedules`,
    {
      headers: { id_token: token },
      params,
    }
  )
  return request
}

const updateAppointment = async (
  id: string,
  params: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.patch(
    `${BACKEND_API}/appointment/${id}`,
    params,
    {
      headers: { id_token: token },
    }
  )
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
const getEStore = async (id: string): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.get(
    `${BACKEND_API}/businesses/estore-business/${id}`,
    {
      headers: { id_token: token },
    }
  )
  return request
}

const updateAppointmentStatus = async (
  id: string,
  params: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.post(
    `${BACKEND_API}/appointment/status/${id}`,
    params,
    {
      headers: { id_token: token },
    }
  )
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
async function getPromotions(
  params?: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.get(`${BACKEND_API}/promotion`, {
    headers: { id_token: token },
    params,
  })
  return request
}

async function deletePromotion(id: string): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.delete(`${BACKEND_API}/promotion/${id}`, {
    headers: { id_token: token },
  })
  return request
}

const editPromotion = async (
  id: string,
  params: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.patch(`${BACKEND_API}/promotion/${id}`, params, {
    headers: { id_token: token },
  })
  return request
}

async function createPromotion(
  promotion: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.post(`${BACKEND_API}/promotion`, promotion, {
    headers: { id_token: token },
  })
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
async function getAllProductsWithoutPromotion(
  params?: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.get(
    `${BACKEND_API}/products/all-products/without-promotion`,
    {
      headers: { id_token: token },
      params,
    }
  )
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
async function getShipmentMethods(
  params?: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.get(`${BACKEND_API}/shipment-method`, {
    headers: { id_token: token },
    params,
  })
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
async function getPickUpLocations(
  params?: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.get(`${BACKEND_API}/pickupLocation`, {
    headers: { id_token: token },
    params,
  })
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
async function getPickupLocationWithLocationId(
  locationId: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.get(
    `${BACKEND_API}/pickupLocation/location/${locationId}`,
    {
      headers: { id_token: token },
    }
  )
  return request
}

async function createPickupLocation(
  params: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.post(`${BACKEND_API}/pickupLocation`, params, {
    headers: { id_token: token },
  })
  return request
}

const editPickupLocation = async (
  id: string,
  params: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.patch(
    `${BACKEND_API}/pickupLocation/${id}`,
    params,
    {
      headers: { id_token: token },
    }
  )
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
async function getAllProductsWithPromotion(
  params?: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.get(
    `${BACKEND_API}/products/all-products/with-promotion`,
    {
      headers: { id_token: token },
      params,
    }
  )
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
async function getSinglePromotion(id: string) {
  const token = await authProvider.getToken()
  const request = await axios.get<GetSinglePromotionResponse>(
    `${BACKEND_API}/promotion/${id}`,
    {
      headers: { id_token: token },
    }
  )
  return request
}

const editShipmentMethod = async (
  id: string,
  params: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.patch(
    `${BACKEND_API}/shipment-method/${id}`,
    params,
    {
      headers: { id_token: token },
    }
  )
  return request
}

async function createShipmentMethod(
  shipmentMethod: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.post(
    `${BACKEND_API}/shipment-method`,
    shipmentMethod,
    {
      headers: { id_token: token },
    }
  )
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
async function getInvoice(
  params?: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.get(`${BACKEND_API}/invoice?`, {
    headers: { id_token: token },
    params,
  })
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
async function getInvoiceSettings(
  params?: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.get(`${BACKEND_API}/invoice-setting`, {
    headers: { id_token: token },
    params,
  })
  return request
}

const editInvoiceSettings = async (
  id: string,
  params: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.patch(`${BACKEND_API}/invoice/${id}`, params, {
    headers: { id_token: token },
  })
  return request
}

const createInvoiceSettings = async (
  params: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.post(`${BACKEND_API}/invoice`, params, {
    headers: { id_token: token },
  })
  return request
}

const sendInvoice = async (id: string): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.post(
    `${BACKEND_API}/invoice/${id}/send-email`,
    {},
    {
      headers: { id_token: token },
    }
  )
  return request
}

const createEstimate = async (
  params: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.post(`${BACKEND_API}/estimate`, params, {
    headers: { id_token: token },
  })
  return request
}

const createEstimateGenerateInvoice = async (
  estimateId: any
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.post(
    `${BACKEND_API}/estimate/${estimateId}/generate-invoice`,
    {
      id: estimateId,
    },
    {
      headers: { id_token: token },
    }
  )
  return request
}

const EstimateAccept = async (estimateId: any): Promise<AxiosResponse> => {
  return await axios.post(
    `${BACKEND_API}/estimate/${estimateId}/share/accept`,
    { id: estimateId }
  )
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
const getEstimates = async (
  params?: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.get(
    `${BACKEND_API}/estimate?join=customer&join=estimateItems&join=estimateItems.product&join=invoice`,
    {
      headers: { id_token: token },
      params,
    }
  )
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
const EstimateNumber = async (
  params?: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.get(`${BACKEND_API}/estimate/estimate-number`, {
    headers: { id_token: token },
    params,
  })
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
const getEstimate = async (
  estimateId: any,
  params?: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.get(
    `${BACKEND_API}/estimate/${estimateId}?join=customer&join=estimateItems&join=estimateItems.product&join=estimate_items&join=invoice`,
    {
      headers: { id_token: token },
      params,
    }
  )
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
const getEstimateWithNoAuthentication = async (
  estimateId: any,
  params?: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.get(
    `${BACKEND_API}/estimate/${estimateId}/share/details`,
    {
      headers: { id_token: token },
      params,
    }
  )
  return request
}

const updateEstimate = async (
  id: string,
  params: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.patch(`${BACKEND_API}/estimate/${id}`, params, {
    headers: { id_token: token },
  })
  return request
}

const deleteEstimate = async (estimateId: any): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.delete(`${BACKEND_API}/estimate/${estimateId}`, {
    headers: { id_token: token },
  })
  return request
}

const updateDomain = async (
  businessId: string,
  params: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.patch(
    `${BACKEND_API}/businesses/${businessId}`,
    params,
    {
      headers: { id_token: token },
    }
  )
  return request
}

async function createLocation(
  location: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.post(`${BACKEND_API}/locations`, location, {
    headers: { id_token: token },
  })
  return request
}

const updateLocation = async (
  locationId: string,
  params: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.patch(
    `${BACKEND_API}/locations/${locationId}`,
    params,
    {
      headers: { id_token: token },
    }
  )
  return request
}

const updateMPOSSetting = async (
  id: string,
  params: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.patch(
    `${BACKEND_API}/mpossetting/${id}`,
    params,
    {
      headers: { id_token: token },
    }
  )
  return request
}

const createMposSetting = async (
  params: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.post(`${BACKEND_API}/mpossetting/`, params, {
    headers: { id_token: token },
  })
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
async function getMPOSSetting(
  params?: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.get(`${BACKEND_API}/mpossetting`, {
    headers: { id_token: token },
    params,
  })
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
async function getDefaultShipmentMethod(
  businessId: string
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.get(
    `${BACKEND_API}/shipment-method/available?businessId=${businessId}`,
    {
      headers: { id_token: token },
    }
  )
  return request
}

async function getThirdPartyShipmentQuote(
  params: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.post(
    `${BACKEND_API}/shipment/third-party/quote`,
    params,
    {
      headers: { id_token: token },
    }
  )
  return request
}

async function createCustomer(
  customer: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.post(`${BACKEND_API}/customer`, customer, {
    headers: { id_token: token },
  })
  return request
}

const updateCustomer = async (
  id: string,
  params: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.patch(`${BACKEND_API}/customer/${id}`, params, {
    headers: { id_token: token },
  })
  return request
}

async function deleteCustomer(id: string): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.delete(`${BACKEND_API}/customer/${id}`, {
    headers: { id_token: token },
  })
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
async function getShipment(
  params?: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.get(`${BACKEND_API}/shipment`, {
    headers: { id_token: token },
    params,
  })
  return request
}

const updateBusiness = async (
  id: string,
  params: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.patch(`${BACKEND_API}/businesses/${id}`, params, {
    headers: { id_token: token },
  })
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
async function getEmployeeTimesheets(
  params?: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.get(`${BACKEND_API}/time-sheets`, {
    headers: { id_token: token },
    params,
  })
  return request
}

async function createEmployeeTimeSheet(
  timesheet: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.post(`${BACKEND_API}/time-sheets`, timesheet, {
    headers: { id_token: token },
  })
  return request
}

async function deleteEmployeeTimeSheet(id: string): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.delete(`${BACKEND_API}/time-sheets/${id}`, {
    headers: { id_token: token },
  })
  return request
}

const updateEmployeeTimeSheet = async (
  id: string,
  params: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.patch(
    `${BACKEND_API}/time-sheets/${id}`,
    params,
    {
      headers: { id_token: token },
    }
  )
  return request
}

async function createShift(
  params: Record<string, any>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.post(`${BACKEND_API}/shift`, params, {
    headers: { id_token: token },
  })
  return request
}

async function updateShift(
  id: string,
  params: Record<string, any>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.patch(`${BACKEND_API}/shift/${id}`, params, {
    headers: { id_token: token },
  })
  return request
}

async function deleteShift(id: string): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.delete(`${BACKEND_API}/shift/${id}`, {
    headers: { id_token: token },
  })
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
async function getEmployeeShifts2(
  params?: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.get(
    `${BACKEND_API}/employees-shift?join=shift&join=employees&join=business`,
    {
      headers: { id_token: token },
      params,
    }
  )
  return request
}

/**
 * @deprecated use use-employee-me.ts instead.
 *
 * @see useEmployeeMe
 * */
async function getEmployeeMe(
  params?: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.get(`${BACKEND_API}/employee/me`, {
    headers: { id_token: token },
    params,
  })
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
async function getEmployeeShifts(
  params?: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.get(`${BACKEND_API}/shift`, {
    headers: { id_token: token },
    params,
  })
  return request
}

async function createRole(
  role: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.post(`${BACKEND_API}/role`, role, {
    headers: { id_token: token },
  })
  return request
}

async function payRunBulkPay(params: any): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.post(`${BACKEND_API}/pay/bulk`, params, {
    headers: { id_token: token },
  })
  return request
}

async function updateRole(
  id: string,
  params: Record<string, any>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.patch(`${BACKEND_API}/role/${id}`, params, {
    headers: { id_token: token },
  })
  return request
}

async function deleteRole(id: string): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  return axios.delete(`${BACKEND_API}/role/${id}`, {
    headers: { id_token: token },
  })
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
async function getBusinessQR(): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.get(`${BACKEND_API}/businesses/qrcode`, {
    headers: { id_token: token },
    responseType: "arraybuffer",
  })
  return request
}

async function EmployeeSendQrToEmail(): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.post(
    `${BACKEND_API}/businesses/email-qrcode`,
    {},
    {
      headers: { id_token: token },
    }
  )
  return request
}

async function uploadEstoreLogo(
  image: any,
  businessId: string
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.post(
    `${BACKEND_API}/estore-setting/upload-logo?businessId=${businessId}`,
    image,
    {
      headers: {
        "Content-Type": image.type,
        id_token: token,
      },
    }
  )
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
async function getStockImages(
  query: string,
  params?: Record<string, unknown>
): Promise<AxiosResponse> {
  const request = await axios.get(
    `https://api.pexels.com/v1/search?query=${query}`,
    {
      headers: {
        Authorization:
          "563492ad6f91700001000001c32feb1defd2428588db326a12d70946",
      },
      params,
    }
  )
  return request
}

async function postLogin(
  email: string,
  password: string,
  pushToken?: string
): Promise<AxiosResponse> {
  const request = await axios.post(`${BACKEND_API}/auth/login`, {
    email,
    password,
    pushToken,
  })
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
const getCartTransactionOrderReport = async (
  type: string,
  params?: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.get(
    `${BACKEND_API}/reporting/cartTransaction/order-report/${type}`,
    {
      headers: { id_token: token },
      params,
    }
  )
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
const getReporting = async (
  type: string,
  params?: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.get(
    `${BACKEND_API}/reporting/cartTransaction/order-report/${type}`,
    {
      headers: { id_token: token },
      params,
    }
  )
  return request
}

/**
 * @deprecated use use-cash-register-report.ts instead.
 *
 * @see useCashRegisterReport
 * */
const getCashRegisterReport = async (
  id: string,
  params?: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.get(`${BACKEND_API}/cashRegister/${id}/report`, {
    headers: { id_token: token },
    params,
  })
  return request
}

/**
 * @deprecated use use-cash-register-transactions.ts instead.
 *
 * @see useCashRegisterTransactions
 * */
const getCashRegisterPayInPayOutReport = async (
  params?: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = axios.get(`${BACKEND_API}/cash-register-transaction`, {
    headers: { id_token: token },
    params,
  })
  return request
}
const deleteCashRegisterPayInPayOutReport = async (
  id?: string
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.delete(
    `${BACKEND_API}/cash-register-transaction/${id}`,
    {
      headers: { id_token: token },
    }
  )
  return request
}

const updateCashRegisterPayInPayOutReport = async (
  params: any
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.patch(
    `${BACKEND_API}/cash-register-transaction/${params.id}`,
    params,
    {
      headers: { id_token: token },
    }
  )
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
const getCartTransactionByProductType = async (
  type: PRODUCT_TYPE,
  params?: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.get(
    `${BACKEND_API}/reporting/cartTransaction/product-type/${type}/transactions`,
    {
      headers: { id_token: token },
      params,
    }
  )
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
const getQRCode = async (): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.get(`${BACKEND_API}/service-qr-setting/qr-code`, {
    responseType: "arraybuffer",
    headers: {
      id_token: token,
    },
  })
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
const getCartTransactionByOrderSTatus = async (
  type: any
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.get(
    `${BACKEND_API}/cartTransaction/order-status/${type}/transactions?isMpos=true`,
    {
      headers: { id_token: token },
    }
  )
  return request
}

async function deleteCartTransaction(id: string): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.delete(`${BACKEND_API}/cartTransaction/${id}`, {
    headers: { id_token: token },
  })
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
const getCustomerDisplay = async (
  params?: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.get(`${BACKEND_API}/customerDisplay`, {
    headers: { id_token: token },
    params,
  })
  return request
}

async function createCustomerDisplay(
  params: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.post(`${BACKEND_API}/customerDisplay`, params, {
    headers: { id_token: token },
  })
  return request
}

const updateCustomerDisplay = async (
  id: string,
  params: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.patch(
    `${BACKEND_API}/customerDisplay/${id}`,
    params,
    {
      headers: { id_token: token },
    }
  )
  return request
}

const deleteCustomerDisplay = async (id: any): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.delete(`${BACKEND_API}/customerDisplay/${id}`, {
    headers: { id_token: token },
  })
  return request
}

const getCustomerDisplayAds = async (
  params?: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.get(`${BACKEND_API}/customerDisplay/ads`, {
    headers: { id_token: token },
    params,
  })
  return request
}

const createCustomerDisplayAds = async (
  params: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.post(
    `${BACKEND_API}/customerDisplay/ads`,
    params,
    {
      headers: { id_token: token },
    }
  )
  return request
}

const updateCustomerDisplayAds = async (
  id: string,
  params: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.patch(
    `${BACKEND_API}/customerDisplay/ads/${id}`,
    params,
    {
      headers: { id_token: token },
    }
  )
  return request
}

const deleteCustomerDisplayAds = async (id: any): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.delete(
    `${BACKEND_API}/customerDisplay/ads/${id}`,
    {
      headers: { id_token: token },
    }
  )
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
const getPaymentTerms = async (
  params?: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.get(`${BACKEND_API}/paymentTerms`, {
    headers: { id_token: token },
    params,
  })
  return request
}

async function createPaymentTerms(
  params: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.post(`${BACKEND_API}/paymentTerms`, params, {
    headers: { id_token: token },
  })
  return request
}

const updatePaymentTerms = async (
  id: string,
  params: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.patch(
    `${BACKEND_API}/paymentTerms/${id}`,
    params,
    {
      headers: { id_token: token },
    }
  )
  return request
}
const BulkupdatePaymentTerms = async (params: any): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.patch(
    `${BACKEND_API}/paymentTerms/toggle`,
    { data: params },
    {
      headers: { id_token: token },
    }
  )
  return request
}

const deletePaymentTerms = async (id: any): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.delete(`${BACKEND_API}/paymentTerms/${id}`, {
    headers: { id_token: token },
  })
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
const getPriceList = async (
  params?: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.get(`${BACKEND_API}/priceList`, {
    headers: { id_token: token },
    params,
  })
  return request
}

async function createPriceList(
  params: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.post(`${BACKEND_API}/priceList`, params, {
    headers: { id_token: token },
  })
  return request
}

const updatePriceList = async (
  id: string,
  params: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.patch(`${BACKEND_API}/priceList/${id}`, params, {
    headers: { id_token: token },
  })
  return request
}

const deletePriceList = async (id: any): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.delete(`${BACKEND_API}/priceList/${id}`, {
    headers: { id_token: token },
  })
  return request
}

const voidCartTransactionTabOrder = async (
  id: string
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.post(
    `${BACKEND_API}/cartTransaction/cancel/${id}`,
    {},
    {
      headers: { id_token: token },
    }
  )
  return request
}

const updateCartTransactionTabOrder = async ({
  cartTransactionId,
  cart,
  customer,
  businessId,
  paymentMethodCode,
  dueDate,
  tax,
  serviceCharge,
  discount,
  totalOutstanding,
  createdBy,
  notes,
  isWalkIn,
  isOpenOrder,
  customerName,
  cashReceived,
  cashChange,
  tables,
  taxes,
  paymentChannel,
  taxExemptions,
  autoPrint,
  cashRegister,
  orderType,
}: {
  cartTransactionId: any
  cart: Cart
  customer: Customer | undefined
  businessId: string
  paymentMethodCode: string
  dueDate: Date | null
  tax: number
  serviceCharge: number
  discount: number
  totalOutstanding: number
  createdBy: string
  cashRegister?: string | null
  orderType?: string
  notes?: any
  isWalkIn?: boolean
  isOpenOrder?: boolean
  customerName?: string
  cashReceived?: number
  cashChange?: number
  tables?: any[]
  taxes?: PostCheckoutResponseBody["taxes"]
  paymentChannel?: string
  taxExemptions?: Array<{
    id: string
    amount: number
    name: string
    type: string
  }>
  autoPrint?: boolean
}): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()

  const uuid = uuidv4()
  const { promotion, items, paymentId } = cart
  const { totalCartAmount, totalItemQuantity } = cartSummarySelector(cart)

  const location = items[0] ? items[0].location : ""
  const products = await Promise.all(
    items.map(async (item) => {
      const customProductDiscount = calculateItemDiscountAmount(item)
      const p = {
        tempId: item.id,
        product: item.product.id,
        totalPrice: item.sellingPrice * item.qty,
        sellingPrice: item.sellingPrice,
        //sellingPrice: item.isCustomPrice ? item.sellingPrice : undefined,
        // totalPrice: item.product.sellingPrice * item.qty,
        // sellingPrice: item.product.sellingPrice,
        quantity: item.qty,
        productVariant:
          item.product.productVariant != null
            ? item.product.productVariant
            : item.productVariant,
        orderDate: new Date(),
        // discount: productPromotion[item.product.id]?.discountAmount ?? 0,
        notes: item.notes,
        discount: customProductDiscount ?? 0,
        discountType: customProductDiscount ? item.discountType : null,
        appointmentId: undefined,
        outstanding: item.outstanding,
        orderedProductAddOns:
          item.orderedProductAddOns
            ?.filter((el) => el.productAddOns.id != null)
            .map((el) => {
              return {
                productAddOns: el.productAddOns.id,
                quantity:
                  item.type === "SERVICE"
                    ? item.details.slots.length * item.qty * el.quantity
                    : el.quantity,
              }
            }) || undefined,
      }
      if (item.type === "SERVICE") {
        p.appointmentId = await createServiceAppointment(
          customer,
          item,
          businessId,
          paymentMethodCode,
          item.details.slots
        )
      }
      return p
    })
  )

  let transaction = {
    products,
    tax,
    serviceCharge,
    discount,
    totalCartAmount,
    totalItemQuantity,
    cashbookTransactionId: uuid,
    creditbookTransactionId: uuid,
    buxRequestId: uuid,
    customer: isWalkIn == true ? undefined : customer?.id,
    buxPaymentStatus: isOpenOrder
      ? "PENDING"
      : paymentMethodCode === "CASH" || paymentMethodCode === "CUSTOM"
        ? "PAID"
        : "PENDING",
    orderStatus: isOpenOrder ? "PENDING_OPEN_ORDER" : "PENDING",
    customerName: customerName,
    shippingCost: 0,
    paymentMethodCode: paymentMethodCode,
    isMpos: true,
    isServiceOrder: orderType === "SERVICE",
    promotions: promotion ? [promotion.id] : undefined,
    dueDate: paymentMethodCode === "PAY LATER" ? dueDate : undefined,
    business: businessId,
    locationId: location,
    outstanding: totalOutstanding,
    notes: notes || "",
    isWalkIn: isWalkIn || false,
    cashReceived: cashReceived,
    cashChange: cashChange,
    source: "web",
    tables: tables || [],
    queueNumber: cart.queueNumber,
    createdBy: createdBy,
    taxExemptions: taxExemptions?.map((el) => ({
      ...el,
      id: undefined,
      tax: el.id,
    })),
    autoPrintEnabled: autoPrint,
    taxes,
    paymentChannel:
      (paymentMethodCode === "EWALLET" || paymentMethodCode === "CUSTOM") &&
      paymentChannel
        ? paymentChannel
        : undefined,
    paymentId: paymentId || undefined,
    localTransactionId: cart.localTransactionId,
    proposedChange: null,
    cashRegister: cashRegister,
    orderType: orderType,
  }

  const request = await axios.patch(
    `${BACKEND_API}/cartTransaction/pending/${cartTransactionId}`,
    transaction,
    {
      headers: { id_token: token },
    }
  )

  return {
    ...request,
    data: {
      ...request.data,
      data: {
        ...request.data.data,
        products: transaction.products.map((p) => {
          const originalItem = cart.items.find((i) => i.id === p.tempId)
          return {
            ...p,
            sellingPrice: originalItem?.sellingPrice,
            name: originalItem?.product.name,
          }
        }),
      },
    },
  }
}

const createRegisterTransaction = async (
  params: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.post(
    `${BACKEND_API}/cash-register-transaction`,
    params,
    {
      headers: { id_token: token },
    }
  )
  return request
}
const createRegisterTransactionUploadAttachment = async (
  params: any
): Promise<AxiosResponse> => {
  const queryString = Object.keys(params)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    )
    .join("&")
  const token = await authProvider.getToken()
  const request = await axios.post(
    `${BACKEND_API}/cash-register-transaction/upload-attachment?${queryString}`,
    undefined,
    {
      headers: { id_token: token },
    }
  )
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
const getRegisterTransaction = async (params: any): Promise<AxiosResponse> => {
  const queryString = Object.keys(params)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    )
    .join("&")
  const token = await authProvider.getToken()
  //&startDate=${params.startDate}&endDate=${params.endDate}
  const request = await axios.get(
    `${BACKEND_API}/cash-register-transaction/summary?${queryString}`,
    {
      headers: { id_token: token },
    }
  )
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
const getCashRegister = async (): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.get(`${BACKEND_API}/cashRegister`, {
    headers: { id_token: token },
  })
  return request
}

const createCashRegister = async (
  params: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.post(`${BACKEND_API}/cashRegister`, params, {
    headers: { id_token: token },
  })
  return request
}
const updateCashRegister = async (
  id: string,
  params: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.patch(
    `${BACKEND_API}/cashRegister/${id}`,
    params,
    {
      headers: { id_token: token },
    }
  )
  return request
}

async function deleteCashRegister(id: string): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.delete(`${BACKEND_API}/cashRegister/${id}`, {
    headers: { id_token: token },
  })
  return request
}

const cashRegisterPinClockIn = async (
  params: Record<string, unknown>,
  cashRegisterID: any
) => {
  const token = await authProvider.getToken()
  const request = await axios.post<{
    id: string
    pin: string
    name: string
    avatar: string
  }>(`${BACKEND_API}/cashRegister/${cashRegisterID}/clockIn`, params, {
    headers: { id_token: token },
  })
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
const cashRegisterActiveUser = async (
  cashRegisterID: any
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.get(
    `${BACKEND_API}/cashRegister/${cashRegisterID}/activeUser`,

    {
      headers: { id_token: token },
    }
  )
  return request
}

const cashRegisterPinClockOut = async (
  params: Record<string, unknown>,
  cashRegisterID: any
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.post(
    `${BACKEND_API}/cashRegister/${cashRegisterID}/clockOut`,
    params,
    {
      headers: { id_token: token },
    }
  )
  return request
}

const createBulkOnBoarding = async (
  params: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.post(`${BACKEND_API}/onboarding/bulk`, params, {
    headers: { id_token: token },
  })
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
async function getLoyaltyProgram(): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.get(`${BACKEND_API}/loyalty`, {
    headers: { id_token: token },
  })
  return request
}

async function createLoyaltyProgram(
  params: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.post(`${BACKEND_API}/loyalty`, params, {
    headers: { id_token: token },
  })
  return request
}

async function updateLoyaltyProgram(
  id: string,
  params: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.patch(`${BACKEND_API}/loyalty/${id}`, params, {
    headers: { id_token: token },
  })
  return request
}

const deleteLoyaltyProgram = async (id: any): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.delete(`${BACKEND_API}/loyalty/${id}`, {
    headers: { id_token: token },
  })
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
async function getRewardProgram(): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.get(`${BACKEND_API}/loyalty-reward`, {
    headers: { id_token: token },
  })
  return request
}

async function createRewardProgram(
  params: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.post(`${BACKEND_API}/loyalty-reward`, params, {
    headers: { id_token: token },
  })
  return request
}

async function updateRewardProgram(
  id: string,
  params: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.patch(
    `${BACKEND_API}/loyalty-reward/${id}`,
    params,
    {
      headers: { id_token: token },
    }
  )
  return request
}

const deleteRewardProgram = async (id: any): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.delete(`${BACKEND_API}/loyalty-reward/${id}`, {
    headers: { id_token: token },
  })
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
const getInvoiceReceivingReport = async (
  params: any,
  additionalQuery?: string
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.get(`${BACKEND_API}/inventory/report`, {
    headers: { id_token: token },
    params,
  })
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
const getInventoryHistory = async (
  params: any,
  additionalQuery?: string
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.get(`${BACKEND_API}/reporting/inventory/report`, {
    headers: { id_token: token },
    params,
  })
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
const getMonitoringReports = async (params: any): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.get(`${BACKEND_API}/monitoring/report`, {
    headers: { id_token: token },
    params,
  })
  return request
}

async function addMonitoringProduct(params: any): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.post(
    `${BACKEND_API}/monitoring/product`,
    params,
    {
      headers: { id_token: token },
    }
  )
  return request
}

async function removeMonitoringProduct(id: string): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.delete(`${BACKEND_API}/monitoring/${id}`, {
    headers: { id_token: token },
  })
  return request
}

async function updateMonitoringProduct(
  id: string,
  params: {
    product: string
    priceControl: number
    quantityControl: number
  }
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.patch(`${BACKEND_API}/monitoring/${id}`, params, {
    headers: { id_token: token },
  })
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
const getMonitoringProducts = async (params?: any): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.get(`${BACKEND_API}/monitoring/product`, {
    headers: { id_token: token },
    params,
  })
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
const getMonitoringLocations = async (
  params?: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.get(`${BACKEND_API}/monitoring/location`, {
    headers: { id_token: token },
    params,
  })
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
const getMonitoringCategory = async (
  params?: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.get(
    `${BACKEND_API}/monitoring/product-category`,
    {
      headers: { id_token: token },
      params,
    }
  )
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
const getReceipts = async (
  params?: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.get(
    `${BACKEND_API}/cartTransaction/listWithIndex`,
    {
      headers: { id_token: token },
      params,
    }
  )
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
async function getCustomerBulkTemplateLink(
  params?: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.get(
    `${BACKEND_API}/customer/bulk-template-link`,
    {
      headers: { id_token: token },
      params,
    }
  )
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
async function getEmployeeBulkTemplateLink(
  params?: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.get(
    `${BACKEND_API}/employee/bulk-template-link`,
    {
      headers: { id_token: token },
      params,
    }
  )
  return request
}

async function getAccountsBulkTemplateLink(
  params?: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.get(
    `${BACKEND_API}/cash-register-transaction/category/bulk-template-link`,
    {
      headers: { id_token: token },
      params,
    }
  )
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
async function getSupplierBulkTemplateLink(
  params?: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.get(
    `${BACKEND_API}/supplier/bulk-template-link`,
    {
      headers: { id_token: token },
      params,
    }
  )
  return request
}

async function sendReceiptSms(params: any): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.post(
    `${BACKEND_API}/cartTransaction/send-sms-receipt`,
    params,
    {
      headers: { id_token: token },
    }
  )
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
async function getCashRegisterTransactionCategory(
  params?: Record<string, unknown>
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.get(
    `${BACKEND_API}/cash-register-transaction/category`,
    {
      headers: { id_token: token },
      params,
    }
  )
  return request
}

async function createCashRegisterTransactionCategory(
  params: any
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.post(
    `${BACKEND_API}/cash-register-transaction/category`,
    params,
    {
      headers: { id_token: token },
    }
  )
  return request
}

async function createBulkCashRegisterTransactionCategory(
  params: any
): Promise<AxiosResponse> {
  const token = await authProvider.getToken()
  const request = await axios.post(
    `${BACKEND_API}/cash-register-transaction/category/bulk-create`,
    params,
    {
      headers: { id_token: token },
    }
  )
  return request
}

const updateCashRegisterTransactionCategory = async (
  id: string,
  params: Record<string, unknown>
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.patch(
    `${BACKEND_API}/cash-register-transaction/category/${id}`,
    params,
    {
      headers: { id_token: token },
    }
  )
  return request
}

const deleteCashRegisterTransactionCategory = async (
  id: any
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.delete(
    `${BACKEND_API}/cash-register-transaction/category/${id}`,
    {
      headers: { id_token: token },
    }
  )
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
const getArgiRecord = async (): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.get(`${BACKEND_API}/agriculture-record`, {
    headers: { id_token: token },
  })
  return request
}

const postArgiRecord = async (params: any): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.post(
    `${BACKEND_API}/agriculture-record`,
    params,
    {
      headers: { id_token: token },
    }
  )
  return request
}

const patchArgiRecord = async (params: any): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.patch(
    `${BACKEND_API}/agriculture-record/${params.id}`,
    params,
    {
      headers: { id_token: token },
    }
  )
  return request
}

const deleteArgiRecord = async (id: any): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.delete(
    `${BACKEND_API}/agriculture-record/${id}`,
    {
      headers: { id_token: token },
    }
  )
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
const getAgriRecordMaintenace = async (id: string): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.get(
    `${BACKEND_API}/agriculture-record/${id}/maintenance`,
    {
      headers: { id_token: token },
    }
  )
  return request
}

const createArgiMaintenance = async (
  recordId: string,
  params: any
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.post(
    `${BACKEND_API}/agriculture-record/${recordId}/maintenance`,
    params,
    {
      headers: { id_token: token },
    }
  )
  return request
}

const patchArgiMaintenance = async (params: any): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.patch(
    `${BACKEND_API}/agriculture-maintenance/${params.id}`,
    params,
    {
      headers: { id_token: token },
    }
  )
  return request
}

const deleteArgiMaintenance = async (id: any): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.delete(
    `${BACKEND_API}/agriculture-maintenance/${id}`,
    {
      headers: { id_token: token },
    }
  )
  return request
}

/**
 * @deprecated use (or create if none exists) use-api.ts based hooks instead.
 *
 * @see useAPI
 * */
const getAgriRecordInput = async (id: string): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.get(
    `${BACKEND_API}/agriculture-record/${id}/input`,
    {
      headers: { id_token: token },
    }
  )
  return request
}

const createArgiInput = async (
  recordId: string,
  params: any
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.post(
    `${BACKEND_API}/agriculture-record/${recordId}/input`,
    params,
    {
      headers: { id_token: token },
    }
  )
  return request
}

const patchArgiInput = async (params: any): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.patch(
    `${BACKEND_API}/agriculture-input/${params.id}`,
    params,
    {
      headers: { id_token: token },
    }
  )
  return request
}

const deleteArgiInput = async (id: any): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.delete(`${BACKEND_API}/agriculture-input/${id}`, {
    headers: { id_token: token },
  })
  return request
}

const createLayout = async (
  cashRegisterId: string,
  params: any
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.post(
    `${BACKEND_API}/cashRegister/${cashRegisterId}/pos-layout`,
    params,
    {
      headers: { id_token: token },
    }
  )
  return request
}

const updateLayout = async (
  cashRegisterId: string,
  layoutId: string,
  params: any
): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.patch(
    `${BACKEND_API}/cashRegister/${cashRegisterId}/pos-layout/${layoutId}`,
    params,
    {
      headers: { id_token: token },
    }
  )
  return request
}

const createJournal = async (params: any): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.post(`${BACKEND_API}/journal`, params, {
    headers: { id_token: token },
  })
  return request
}

const bulkCreateJournal = async (params: any): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.post(`${BACKEND_API}/journal/bulk`, params, {
    headers: { id_token: token },
  })
  return request
}

const deleteJournal = async (id: any): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.delete(`${BACKEND_API}/journal/${id}`, {
    headers: { id_token: token },
  })
  return request
}

const updateJournal = async (id: any, params: any): Promise<AxiosResponse> => {
  const token = await authProvider.getToken()
  const request = await axios.patch(`${BACKEND_API}/journal/${id}`, params, {
    headers: { id_token: token },
  })
  return request
}

/**
 * @deprecated use use-api.ts/use-api-mutation.ts based hooks instead.
 * useApi provides global caching, deduplication, automatic retries, automatic
 * revalidation, offline caching, and more.
 * For more info see {@link https://swr.vercel.app/ SWR docs}.
 *
 * @see useAPI
 * @see useProducts
 * */
export default {
  getAppointment,
  getDeductionSummary,
  getCartTransaction,
  getPayrun,
  getEmployeePaySummary,
  getEmployeeLoan,
  createEmployeeLoan,
  updateEmployeeLoan,
  deleteEmployeeLoan,
  getApprovedClaim,
  getLeaveSummary,
  getProduct,
  getInventorySummary,
  getInventoryReport,
  getInventoryDetail,
  getMpos,
  getInventory,
  getTimesheet,
  getBusiness,
  getLocations,
  getEmployee,
  patchEmployee,
  getPayroll,
  createPayroll,
  updatePayroll,
  bulkCreate,
  getBulkTemplate,
  bulkImages,
  getCustomers,
  getCustomer,
  getSingleCartTransaction,
  createBusinessProfile,
  createCartTransaction,
  getCartTransactionBuxToken,
  generatePaymentLink,
  checkPaymentStatus,
  updateCartTransactionPaymentStatus,
  createAppointment,
  getSchedules,
  updateAppointment,
  getEStore,
  updateAppointmentStatus,
  getPromotions,
  deletePromotion,
  editPromotion,
  createPromotion,
  getShipmentMethods,
  getPickUpLocations,
  getPickupLocationWithLocationId,
  getAllProductsWithPromotion,
  getSinglePromotion,
  editShipmentMethod,
  createShipmentMethod,
  createPickupLocation,
  editPickupLocation,
  getInvoiceSettings,
  editInvoiceSettings,
  getAllProductsWithoutPromotion,
  updateDomain,
  createLocation,
  updateLocation,
  updateMPOSSetting,
  getMPOSSetting,
  getDefaultShipmentMethod,
  getThirdPartyShipmentQuote,
  createCustomer,
  updateCustomer,
  deleteCustomer,
  getShipment,
  updateBusiness,
  getEmployeeTimesheets,
  createEmployeeTimeSheet,
  deleteEmployeeTimeSheet,
  updateEmployeeTimeSheet,
  createInvoiceSettings,
  createShift,
  getEmployeeShifts,
  getEmployeeShifts2,
  updateShift,
  deleteShift,
  updateRole,
  deleteRole,
  createRole,
  getBusinessQR,
  getEmployeeMe,
  createEstimate,
  getEstimates,
  getEstimateWithNoAuthentication,
  getEstimate,
  EstimateNumber,
  deleteEstimate,
  updateEstimate,
  createEstimateGenerateInvoice,
  EstimateAccept,
  EmployeeSendQrToEmail,
  payRunBulkPay,
  sendInvoice,
  uploadEstoreLogo,
  getStockImages,
  postLogin,
  createMposSetting,
  getCartTransactionOrderReport,
  getCartTransactionAllOrders,
  getCartTransactionByProductType,
  getQRCode,
  checkEmailIFExist,
  getPayslips,
  getCartTransactionByOrderSTatus,
  updateCartTransactionTabOrder,
  voidCartTransactionTabOrder,
  createRegisterTransaction,
  createRegisterTransactionUploadAttachment,
  getRegisterTransaction,
  deleteCartTransaction,
  getCashRegister,
  createCashRegister,
  updateCashRegister,
  deleteCashRegister,
  cashRegisterPinClockIn,
  cashRegisterActiveUser,
  cashRegisterPinClockOut,
  createBulkOnBoarding,
  getCashRegisterReport,
  createLoyaltyProgram,
  getLoyaltyProgram,
  updateLoyaltyProgram,
  deleteLoyaltyProgram,
  createRewardProgram,
  getRewardProgram,
  updateRewardProgram,
  deleteRewardProgram,
  getInvoiceReceivingReport,
  getInventoryHistory,
  getMonitoringReports,
  addMonitoringProduct,
  getMonitoringProducts,
  getMonitoringLocations,
  getMonitoringCategory,
  getReceipts,
  updateCustomerLocation,
  createCustomerLocation,
  deleteCustomerLocation,
  getCashRegisterPayInPayOutReport,
  getCustomerBulkTemplateLink,
  getEmployeeBulkTemplateLink,
  getAccountsBulkTemplateLink,
  getSupplierBulkTemplateLink,
  removeMonitoringProduct,
  updateMonitoringProduct,
  forgotPassword,
  forgotPasswordSubmit,
  getPriceList,
  createPriceList,
  updatePriceList,
  deletePriceList,
  getReporting,
  deletePaymentTerms,
  updatePaymentTerms,
  createPaymentTerms,
  getPaymentTerms,
  BulkupdatePaymentTerms,
  updateCustomerDisplay,
  createCustomerDisplay,
  deleteCustomerDisplay,
  getCustomerDisplay,
  getCustomerDisplayAds,
  createCustomerDisplayAds,
  updateCustomerDisplayAds,
  deleteCustomerDisplayAds,
  createLeaveApproval,
  createLeaveApprovalAttachment,
  LeaveApprovalAttachment,
  updateLeaveApproval,
  deleteLeaveApproval,
  acceptOrRejectLeaveApproval,
  getInventoryDashboard,
  getInvoice,
  sendReceiptSms,
  createCashRegisterTransactionCategory,
  createBulkCashRegisterTransactionCategory,
  getCashRegisterTransactionCategory,
  updateCashRegisterTransactionCategory,
  deleteCashRegisterTransactionCategory,
  getArgiRecord,
  getAgriRecordMaintenace,
  postArgiRecord,
  patchArgiRecord,
  deleteArgiRecord,
  createArgiMaintenance,
  patchArgiMaintenance,
  deleteArgiMaintenance,
  getAgriRecordInput,
  createArgiInput,
  patchArgiInput,
  deleteArgiInput,
  deleteCashRegisterPayInPayOutReport,
  updateCashRegisterPayInPayOutReport,
  createLayout,
  updateLayout,
  createJournal,
  bulkCreateJournal,
  deleteJournal,
  updateJournal,
}
