import DigLogLogo from "../../assets/Logo_DigLog.png"
import DigLogLogoWhite from "../../assets/Horizontal_logo_new.png"
import React, { FC, FormEventHandler, Suspense, useState } from "react"
import useQueryString from "../../hooks/use-query-string"
import useSubscriptionPlanDetails from "../../hooks/api/use-subscription-plan-details"
import { formatNumberToCurrency } from "../../lib/currency"
import { Link, Redirect, useHistory } from "react-router-dom"
import { t } from "i18next"
import {
  confirmSignUp,
  login,
  logout,
  resendConfirmationCode,
  signUp,
} from "../../utils/authProvider"
import { Trans } from "react-i18next"
import { Autocomplete, Box, TextField, Typography } from "@mui/material"
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined"
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined"
import OtpInput from "react-otp-input"
import { forceDeleteUser, validateEmail, validatePhone } from "../../lib/auth"
import {
  BusinessType,
  PlacesSuggestions,
  SubscriptionPlan,
} from "../../lib/models"
import useCountries from "../../hooks/api/use-countries"
import apiProvider from "../../utils/apiProvider"
import { sortBy } from "lodash"
import TermsAndConditionModal from "../../components/TermsAndConditionModal"
import Input from "../../components/Input"
import IconButton from "../../components/IconButton"
import Checkbox from "../../components/Checkbox"
import Button from "../../components/Button"
import BusinessTypeSelect, {
  BusinessTypeSelectLoading,
} from "../../components/BusinessTypeSelect"
import { useSWRConfig } from "swr"
import usePlacesAutocomplete, {
  getGeocode,
  getZipCode,
} from "use-places-autocomplete"
import { useShowSnackbar } from "../../components/Snackbar"
import SubscriptionButtons from "../../components/SubscriptionButtons"
import useClearAllCache from "../../hooks/use-clear-all-cache"
import Modal from "../../components/Modal"

const DirectCheckout = () => {
  const id = useQueryString("id")
  const { data: plan } = useSubscriptionPlanDetails(id)

  const limit = 11
  const descriptionLImitDisplay = () => {
    return (
      plan?.description.split("\n").filter((p, idx) => {
        return idx <= limit
      }) ?? []
    )
  }

  const planDescriptionCount = () => {
    return plan?.description.split("\n").length ?? 0
  }

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    bgcolor: "background.paper",
    // border: "2px solid #000",
    boxShadow: 24,
    p: 2,
  }

  const [showAllFeatures, setShowAllFeatures] = useState(false)
  if (!id) return <Redirect to={"/subscriptions"} />

  return (
    <div className="flex flex-col-reverse font-body xl:min-h-screen xl:flex-row">
      <div className="flex w-full items-center justify-center bg-white sm:w-full md:w-full lg:w-full xl:w-[50%]">
        <div className={"mx-auto max-w-md"}>
          <div className={"mb-8 hidden items-center xl:flex"}>
            <img src={DigLogLogo} alt="DizLog" className={"h-12 "} />
            <h1 className="my-0 ml-auto text-xl font-black uppercase text-zinc-500">
              {/* Checkout */}
            </h1>
          </div>

          <CheckoutFlow
            plan={
              !plan
                ? undefined
                : {
                    ...plan,
                    localPrice: plan.price,
                    localCurrency: "USD",
                  }
            }
          />
        </div>
      </div>

      <div
        className={
          "flex h-full flex-1 flex-col items-center justify-center overflow-y-scroll bg-[#003366]"
        }
      >
        <div
          className={
            " flex  h-full w-full flex-1 flex-col items-center justify-center"
          }
        >
          <div className={"mb-4 mt-2 flex items-center xl:hidden"}>
            <img src={DigLogLogoWhite} alt="DizLog" className={"h-8"} />
            {/* <h1 className={"m-0 ml-auto text-xl uppercase text-zinc-400"}>
              Checkout
            </h1> */}
          </div>

          <h1 className="m-0 mb-2 p-0 text-xl font-bold text-primary-600 xl:text-center">
            {plan?.name}
          </h1>
          <h2 className="m-0 p-0 text-5xl font-bold text-white xl:text-center">
            {formatNumberToCurrency("USD", plan?.price)} USD
            <span className="opacity-50">
              {plan?.billingFrequency === "year" && "/year"}
              {plan?.billingFrequency === "month" && "/month"}
              {plan?.billingFrequency === "quarter" && "/quarter"}
            </span>
          </h2>

          <h3 className="mx-0 mt-5 mb-2 hidden p-0 text-xl font-bold text-white xl:block xl:text-center">
            Plan Includes
          </h3>

          {/* <ul className={"flex hidden flex-wrap p-0 xl:flex xl:justify-center"}>
            {plan?.description.split("\n").map((features) => (
              <li
                key={features}
                className=" mr-4 mb-4 list-none rounded-lg bg-zinc-800 py-2 px-4 text-center text-sm text-zinc-100 xl:mx-2"
              >
                {features}
              </li>
            ))}
          </ul> */}
          <div className=" mb-3 hidden w-[95%] rounded-lg  bg-white p-3 xl:flex">
            <div className="grid w-full grid-flow-row grid-cols-2 gap-2 p-1">
              {/* {plan?.description.split("\n").map((features) => ( */}
              {descriptionLImitDisplay().map((features) => (
                <div className=" p-1" key={features}>
                  <span className="text-base text-slate-800">- {features}</span>
                </div>
              ))}
            </div>
          </div>
          <div>
            {planDescriptionCount() > limit ? (
              <Button
                className={" w-full"}
                onClick={() => setShowAllFeatures(true)}
              >
                <Trans>Show all features</Trans>
              </Button>
            ) : null}
          </div>
        </div>
      </div>
      <Modal
        open={showAllFeatures}
        onClose={() => setShowAllFeatures(false)}
        title={t("Terms and Privacy Policy")}
        className=""
      >
        <Box sx={style} className=" overflow-y-scroll  rounded-lg">
          <div className="flex justify-end">
            <span
              onClick={() => setShowAllFeatures(false)}
              className="w-9 cursor-pointer rounded-full bg-red-500 p-2 text-center text-white"
            >
              x
            </span>
          </div>
          <div>
            <span className="font-roboto text-lg text-orange-500">
              Features
            </span>
          </div>
          <div className=" mb-3 hidden w-[100%] rounded-lg  bg-white p-3 xl:flex">
            <div className="grid w-full grid-flow-row grid-cols-2 gap-2 p-1">
              {plan?.description.split("\n").map((features) => (
                <div className=" p-1" key={features}>
                  <span className="text-base text-slate-800">- {features}</span>
                </div>
              ))}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  )
}

const CheckoutFlow: FC<{ plan?: SubscriptionPlan }> = ({ plan }) => {
  const [view, setView] = useState("signup")
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")

  if (view === "signup") {
    return (
      <CreateAccountForm
        onFinish={async (username, password) => {
          if (process.env.NODE_ENV === "production") {
            window.gtag("event", "conversion", {
              send_to: "AW-10829576507/_9BzCNm0yIsDELvy-Kso",
            })
            window.gtag("event", "business_signup")
          }
          setUsername(username)
          setPassword(password)
          setView("OTP")
        }}
        onLoginClick={() => setView("login")}
      />
    )
  }

  if (view === "login") {
    return (
      <LoginForm
        onFinish={async () => setView("payment")}
        onOTPRequest={async (username, password) => {
          setUsername(username)
          setPassword(password)
          setView("OTP")
        }}
        onSignUpClick={async () => setView("signup")}
        onNoBusinessFound={async (username, password) => {
          setUsername(username)
          setPassword(password)
          setView("business-profile-form")
        }}
      />
    )
  }

  if (view === "OTP") {
    return (
      <OTPForm
        username={username}
        onFinish={async () => {
          await logout()
          await login(username.trim(), password)
          setView("business-profile-form")
        }}
        onReset={async () => {
          await forceDeleteUser(username)
          await logout()
          setView("signup")
        }}
      />
    )
  }

  if (view === "business-profile-form") {
    return (
      <CreateBusinessProfileForm
        username={username}
        onFinish={async () => {
          try {
            await logout()
            await login(username.trim(), password)
            setView("payment")
          } catch (e: any) {
            window.console.log(e)
          }
        }}
      />
    )
  }

  if (view === "payment") {
    return (
      <Suspense fallback={<></>}>
        <PaymentOptions
          disableRecurring={plan?.billingFrequency === "lifetime"}
          plan={plan}
        />
      </Suspense>
    )
  }

  return <></>
}

const CreateAccountForm: FC<{
  onFinish: (username: string, password: string) => Promise<void>
  onLoginClick?: () => void
}> = ({ onFinish, onLoginClick }) => {
  const history = useHistory()
  const [error, setError] = useState("")
  const [phoneOrEmail, setPhoneOrEmail] = useState("")
  const [password, setPassword] = useState("")
  const [confirmpassword, setconfirmpassword] = useState("")
  const [agreeToTerms, setAgreeToTerms] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showconfirmpassword, setshowconfirmpassword] = useState(false)
  const [showTermsModal, setShowTermsModal] = useState(false)

  const passwordError =
    password !== "" && password.length < 6
      ? "Password must be at least 6 characters long"
      : ""
  const handleSubmit: FormEventHandler = async (e) => {
    e.preventDefault()
    setError("")

    if (passwordError !== "") {
      return setError(t("Please validate your input"))
    }

    const isValidEmail: any = validateEmail(phoneOrEmail)
    const isValidPhone: any = validatePhone(phoneOrEmail)

    if (
      isValidPhone &&
      !(phoneOrEmail.startsWith("+65") || phoneOrEmail.startsWith("+63"))
    ) {
      return setError(
        t("Phone numbers are only allowed in Philippine and Singapore")
      )
    }

    if (!(isValidEmail !== null || isValidPhone !== null)) {
      return setError(t("Please validate your input"))
    }

    const username = isValidPhone !== null ? isValidPhone[0] : isValidEmail[0]
    try {
      const res: any = await signUp(username.trim(), password)
      if (res.user && res.user.username) {
        await onFinish(res.user.username, password)
        // showSnackBar("OTP was sent to email", "success")
      }
    } catch (e: any) {
      setError(e.message)
      console.log(e)
    }
  }

  return (
    <>
      <form className="m-0" onSubmit={handleSubmit}>
        <h1 className={"text-lef mb-4 w-full p-0"}>
          <Trans>Create an Account</Trans>
        </h1>
        <p className={"mb-8 w-full p-0 !text-left !text-base text-gray-700"}>
          Complete your purchase by creating an account.
        </p>

        <Input
          label={"Email"}
          type="email"
          containerClassName={"w-full"}
          value={phoneOrEmail}
          onChange={(e) => {
            setPhoneOrEmail(e.target.value)
          }}
          required
        />

        <div className={"mt-4 flex w-full items-end"}>
          <Input
            label={t("Password")}
            containerClassName={"w-full"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type={showPassword ? "text" : "password"}
            required
          />
          <IconButton
            onClick={() => setShowPassword(!showPassword)}
            className={"-ml-12 mb-1 opacity-50"}
          >
            {showPassword ? (
              <VisibilityOffOutlinedIcon />
            ) : (
              <VisibilityOutlinedIcon />
            )}
          </IconButton>
        </div>
        {passwordError && (
          <Box style={{ marginTop: 10, color: "#D32929" }}>{passwordError}</Box>
        )}
        <div className={"mt-4 flex w-full items-end"}>
          <Input
            label={t("Confirm Password")}
            containerClassName={"w-full"}
            value={confirmpassword}
            onChange={(e) => setconfirmpassword(e.target.value)}
            type={showconfirmpassword ? "text" : "password"}
            required
          />
          <IconButton
            onClick={() => setshowconfirmpassword(!showconfirmpassword)}
            className={"-ml-12 mb-1 opacity-50"}
          >
            {showPassword ? (
              <VisibilityOffOutlinedIcon />
            ) : (
              <VisibilityOutlinedIcon />
            )}
          </IconButton>
        </div>

        <div className={"m-4 mt-8 flex w-full items-center"}>
          <Checkbox
            label={"I have read and agreed to Dizlog"}
            onChange={() => setAgreeToTerms(!agreeToTerms)}
            checked={agreeToTerms}
            containerClassName="whitespace-nowrap leading-none !font-normal"
            required
          />
          <Button
            variant={"text"}
            onClick={() => setShowTermsModal(true)}
            className={"ml-2 w-auto"}
          >
            T&C
          </Button>
        </div>

        {error && <p className={"m-4 italic text-red-500"}>**{error}</p>}

        <Button
          className={"mb-4 mt-16 w-full"}
          disabled={
            phoneOrEmail === "" ||
            password === "" ||
            !agreeToTerms ||
            password != confirmpassword
          }
          type={"submit"}
        >
          <Trans>Send OTP</Trans>
        </Button>

        <Button
          variant="text"
          className={"mb-8 w-full py-4"}
          onClick={() => history.push("/subscriptions")}
        >
          <Trans>Cancel</Trans>
        </Button>

        <Typography className={"flex justify-center text-center"}>
          <Trans>Already have an account?</Trans>{" "}
          <Link to="/">
            <Button
              variant="text"
              className={"ml-2 w-auto font-bold text-primary-900 no-underline"}
              // onClick={onLoginClick}
            >
              <Trans>Log In</Trans>
            </Button>
          </Link>
        </Typography>
      </form>

      {showTermsModal && (
        <TermsAndConditionModal onClose={() => setShowTermsModal(false)} />
      )}
    </>
  )
}

const LoginForm: FC<{
  onFinish: (username: string, password: string) => Promise<void>
  onSignUpClick: () => void
  onOTPRequest: (username: string, password: string) => void
  onNoBusinessFound: (username: string, password: string) => void
}> = ({ onFinish, onSignUpClick, onOTPRequest, onNoBusinessFound }) => {
  const history = useHistory()
  const clearAllCache = useClearAllCache()

  const [error, setError] = useState("")
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const [showTermsModal, setShowTermsModal] = useState(false)

  const handleSubmit: FormEventHandler = async (e) => {
    e.preventDefault()
    try {
      const res: any = await login(username.trim(), password)
      if (res.attributes["custom:businessId"]) {
        await clearAllCache()
        try {
          await apiProvider.getEmployeeMe()
          localStorage.setItem("isBusinessOwner", "false")
        } catch (e) {
          localStorage.setItem("isBusinessOwner", "true")
        }
        await onFinish(username, password)
      } else {
        onNoBusinessFound(username, password)
      }
    } catch (e: any) {
      if (e.message === "User is not confirmed.") {
        await resendConfirmationCode(username.trim())
        await onOTPRequest(username, password)
      } else {
        setError(e.message)
      }
    }
  }

  return (
    <>
      <form className="m-0" onSubmit={handleSubmit}>
        <h1 className={"text-lef mb-4 w-full p-0"}>
          <Trans>Log In</Trans>
        </h1>
        <p className={"mb-8 w-full p-0 !text-left !text-base text-gray-700"}>
          <Trans>Log in to your account to continue the purchase.</Trans>
        </p>

        <Input
          label={"Email or Phone Number"}
          containerClassName={"w-full"}
          value={username}
          onChange={(e) => {
            setUsername(e.target.value)
          }}
          required
        />

        <div className={"mt-4 flex w-full items-end"}>
          <Input
            label={t("Password")}
            containerClassName={"w-full"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type={showPassword ? "text" : "password"}
            required
          />
          <IconButton
            onClick={() => setShowPassword(!showPassword)}
            className={"-ml-12 mb-1 opacity-50"}
          >
            {showPassword ? (
              <VisibilityOffOutlinedIcon />
            ) : (
              <VisibilityOutlinedIcon />
            )}
          </IconButton>
        </div>

        {error && <p className={"m-4 italic text-red-500"}>**{error}</p>}

        <Button
          className={"mb-4 mt-16 w-full"}
          disabled={username === "" || password === ""}
          type={"submit"}
        >
          <Trans>Log In</Trans>
        </Button>

        <Button
          variant="text"
          className={"mb-8 w-full py-4"}
          onClick={() => history.push("/subscriptions")}
        >
          <Trans>Cancel</Trans>
        </Button>

        <Typography className={"flex justify-center pt-8 text-center"}>
          <Trans>Don't have an account?</Trans>{" "}
          <Button
            variant="text"
            className={"ml-2 w-auto font-bold text-primary-900 no-underline"}
            onClick={onSignUpClick}
          >
            <Trans>Sign Up</Trans>
          </Button>
        </Typography>
      </form>

      {showTermsModal && (
        <TermsAndConditionModal onClose={() => setShowTermsModal(false)} />
      )}
    </>
  )
}

const OTPForm: FC<{
  onFinish: () => Promise<void>
  onReset: () => void
  username: string
}> = ({ onFinish, onReset, username }) => {
  const [OTP, setOTP] = useState("")
  const [error, setError] = useState("")
  const showSnackBar = useShowSnackbar()

  const handleSubmit = async () => {
    try {
      const res = await confirmSignUp(username, OTP)
      if (res !== "SUCCESS") return
      // alert(t("Sign Up Success!"))
      showSnackBar("Sign Up Success!", "success")
      await onFinish()
    } catch (e: any) {
      setError(e.message)
      setOTP("")
      console.log(e)
    }
  }

  const handleResendOTP = async () => {
    try {
      await resendConfirmationCode(username.trim())
      // alert(t("OTP sent"))
      showSnackBar("OTP sent", "success")
    } catch (e: any) {
      setError(e.message)
      window.console.log(e)
    }
  }

  return (
    <div className="sign-up-form" style={{ height: "300px" }}>
      <h1 style={{ marginBottom: "1rem", textAlign: "center" }}>
        <Trans>Enter OTP</Trans>
      </h1>
      <OtpInput
        value={OTP}
        onChange={setOTP}
        numInputs={6}
        separator={<span>-</span>}
      />
      {error && <p className={"m-4 italic text-red-500"}>**{error}</p>}
      <Button
        disabled={OTP.length !== 6}
        onClick={handleSubmit}
        className={"mt-16 mb-4 px-20"}
      >
        <Trans>Register</Trans>
      </Button>

      <Button
        variant={"text"}
        type="button"
        className={"px-20 py-4"}
        onClick={handleResendOTP}
      >
        <Trans>Resend OTP</Trans>
      </Button>

      <Typography sx={{ mt: 4, mb: 1.5, color: "#001130" }} align="center">
        <Trans>Having trouble? </Trans>
        <button
          onClick={onReset}
          className="m-0 flex-shrink-0 whitespace-nowrap border-none bg-transparent text-base font-bold text-primary-900 no-underline"
        >
          <Trans>Go back and reset.</Trans>
        </button>
      </Typography>
    </div>
  )
}

const CreateBusinessProfileForm: FC<{
  username: string
  onFinish: () => Promise<void>
}> = ({ username, onFinish }) => {
  const afmc = useQueryString("afmc")
  const placesAutocomplete = usePlacesAutocomplete({
    debounce: 300,
  })

  const { data: countries } = useCountries({ suspense: false })

  const [loading, setLoading] = useState(false)
  const [phoneCode, setPhoneCode] = useState<{
    value: string
    title: string
  } | null>({ value: "+61", title: "+61 - Singapore" })
  const [isError, setIsError] = useState({ v: false, m: "" })
  const [businessName, setBusinessName] = useState("")
  const [businessType, setBusinessType] = useState<BusinessType | null>(null)
  const [email, setEmail] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [affiliateCode, setAffiliateCode] = useState(afmc)
  const [country, setCountry] = useState<{
    id: number
    label: string
    phoneCode: string
  } | null>(null)
  const [location, setLocation] = useState({
    addressLine1: "",
    city: "",
    state: "",
    zip: "",
  })

  const places =
    (placesAutocomplete.suggestions.data as PlacesSuggestions) ?? []

  const phoneCodeOptionList =
    countries?.map(({ name, phoneCode }) => {
      return {
        value: phoneCode,
        title: phoneCode + " - " + name,
      }
    }) || []

  const handleContinue = async () => {
    if (!country) {
      setIsError({ v: true, m: t("Please select a country") })
      return
    }

    setLoading(true)
    const propsIsEmail = username.includes("@")

    let emailParams: string
    if (propsIsEmail) {
      emailParams = username
    } else {
      const isValidEmail: any = validateEmail(email)
      emailParams = isValidEmail[0]
    }

    let phoneParams: string
    if (!propsIsEmail) {
      phoneParams = username
    } else {
      const phoneFull = phoneCode?.value + phoneNumber
      const isValidPhone: any = validatePhone(phoneFull)
      phoneParams = isValidPhone[0]
    }

    const business = {
      name: businessName,
      type: businessType,
      email: emailParams,
      phone: phoneParams,
      country: country.id,
      affiliateCode: affiliateCode,
    }

    try {
      await apiProvider.createBusinessProfile(business)
      await onFinish()
      await apiProvider.createLocation({
        ...location,
        name: t("Main Location"),
      })
    } catch (e: any) {
      setIsError({ ...isError, v: true, m: e?.response?.data?.message })
      console.log(e)
      setLoading(false)
    }
  }

  return (
    <div
      className="sign-up-form"
      style={{ paddingTop: "2rem", paddingBottom: "2rem" }}
    >
      <h2 style={{ alignSelf: "flex-start" }} className={"mb-4"}>
        <Trans>Set up your Business Profile</Trans>
      </h2>

      <p className={"mb-8 w-full p-0 text-gray-500"}>
        Tell us more about your business.
      </p>

      {username && username.includes("@") ? (
        <TextField
          defaultValue={username}
          // InputProps={{ readOnly: true }}
          variant="outlined"
          sx={{ width: "100%", mb: 3 }}
          label={t("Email")}
          disabled
        />
      ) : (
        <TextField
          type="text"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          sx={{ width: "100%", mb: 3 }}
          value={email}
          onChange={(event): void => setEmail(event.target.value)}
          label={t("Email")}
        />
      )}

      <TextField
        type="text"
        variant="outlined"
        value={businessName}
        onChange={(event): void => setBusinessName(event.target.value)}
        sx={{ width: "100%", mb: 3 }}
        label={t("Business Name")}
      />

      <Suspense fallback={<BusinessTypeSelectLoading />}>
        <BusinessTypeSelect onChange={setBusinessType} value={businessType} />
      </Suspense>

      <Autocomplete
        value={location.addressLine1}
        options={places.map(({ description }) => description) ?? []}
        isOptionEqualToValue={(option, value) => option === value}
        onChange={async (event, value) => {
          const geocode = await getGeocode({ address: value })

          if (geocode.length > 0) {
            let countryCode:
              | undefined
              | {
                  long_name: string
                  short_name: string
                  types: string[]
                } = geocode[0].address_components.find(
              (address: { types: any[] }) => {
                return address.types.includes("country")
              }
            )
            const country = countries?.find(
              (country) => country.codeAlpha2 === countryCode?.short_name
            )
            const state = geocode[0].address_components.find(
              (address: { types: any[] }) => {
                return address.types.includes("administrative_area_level_1")
              }
            )
            const city = geocode[0].address_components.find(
              (address: { types: any[] }) => {
                return (
                  address.types.includes("locality") ||
                  address.types.includes("administrative_area_level_2")
                )
              }
            )
            const zip = await getZipCode(geocode[0], false)

            const phoneCode = phoneCodeOptionList.find(
              (option) => option.value === country?.phoneCode
            )
            if (country && phoneCode) {
              setCountry(country)
              setPhoneCode(phoneCode)
            }
            console.log(geocode)

            setLocation({
              addressLine1: value || "",
              zip: zip || "",
              city: city?.long_name,
              state: state?.long_name,
            })
          }
        }}
        sx={{ fontSize: "0.3rem", width: "100%", mb: 3 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("Main Store Location")}
            variant="outlined"
            onChange={(event) => {
              placesAutocomplete.setValue(event.target.value)
            }}
            required
          />
        )}
      />

      <Autocomplete
        disabled
        value={country}
        options={sortBy(countries, (c) => c.label.toLowerCase()) ?? []}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) => option === value}
        onChange={(event, value) => {
          setCountry(value)
          const phoneCode = phoneCodeOptionList.find(
            (el) => el.value === value?.phoneCode
          )
          if (phoneCode) setPhoneCode(phoneCode)
        }}
        sx={{ fontSize: "0.3rem", width: "100%", mb: 3 }}
        renderInput={(params) => (
          <TextField {...params} label={"Country"} variant="outlined" />
        )}
      />

      {username && !username.includes("@") ? (
        <TextField
          type="text"
          variant="standard"
          sx={{ width: "100%", mb: 3 }}
          defaultValue={username}
          InputProps={{ readOnly: true }}
          label={t("Phone Number")}
        />
      ) : (
        <div className={"mb-8 flex w-full flex-row"}>
          <Autocomplete
            disabled
            disablePortal
            value={phoneCode || ({} as any)}
            options={phoneCodeOptionList}
            getOptionLabel={(option) => option.value}
            disableClearable
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            onChange={(event, value) => {
              if (value) setPhoneCode(value)
            }}
            sx={{ width: "40%", fontSize: "0.3rem", mr: 2 }}
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                label={t("Country Code")}
              />
            )}
          />
          <TextField
            type="number"
            variant="outlined"
            value={phoneNumber}
            onChange={(event): void => setPhoneNumber(event.target.value)}
            label={t("Phone Number")}
            sx={{ width: "60%" }}
          />
        </div>
      )}
      <TextField
        type="text"
        variant="outlined"
        disabled={!!afmc}
        onChange={(event): void => setAffiliateCode(event.target.value)}
        label={t("Affiliate Code")}
        sx={{ width: "100%" }}
        value={affiliateCode}
        helperText={t(
          " This is the code unique to each of our DizLog referral partners."
        )}
      />
      {isError.v && <p className={"m-4 italic text-red-500"}>**{isError.m}</p>}
      {username && (
        <Button
          disabled={
            loading ||
            businessName === "" ||
            !country ||
            (email === "" && !username.includes("@")) ||
            !businessType ||
            ((!phoneCode || phoneNumber === "") && username.includes("@"))
          }
          onClick={handleContinue}
          className={"mt-16 w-full"}
        >
          {loading ? t("Loading") : t("Continue to Payment")}
        </Button>
      )}
    </div>
  )
}

const PaymentOptions: FC<{
  disableRecurring?: boolean
  plan?: SubscriptionPlan
}> = ({ disableRecurring, plan }) => {
  const id = useQueryString("id")

  return (
    <>
      <h1 className={"text-lef mb-4 mt-16 w-full p-0"}>
        <Trans>Payment Method</Trans>
      </h1>
      <p className={"mb-8 w-full p-0 !text-left !text-base text-gray-700"}>
        <Trans>Choose a payment method that you'd like to use.</Trans>
      </p>

      {plan && <SubscriptionButtons plan={plan} />}
    </>
  )
}

export default DirectCheckout
