import useSWR from "swr"
import { getPublicAPI } from "../../lib/api"
import { InvoiceStatus } from "../../lib/models"
import { TProductVariant } from "../../views/BusinessSettings/PosLayout/index.types"

export type GetInvoicePayDetails = {
  id: string
  totalPrice: number
  shippingCost: number
  otherCost: number
  otherCostDetails: string
  status: InvoiceStatus
  notes: any
  invoiceNumber: string
  referenceNumber: string
  currency: string
  termsAndCondition: any
  memo: string
  fixedDiscount: number
  percentageDiscount: number
  tax: number
  createdAt: string
  updatedAt: string
  subtotal: number
  dueDate: string
  invoiceDate: string
  business: {
    id: string
    name: string
    phone: string
    email: string
  }
  orderedProducts: Array<{
    id: string
    name: string
    sellingPrice: number
    quantity: number
    discount: number
    product: {
      id: string
      name: string
      description: string
    }
    productVariant?: {
      id: string
      name: string
    }
  }>
  location: {
    id: string
    name: string
    addressLine1: string
    addressLine2: string
    city: string
    state: string
    zip: string
  }
  customer: {
    id: string
    firebaseId: string
    stripeCustomerId: any
    phone: string
    email: string
    fullName: string
    dob: string
    createdAt: string
    updatedAt: string
    lang: string
    referralCode: any
  }
  paymentMethods: Array<{
    method: string
    clientId: string
    merchantId: string
  }>
  taxDetails: Array<{
    id: string
    name: string
    rate: number
    amount: number
    type: string
  }>
}

const useInvoicePayDetails = (id: string) => {
  return useSWR<GetInvoicePayDetails>(
    id ? `/invoice/${id}/pay/details` : null,
    getPublicAPI
  )
}

export default useInvoicePayDetails
