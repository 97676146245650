/**
 * Convert hours with this format 10.5 to object {hours: 10, minutes: 30}
 * @param hours
 * @returns
 */
export const convertHoursToObject = (totalHours: number) => {
  const hours = Math.floor(totalHours)
  const minutes = Math.round((totalHours - Math.floor(totalHours)) * 60)
  return { hours, minutes }
}

/**
 * Convert minutes with this format 10.5 to object {hours: 10, minutes: 30}
 *
 * @param totalMinutes
 * @returns
 */
export const convertMinutesToObject = (totalMinutes: number) => {
  const hours = Math.floor(totalMinutes / 60)
  const minutes = Math.round(totalMinutes % 60)
  return { hours, minutes }
}
