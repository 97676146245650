import React, { FC } from "react"
import IpaySubscribeButton from "./IpaySubscribeButton"
import IpayAfricaSubscribeButton from "./IpayAfricaSubscribeButton"
import FlutterwaveSubscribeButton from "./FlutterwaveSubscribeButton"
import StripeSubscribeButton from "./StripeSubscribeButton"
import { SubscriptionPlan } from "../lib/models"
import PayPalSubscribeButton from "./PayPalSubscribeButton"
import BuxSubscribeButton from "./BuxSubscribeButton"
import useSubscriptionPaymentMethods from "../hooks/api/use-subscription-payment-methods"
import { t } from "i18next"
import RazerSubscribeButton from "./RazerSubscribeButton"
import useBusiness from "../hooks/api/use-business"
import { Trans } from "react-i18next"
import CheckoutWithCashModal from "./CheckoutWithCashModal"
import useToggle from "../hooks/use-toggle"
import DPOSubscribeButton from "./DPOSubscribeButton"
import CashfreeSubscribeButton from "./CashfreeSubscribeButton"

const SubscriptionButtons: FC<{
  plan: SubscriptionPlan
  disableRecurring?: boolean
  buttonLabel?: string
}> = ({ plan, disableRecurring, buttonLabel }) => {
  const { data: payment } = useSubscriptionPaymentMethods()
  const { data: business } = useBusiness()

  if (business?.affiliate?.isCashAllowed) {
    return <CashSubscribeButton planId={plan.planId} />
  }

  if (plan.billingFrequency === "month") {
    if (payment?.monthlySubscriptionCode === "razer-recurring") {
      return <RazerSubscribeButton disableOTP planId={plan.planId} />
    } else if (payment?.monthlySubscriptionCode === "razer-otp") {
      return <RazerSubscribeButton disableRecurring planId={plan.planId} />
    } else if (payment?.monthlySubscriptionCode === "stripe-recurring") {
      return (
        <StripeSubscribeButton stripePlanId={plan.stripePlanId} disableOTP />
      )
    } else if (payment?.monthlySubscriptionCode === "flutterwave-recurring") {
      return (
        <FlutterwaveSubscribeButton
          flwPlanId={plan.flwPlanId}
          disableOTP
          buttonLabel={buttonLabel || t("Subscribe")}
        />
      )
    } else if (payment?.monthlySubscriptionCode === "flutterwave-otp") {
      return (
        <FlutterwaveSubscribeButton
          flwPlanId={plan.flwPlanId}
          buttonLabel={buttonLabel || t("Subscribe")}
        />
      )
    } else if (payment?.monthlySubscriptionCode === "ipayafrica") {
      return <IpayAfricaSubscribeButton planId={plan.planId} />
    } else if (payment?.monthlySubscriptionCode === "bux-otp") {
      return <BuxSubscribeButton planId={plan.planId} />
    } else if (payment?.monthlySubscriptionCode === "ipay-id-otp") {
      return (
        <IpaySubscribeButton
          subscriptionPlanId={plan.id}
          planId={plan.planId}
          className={"mb-0 mt-1"}
        />
      )
    } else {
      return <PayPalSubscribeButton planId={plan.planId} disableOTP />
    }
  }

  return (
    <>
      {/* eWallet Payments */}
      {payment?.eWalletCode === "bux-otp" && (
        <BuxSubscribeButton planId={plan.planId} />
      )}
      {payment?.eWalletCode === "flutterwave-otp" && (
        <FlutterwaveSubscribeButton
          flwPlanId={plan.flwPlanId}
          disableRecurring
          buttonLabel={buttonLabel || t("Subscribe")}
        />
      )}
      {payment?.eWalletCode === "ipayafrica" && (
        <IpayAfricaSubscribeButton planId={plan.planId} />
      )}
      {payment?.eWalletCode === "ipay-id-otp" && (
        <IpaySubscribeButton
          subscriptionPlanId={plan.id}
          planId={plan.planId}
          className={"mb-0 mt-1"}
        />
      )}
      {payment?.eWalletCode === "paypal-otp" && (
        <PayPalSubscribeButton planId={plan.planId} disableRecurring />
      )}
      {payment?.eWalletCode === "stripe-otp" && (
        <StripeSubscribeButton
          stripePlanId={plan.stripePlanId || plan.planId}
          disableRecurring
        />
      )}
      {payment?.eWalletCode === "razer-otp" && (
        <RazerSubscribeButton
          disableRecurring
          planId={plan.planId}
          buttonLabel={buttonLabel || t("Subscribe")}
        />
      )}
      {payment?.eWalletCode === "dpo" && (
        <DPOSubscribeButton
          planId={plan.planId}
          buttonLabel={buttonLabel || t("Subscribe")}
        />
      )}
      {payment?.bankTransferCode === "cashfree-otp" && (
        <CashfreeSubscribeButton
          planId={plan.planId}
          buttonLabel={buttonLabel || t("Subscribe")}
          disableRecurring={plan.billingFrequency === "lifetime"}
        />
      )}
      {payment?.bankTransferCode === "cashfree-recurring" && (
        <CashfreeSubscribeButton
          planId={plan.planId}
          buttonLabel={buttonLabel || t("Subscribe")}
          disableRecurring={plan.billingFrequency === "lifetime"}
        />
      )}

      {payment?.creditCardCode !== payment?.eWalletCode ? (
        <>
          {/* Credit Card Payments */}
          {payment?.creditCardCode === "ipayafrica" && (
            <IpayAfricaSubscribeButton
              planId={plan.planId}
              buttonLabel={buttonLabel || t("Subscribe")}
            />
          )}
          {payment?.creditCardCode === "flutterwave-otp" && (
            <FlutterwaveSubscribeButton
              flwPlanId={plan.flwPlanId}
              disableRecurring
              buttonLabel={buttonLabel || t("Subscribe")}
            />
          )}
          {payment?.creditCardCode === "stripe-otp" && (
            <StripeSubscribeButton
              stripePlanId={plan.stripePlanId || plan.planId}
              disableRecurring
            />
          )}
          {payment?.creditCardCode === "razer-otp" && (
            <RazerSubscribeButton
              disableRecurring
              planId={plan.planId}
              buttonLabel={buttonLabel || t("Subscribe")}
            />
          )}
          {payment?.eWalletCode === "dpo" && (
            <DPOSubscribeButton
              planId={plan.planId}
              buttonLabel={buttonLabel || t("Subscribe")}
            />
          )}
        </>
      ) : null}

      {payment?.bankTransferCode !== payment?.eWalletCode &&
      payment?.bankTransferCode !== payment?.creditCardCode ? (
        <>
          {/* Bank Transfer Payments */}
          {payment?.bankTransferCode === "bux-otp" && (
            <BuxSubscribeButton planId={plan.planId} />
          )}
          {payment?.bankTransferCode === "flutterwave-otp" && (
            <FlutterwaveSubscribeButton
              flwPlanId={plan.flwPlanId}
              disableRecurring
              buttonLabel={buttonLabel || t("Subscribe")}
            />
          )}
          {payment?.bankTransferCode === "ipayafrica" && (
            <IpayAfricaSubscribeButton planId={plan.planId} />
          )}
          {payment?.bankTransferCode === "ipay-id-otp" && (
            <IpaySubscribeButton
              subscriptionPlanId={plan.id}
              planId={plan.planId}
              className={"mb-0 mt-1"}
            />
          )}
          {payment?.bankTransferCode === "paypal-otp" && (
            <PayPalSubscribeButton planId={plan.planId} disableRecurring />
          )}
          {payment?.bankTransferCode === "stripe-otp" && (
            <StripeSubscribeButton
              stripePlanId={plan.stripePlanId || plan.planId}
              disableRecurring
            />
          )}
          {payment?.bankTransferCode === "razer-otp" && (
            <RazerSubscribeButton
              disableRecurring
              planId={plan.planId}
              buttonLabel={buttonLabel || t("Subscribe")}
            />
          )}
          {payment?.bankTransferCode === "dpo" && (
            <DPOSubscribeButton
              planId={plan.planId}
              buttonLabel={buttonLabel || t("Subscribe")}
            />
          )}
        </>
      ) : null}
    </>
  )
}

const CashSubscribeButton: FC<{
  planId: string
}> = ({ planId }) => {
  const { data: business } = useBusiness()
  const [showPayWithCashModal, togglePayWithCashModal] = useToggle()

  return (
    <>
      <button
        className="w-xl mx-0 mt-1 block w-full rounded-xl border border-solid border-primary-800 bg-primary-800 py-3 text-center text-sm font-semibold text-white no-underline hover:bg-primary-900 disabled:cursor-not-allowed disabled:opacity-50"
        onClick={togglePayWithCashModal}
      >
        <Trans>Subscribe</Trans>
      </button>

      {business && showPayWithCashModal && (
        <CheckoutWithCashModal
          businessId={business.id}
          onClose={togglePayWithCashModal}
          planId={planId}
          originalCode={business.affiliateCode}
        />
      )}
    </>
  )
}

export default SubscriptionButtons
