import React, { FC, ReactNode } from "react"
import { Autocomplete, TextField, styled } from "@mui/material"
import clsx from "clsx"

const CssTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    height: "43px",
    borderRadius: "7px",
  },
  "& .MuiInputBase-input": {
    padding: "0px !important",
  },
  "& label.Mui-focused": {
    color: "#A0AAB4",
  },
  "& .MuiInput-underline:after": {
    borderBottom: "none",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(75, 85, 99, 0.2)",
    },
    "&:hover fieldset": {
      borderColor: "rgba(75, 85, 99, 0.2)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#F09000",
    },
  },
})

const AutoComplete: FC<{
  label: string
  options: any[]
  value: any
  onChange: (event: any, newValue: any) => void
  getOptionLabel?: (option: any) => string
  renderOption?: (props: any, option: any) => ReactNode
  disabled?: boolean
  required?: boolean
  disablePortal?: boolean
  multiple?: boolean
  groupBy?: (option: any) => any
  isOptionEqualToValue?: (option: any, value: any) => boolean
  className?: string
  renderInputOnChange?: (event: any) => void
  sx?: any
}> = ({
  label,
  options,
  value,
  onChange,
  getOptionLabel,
  renderOption,
  disabled,
  required,
  disablePortal,
  multiple,
  groupBy,
  isOptionEqualToValue,
  className,
  renderInputOnChange,
  sx,
}) => {
  return (
    <div className={clsx("flex flex-col", className)}>
      <label
        className={
          "mb-1 text-sm font-medium opacity-80 peer-disabled:opacity-50"
        }
      >
        {label}
        {required && <span className="font-semibold text-red-300">*</span>}{" "}
      </label>
      <Autocomplete
        disablePortal={disablePortal}
        options={options}
        renderInput={
          renderInputOnChange
            ? (params: any) => (
                <CssTextField
                  {...params}
                  onChange={(e) => renderInputOnChange(e)}
                />
              )
            : (params: any) => <CssTextField {...params} />
        }
        value={value}
        onChange={(event: any, newValue: any) => onChange(event, newValue)}
        disabled={disabled}
        getOptionLabel={getOptionLabel}
        renderOption={renderOption}
        multiple={multiple}
        groupBy={groupBy}
        sx={sx || {}}
        isOptionEqualToValue={isOptionEqualToValue}
      />
    </div>
  )
}

export default AutoComplete
