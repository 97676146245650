import React from "react"
import { LoginLayout } from "./layouts"
import Login from "./views/Auth/Login"
import SignUp from "./views/Auth/SignUp"
import ForwardingToken from "./views/Auth/ForwardingToken"
import ForgotPassword from "./views/Auth/ForgotPassword"
import NewPassword from "./views/Auth/NewPassword"
import GettingStarted from "./views/Auth/GettingStarted"
import ENiaga, { ENiagaLayout } from "./views/ENiagaRahmah/Login"
import CustomerDisplayLayout from "./layouts/CustomerDisplayLayout"
import CustomerDisplay from "./views/BusinessSettings/Customers/CustomerDisplay"

export interface RouteInterface {
  id: number
  path: string
  exact: boolean
  layout: React.ReactElement
  component: React.ReactElement
}

const routes = [
  {
    id: 1,
    path: "/",
    exact: true,
    layout: LoginLayout,
    component: Login,
  },
  {
    id: 2,
    path: "/sign-up",
    exact: true,
    layout: LoginLayout,
    component: SignUp,
  },
  {
    id: 3,
    path: "/forgot-password",
    exact: true,
    layout: LoginLayout,
    component: ForgotPassword,
  },
  {
    id: 4,
    path: "/forwarding-token",
    exact: true,
    layout: LoginLayout,
    component: ForwardingToken,
  },
  {
    id: 5,
    path: "/forgot-password",
    exact: true,
    layout: LoginLayout,
    component: ForgotPassword,
  },
  {
    id: 6,
    path: "/new-password",
    exact: true,
    layout: LoginLayout,
    component: NewPassword,
  },
  {
    id: 6,
    path: "/eniaga",
    exact: true,
    layout: ENiagaLayout,
    component: ENiaga,
  },
  {
    id: 7,
    path: "/customer-display",
    exact: true,
    layout: CustomerDisplayLayout,
    component: CustomerDisplay,
  },
  // {
  //   id: 7,
  //   path: "/getting-started",
  //   exact: true,
  //   layout: LoginLayout,
  //   component: GettingStarted,
  // },
]

export default routes
