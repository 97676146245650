import useAPI from "./use-api"
import { SWRConfiguration } from "swr"
import { ZReportResponse } from "./use-z-report"
import { XReportResponse } from "./use-x-report"

type Query = Partial<{
  startDate: string
  endDate: string
  isService: boolean
  location: string
  inventoryId?: string
  join?: string | string[]
}>

export type GetBIRJournalResponse = Array<{
  id: string
  buxPaymentStatus: string
  totalCartAmount: number
  buxRequestId: string
  created_at: string
  updated_at: string
  createdBy: string
  updatedBy?: string
  tax: number
  shippingCost: number
  businessId: string
  promotionId: any
  customerId?: string
  orderStatus: string
  isMpos: boolean
  locationId: string
  discount: number
  orderNumber: string
  pickupTime: any
  promotions?: Array<string>
  isServiceOrder: boolean
  pickupLocationId: any
  serviceCharge: number
  cashReceived: number
  cashChange: number
  appointmentId: any
  dueDate: any
  paymentId: any
  paymentMethodCode: string
  customerLocationId: any
  outstanding: number
  notes: string
  isWalkIn: boolean
  customerName: string
  cashRegisterId: string
  totalItemQuantity: number
  paymentChannel?: string
  rewardedPoints?: number
  serviceChargePercentage: number
  taxPercentage: number
  loyaltyRewardId: any
  taxes: Array<{
    id: string
    rate: number
    name: string
    type: string
    applyTo: string
    option: string
    accountType: any
    amount: number
  }>
  source: string
  rewardedCashback: any
  cashbackApplied: number
  isB2B: boolean
  paymentTermId: any
  ticketId: any
  promotionMetadata?: {
    type: string
    pwdCount?: number
    seniorCitizenCount?: number
    pnstmId?: string
    customer?: {
      id: string
      dob: string
      lang: string
      email: string
      phone: string
      taxId: string
      gender: any
      points: number
      cashback: number
      fcmToken: any
      fullName: string
      termType: string
      createdAt: string
      priceList: Array<any>
      updatedAt: string
      firebaseId: string
      totalSpend: number
      customerType: string
      paymentTerms: Array<any>
      referralCode: any
      encrypted_dob: any
      approvalStatus: string
      enableCashback: boolean
      membershipCode: string
      openingBalance: number
      encrypted_phone: any
      seniorCitizenId: string
      stripeCustomerId: any
      customerLocations: Array<any>
      cashbackPercentage: any
      membershipExpiryDate: any
      enableReceiveMarketingEmail: boolean
      notes?: string
      encrypted_email: any
      business?: Array<{
        id: string
        pin: string
        code: number
        lang: string
        name: string
        type: string
        admin: any
        email: string
        phone: string
        endTime: any
        logoUrl: any
        website: any
        deviceId: any
        isActive: boolean
        taxMonth: any
        createdAt: string
        enableBIR: boolean
        startTime: any
        storeName: any
        targetArn: any
        typeGroup: any
        updatedAt: string
        catalogCode: any
        contactName: any
        description: any
        productType: any
        salaryCycle: any
        workDayTime: {
          friday: {
            endTime: string
            startTime: string
            breakEndTime: string
            breakStartTime: string
          }
          monday: {
            endTime: string
            startTime: string
            breakEndTime: string
            breakStartTime: string
          }
          sunday: {
            endTime: string
            startTime: string
            breakEndTime: string
            breakStartTime: string
          }
          tuesday: {
            endTime: string
            startTime: string
            breakEndTime: string
            breakStartTime: string
          }
          saturday: {
            endTime: string
            startTime: string
            breakEndTime: string
            breakStartTime: string
          }
          thursday: {
            endTime: string
            startTime: string
            breakEndTime: string
            breakStartTime: string
          }
          wednesday: {
            endTime: string
            startTime: string
            breakEndTime: string
            breakStartTime: string
          }
        }
        workingDays: number
        businessType: string
        referralCode: any
        weekWorkdays: any
        affiliateCode: string
        defaultDomain: any
        deviceLatitude: any
        financialMonth: any
        deviceLongitude: any
        isQRcodeEnabled: boolean
        onlyCashPayment: boolean
        payslipTemplate: any
        accountingMethod: any
        businessCurrency: string
        taxInvoiceNumber: any
        multiWalletEnabled: boolean
        statutoryCompliance: boolean
        registrationLocation: any
        isNotificationsEnable: boolean
        registeredCompanyName: string
        isFaceRecognitionEnable: boolean
        isSelfManagementEnabled: boolean
        manuallyApproveCustomer: boolean
        gapBetweenAppointmentTime: number
        isGPSLocationTrackingEnable: boolean
        isAttendancePresentByDefault: boolean
      }>
      projects?: Array<any>
      businessLocations?: Array<{
        id: string
        tin: string
        zip: string
        city: string
        name: string
        state: string
        latCoord: number
        createdAt: string
        longCoord: number
        updatedAt: string
        birEnabled: boolean
        companyName: string
        addressLine1: string
        addressLine2: string
        birAccredited: boolean
        birIndustryType: string
        isVatRegistered: string
        isPrimaryLocation: boolean
      }>
    }
  }
  isDeliveryOrder: any
  deliverectOrderType: any
  deliverectOrderId: string
  deliverectChannelOrderDisplayId: string
  deliverectDeliveryTime: any
  deliverectPickupTime: any
  isPickup: boolean
  location: {
    id: string
    name: string
    addressLine1: string
    addressLine2: string
    city: string
    state: string
    zip: string
    isPrimaryLocation: boolean
    created_at: string
    updated_at: string
    businessId: string
    latCoord: number
    longCoord: number
    isVatRegistered: string
    tin: string
    companyName: string
    birAccredited: boolean
    birIndustryType: string
    birEnabled: boolean
  }
  customer?: {
    id: string
    phone: string
    email: string
    fullName: string
    dob: string
    created_at: string
    updated_at: string
    firebaseId: string
    stripeCustomerId: any
    lang: string
    referralCode: any
    fcmToken: any
    gender: any
    enableReceiveMarketingEmail: boolean
    points: number
    taxId: string
    termType: string
    customerType: string
    enableCashback: boolean
    cashbackPercentage: any
    cashback: number
    approvalStatus: string
    openingBalance: number
    membershipCode: string
    seniorCitizenId: string
    encrypted_phone: any
    encrypted_dob: any
    encrypted_email: any
    temp_email: any
    membershipExpiryDate: any
    notes: string
  }
  refund?: {
    id: string
    notes: string
    created_at: string
    updated_at: string
    type: string
    businessId: string
    customerId?: string
    cartTransactionId: string
    isReturned: boolean
    isRefunded: boolean
    manualRefund: boolean
    status: string
    acceptedDate: string
    amount: number
    shippingCost: number
  }
  cashRegister: {
    id: string
    name: string
    licenseKey?: string
    controlToken?: string
    created_at: string
    updated_at: string
    businessId: string
    locationId: string
    isActive: boolean
    min: string
    serialNo: string
    ptu: string
    ptuDateIssued: string
    ptuValidUntil: string
  } | null
  orderedProducts: Array<{
    id: string
    SKU: string
    name: string
    purchaseOrderId: any
    addOns?: Array<{
      id: string
      name: string
      sellingPrice: number
      quantity: number
    }>
    discount: number
    quantity: number
    orderDate: string
    productId: string
    created_at: string
    totalPrice: number
    sellingPrice: number
    unitOfMeasure?: string
    productVariants?: Array<{
      id: string
      name: string
    }>
    enableBIR: boolean
    taxable: boolean
  }>
  ORNumber: string
  seriesNumber: string
  type: string
  rowType: string
  grandTotal: number
  cashierName: string
  taxExemptions: Array<
    | {
        id: string
        name: string
        rate: number
        type: string
        amount: number
        cartTransactionId: string
      }
    | undefined
  >
  promotionDetails: Array<
    | {
        id: string
        code: string
        name: string
        endTime?: string
        category?: string
        isActive: boolean
        startTime: string
        businessId?: string
        created_at: string
        limitTotal: any
        updated_at: string
        usageCount: number
        minDuration: any
        discountType: string
        taxExemption: boolean
        maxUsesPerOrder: any
        minItemQuantity: number
        isProductGotFree: boolean
        restrictedAccess: boolean
        discountApplyType: string
        minPurchaseAmount: number
        absoluteUsageLimit: number
        discountPercentage?: number
        productGotQuantity: any
        discountFixedAmount?: number
        isAppliedPerQuantity: boolean
        productGotPercentage: any
        maxFreeShippingAmount: any
        perCustomerUsageLimit: number
        subsriptionServiceIds: any
        isAppliedToAllProducts: boolean
        isAppliedToAllCountries: boolean
        isAppliedToAllCustomers: boolean
        subsriptionPublicityIds: any
        enableAbsoluteUsageLimit: boolean
        isLimitedToOnePerCustomer: boolean
        enablePerCustomerUsageLimit: boolean
      }
    | undefined
  >
  nonTaxDiscounts: number
  birZReport: ZReportResponse[number] | null
  birXReport: XReportResponse[number] | null
}>

const useBIRJournal = (query?: Query, options?: SWRConfiguration) => {
  return useAPI<GetBIRJournalResponse>(
    `/reporting/cartTransaction/bir-journal`,
    query,
    options
  )
}

export default useBIRJournal
