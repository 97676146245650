import Peer from "peerjs"
import { nanoid } from "nanoid"

const url = new URL(process.env.REACT_APP_BACKEND_API || "")

const createPeer = () => {
  try {
    return new Peer({
      host: url.hostname,
      port: url.port ? Number(url.port) : 443,
      path: "/peer",
      secure: url.hostname !== "localhost",
    })
  } catch (e) {
    console.log(e)
    return null
  }
}

export const createPeerAsync = (): Promise<Peer | null> => {
  return new Promise((resolve, reject) => {
    try {
      const peer = new Peer({
        host: url.hostname,
        port: url.port ? Number(url.port) : 443,
        path: "/peer",
        secure: url.hostname !== "localhost",
      })

      peer.on("open", () => {
        resolve(peer)
      })

      peer.on("error", (err) => {
        console.error(err)
        reject(err)
      })
    } catch (e) {
      console.error(e)
      reject(e)
    }
  })
}

export default createPeer
