import React, { FC } from "react"
import clsx from "clsx"
import styles from "./Skeleton.module.css"

const Skeleton: FC<{ className: string }> = ({ className, children }) => {
  return (
    <div className={clsx("rounded-lg", className, styles.skeleton)}>
      {children}
    </div>
  )
}

export default Skeleton
