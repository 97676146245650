import useAPI from "./use-api"
import { SWRConfiguration } from "swr"

export const REDIRECT_BASE_URL =
  process.env.NODE_ENV === "production"
    ? `https://${window.location.hostname}`
    : `https://dev-web.dizlog.com`

const useXeroAccessToken = (code?: string, option?: SWRConfiguration) => {
  return useAPI<{ success: boolean }>(
    code ? "/xero-account/access-token" : null,
    {
      code,
      redirectUrl: REDIRECT_BASE_URL + "/verify-xero",
    },
    option
  )
}

export default useXeroAccessToken
