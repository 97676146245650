// make sure i18next is initialized first, so that global variables can be translated.
import "./i18n"
import "./index.css"
import "@fontsource/roboto/700.css"
import "@fontsource/roboto/400.css"
import "@fontsource/raleway/400.css"
// import "@fontsource/chilanka"
import React from "react"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"
import { createRoot } from "react-dom/client"
import useThermalPrinter from "./hooks/use-thermal-printer"
import * as Sentry from "@sentry/react"
import { IS_PROD } from "./constants/Config"
import { createBrowserHistory } from "history"
import { Router } from "react-router-dom"

// @ts-ignore
window.VERSION = "v" + process.env.REACT_APP_VERSION

const history = createBrowserHistory()

Sentry.init({
  dsn: "https://7c6bba487529b932de8ec95697a9ec0b@o4508063332696064.ingest.us.sentry.io/4508063334727680",
  integrations: [
    Sentry.reactRouterV5BrowserTracingIntegration({ history }),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
  ],
  // // Tracing
  tracesSampleRate: 0.2,
  // // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    "dev-payroll.dizlog.com",
    "api-payroll2.dizlog.com",
  ],
  // tracePropagationTargets: [],
  // // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 0.2, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: IS_PROD ? "production" : "development",
  ignoreErrors: [
    "messaging/unsupported-browser",
    "User cancelled the requestDevice() chooser.",

    // Ignore network errors
    "NetworkError: Connection failed for unknown reason.",
    "AxiosError: Network Error",
    "Request aborted",

    // ignore harmless imin related printer error
    "No paper feed found",
    "Printer is running out of paper",
    "Printer is opened",
    "ALREADY_WATCHING_ADVERTISEMENTS",
    "Connection attempt failed.",
    "timeout exceeded",
  ],
  release: process.env.REACT_APP_VERSION,
  profilesSampleRate: 1,
})

const container = document.getElementById("root")
const root = createRoot(
  container as any,
  {
    // Callback called when an error is thrown and not caught by an ErrorBoundary.
    onUncaughtError: Sentry.reactErrorHandler((error, errorInfo) => {
      console.warn("Uncaught error", error, errorInfo.componentStack)
    }),
    // Callback called when React catches an error in an ErrorBoundary.
    onCaughtError: Sentry.reactErrorHandler(),
    // Callback called when React automatically recovers from errors.
    onRecoverableError: Sentry.reactErrorHandler(),
  } as any
)

root.render(
  <React.StrictMode>
    <Router history={history}>
      <App />
    </Router>
  </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

window.addEventListener("beforeunload", async () => {
  await useThermalPrinter.getState().disconnectAll()
})
