import ThermalPrinter from "./model"
import * as Sentry from "@sentry/react"
import { sleep } from "../async"

const THERMAL_SERVICE_UUID = "000018f0-0000-1000-8000-00805f9b34fb"
const PRINT_CHARACTERISTIC_UUID = "00002af1-0000-1000-8000-00805f9b34fb"
const UART_SERVICE_UUID = "49535343-fe7d-4ae5-8fa9-9fafd205e455"
const UART_RX_CHARACTERISTIC_UUID = "49535343-8841-43f4-a8d4-ecbe34729bb3"

export default class BluetoothThermalPrinter implements ThermalPrinter {
  printer: BluetoothDevice | null = null
  server: BluetoothRemoteGATTServer | null = null
  service: BluetoothRemoteGATTService | null = null
  characteristic: BluetoothRemoteGATTCharacteristic | null = null
  mode: "THERMAL" | "UART" = "THERMAL"

  async getConnectedDeviceIds(): Promise<string[]> {
    const devices = await navigator.bluetooth.getDevices()
    return devices.map((device) => device.id)
  }

  async connect(): Promise<void> {
    await this.connectBluetooth()
  }

  async print(data: Uint8Array): Promise<void> {
    if (!this.characteristic) {
      throw new Error("CHARACTERISTIC_NOT_FOUND")
    }

    const dataLength = data.length
    // split into 512 bytes
    const chunkSize = 100
    const chunks = []
    for (let i = 0; i < dataLength; i += chunkSize) {
      chunks.push(data.slice(i, i + chunkSize))
    }

    if (!this.printer?.gatt?.connected) {
      await this.refreshConnection()
    }

    for (let chunk of chunks) {
      if (this.characteristic.writeValueWithResponse) {
        await this.characteristic.writeValueWithResponse(chunk)
      } else if (this.characteristic.writeValueWithoutResponse) {
        await this.characteristic.writeValueWithoutResponse(chunk)
      } else {
        await this.characteristic.writeValue(chunk)
      }
      await sleep(100)
    }
  }

  async refreshConnection() {
    await this.printer?.gatt?.connect()

    const service = await this.server?.getPrimaryService(
      this.mode === "THERMAL" ? THERMAL_SERVICE_UUID : UART_SERVICE_UUID
    )
    if (!service) {
      throw new Error("SERVICE_NOT_FOUND")
    }
    this.service = service

    const characteristic = await service.getCharacteristic(
      this.mode === "THERMAL"
        ? PRINT_CHARACTERISTIC_UUID
        : UART_RX_CHARACTERISTIC_UUID
    )
    if (!characteristic) {
      throw new Error("CHARACTERISTIC_NOT_FOUND")
    }
    this.characteristic = characteristic
  }

  async disconnect(): Promise<void> {
    if (this.server) {
      this.server.disconnect()
    }
  }

  getId(): string {
    return this.printer?.id || ""
  }

  onDisconnect(callback: { (): void }) {
    if (this.printer) {
      this.printer.ongattserverdisconnected = callback
    }
  }

  async reconnect(deviceId: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        const devices = await navigator.bluetooth.getDevices()
        const printerDevice = devices.find((device) => device.id === deviceId)
        if (!printerDevice) {
          Sentry.setContext("bluetooth thermal", {
            deviceId,
            pairedDevices: devices.map((device) => device.id),
          })
          reject(new Error("DEVICE_NOT_FOUND"))
          return
        }

        if (!printerDevice.watchingAdvertisements) {
          await printerDevice.watchAdvertisements()
          printerDevice.onadvertisementreceived = async () => {
            await this.connectBluetooth(printerDevice)
            if (!this.printer) {
              reject(new Error("PRINTER_NOT_FOUND"))
              return
            }
            resolve()
          }
        } else {
          reject(new Error("ALREADY_WATCHING_ADVERTISEMENTS"))
        }
      } catch (error) {
        reject(error)
      }
    })
  }

  async connectBluetooth(defaultPrinter?: BluetoothDevice): Promise<void> {
    let printer = defaultPrinter
    if (!printer) {
      printer = await navigator.bluetooth.requestDevice({
        acceptAllDevices: true,
        optionalServices: [THERMAL_SERVICE_UUID, UART_SERVICE_UUID],
      })
    }
    if (!printer.gatt) {
      throw new Error("GATT_SERVER_NOT_FOUND")
    }

    const server = await printer.gatt?.connect()
    const services = await server.getPrimaryServices()
    const thermalService = services.find(
      (service) => service.uuid === THERMAL_SERVICE_UUID
    )
    if (thermalService) {
      const thermalCharacteristic = await thermalService.getCharacteristic(
        PRINT_CHARACTERISTIC_UUID
      )
      // return {
      //   printer,
      //   server,
      //   service: thermalService,
      //   characteristic: thermalCharacteristic,
      // }
      this.printer = printer
      this.server = server
      this.service = thermalService
      this.characteristic = thermalCharacteristic
      this.mode = "THERMAL"
    }

    const uartService = services.find(
      (service) => service.uuid === UART_SERVICE_UUID
    )
    if (!uartService) {
      throw new Error("SERVICE_NOT_FOUND")
    }
    const uartCharacteristic = await uartService.getCharacteristic(
      UART_RX_CHARACTERISTIC_UUID
    )

    this.printer = printer
    this.server = server
    this.service = uartService
    this.characteristic = uartCharacteristic
    this.mode = "UART"
  }
}
