import React, { useEffect, useMemo, useState } from "react"
import PageHeader from "../../../../components/PageHeader"
import { t } from "i18next"
import useLocations from "../../../../hooks/api/use-locations"
import Datepicker, {
  initStartDateToday,
  initEndDate,
} from "../../../../components/atom/datepicker/datepicker"
import Button from "../../../../components/Button"
import moment from "moment"
import MultiLocationSelect from "../../../../components/MultiLocationSelect"
import { getCartTransactionReport } from "../../../../lib/cart-transaction-report"
import _ from "lodash"
import Select from "../../../../components/Select"
import Chart from "../../../../components/atom/highcharts/hightcharts"
import { CSVLink } from "react-csv"
import chartHelper from "../../../../utils/chartHelper"
import useBusinessCurrency from "../../../../hooks/use-business-currency"
import LoadingSpinner from "../../../../components/LoadingSpinner"
import usePrimaryLocation from "../../../../hooks/api/use-primary-location"
import { GetCartTransactionReportResponse } from "../../../../hooks/api/use-cart-transaction-report"

function PeakSalesTimeReport(): React.ReactElement {
  const Title = t("Peak Sales Time")
  const currency = useBusinessCurrency()
  const { data: primaryLocation } = usePrimaryLocation()
  const { data: businessLocations } = useLocations()
  const [startDate, setStartDate] = useState<string>(initStartDateToday)
  const [endDate, setEndDate] = useState<string>(initEndDate)
  const [reportIntervalType, setReportIntervalType] = useState("hour")
  const [peakSalesTimeExportData, setPeakSalesTimeExportData] = useState<
    GetCartTransactionReportResponse["PEAK_SALES_TIME_SUMMARY"][]
  >([])
  const [locations, setLocations] = useState<any[]>(
    primaryLocation ? [primaryLocation.id] : []
  )
  const [isLoading, setIsLoading] = useState(false)
  const [allReportData, setAllReportData] = useState<any[]>([])
  const [metric, setMetric] = useState("avg_sales_amount")

  useEffect(() => {
    getAllReportData()
  }, [startDate, endDate, locations, reportIntervalType])

  const getAllReportData = async () => {
    setIsLoading(true)
    let all = [] as any[]
    let exportData = [] as any[]
    await Promise.all(
      locations.map(async (locationId) => {
        const data = await getCartTransactionReport("PEAK_SALES_TIME_SUMMARY", {
          startDate,
          endDate,
          location: locationId,
          reportType: reportIntervalType,
        })
        const locationName =
          businessLocations?.find((l) => l.id === locationId)?.name || ""
        const locationData =
          data?.map((item: any) => ({
            ...item,
            datetime:
              reportIntervalType === "hour"
                ? moment(item.datetime).format("MM-DD HH:mm")
                : moment(item.datetime).format("YYYY-MM-DD"),
            avg_sales_amount: parseFloat(item.avg_sales_amount || 0),
            transaction_count: parseInt(item.transaction_count || 0),
            unique_customer_count: parseInt(item.unique_customer_count || 0),
            locationName: locationName,
          })) || []
        exportData = exportData.concat(locationData)
        all.push({
          locationId,
          data: locationData,
        })
      })
    )
    setAllReportData(all)
    setPeakSalesTimeExportData(exportData)
    setIsLoading(false)
  }

  const reportData = useMemo(() => {
    let data = [] as any[]
    allReportData.map((d) => {
      data = data.concat(d.data)
    })
    //get all unique dates
    let uniqueDates = _.uniq(_.map(data, "datetime"))
    if (reportIntervalType === "hour") uniqueDates = uniqueDates.slice(-168) //show time for last 7 days only
    //loop through all unique dates and get the sum of each metric
    let reportData = uniqueDates.map((datetime) => {
      let dateData = data.filter((d) => d.datetime === datetime)
      let sum = {
        datetime,
        avg_sales_amount: _.meanBy(dateData, "avg_sales_amount"),
        transaction_count: _.sumBy(dateData, "transaction_count"),
        unique_customer_count: _.sumBy(dateData, "unique_customer_count"),
      }
      return sum
    })
    return reportData
  }, [allReportData, metric])

  const chartOpt = chartHelper.peakSalesTimeOpt(
    metric,
    currency,
    {
      x: _.map(reportData || [], "datetime"),
      y: _.map(reportData || [], metric),
    },
    allReportData.map((data) => ({
      name:
        businessLocations?.find((l) => l.id === data.locationId)?.name || "",
      data: reportData.map((item) => {
        let datetime = item.datetime
        let dateData = data.data.filter((d: any) => d.datetime === datetime)
        let sum = {
          datetime,
          avg_sales_amount: _.meanBy(dateData, "avg_sales_amount") || 0,
          transaction_count: _.sumBy(dateData, "transaction_count") || 0,
          unique_customer_count:
            _.sumBy(dateData, "unique_customer_count") || 0,
        } as any
        return sum[metric]
      }),
      //generate random color
      color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
      type: "line",
    }))
  )

  return (
    <div className="flex w-full flex-col p-4">
      <PageHeader title={Title} hasGoBack />
      <div className="my-4 flex flex-row gap-4">
        <Datepicker
          value={{ endDate, startDate }}
          onChange={(e: any) => {
            setStartDate(e.startDate)
            setEndDate(e.endDate)
          }}
        />
        <MultiLocationSelect
          value={locations}
          onChange={(e) => setLocations(e)}
        />
        <Select
          label={"View by"}
          value={reportIntervalType}
          onChange={(e) => {
            setReportIntervalType(e.target.value as string)
          }}
        >
          <option value={"hour"}>Hour</option>
          <option value={"day"}>Day</option>
          <option value={"week"}>Week</option>
          <option value={"month"}>Month</option>
        </Select>
        <Select
          label={"Metric"}
          value={metric}
          onChange={(e) => setMetric(e.target.value)}
          selectClassName="capitalize"
        >
          {[
            "avg_sales_amount",
            "transaction_count",
            "unique_customer_count",
          ].map((item) => (
            <option key={item} value={item} className="capitalize">
              {t(item.replace(/_/g, " "))}
            </option>
          ))}
        </Select>
      </div>

      {isLoading ? (
        <div className="flex w-full items-center justify-center border-t py-32 text-primary-900">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="bg-white p-4 pt-8 min-h-[428px] relative">
          <Chart options={chartOpt} />
          <div className="absolute top-8 right-6">
            <CSVLink
              headers={[
                { label: "Datetime", key: "datetime" },
                { label: "Average sales amount", key: "avg_sales_amount" },
                { label: "Number of transactions", key: "transaction_count" },
                { label: "Number of customers", key: "unique_customer_count" },
                { label: "Location", key: "locationName" },
              ]}
              data={peakSalesTimeExportData}
              filename={`peak_sales_time_${Date.now()}.csv`}
              style={{ textDecoration: "none", width: "100%" }}
            >
              <Button
                variant="outlinedSmall"
                className="w-full"
                disabled={isLoading || !reportData.length}
              >
                {t("Export")}
              </Button>
            </CSVLink>
          </div>
        </div>
      )}
    </div>
  )
}

export default PeakSalesTimeReport
