import Select from "./Select"
import { t } from "i18next"
import useEmployees from "../hooks/api/use-employees"
import { FC } from "react"

const SupplierSelect: FC<{
  label?: string
  disabled?: boolean
  required?: boolean
  value?: string
  onChange?: (value: string) => void
  containerClassName?: string
  selectClassName?: string
  hideLabel?: boolean
  enableAll?: boolean
}> = ({
  value,
  onChange,
  required,
  containerClassName,
  selectClassName,
  hideLabel,
  disabled,
  label,
  enableAll,
}) => {
  const { data: employees } = useEmployees()

  return (
    <Select
      label={label || t("Employees (Optional)")}
      disabled={disabled}
      containerClassName={containerClassName}
      selectClassName={selectClassName}
      value={value}
      hideLabel={hideLabel}
      required={required}
      onChange={(e) => {
        if (onChange) onChange(e.target.value)
      }}
    >
      <option value="" disabled>
        Select an employee
      </option>
      {enableAll && <option value="all">All</option>}
      {employees
        ?.filter((e) => e.isActive)
        ?.map((e) => (
          <option key={e.id} value={e.id}>
            {e.fullName}
          </option>
        ))}
    </Select>
  )
}

export default SupplierSelect
