import { create } from "zustand"
import { combine } from "zustand/middleware"
import { Alert, AlertColor, Snackbar as MuiSnackbar } from "@mui/material"
import { useEffect } from "react"
import { ReactComponent as AlertSuccess } from "../assets/icons/alert_success.svg"
import { ReactComponent as AlertError } from "../assets/icons/alert_error.svg"
import { ReactComponent as AlertWarning } from "../assets/icons/alert_warning.svg"
import { ReactComponent as AlertInfo } from "../assets/icons/alert_info.svg"
import HTMLReactParser from "html-react-parser"

const icons = {
  success: <AlertSuccess fill="#3AA76D" />,
  error: <AlertError fill="#FF0000" />,
  warning: <AlertWarning fill="##F2C94C" />,
  info: <AlertInfo fill="#0061FF" />,
}

const DEFAULT_SNACKBAR_STATE = {
  message: "",
  severity: "success" as AlertColor,
  visible: false,
}

const useSnackbarStore = create(
  combine(DEFAULT_SNACKBAR_STATE, (set, get) => ({
    showSnackbar: (message: string, severity = "success" as AlertColor) => {
      set({ message, severity, visible: true })
    },
    hide: () => {
      set({ ...get(), visible: false })
    },
  }))
)

const Snackbar = () => {
  const { message, severity, visible, hide } = useSnackbarStore(
    ({ message, severity, visible, hide }) => ({
      message,
      severity,
      visible,
      hide,
    })
  )

  useEffect(() => {
    let timeout: NodeJS.Timeout
    if (visible) {
      timeout = setTimeout(
        hide,
        message === "Item has been added to your shopping cart" ? 1000 : 4000
      )
    }
    return () => clearTimeout(timeout)
  }, [hide, visible])

  return (
    <MuiSnackbar
      onClose={hide}
      open={visible}
      autoHideDuration={10000}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      classes={{ root: "!top-8 !right-8" }}
    >
      <Alert
        severity={severity}
        classes={{
          root: "!rounded-xl !font-medium flex flex-row items-center text-blue-300",
          standardSuccess:
            "!bg-green-50 border border-solid border-green-300 !shadow",
          standardError: "!bg-red-50 !shadow",
          standardInfo: "!bg-blue-50 !shadow",
          standardWarning:
            "!bg-yellow-50 !shadow border border-solid border-yellow-300",
        }}
        icon={icons[severity]}
      >
        <div>
          <div className="text-md font-semibold capitalize">{severity}</div>
          <div>{HTMLReactParser(message || "")}</div>
        </div>
      </Alert>
    </MuiSnackbar>
  )
}

export const useShowSnackbar = () => {
  return useSnackbarStore(({ showSnackbar }) => showSnackbar)
}

export default Snackbar
