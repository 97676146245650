import { useIntercom } from "react-use-intercom"
import { QuestionMark } from "@mui/icons-material"
import React from "react"

const HelpButton = () => {
  const { show } = useIntercom()
  return (
    <button
      className="ml-2 flex h-[36px] w-[36px] flex-row items-center rounded-lg bg-white px-2 py-1 text-sm font-bold text-primary-900"
      onClick={show}
    >
      <QuestionMark />
    </button>
  )
}

export default HelpButton
