import useAPI from "./use-api"
import { SWRConfiguration } from "swr"

interface Country {
  code: string
  codeAlpha2: string
  currency: string
  id: number
  label: string
  name: string
  phoneCode: string
  serviceCharge: number
  tax: number
}

const useCountries = (config?: SWRConfiguration) => {
  return useAPI<Country[]>("/country", undefined, config)
}

export default useCountries
