import React, { FC, ReactNode } from "react"
import useEmployeeMe from "../hooks/api/use-employee-me"
import { RolePermissions } from "../lib/models"
import useSubscription from "../hooks/api/use-subscription"
import {
  SUBSCRIPTION_ONLY_PERMISSIONS,
  SUBSCRIPTION_RELATED_PERMISSIONS,
} from "../hooks/use-authorization-control"
import { Trans } from "react-i18next"
import LoadingSpinner from "./LoadingSpinner"
import useEmployees from "../hooks/api/use-employees"
import useBusiness from "../hooks/api/use-business"

export const usePermissionCheck = () => {
  const { data: allEmployees } = useEmployees()
  const { data: myDetails } = useEmployeeMe()
  const { data: business } = useBusiness()

  return {
    permissionCheck: (pin: any, permission: string) => {
      let hasPermission = false
      const findByPin = allEmployees?.filter((employee) => employee.pin === pin)
      if (pin === business?.pin) {
        myDetails?.permission.forEach((el: any) => {
          if (el.permission === permission) {
            hasPermission = true
          }
        })
      } else {
        findByPin?.[0]?.permissions.forEach((el: any) => {
          if (el === permission) {
            hasPermission = true
          }
        })
      }
      return hasPermission
    },
  }
}

const AuthorizationControl: FC<{
  /** by default, if one of the permission is authorized, the children will be shown. */
  permission: RolePermissions[]
  customNotAuthorizedView?: ReactNode
  notAuthorizedText?: string
  /** When the restriction is based on item count, you can pass in the total count of current items. */
  restrictedItemCount?: number
  /** Shown when access is outside of user's current subscription */
  customNotSubscribedView?: ReactNode
  disabled?: boolean
}> = ({
  children,
  permission,
  customNotAuthorizedView,
  notAuthorizedText,
  customNotSubscribedView,
  restrictedItemCount,
  disabled,
}) => {
  const { data: subscription } = useSubscription()
  const { data: myDetails } = useEmployeeMe()

  if (!myDetails) {
    return (
      <div className="w-full py-32 flex items-center justify-center text-primary-900">
        <LoadingSpinner />
      </div>
    )
  }

  if (disabled) {
    return <>{children}</>
  }

  // skip subscription check if permission is not used by subscription.
  const subscriptionPermissions =
    permission.filter((p) => SUBSCRIPTION_RELATED_PERMISSIONS.includes(p)) ?? []

  if (subscriptionPermissions.length > 0 && !subscription?.isLegacyPlan) {
    const permissions =
      subscription?.features?.filter((f) =>
        subscriptionPermissions.includes(f.id as RolePermissions)
      ) ?? []

    const belowPermissionLimit = permissions.every((p) => {
      if (!restrictedItemCount || !p.availableCount || p.availableCount === 0)
        return true

      return p.availableCount > restrictedItemCount
    })

    if (permissions.length <= 0 || !belowPermissionLimit) {
      return customNotSubscribedView !== undefined ? (
        <>{customNotSubscribedView}</>
      ) : (
        <NotSubscribed />
      )
    }
  }

  // skip employee permission check if the permission is only used in subscription.
  const employeePermissions = permission.filter(
    (p) => !SUBSCRIPTION_ONLY_PERMISSIONS.includes(p)
  )
  // this is the new permission response, its still new and might change
  const hasPermission = !!myDetails?.permission?.find((p) =>
    employeePermissions.includes(p.permission)
  )

  if (hasPermission || employeePermissions.length === 0) {
    return <>{children}</>
  }

  if (customNotAuthorizedView) {
    return <>{customNotAuthorizedView}</>
  }

  if (notAuthorizedText) {
    return (
      <p className={"py-16 text-center text-gray-500"}>{notAuthorizedText}</p>
    )
  }

  return null
}

const NotSubscribed: FC = () => {
  return (
    <div className="flex h-full flex-col items-center justify-center">
      <div className="text-center">
        <h1 className="text-2xl font-bold">
          <Trans>Not Authorized</Trans>
        </h1>
        <p className="text-gray-500">
          <Trans>
            You are not subscribed to this feature. Please upgrade your plan to
            access this feature.
          </Trans>
        </p>
      </div>
    </div>
  )
}

export default AuthorizationControl
