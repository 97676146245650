// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { IS_PROD } from "../constants/Config"
import { getMessaging, getToken } from "firebase/messaging"
import apiProvider from "../utils/apiProvider"

const firebaseConfig = IS_PROD
  ? {
      apiKey: "AIzaSyAqJEYdpD-Hz7KfCCHX1ye4AFpL2xmmu7Y",
      authDomain: "diglog-prod.firebaseapp.com",
      projectId: "diglog-prod",
      storageBucket: "diglog-prod.appspot.com",
      messagingSenderId: "423343295197",
      appId: "1:423343295197:web:56e555d68a55c2fc44a934",
      measurementId: "G-K29F9YG61X",
    }
  : {
      apiKey: "AIzaSyDM6gcmMQ97mCNclw13S8ucPEOwDtHMs_k",
      authDomain: "diglog-8b0ae.firebaseapp.com",
      projectId: "diglog-8b0ae",
      storageBucket: "diglog-8b0ae.appspot.com",
      messagingSenderId: "670072573715",
      appId: "1:670072573715:web:87a701b1731655b71e54e6",
      measurementId: "G-7H150KR29D",
    }

initializeApp(firebaseConfig)

export const messaging = (() => {
  try {
    return getMessaging()
  } catch (e) {
    console.log(e)
    return null
  }
})()

export const getFirebaseToken = async () => {
  let swReg: ServiceWorkerRegistration | undefined
  if ("serviceWorker" in navigator) {
    swReg = await navigator.serviceWorker.getRegistration()
  }

  if (messaging === null) return

  return getToken(messaging, {
    vapidKey: IS_PROD
      ? "BGmspf1I5L6vdeGXbni_oESj9tZ0qvk4PUqROD7iVBGzdeHCeapPVgLP-ZDOAg_DOzL33glegRKCkF9Pn9OYDJs"
      : "BHOjjclbvvIULfTXPApKyCsw_qevvAT1IoS1pf4a9DFZlkdbZp3lRCEoAdRB03zrVyeI5lghxLDbnCdhOtO8sic",
    serviceWorkerRegistration: swReg,
  })
}

export const registerPushTokenWithServer = async (
  username: string,
  password: string
) => {
  let pushToken: string | undefined
  try {
    pushToken = await Promise.race([
      getFirebaseToken(),
      new Promise((_, reject) =>
        setTimeout(
          () => reject(new Error("Timeout waiting for Firebase token")),
          2000
        )
      ) as Promise<undefined>,
    ])
  } catch (e) {
    // ignore when fail. Ex. because notification access is declined.
    console.log(e)
  }
  try {
    // yes, this is weird. unused POST call to log in you might wonder?
    //
    // Backend uses API call to this endpoint to register push token for notifications.
    // if we decided to migrate away from amplify SDK for login and rely on our API only,
    // we won't need this unused call anymore.
    await apiProvider.postLogin(username.trim(), password, pushToken)
  } catch (e) {
    // ignore when fail. Ex. because notification access is declined.
    console.log(e)
  }
}
