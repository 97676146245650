import React, { FC, useState } from "react"
import { postIpayIDOTPSubscription } from "../../lib/subscriptions"
import IpayCompleteCheckoutForm from "../IpayCompleteCheckoutForm"
import { IS_PROD } from "../../constants/Config"
import useBusiness from "../../hooks/api/use-business"
import useLocations from "../../hooks/api/use-locations"

const IpayIDOTPForm: FC<{
  planId: string
  businessId: string
}> = ({ planId }) => {
  const { data: business } = useBusiness()
  const { data: locations } = useLocations()

  const primaryLocation = locations?.find((l) => l.isPrimaryLocation)

  const [details, setDetails] = useState<{
    RefNo: string
    Signature: string
    CheckoutID: string
    Code: string
    Message: string
  } | null>(null)

  const handleSubmit = async (data: {
    idCardNumber: string
    location: {
      city?: string
      state?: string
      country?: string
      zip?: string
      addressLine1?: string
      addressLine2?: string
    }
    name?: string
    email?: string
  }) => {
    const result = await postIpayIDOTPSubscription(planId, data)

    if (result.data) {
      setDetails(result.data)
    }
  }

  return (
    <IpayCompleteCheckoutForm
      onSubmit={handleSubmit}
      signedFormValues={details}
      actionURL={
        IS_PROD
          ? "https://payment.ipay88.co.id/PG/"
          : "https://sandbox.ipay88.co.id/PG/"
      }
      disableIdCardNumber
      defaultValues={{
        name: business?.contactName,
        email: business?.email,
        phone: business?.phone,
        location: {
          ...primaryLocation,
          country: "Indonesia",
        },
      }}
    />
  )
}

export default IpayIDOTPForm
