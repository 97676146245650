import React, { useState } from "react"
import { Link, useHistory } from "react-router-dom"
import "./index.css"
import Square_Blue_WhiteLogo from "../../../assets/loginPage/Square_Blue&WhiteLoo.png"
import Button from "../../../components/Button"
import { Trans } from "react-i18next"
import { t } from "i18next"
import { useShowSnackbar } from "../../../components/Snackbar"
import LoadingSpinner from "../../../components/LoadingSpinner"
import parse from "html-react-parser"
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined"
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined"
import LockOutlinedIcon from "@mui/icons-material/LockOutlined"
import NoEncryptionOutlinedIcon from "@mui/icons-material/NoEncryptionOutlined"
import apiProvider from "../../../utils/apiProvider"
import ToolTipComponent from "../../../components/ToolTipComponent"
import Input from "../../../components/Input"
import IconButton from "../../../components/IconButton"

function ForgotPassword(): React.ReactElement {
  const showSnackbar = useShowSnackbar()
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)
  const [loading, setLoading] = useState(false)
  const [stage, setStage] = useState(1) // 1 - email; 2 - code stage
  const [username, setUsername] = useState("")
  const [code, setCode] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [error, setError] = useState({
    isError: false,
    message: "",
  })
  const history = useHistory()

  const sendCode = (event: any) => {
    event.preventDefault()
    apiProvider
      .checkEmailIFExist({
        email: username,
      })
      .then((r) => {
        if (
          r.data.uniqueInBusiness == true &&
          r.data.uniqueInEmployee == true
        ) {
          showSnackbar(
            "The email you entered isn’t connected to an account",
            "warning"
          )
        } else {
          apiProvider
            .forgotPassword({ email: username })
            .then((r) => {
              if (
                r.data.UserStatus &&
                r.data.UserStatus == "FORCE_CHANGE_PASSWORD"
              ) {
                showSnackbar("Temporary password was sent to your email")
                history.push("/")
              } else {
                setStage(2)
              }
            })
            .catch((e) => {
              setError({ ...error, isError: true, message: e.message })
              window.console.log(error)
            })
          // setStage(2)
        }
      })
  }

  const resetPassword = (event: any) => {
    event.preventDefault()
    if (password !== confirmPassword) {
      showSnackbar("Password didn't match, please check your input.", "error")
    } else {
      setLoading(true)
      apiProvider
        .forgotPasswordSubmit({
          email: username,
          code: code,
          password: password,
        })
        .then(() => {
          showSnackbar("Password reset success!")
          history.push("/")
        })
        .catch((e) => {
          showSnackbar(e.message, "error")
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  return (
    <div className="flex w-[80%] flex-col items-center justify-center md:w-[45rem] md:flex-row">
      <div className="flex w-full flex-col items-center justify-center rounded-t-3xl bg-primary-900 p-6 md:h-full md:w-1/2 md:rounded-l-3xl md:rounded-tr-none">
        <img
          src={Square_Blue_WhiteLogo}
          className="h-56 w-auto"
          alt="dizlog logo"
        />
      </div>
      <div className="flex w-full flex-col items-center justify-center gap-4 rounded-b-3xl bg-white p-6 md:w-1/2 md:rounded-r-3xl md:rounded-bl-none">
        <h1 className={"m-0 mt-4 text-2xl"}>Forgot Password</h1>

        {stage === 1 && (
          <>
            <p className={"max-w-sm px-4 text-center text-sm text-gray-500"}>
              <Trans>
                Enter your email / phone number and we'll send a verification
                code to reset your password.
              </Trans>
            </p>

            <form
              onSubmit={sendCode}
              className={"mt-0 box-border flex w-full flex-col gap-6"}
            >
              <Input
                label={t("Email")}
                type="email"
                required
                value={username}
                onChange={(event: any): void => {
                  setUsername(event.target.value)
                  setError({ ...error, isError: false })
                }}
              />
              <Button className={"w-full text-base"} type="submit">
                <Trans>Send verification code</Trans>
              </Button>
              <Button onClick={() => history.push("/")} variant="text">
                <Trans>Back to Login</Trans>
              </Button>
            </form>
          </>
        )}
        {stage === 2 && (
          <>
            <p className={"max-w-sm text-center text-sm text-gray-500"}>
              <Trans>
                We've sent a code to your phone/email. Please enter it and your
                new password into the fields below.
              </Trans>
            </p>
            <form
              onSubmit={resetPassword}
              className={
                "mt-0 box-border flex h-[300px] w-full flex-col flex-col gap-4 overflow-auto"
              }
            >
              <Input
                label={t("Phone Number or Email")}
                value={username}
                disabled={true}
              />
              <Input
                value={code}
                onChange={(event) => setCode(event.target.value)}
                placeholder="Eg. 793474"
                label={t("Verification Code")}
                required
              />
              <Input
                value={password}
                type={showPassword ? "text" : "password"}
                onChange={(event) => setPassword(event.target.value)}
                placeholder="Enter new password"
                label={t("New Password")}
                trailingElement={
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <NoEncryptionOutlinedIcon />
                    ) : (
                      <LockOutlinedIcon />
                    )}
                  </IconButton>
                }
                required
              />
              <Input
                value={confirmPassword}
                type={showPasswordConfirm ? "text" : "password"}
                onChange={(event) => setConfirmPassword(event.target.value)}
                placeholder="Confirm new password"
                label={t("Confirm New Password")}
                trailingElement={
                  <IconButton
                    onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                    onMouseDown={() =>
                      setShowPasswordConfirm(!showPasswordConfirm)
                    }
                  >
                    {showPasswordConfirm ? (
                      <VisibilityOffOutlinedIcon />
                    ) : (
                      <VisibilityOutlinedIcon />
                    )}
                  </IconButton>
                }
                required
              />
              <Button
                className={"w-full text-base"}
                type="submit"
                disabled={loading}
              >
                {loading && <LoadingSpinner className={"mr-3"} />}
                <Trans>Submit</Trans>
              </Button>
              <Button onClick={() => history.push("/")} variant="text">
                <Trans>Back to Login</Trans>
              </Button>
            </form>
          </>
        )}

        <div
          className={
            "flex cursor-pointer items-center pb-2 text-sm font-bold text-[#F09000] underline"
          }
          onClick={() =>
            window.open(
              "https://helpcenter.dizlog.com/en/collections/3192279-getting-started-with-diglog",
              "_blank"
            )
          }
        >
          {t("Get Help")}
          <ToolTipComponent
            toolTipContent={parse(
              t("Find all the items that will get you started!")
            )}
            toolTipPosition="right"
          />
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword
