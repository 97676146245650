import * as React from "react"
import { FC } from "react"
import clsx from "clsx"
import Tooltip from "@mui/material/Tooltip"
import parse from "html-react-parser"
import { InfoOutlined } from "@mui/icons-material"

const ToolTipComponent: FC<{
  label?: string
  labelClassName?: string
  toolTipContent?: any
  toolTipPosition?: any
  onKeyPress?: (e: any) => void
}> = ({ label, toolTipContent, toolTipPosition, labelClassName }) => (
  <Tooltip
    arrow
    className="pl-1 pr-1 "
    placement={toolTipPosition != undefined ? toolTipPosition : "top"}
    title={<React.Fragment>{parse(toolTipContent)}</React.Fragment>}
  >
    <span
      className={clsx(
        "mb-1 cursor-pointer text-sm font-medium opacity-80 peer-disabled:opacity-50",
        labelClassName
      )}
    >
      {label}{" "}
      <InfoOutlined
        sx={{ fontSize: 15 }}
        className="font-semibold text-primary-900"
      />
    </span>
  </Tooltip>
)

export default ToolTipComponent
