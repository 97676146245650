import { FC } from "react"
import clsx from "clsx"

const CheckoutDetailsTable: FC<{
  className?: string
  details: Array<{
    label: string
    value?: string
    hide?: boolean
  }>
}> = ({ className, details }) => (
  <div
    className={clsx(
      "-mx-1 box-border rounded-lg border border-solid border-gray-200 bg-white",
      className
    )}
  >
    {details.map((d) => {
      if (d.hide) return null

      return (
        <div
          key={d.label}
          className="border-x-0 border-b border-t-0 border-solid border-gray-200 px-4 py-3 last:border-none"
        >
          <p className={"m-0 p-0 text-sm"}>{d.label}</p>
          <p className={"m-0 p-0"}>{d.value || "-"}</p>
        </div>
      )
    })}
  </div>
)

export default CheckoutDetailsTable
