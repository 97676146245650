import { uploadFile } from "./api"
import { CSSProperties } from "react"

export const uploadTemplatesAssets = async (file: File, businessId: string) => {
  const res = await uploadFile<{ imageUrl: string }>(
    `/templates/upload-assets?ext=jpg&businessId=${businessId}`,
    file,
    { headers: { "Content-Type": file.type } }
  )
  if (res.data && res.data.imageUrl) {
    return res.data.imageUrl
  }
}

export const hexToRgb = (hex: string) =>
  hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => "#" + r + r + g + g + b + b
    )
    .substring(1)
    .match(/.{2}/g)
    ?.map((x) => parseInt(x, 16))
    ?.join(" ")

export const generateTemplateCSSVar = (template?: { primaryColor: string }) =>
  ({
    "--template-primary": hexToRgb(template?.primaryColor || "#f09000"),
  }) as CSSProperties
