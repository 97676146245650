import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import Backend from "i18next-http-backend"

console.log(window.location.host)
console.log("i18n", window.location.host === "dev-web.dizlog.com")

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(Backend)
  .init({
    fallbackLng: "en",
    returnEmptyString: false,
    detection: {
      // we don't use path prefix for language preference because it requires a lot of custom code
      // for routing with react-router v5, which is what we use when we implement i18n.
      // react-router v5 don't automatically handle nested routes, Link would also need to
      // be wrapped with custom components to properly handle language path prefix.
      //
      // Not using path is currently fine since we don't care about SEO here.
      order: ["localStorage", "navigator"],
    },
    lng: window.location.host === "web.dizlog.id" ? "id" : undefined,
    supportedLngs: [
      "ar",
      "bn",
      "cs",
      "da",
      "de",
      "el",
      "en",
      "es",
      "fi",
      "fr",
      "he",
      "hi",
      "hr",
      "hu",
      "id",
      "it",
      "ja",
      "ka",
      "km",
      "ko",
      "lo",
      "lt",
      "lv",
      "mk",
      "mn",
      "ms",
      "nl",
      "no",
      "pl",
      "pt",
      "ro",
      "sq",
      "sr",
      "sv",
      "te",
      "th",
      "tl",
      "tr",
      "ur",
      "uz",
      "vi",
      "zh",
    ],
    interpolation: {
      escapeValue: false, // react already safe from xss
    },
    backend: {
      loadPath: "/locales/{{lng}}/translation.json",
    },
  })

export default i18n
