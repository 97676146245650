import useAPI from "./use-api"

export type InvoiceSettingsResponseBody = Array<{
  id?: string
  message: string
  showBarcode: string
  showCustomerInfo: string
  showLocation: string
  showOrderNote: string
  showMessage: string
  showPhone: string
  location?: {
    id: string
  } | null
  showLogo: boolean
  showBusinessHours: boolean
  width?: number
  showTaxPIN?: boolean
  taxPIN?: string
  showSKU?: boolean
  disclaimer?: string
  transactionType: string
  storeName: string
  showDueDate?: string
  emailLogo?: string
  printLogo?: string
  showOrderType?: string
  qrCode?: string
  showReferenceNumber?: boolean
  showDisclaimer?: string
}>

const useInvoiceSettings = (locationId?: string) => {
  const swr = useAPI<InvoiceSettingsResponseBody>("/invoice-setting", {
    join: ["location"],
  })

  return {
    ...swr,
    data: locationId
      ? swr.data?.find((data) => data.location?.id === locationId)
      : swr.data?.[0],
  }
}

export default useInvoiceSettings
