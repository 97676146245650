import {
  GetCartTransactionReportResponse,
  UseCartTransactionQuery,
} from "../hooks/api/use-cart-transaction-report"
import { getAPI } from "./api"

export const getCartTransactionReport = <
  T extends keyof GetCartTransactionReportResponse,
>(
  reportType: T,
  query?: UseCartTransactionQuery
) => {
  return getAPI<GetCartTransactionReportResponse[T]>(
    `/reporting/cartTransaction/order-report/${String(reportType)}`,
    query
  )
}
