import React, { FC, FormEventHandler, useState } from "react"
import { Trans } from "react-i18next"
import {
  subscribeWithDPOOTP,
  subscribeWithFlutterwaveOTP,
  subscribeWithFlutterwaveRecurring,
} from "../lib/subscriptions"
import Modal from "./Modal"
import { useShowSnackbar } from "./Snackbar"
import Button from "./Button"
import { t } from "i18next"
import clsx from "clsx"
import Select from "./Select"

const DPOSubscribeButton: FC<{
  planId?: string
  className?: string
  buttonLabel?: string
}> = ({ planId, className, buttonLabel = "Subscribe" }) => {
  const showSnackBar = useShowSnackbar()

  const [loading, setLoading] = useState(false)

  if (!planId) {
    return null
  }

  const handleSubscribeOTP = async () => {
    setLoading(true)
    try {
      const result = await subscribeWithDPOOTP(planId)
      if (result.data.paymentLinkUrl) {
        window.location.href = result.data.paymentLinkUrl
      }
    } catch (e: any) {
      if (e.response?.data?.message) {
        showSnackBar(e.response.data.message, "error")
      } else {
        showSnackBar(
          t("Oops! Something went wrong. Please try again."),
          "error"
        )
      }
      setLoading(false)
    }
  }

  return (
    <button
      className={clsx(
        "w-xl mx-0 mt-2 block w-full rounded-xl border border-solid border-primary-800 bg-primary-800 py-3 text-center text-sm font-semibold text-white no-underline hover:bg-primary-900 disabled:cursor-not-allowed disabled:opacity-50",
        className
      )}
      onClick={async () => {
        await handleSubscribeOTP()
      }}
    >
      {loading ? <Trans>Loading</Trans> : buttonLabel}
    </button>
  )
}

export default DPOSubscribeButton
