import React, { FC } from "react"
import { CSVLink } from "react-csv"
import Tooltip from "@mui/material/Tooltip"
import IconButton from "./IconButton"
import FileDownloadIcon from "@mui/icons-material/FileDownload"

const DownloadCSVButton: FC<{
  data: Array<string | number | any>[]
  headers: string[] | { label: string; key: string }[]
  fileName: string
}> = ({ data, headers, fileName }) => {
  if (!data) return null
  return (
    <CSVLink
      headers={headers}
      data={data}
      filename={fileName + ".csv"}
      style={{ textDecoration: "none" }}
    >
      <Tooltip title={"Download"}>
        <IconButton className="h-[43px] w-[43px] text-primary-900 hover:bg-orange-100">
          <FileDownloadIcon />
        </IconButton>
      </Tooltip>
    </CSVLink>
  )
}

export default DownloadCSVButton
