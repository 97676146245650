import useAPI from "./use-api"
import { BusinessType } from "../../lib/models"

export type GetBusinessTypesResponse = {
  groups: BusinessType[]
  businessTypesByGroup: Record<string, BusinessType[]>
  allTypes: BusinessType[]
}

const useBusinessTypes = () => {
  return useAPI<GetBusinessTypesResponse>("/businesses/type")
}

export default useBusinessTypes
