import { useEffect, useRef, useState } from "react"
import { Trans } from "react-i18next"
import { Close } from "@mui/icons-material"
import clsx from "clsx"
import LoadingSpinner from "./LoadingSpinner"

const PWAUpdateNotification = () => {
  const [hasUpdate, setHasUpdate] = useState(false)
  const [reloading, setReloading] = useState(false)
  const reg = useRef<ServiceWorkerRegistration | null>(null)

  useEffect(() => {
    if (!("serviceWorker" in navigator)) return
    const handleUpdateFound = () => {
      setHasUpdate(true)
    }

    navigator.serviceWorker.ready.then((registration) => {
      registration.addEventListener("updatefound", handleUpdateFound)
      reg.current = registration
      setHasUpdate(!!reg.current?.waiting)
    })

    return () => {
      if (reg) {
        reg.current?.removeEventListener("updatefound", handleUpdateFound)
      }
    }
  }, [])

  return (
    <div
      className={clsx(
        "fixed inset-x-0 flex justify-center transition-all",
        hasUpdate ? "bottom-8" : "-bottom-16 "
      )}
    >
      <div className={"flex items-center rounded-xl border bg-white shadow"}>
        <button
          className="rounded-l-lg border-r px-3 font-bold text-gray-500 hover:bg-gray-50"
          onClick={() => setHasUpdate(false)}
        >
          <Close />
        </button>
        <p className={"px-4 leading-none text-gray-500"}>
          <Trans>Update available!</Trans>
        </p>
        <button
          className="relative flex items-center rounded-r-lg border-l bg-primary-50 py-3 px-6 font-bold leading-none text-primary-900 hover:bg-primary-100/50"
          onClick={async () => {
            setReloading(true)
            reg.current?.waiting?.postMessage({ type: "SKIP_WAITING" })
            setTimeout(() => {
              window.location.reload()
            }, 500)
          }}
        >
          {reloading && <LoadingSpinner className={"mr-2"} />}
          Click to Update
          <span className="absolute -right-2 -top-2 flex h-3 w-3">
            <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-primary-400 opacity-75"></span>
            <span className="relative inline-flex h-3 w-3 rounded-full bg-primary-900"></span>
          </span>
        </button>
      </div>
    </div>
  )
}

export default PWAUpdateNotification
