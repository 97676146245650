import useSWR, { mutate } from "swr"
import { getPublicAPI } from "../../lib/api"

export interface SubscriptionPlan {
  id: number
  name: string
  description: string
  features: Feature[]
  isEnabled: boolean
  billingFrequency: string
  planId: string
  flwPlanId?: string
  stripePlanId?: string
  appleProductId: any
  price: number
  countryGroup: any
  createdAt: string
  updatedAt: string
  maxEmployeesCount?: number
  maxProductsCount?: number
  maxStoresCount?: number
  localCurrency: string
  localPrice: number
}

export interface Feature {
  id: string
  displayName: string
  availableCount?: number
}

const useSubscriptionPlanDetails = (id: string | null) => {
  return useSWR<SubscriptionPlan>(
    id ? `/subscriptionPlan/${id}` : null,
    getPublicAPI
  )
}

export const prefetchSubscriptionPlanDetails = async (id: number) => {
  await mutate(
    `/subscriptionPlan/${id}`,
    getPublicAPI(`/subscriptionPlan/${id}`)
  )
}

export default useSubscriptionPlanDetails
