import Amplify, { Auth, CognitoUser } from "@aws-amplify/auth"
import {
  USER_POOL_WEB_CLIENT_ID,
  USER_POOL_ID,
  REGION,
} from "../constants/Config"

const userPoolWebClientId = USER_POOL_WEB_CLIENT_ID

Amplify.configure({
  Auth: {
    userPoolId: USER_POOL_ID,
    region: REGION,
    userPoolWebClientId: USER_POOL_WEB_CLIENT_ID,
  },
})

export function login(
  username: string,
  password: string
): Promise<CognitoUser> {
  try {
    localStorage.clear()
    return Auth.signIn(username, password)
  } catch (error) {
    window.console.log("error signing in", error)
    return Promise.reject(error)
  }
}

export async function getToken(): Promise<string> {
  const data = await Auth.currentSession()
  return data.getIdToken().getJwtToken()
}

export function logout(): Promise<CognitoUser> {
  try {
    return Auth.signOut()
  } catch (error) {
    window.console.log("error signing out", error)
    return Promise.reject(error)
  }
}

export function getUser(): Promise<any> {
  return Auth.currentUserInfo()
}

export function setToken(
  lastAuthUser: string,
  idToken: string,
  accessToken: string
): void {
  localStorage.setItem(
    `CognitoIdentityServiceProvider.${userPoolWebClientId}.LastAuthUser`,
    lastAuthUser
  )
  localStorage.setItem(
    `CognitoIdentityServiceProvider.${userPoolWebClientId}.${lastAuthUser}.accessToken`,
    accessToken
  )
  localStorage.setItem(
    `CognitoIdentityServiceProvider.${userPoolWebClientId}.${lastAuthUser}.idToken`,
    idToken
  )
}

export function forgotPassword(username: string): Promise<any> {
  try {
    return Auth.forgotPassword(username)
  } catch (error) {
    window.console.log("error sending confirmation code", error)
    return Promise.reject(error)
  }
}

export function forgotPasswordSubmit(
  username: string,
  code: string,
  new_password: string
): Promise<any> {
  try {
    return Auth.forgotPasswordSubmit(username, code, new_password)
  } catch (error) {
    window.console.log("error signing in", error)
    return Promise.reject(error)
  }
}

export async function signUp(username: string, password: string) {
  try {
    return Auth.signUp({
      username,
      password,
    })
    //console.log(user);
  } catch (error) {
    console.log("error signing up:", error)
  }
}

export async function confirmSignUp(username: string, code: string) {
  try {
    return Auth.confirmSignUp(username, code)
  } catch (error) {
    console.log("error confirming sign up", error)
  }
}

export async function resendConfirmationCode(username: string) {
  try {
    return Auth.resendSignUp(username)
  } catch (err) {
    console.log("error resending code: ", err)
  }
}

export default {
  login,
  getToken,
  logout,
  getUser,
  forgotPassword,
  forgotPasswordSubmit,
}
