import { oldGetAPI, postAPI } from "./api"
import { HOMEPAGE_URL } from "./models"

export const postPayPalSubscriptionRecurring = async (planId: string) => {
  const currentDomain = window.location.origin.includes("localhost")
    ? "https://dev-web.dizlog.com"
    : window.location.origin

  return await postAPI<{ approvalUrl: string }>("/subscription", {
    planId,
    returnUrl: `${currentDomain}/verify-subscription`,
    paymentMethod: "paypal",
  })
}

export const postPayPalSubscriptionOTP = async (planId: string) => {
  const currentDomain = window.location.origin.includes("localhost")
    ? "https://dev-web.dizlog.com"
    : window.location.origin

  return await postAPI<{
    orderId: string
    approvalUrl: string
  }>("/subscription", {
    planId,
    returnUrl: `${currentDomain}/verify-subscription`,
    paymentMethod: "paypal-otp",
  })
}

export const postSubscribePayPalCapture = (orderId: string) => {
  return postAPI(`/subscription/paypal-capture/${orderId}`)
}

export const postIpayMYRecurringSubscription = async (
  planId: string,
  data: {
    name?: string
    businessId: string
    idCard?: string
    email?: string
    phone?: string
    location: {
      city?: string
      state?: string
      country?: string
      zip?: string
      addressLine1?: string
      addressLine2?: string
    }
  }
) => {
  const currentDomain = window.location.origin.includes("localhost")
    ? "https://dev-web.dizlog.com"
    : window.location.origin

  return await postAPI<{
    MerchantCode: string
    RefNo: string
    FirstPaymentDate: string
    Currency: string
    Amount: string
    NumberOfPayments: string
    Frequency: string
    Desc: string
    CC_Ic: string
    CC_Name: string
    CC_Email: string
    CC_Phone: string
    P_Name: string
    P_Email: string
    P_Phone: string
    P_Addrl1: string
    P_Addrl2: string
    P_City: string
    P_State: string
    P_Zip: string
    P_Country: string
    Backend_URL: string
    ResponseURL: string
    Signature: string
  }>("/subscription", {
    planId,
    returnUrl: `${currentDomain}/verify-subscription`,
    paymentMethod: "ipay-my-recurring",
    data: {
      ...data,
      errorPage: `${currentDomain}/subscriptions`,
      successPage: `${currentDomain}${HOMEPAGE_URL}?subscribed=true`,
    },
  })
}

export const postIpayMYOTPSubscription = async (
  planId: string,
  data: {
    name: string
    email: string
    paymentId: string
  }
) => {
  const currentDomain = window.location.origin.includes("localhost")
    ? "https://dev-web.dizlog.com"
    : window.location.origin

  return await postAPI<{
    MerchantCode: string
    RefNo: string
    Currency: string
    Amount: string
    ProdDesc: string
    UserName: string
    UserEmail: string
    SignatureType: string
    Signature: string
    BackendURL: string
    ResponseURL: string
  }>("/subscription", {
    planId,
    returnUrl: `${currentDomain}/verify-subscription`,
    paymentMethod: "ipay-my-otp",
    data: {
      ...data,
      errorPage: `${currentDomain}/subscriptions`,
      successPage: `${currentDomain}${HOMEPAGE_URL}?subscribed=true`,
    },
  })
}

export const postIpayIDOTPSubscription = async (
  planId: string,
  data: {
    location: {
      city?: string
      state?: string
      country?: string
      zip?: string
      addressLine1?: string
      addressLine2?: string
    }
    name?: string
    email?: string
  }
) => {
  const currentDomain = window.location.origin.includes("localhost")
    ? "https://dev-web.dizlog.com"
    : window.location.origin

  return await postAPI<{
    RefNo: string
    Signature: string
    CheckoutID: string
    Code: string
    Message: string
  }>("/subscription", {
    planId,
    returnUrl: `${currentDomain}/verify-subscription`,
    paymentMethod: "ipay-id-otp",
    data: {
      ...data,
      errorPage: `${currentDomain}/subscriptions`,
      successPage: `${currentDomain}${HOMEPAGE_URL}?subscribed=true`,
    },
  })
}

interface Subscription {
  id: string
  expirationDate: any
  freeTo: any
  includedServices: number[]
  includedApplications: any
  totalDuration: any
  additionalSlot: boolean
  createdAt: string
  updatedAt: string
  billingFrequency: string
  planId: string
  subscriptionId: string
  status: string
  message: string
}

export const getCurrentSubscription = async () => {
  return await oldGetAPI<Subscription>("/subscription")
}

export const subscribeWithCash = async (planId: string) => {
  return await postAPI<{ approvalUrl: string }>("/subscription", {
    planId,
    paymentMethod: "CASH",
  })
}

export const subscribeWithGcash = async (planId: string) => {
  const currentDomain = window.location.origin.includes("localhost")
    ? "https://dev-web.dizlog.com"
    : window.location.origin
  return await postAPI<{ paymentUrl: string }>("/subscription", {
    planId,
    paymentMethod: "bux-otp",
    returnUrl: `${currentDomain}${HOMEPAGE_URL}?subscribed=true`,
  })
}

export const subscribeWIthIpayAfrica = async (
  planId: string,
  data: {
    phone: string
    email: string
  }
) => {
  const currentDomain = window.location.origin.includes("localhost")
    ? "https://dev-web.dizlog.com"
    : window.location.origin

  return await postAPI<{ redirectUrl: string }>("/subscription", {
    planId,
    paymentMethod: "ipayafrica",
    returnUrl: `${currentDomain}${HOMEPAGE_URL}?subscribed=true`,
    data,
  })
}

export const subscribeWithFlutterwaveOTP = async (flwPlanId: string) => {
  const currentDomain = window.location.origin.includes("localhost")
    ? "https://dev-web.dizlog.com"
    : window.location.origin

  return await postAPI<{
    status: string
    message: string
    data: {
      link: string
      txRef: string
    }
  }>("/subscription", {
    planId: flwPlanId,
    paymentMethod: "flutterwave-otp",
    returnUrl: `${currentDomain}${HOMEPAGE_URL}?subscribed=true`,
  })
}

export const subscribeWithDPOOTP = async (planId: string) => {
  const currentDomain = window.location.origin.includes("localhost")
    ? "https://dev-web.dizlog.com"
    : window.location.origin

  return await postAPI<{
    paymentLinkUrl: string
  }>("/subscription", {
    planId: planId,
    paymentMethod: "dpo-otp",
    returnUrl: `${currentDomain}${HOMEPAGE_URL}?subscribed=true`,
  })
}

export const subscribeWithRazerOTP = async (planId: string) => {
  const currentDomain = window.location.origin.includes("localhost")
    ? "https://dev-web.dizlog.com"
    : window.location.origin

  return await postAPI<{
    url: string
  }>("/subscription", {
    planId,
    paymentMethod: "razer-otp",
    returnUrl: `${currentDomain}${HOMEPAGE_URL}?subscribed=true`,
  })
}

export const subscribeWithRazerRecurring = async (planId: string) => {
  const currentDomain = window.location.origin.includes("localhost")
    ? "https://dev-web.dizlog.com"
    : window.location.origin

  return await postAPI<{
    url: string
  }>("/subscription", {
    planId,
    paymentMethod: "razer-recurring",
    returnUrl: `${currentDomain}${HOMEPAGE_URL}?subscribed=true`,
  })
}

export const subscribeWithFlutterwaveRecurring = async (flwPlanId: string) => {
  const currentDomain = window.location.origin.includes("localhost")
    ? "https://dev-web.dizlog.com"
    : window.location.origin

  return await postAPI<{
    status: string
    message: string
    data: {
      link: string
      txRef: string
    }
  }>("/subscription", {
    planId: flwPlanId,
    paymentMethod: "flutterwave-recurring",
    returnUrl: `${currentDomain}${HOMEPAGE_URL}?subscribed=true`,
  })
}

export const subscribeWithStripeOTP = async (flwPlanId: string) => {
  const currentDomain = window.location.origin.includes("localhost")
    ? "https://dev-web.dizlog.com"
    : window.location.origin

  return await postAPI<{
    url: string
  }>("/subscription", {
    planId: flwPlanId,
    paymentMethod: "stripe-otp",
    returnUrl: `${currentDomain}${HOMEPAGE_URL}?subscribed=true`,
  })
}

export const subscribeWithStripeRecurring = async (flwPlanId: string) => {
  const currentDomain = window.location.origin.includes("localhost")
    ? "https://dev-web.dizlog.com"
    : window.location.origin

  return await postAPI<{
    url: string
  }>("/subscription", {
    planId: flwPlanId,
    paymentMethod: "stripe-recurring",
    returnUrl: `${currentDomain}${HOMEPAGE_URL}?subscribed=true`,
  })
}

export const cancelSubscription = async (id: string) => {
  return await postAPI(`/subscription/cancel/${id}`)
}

export const subscribeWithCashfreeOTP = async (planId: string) => {
  const currentDomain = window.location.origin.includes("localhost")
    ? "https://dev-web.dizlog.com"
    : window.location.origin

  const returnUrl = `${currentDomain}${HOMEPAGE_URL}?subscribed=true`
  const result = await postAPI<{
    sessionId: string
  }>("/subscription", {
    planId: planId,
    paymentMethod: "cashfree-otp",
    returnUrl: returnUrl,
  })

  return {
    ...result.data,
    returnUrl,
  }
}

export const subscribeWithCashfreeRecurring = async (planId: string) => {
  const currentDomain = window.location.origin.includes("localhost")
    ? "https://dev-web.dizlog.com"
    : window.location.origin

  return await postAPI<{
    redirectUrl: string
  }>("/subscription", {
    planId: planId,
    paymentMethod: "cashfree-recurring",
    returnUrl: `${currentDomain}${HOMEPAGE_URL}?subscribed=true`,
  })
}
