import React, { FC, forwardRef, useRef } from "react"
import PageHeader from "../../../../components/PageHeader"
import { t } from "i18next"
import { format, parseISO } from "date-fns"
import Select from "../../../../components/Select"
import useBusiness, { Business } from "../../../../hooks/api/use-business"
import useQueryString from "../../../../hooks/use-query-string"
import IconButton from "../../../../components/IconButton"
import PrintIcon from "@mui/icons-material/Print"
import Tooltip from "@mui/material/Tooltip"
import moment from "moment"
import Input from "../../../../components/Input"
import { connectPrinter } from "../../../../hooks/use-thermal-printer"
import { printToThermal } from "../../../../lib/thermal-printer/helper"
import useXReport, { XReportResponse } from "../../../../hooks/api/use-x-report"
import { encodeBIRXReport } from "../../../../lib/thermal-printer/bir-x-report"
import { useShowSnackbar } from "../../../../components/Snackbar"
import useOneCashRegister, {
  GetCashRegisterResponse,
} from "../../../../hooks/api/use-one-cash-register"
import DownloadTXTButton from "../../../../components/DownloadTXTButton"
import dedent from "dedent"
import { postAPI } from "../../../../lib/api"
import { ZReportResponse } from "../../../../hooks/api/use-z-report"
import clsx from "clsx"
import { useReactToPrint } from "react-to-print"
import html2PDF from "jspdf-html2canvas"
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"
import { result } from "lodash"

const logReportPrint = (logId: string) => {
  return postAPI(`/cashRegister/log/${logId}/print-bir-x`)
}

const BIRX: FC = () => {
  const Title = t("BIR X-Report")
  const type = useQueryString("type")
  const cashRegisterId = useQueryString("cashRegisterId")
  const logId = useQueryString("logId")
  const closedAt = useQueryString("closedAt")
  const showSnackbar = useShowSnackbar()
  const currentDate = new Date()
  const printTarget = useRef<HTMLDivElement>(null)

  const { data: register } = useOneCashRegister(cashRegisterId)
  const { data: report } = useXReport(cashRegisterId, logId)
  const { data: business } = useBusiness()

  const componentRef = useRef(null)

  const handleDownload = useReactToPrint({
    onPrintError: (error) => console.log(error),
    content: () => printTarget.current,
    removeAfterPrint: true,
    print: async (printIframe) => {
      const document = printIframe.contentDocument
      if (document) {
        const html = document.getElementsByTagName("html")[0]
        const exporter = await html2PDF(html)
        await exporter.save("bir-x-report.pdf")
      }
    },
  })

  const handlePrintThermal = async () => {
    if (!report) {
      showSnackbar("Something went wrong, please try again.", "error")
      return
    }
    const { server, characteristic } = await connectPrinter()

    const now = new Date()
    const encodedValues = encodeBIRXReport({
      storeName: business?.name || "",
      operatedBy: register?.location?.companyName || "",
      address: register?.location?.addressLine1 || "",
      vatRegTin: register?.location?.tin || "",
      min: register?.min || "",
      serialNo: register?.serialNo || "",
      reportDate: format(now, "MMMM dd, yyyy"),
      reportTime: format(now, "hh:mm a"),
      startDate: format(parseISO(report.openedAt), "dd/MM/yy hh:mm a"),
      endDate: format(parseISO(report.closedAt), "dd/MM/yy hh:mm a"),

      cashier: report?.openedBy,

      begOR: report?.beginningOR.toString(),
      endOR: report?.endingOR.toString(),

      openingFund: "0",

      paymentReceivedBreakdown: report.tenderBreakdown,
      totalPayments: report.tenderBreakdown
        .reduce((acc, item) => acc + item.sum, 0)
        ?.toFixed(2),

      void: "0",

      refund: report.refundAmount?.toFixed(2) || "0",

      withdrawal: report.withdrawal?.toFixed(2) || "0",

      cashInDrawer: "0",
      transactionSummaryBreakdown: report.tenderBreakdown,
      lessWithdrawal: report.lessWithdrawal?.toFixed(2) || "0",
      paymentReceived: report.paymentReceived.toFixed(2),

      shortOver: "0",
      country: business?.country?.codeAlpha2,
    })

    await printToThermal(characteristic, encodedValues.encode())
    server.disconnect()
    await logReportPrint(logId)
  }

  if (!report) return null

  let birDetails = ""
  if (business?.country?.codeAlpha2 === "PH") {
    birDetails = dedent`
      ${register?.location?.isVatRegistered ? `VAT REG TIN: ${register?.location?.tin}` : "NON VAT REG TIN"}
      MIN: ${register?.min}
      S/N: ${register?.serialNo}  
    `
  }

  const textExport = dedent`
    ${business?.name}
    Operated By: ${register?.location?.companyName}
    ${register?.location?.addressLine1}
    ${birDetails}
    
    X-READING REPORT
    
    Report Date: ${format(currentDate, "MM/dd/yyyy")}
    Report Time: ${format(currentDate, "hh:mm a")}
    Start Date & Time: ${format(parseISO(report?.openedAt), "MM/dd/yyyy hh:mm a")}
    End Date & Time: ${format(parseISO(report?.closedAt), "MM/dd/yyyy hh:mm a")}
    Cashier: ${report.openedBy}
    Beg. OR: ${report.beginningOR}
    End OR: ${report.endingOR}
    Opening Fund: ${report.openingFund.toFixed(2)}
    
    PAYMENT RECEIVED
    ${report.tenderBreakdown.map((item) => `${item.paymentMethodCode}: ${item.sum.toFixed(2)}`).join("\n")}
    Total Payments: ${report.paymentReceived.toFixed(2)}
    Void: ${report.voidAmount}
    Refund: ${report.refundAmount || "0"}
    Withdrawal: ${report.withdrawal || "0"}
    
    TRANSACTION SUMMARY
    Cash in Drawer: 0
    ${report.tenderBreakdown.map((item) => `${item.paymentMethodCode}: ${item.sum.toFixed(2)}`).join("\n")}
    Opening Fund: ${report.openingFund.toFixed(2)}
    Less Withdrawal: ${report.lessWithdrawal?.toFixed(2) || "0"}
    Payment Received: ${report.paymentReceived.toFixed(2)}
    
    SHORT/OVER: 0
  `

  return (
    <div className="flex w-full flex-col p-4" ref={componentRef}>
      <PageHeader title={Title} hasGoBack />
      <div className="flex flex-col w-full bg-white p-6  mt-4 rounded-md">
        <div className="grid grid-cols-1 gap-4 p-3 ">
          <div className="flex flex-col ">
            <div className="flex flex-col">
              <span className="text-2xl text-primary-900 font-bold">
                General
              </span>

              <hr className="my-4" />

              <div className="flex gap-4 py-4 mb-4">
                <Select
                  label={t("Register")}
                  containerClassName="w-[30%]"
                  value={cashRegisterId}
                  disabled
                >
                  <option value="" disabled selected>
                    {t("Select cash register")}
                  </option>
                  <option value={register?.id}>{register?.name}</option>
                </Select>

                <Input
                  label={"Date"}
                  type="date"
                  value={format(parseISO(closedAt), "yyyy-MM-dd")}
                  disabled
                />

                <div className="ml-auto flex w-full md:mb-0 md:w-auto">
                  <DownloadTXTButton
                    text={textExport}
                    filename={"bir-x-report"}
                  />
                  {/*<DownloadCSVButton*/}
                  {/*  headers={[]}*/}
                  {/*  // data={dataReportCsv}*/}
                  {/*  fileName={"bir-report"}*/}
                  {/*/>*/}
                  {/*<DownloadPDFButton*/}
                  {/*  headers={headers}*/}
                  {/*  // data={dataReport}*/}
                  {/*  title={dataTitle}*/}
                  {/*  fileName={"bir-report"}*/}
                  {/*/>*/}
                  <Tooltip title={"Download"}>
                    <IconButton
                      className="h-[43px] w-[43px] text-primary-900 hover:bg-orange-100"
                      onClick={handleDownload}
                    >
                      <PictureAsPdfIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={"Print"}>
                    <IconButton
                      className="h-[43px] w-[43px] text-primary-900 hover:bg-orange-100"
                      onClick={handlePrintThermal}
                    >
                      <PrintIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>

              <BIRXReport
                ref={printTarget}
                report={report}
                currentDate={currentDate}
                business={business}
                register={register}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const BIRXReport = forwardRef<
  HTMLDivElement,
  {
    report: XReportResponse[number]
    business?: Business
    register?: GetCashRegisterResponse
    currentDate: Date
    hideHeader?: boolean
    disableMaxWidth?: boolean
    containerClassName?: string
  }
>(
  (
    {
      report,
      disableMaxWidth,
      business,
      hideHeader,
      register,
      currentDate,
      containerClassName,
    },
    ref
  ) => {
    const calculateVariance = () => {
      const openingBalance = report.openingFund || 0
      const payIn = report.cashRegisterLog?.payIn || 0
      const payOut = report.cashRegisterLog?.payOut || 0
      const cashSales =
        report.cashRegisterLog?.tenderBreakdown
          ?.filter((el: any) => el.paymentMethodCode !== "CASH-null")
          ?.reduce((acc: any, el: any) => acc + el.sum, 0) || 0
      const refund = report.refundAmount || 0
      const closeBalance = report.cashRegisterLog?.closingBalance || 0

      return (
        openingBalance + cashSales + payIn - (refund + payOut + closeBalance)
      )
    }
    return (
      <div className={clsx(!disableMaxWidth && "max-w-lg mx-auto")}>
        <div ref={ref} className={clsx(containerClassName, "px-6")}>
          <div>
            {!hideHeader && (
              <>
                <h2 className={"text-center font-bold text-lg"}>
                  {business?.name}
                </h2>
                {register?.location?.companyName && (
                  <p className={"text-center font-bold text-lg"}>
                    Operated By: {register?.location?.companyName}
                  </p>
                )}
                <p className={"text-center font-bold text-lg"}>
                  {register?.location?.addressLine1}
                </p>

                {business?.country?.codeAlpha2 === "PH" && (
                  <>
                    {register?.location?.isVatRegistered ? (
                      <p className={"text-center font-bold text-lg"}>
                        VAT REG TIN: {register?.location?.tin}
                      </p>
                    ) : (
                      <p className={"text-center font-bold text-lg"}>
                        NON VAT REG TIN
                      </p>
                    )}
                    <p className={"text-center font-bold text-lg"}>
                      MIN: {register?.min}
                    </p>
                    <p className={"text-center font-bold text-lg"}>
                      S/N: {register?.serialNo}
                    </p>
                  </>
                )}
              </>
            )}

            <p
              className={clsx(
                "text-center font-bold text-lg ",
                !hideHeader && "mt-8"
              )}
            >
              X-READING REPORT
            </p>
          </div>

          <div className={clsx("w-full mx-auto mt-4")}>
            <table className={"w-full"}>
              <tbody>
                <tr>
                  <td className={"text-left"}>Report Date</td>
                  <td className={"text-right"}>
                    {format(currentDate, "MM/dd/yyyy")}
                  </td>
                </tr>
                <tr className={"border-dashed border-b border-gray-400"}>
                  <td className={"text-left"}>Report Time</td>
                  <td className={"text-right"}>
                    {format(currentDate, "hh:mm a")}
                  </td>
                </tr>
                {report?.openedAt && (
                  <tr>
                    <td className={"text-left"}>Start Date & Time</td>
                    <td className={"text-right"}>
                      {format(parseISO(report?.openedAt), "MM/dd/yyyy hh:mm a")}
                    </td>
                  </tr>
                )}
                {report?.closedAt && (
                  <tr className={"border-b border-dashed border-gray-400"}>
                    <td className={"text-left"}>End Date & Time</td>
                    <td className={"text-right"}>
                      {format(parseISO(report?.closedAt), "MM/dd/yyyy hh:mm a")}
                    </td>
                  </tr>
                )}

                <tr className={"h-2"} />
                <tr>
                  <td className={"text-left"}>Cashier</td>
                  <td className={"text-right"}>{report.openedBy}</td>
                </tr>
                <tr className={"h-2"} />

                <tr>
                  <td className={"text-left"}>Beg. OR</td>
                  <td className={"text-right"}>{report.beginningOR}</td>
                </tr>
                <tr>
                  <td className={"text-left"}>End OR</td>
                  <td className={"text-right"}>{report.endingOR}</td>
                </tr>
                <tr className={"h-2"} />

                <tr className={"border-b border-dashed border-gray-400"}>
                  <td className={"text-left"}>Opening Fund</td>
                  <td className={"text-right"}>
                    {report.openingFund.toFixed(2)}
                  </td>
                </tr>

                <tr>
                  <td colSpan={2}>PAYMENT RECEIVED</td>
                </tr>
                {report.tenderBreakdown.map((item) => (
                  <tr>
                    <td className={"text-left"}>{item.paymentMethodCode}</td>
                    <td className={"text-right"}>{item.sum.toFixed(2)}</td>
                  </tr>
                ))}
                <tr className={"border-b border-dashed border-gray-400"}>
                  <td className={"text-left"}>Total Payments</td>
                  <td className={"text-right"}>
                    {report.paymentReceived.toFixed(2)}
                  </td>
                </tr>

                <tr className={"border-b border-dashed border-gray-400"}>
                  <td className={"text-left"}>Void</td>
                  <td className={"text-right"}>{report.voidAmount}</td>
                </tr>
                <tr className={"border-b border-dashed border-gray-400"}>
                  <td className={"text-left"}>Refund</td>
                  <td className={"text-right"}>{report.refundAmount}</td>
                </tr>
                <tr className={"border-b border-dashed border-gray-400"}>
                  <td className={"text-left"}>Withdrawal</td>
                  <td className={"text-right"}>{report.withdrawal}</td>
                </tr>

                <tr>
                  <td colSpan={2}>TRANSACTION SUMMARY</td>
                </tr>
                <tr>
                  <td className={"text-left"}>Cash in Drawer</td>
                  <td className={"text-right"}>0</td>
                </tr>
                {report.tenderBreakdown.map((item) => (
                  <tr>
                    <td className={"text-left"}>{item.paymentMethodCode}</td>
                    <td className={"text-right"}>{item.sum.toFixed(2)}</td>
                  </tr>
                ))}
                <tr>
                  <td className={"text-left"}>Opening Fund</td>
                  <td className={"text-right"}>
                    {report.openingFund.toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td className={"text-left"}>Less Withdrawal</td>
                  <td className={"text-right"}>
                    {report.cashRegisterLog.payOut?.toFixed(2) || "0"}
                  </td>
                </tr>
                <tr className={"border-b border-dashed border-gray-400"}>
                  <td className={"text-left"}>Payment Received</td>
                  <td className={"text-right"}>
                    {report.paymentReceived.toFixed(2)}
                  </td>
                </tr>

                <tr className={"border-b border-dashed border-gray-400"}>
                  <td className={"text-left"}>SHORT/OVER</td>
                  <td className={"text-right"}>
                    {calculateVariance().toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
)

export default BIRX
