import { FC, FormEventHandler, useState } from "react"
import { Trans } from "react-i18next"
import useBusiness from "../hooks/api/use-business"
import { subscribeWIthIpayAfrica } from "../lib/subscriptions"
import Input from "./Input"
import Modal from "./Modal"
import { useShowSnackbar } from "./Snackbar"
import Button from "./Button"
import { t } from "i18next"
import clsx from "clsx"
import usePaymentMethodSubscriptionAvailability from "../hooks/use-payment-method-subscription-availability"

const IpayAfricaSubscribeButton: FC<{
  planId: string
  className?: string
  buttonLabel?: string
}> = ({ planId, className, buttonLabel = t("Subscribe") }) => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <button
        className={clsx(
          "w-xl mx-0 mt-2 block w-full rounded-xl border border-solid border-primary-800 bg-primary-800 py-3 text-center text-sm font-semibold text-white no-underline hover:bg-primary-900 disabled:cursor-not-allowed disabled:opacity-50",
          className
        )}
        onClick={() => setShowModal(true)}
      >
        {buttonLabel}
      </button>
      {showModal && (
        <IpayAfricaSubscribeModal
          planId={planId}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  )
}

const IpayAfricaSubscribeModal: FC<{
  planId: string
  onClose?: () => void
}> = ({ planId, onClose }) => {
  const showSnackBar = useShowSnackbar()

  const { data: business } = useBusiness()

  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState(business?.email || "")
  const [phone, setPhone] = useState(business?.phone || "")

  const handleCheckout: FormEventHandler = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      const result = await subscribeWIthIpayAfrica(planId, {
        email,
        phone,
      })
      if (result.data.redirectUrl) {
        window.location.href = result.data.redirectUrl
      }
    } catch (e: any) {
      if (e.response?.data?.message) {
        showSnackBar(e.response.data.message, "error")
      } else {
        showSnackBar(
          t("Oops! Something went wrong. Please try again."),
          "error"
        )
      }
      setLoading(false)
    }
  }

  return (
    <Modal
      open
      title={"Local Payment Checkout"}
      className={"max-w-lg !overflow-hidden !p-0"}
      titleClassName={"p-6"}
      onClose={() => {
        if (onClose) onClose()
      }}
    >
      <p className={"m-0 !max-w-sm p-0 px-6 pb-6 text-gray-700"}>
        <Trans>
          Use the form below to pay through local methods such as mpesa, airtel
          and visa.
        </Trans>
      </p>
      <form
        className="border-x-0 border-t border-b-0 border-solid border-gray-200 bg-gray-50 p-6"
        onSubmit={handleCheckout}
      >
        <Input
          label={"Email"}
          containerClassName={"mb-4"}
          placeholder={"example@dizlog.com"}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Input
          label={"Phone"}
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />

        <div className={"mt-6 flex"}>
          <Button variant={"outlined"} onClick={onClose}>
            <Trans>Cancel</Trans>
          </Button>
          <Button className={"ml-auto"} type={"submit"}>
            {loading ? <Trans>Loading...</Trans> : <Trans>Checkout</Trans>}
          </Button>
        </div>
      </form>
    </Modal>
  )
}

export default IpayAfricaSubscribeButton
