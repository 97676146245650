import useSWR, { SWRConfiguration } from "swr"
import { postAPI } from "../../lib/api"
import { AxiosResponse } from "axios"

export interface ValidateSubscriptionResponse {
  id: string
  expirationDate: any
  freeTo: any
  includedServices: Array<number>
  includedApplications: any
  totalDuration: any
  additionalSlot: boolean
  createdAt: string
  updatedAt: string
  billingFrequency: string
  planId: string
  subscriptionId: string
  status: string
  paymentMethod: string
}

const useValidateSubscription = (
  subscriptionId?: string,
  config?: SWRConfiguration
) => {
  return useSWR<AxiosResponse<ValidateSubscriptionResponse>>(
    subscriptionId ? `/subscription/paypal/validate/${subscriptionId}` : null,
    postAPI,
    {
      ...config,
    }
  )
}

export default useValidateSubscription
