import useLocations from "../hooks/api/use-locations"
import Select from "./Select"
import { FCWithLoading } from "../lib/models"
import { t } from "i18next"
import { useHistory } from "react-router-dom"
import useEmployeeMe from "../hooks/api/use-employee-me"
import { useEffect } from "react"

const LocationSelect: FCWithLoading<
  {
    disabled?: boolean
    required?: boolean
    value?: string
    onChange?: (locationId: string, address: string, name: string) => void
    containerClassName?: string
    selectClassName?: string
    hideLabel?: boolean
    toolTipContent?: string
    productType?: any
    /** Don't show "All" as one of the option */
    disableAllOption?: boolean
    /**specify locations that will be included in the options */
    includedLocations?: string[]
    iconClassName?: string
    enableSelectPlaceholder?: boolean
    showAll?: boolean
  },
  { hideLabel?: boolean }
> = ({
  required,
  value,
  onChange,
  containerClassName,
  selectClassName,
  hideLabel,
  disableAllOption,
  disabled,
  includedLocations,
  toolTipContent,
  productType,
  iconClassName,
  enableSelectPlaceholder,
  showAll,
}) => {
  const history = useHistory()
  const { data: me } = useEmployeeMe()
  const { data: locations } = useLocations(undefined, showAll)

  useEffect(() => {
    // if employee is logged in, we automatically select their primary location
    // to limit the data shown on their accounts to only the locations they are assigned to.
    // ref: #4258
    if (me?.workLocation != undefined && (value === "all" || value == "")) {
      const primaryLocation = locations?.find((l) => l.isPrimaryLocation)
      if (primaryLocation) {
        onChange?.(
          primaryLocation.id,
          primaryLocation.addressLine1,
          primaryLocation.name
        )
      }
    }
  }, [locations, me?.id, onChange, value])

  const disableAll = !!disableAllOption || (me && me.id != null)

  const filteredLocations = locations?.filter((el) => {
    if (includedLocations) {
      return includedLocations.includes(el.id)
    } else {
      return true
    }
  })

  return (
    <Select
      id="location"
      name="location"
      label={`${t(`Location`)} ${required ? "*" : ""}`}
      disabled={disabled}
      toolTipContent={tooltips(history.location.pathname) ?? toolTipContent}
      value={value}
      required={required}
      onChange={(e) => {
        if (onChange) {
          const l = locations?.find(({ id }) => id === e.target.value)
          console.log("l: ", l)
          onChange(e.target.value, l?.addressLine1 || "", l?.name || "")
        }
      }}
      containerClassName={containerClassName}
      selectClassName={selectClassName}
      hideLabel={hideLabel}
      iconClassName={iconClassName}
    >
      <option disabled={!enableSelectPlaceholder} value={""}>
        {t("Select a Location")}
      </option>
      {!disableAll && <option value={"all"}>{t("All")}</option>}
      {filteredLocations?.map(({ id, name }) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))}
    </Select>
  )
}

LocationSelect.Loading = ({ hideLabel }) => (
  <Select
    id="location"
    name="location"
    label={t("Location")}
    hideLabel={hideLabel}
  >
    <option value={""}>{t("Loading...")}</option>
  </Select>
)

const tooltips = (pathName: string) => {
  if (pathName == "/dashboard/inventory/stock-catalog/purchase-orders") {
    return "<span className='cursor-potracking-wide pt-1 pb-1 text-xs text-center'>Filter purchase order by location. </span>"
  }
  if (pathName == "/dashboard/inventory/stock-catalog/manage") {
    return "<span className='tracking-wide pt-1 pb-1 text-xs'>Location of the store with this item / service.</span>"
  }
  // if (pathName == "/dashboard/inventory/stock-catalog/manage") {
  //   return "<span className='tracking-wide pt-1 pb-1 text-xs'>Filter items or service by location.</span>"
  // }
  if (pathName == "/dashboard/mpos/business-catalog") {
    return "<span className='tracking-wide pt-1 pb-1 text-xs'>Filter items and services by location. </span>"
  }
  if (pathName == "/dashboard/mpos/order-management/my-orders") {
    return "<span className='tracking-wide pt-1 pb-1 text-xs'>Select from your business locations </span>"
  }
}

export default LocationSelect
