import Logo from "../../assets/Logo_DigLog.png"
import useValidateSubscription from "../../hooks/api/use-validate-subscription"
import { Redirect, useLocation } from "react-router-dom"
import querystring from "query-string"
import { Trans } from "react-i18next"
import { HOMEPAGE_URL } from "../../lib/models"

const VerifySubscription = () => {
  const { search } = useLocation()
  const query = querystring.parse(search)

  const { data: subscription, error } = useValidateSubscription(
    query?.subscription_id as string,
    { refreshInterval: 5 }
  )
  if (subscription?.data?.status === "ACTIVE") {
    if (process.env.NODE_ENV === "production") {
      window.gtag("event", "user_subscription")
    }
  }
  return (
    <div className="flex min-h-full flex-col bg-white pb-12">
      <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
        <div className="flex flex-shrink-0 justify-center">
          <a href="/" className="inline-flex">
            <span className="sr-only">DizLog</span>
            <img className="h-12 w-auto" src={Logo} alt="" />
          </a>
        </div>
        <div className="py-16">
          <div className="flex flex-col text-center">
            <p className="p-0 font-body font-semibold uppercase tracking-wide text-primary-900">
              <Trans>Verifying Payment</Trans>
            </p>
            <h1 className="mt-2 mb-4 font-body text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
              <Trans>
                Thanks for <br />
                Subscribing!
              </Trans>
            </h1>
            <p className="mx-auto mt-2 max-w-xs p-0 font-body text-base text-gray-500">
              <Trans>
                Thanks for joining us. Please wait a moment while we verify your
                payment.
              </Trans>
            </p>
            <p className="bg-p mx-auto mt-6 flex w-auto rounded-full bg-primary-900 py-3 px-4 text-sm font-bold text-white shadow-sm">
              <svg
                className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <Trans>Verifying Payment</Trans>
            </p>
          </div>
        </div>
      </main>

      {error?.response?.status === 404 && (
        <Redirect to={"/verify-subscription-failed"} />
      )}

      {subscription?.data?.status === "ACTIVE" && (
        <Redirect to={HOMEPAGE_URL} />
      )}
    </div>
  )
}

export default VerifySubscription
