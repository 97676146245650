import { formatNumberToCurrency } from "../lib/currency"
import React, { FC } from "react"
import { Business, Estimate, Location } from "../lib/models"
import { MailOutlined, PhoneOutlined } from "@mui/icons-material"
import Placeholder from "../assets/images/placeholder.png"
import moment from "moment"
import { Trans } from "react-i18next"
import { generateTemplateCSSVar } from "../lib/templates"
import useTemplatePublic from "../hooks/api/use-template-public"

const EstimatePreview: FC<{
  business: Business
  location: Location
  estimate: Estimate
}> = ({ location, business, estimate }) => {
  const { data: template } = useTemplatePublic(business.id)

  const calculateSubTotal = () => {
    let subTotal = 0
    estimate.estimateItems.forEach((item: any) => {
      subTotal += item.quantity * item.sellingPrice - item.tax
    })
    return subTotal
  }

  const subTotal = calculateSubTotal()

  const logo = template?.logoUrl || business?.logoUrl

  const totalCost = subTotal + estimate.shippingCost + estimate.otherCost

  return (
    <div
      className="w-full overflow-hidden rounded-xl border border-solid border-gray-200 bg-white shadow-sm lg:basis-3/4"
      style={generateTemplateCSSVar(template)}
    >
      <div className={"flex flex-col lg:flex-row"}>
        <div className={"m-8 mb-0"}>
          {logo && (
            <div className="-ml-2 -mt-2 mb-4 flex h-20 w-20 flex-shrink-0 items-center justify-center rounded-lg bg-gray-50 ring-primary-900">
              <img
                src={logo ?? Placeholder}
                className={"h-full w-full rounded-lg object-cover"}
                alt={""}
              />
            </div>
          )}
          <p className={"mx-0 mb-1 mt-0 p-0 text-[14px]"}>{business.name}</p>
          <p className={"mx-0 mb-1 mt-0  p-0 text-[14px]"}>
            {location.addressLine1}
          </p>
          {location.addressLine2 && (
            <p className={"mx-0 mb-1 mt-0  p-0 text-[14px]"}>
              {location.addressLine2}
            </p>
          )}
          <p className={"mx-0 mb-1 mt-0 p-0 text-[14px]"}>
            {location.city}, {location.state}, {location.zip}
          </p>
        </div>

        <div className={"m-8 mb-0 lg:ml-auto lg:text-right"}>
          <p className={"mx-0 mb-1 mt-0 p-0 text-[14px]"}>
            Quote # {estimate.estimateNumber}
          </p>
          <p className={"mx-0 mb-1 mt-0 p-0 text-[14px] text-gray-500"}>
            Issued :{" "}
            <span className={"font-medium"}>
              {moment(estimate.createdAt).format("dddd, MMMM D YYYY")}
            </span>
          </p>
          <p
            className={"mx-0 mb-1 mt-0 p-0 text-[14px] text-gray-500 truncate"}
          >
            Expiry date :{" "}
            <span className={"font-medium "}>
              {moment(estimate.expirationDate).format("dddd, MMMM D YYYY")}
            </span>
          </p>
        </div>
      </div>

      <div className={"m-8 flex items-end mt-12"}>
        {estimate.customer && (
          <div>
            <p className={"m-0 mb-2 p-0 font-medium"}>Quotation for</p>
            <p className={"m-0 mb-1 p-0 text-[14px] text-gray-700"}>
              {estimate.customer.fullName}
            </p>
            <p className={"m-0 mb-1 p-0 text-[14px] text-gray-700"}>
              {estimate.customer.phone}
            </p>
            <p className={"m-0 p-0 text-[14px] text-gray-700"}>
              {estimate.customer.email}
            </p>
          </div>
        )}

        <p className={"text-2xl font-bold lg:ml-auto"}>
          {formatNumberToCurrency(estimate.currency, estimate.totalPrice)}
        </p>
      </div>

      <div className="flex items-center border-y border-template-primary/20 bg-template-primary/10 px-8 py-3">
        <div className={"flex items-center text-gray-700 no-underline"}>
          <p className={"m-0 p-0 text-[14px]"}>{business.name}</p>
        </div>
        <p className={"mx-4 p-0 text-gray-400"}>•</p>
        {business.phone && (
          <a
            className={"flex items-center text-gray-700 no-underline"}
            href={`tel:${business.phone}`}
          >
            <PhoneOutlined className={"mr-2 !h-4 !w-4 opacity-70"} />
            <p className={"m-0 p-0 text-[14px]"}>{business.phone}</p>
          </a>
        )}
        {business.email && business.phone && (
          <p className={"mx-4 p-0 text-gray-400"}>•</p>
        )}
        {business.email && (
          <a
            className={"flex items-center text-gray-700 no-underline"}
            href={`mailto:${business.email}`}
          >
            <MailOutlined className={"mr-2 !h-4 !w-4 opacity-70"} />
            <span className={"m-0 p-0 text-[14px]"}>{business.email}</span>
          </a>
        )}
      </div>

      <div className={"m-8"}>
        <p className={"mb-2 p-0 text-lg font-medium"}>Items</p>
        <div>
          {estimate.estimateItems.map((i) => {
            return (
              <div
                key={i.id}
                className="mb-4 rounded-lg border border-solid border-gray-200 p-4"
              >
                <div className={"flex"}>
                  <div>
                    <p className={"m-0 mb-1 p-0 text-[14px]"}>
                      {i.product.name}
                    </p>
                    {i.productVariant && (
                      <p className={"m-0 mb-1 p-0 text-[14px]"}>
                        {i.productVariant?.variantName}
                      </p>
                    )}

                    <p className={"m-0 p-0 text-[14px] text-gray-700"}>
                      {i.quantity} x{" "}
                      {formatNumberToCurrency(
                        estimate.currency,
                        i.sellingPrice
                      )}
                    </p>

                    {i.product.description && (
                      <p className={"m-0 p-0 text-[14px] text-gray-700"}>
                        {i.product.description}
                      </p>
                    )}
                  </div>

                  <p className={"m-0 ml-auto p-0 text-lg font-medium"}>
                    {formatNumberToCurrency(
                      estimate.currency,
                      i.sellingPrice * i.quantity
                    )}
                  </p>
                </div>
              </div>
            )
          })}
        </div>
      </div>

      <div className={"ml-auto box-border w-full max-w-lg p-8 !pb-2 !pt-0"}>
        <div className={"flex text-gray-500 "}>
          <p className={"mb-2 mt-0 p-0"}>Subtotal</p>
          <p className={"mb-2 ml-auto mt-0 p-0"}>
            {formatNumberToCurrency(estimate.currency, subTotal)}
          </p>
        </div>

        <div className={"flex text-gray-500"}>
          <p className={"mb-2 mt-0 p-0"}>Discount</p>
          <p className={"mb-2 ml-auto mt-0 p-0"}>
            {estimate.customFixedDiscount !== 0 ? (
              <span>
                {formatNumberToCurrency(
                  estimate.currency,
                  estimate.customFixedDiscount
                )}
              </span>
            ) : estimate.customPercentageDiscount !== 0 ? (
              <span>
                {formatNumberToCurrency(
                  estimate.currency,
                  (estimate.customPercentageDiscount * subTotal) / 100
                )}{" "}
                ({estimate.customPercentageDiscount}%)
              </span>
            ) : (
              <span>{formatNumberToCurrency(estimate.currency, 0)}</span>
            )}
          </p>
        </div>

        <div className={"flex text-gray-500"}>
          <p className={"mb-2 mt-0 p-0"}>Shipping</p>
          <p className={"mb-2 ml-auto mt-0 p-0"}>
            {formatNumberToCurrency(estimate.currency, estimate.shippingCost)}
          </p>
        </div>

        <div className={"flex text-gray-500"}>
          <p className={"mb-2 mt-0 p-0"}>Other cost</p>
          <p className={"mb-2 ml-auto mt-0 p-0"}>
            {formatNumberToCurrency(estimate.currency, estimate.otherCost)}
          </p>
        </div>

        <div className={"flex text-gray-500"}>
          <p className={"mb-2 mt-0 p-0"}>Tax</p>
          <p className={"mb-2 ml-auto mt-0 p-0"}>
            {formatNumberToCurrency(estimate.currency, estimate.tax)}
          </p>
        </div>
        <div className="flex flex-col">
          {estimate.taxes?.map((t) => {
            return (
              <ul key={t.id} className="flex text-gray-500">
                <li className={"mt-0 p-0 flex w-full"}>
                  <div>
                    • {t.name} {t.type === "INCLUDED" ? "(Included)" : ""}
                  </div>
                  <div className={"mb-2 ml-auto mt-0 p-0"}>
                    {formatNumberToCurrency(
                      estimate.currency,
                      (t.rate / 100) * totalCost
                    )}
                  </div>
                </li>
              </ul>
            )
          })}
        </div>

        <div className={"my-2 h-[1px] w-full bg-gray-300"} />

        <div className={"flex font-medium"}>
          <p className={"mb-1 mt-0.5 p-0"}>Total</p>
          <p className={"mb-1 ml-auto mt-0.5 p-0"}>
            {formatNumberToCurrency(estimate.currency, estimate.totalPrice)}
          </p>
        </div>
      </div>

      <div className={"box-border w-full px-8 pb-8"}>
        <div className={"h-[1px] w-full bg-gray-300"} />
      </div>

      {estimate.customerNote && (
        <>
          <div className={"box-border w-full px-8 pb-8"}>
            <p className={"m-0 mb-2 p-0 font-bold"}>Note to Customer</p>
            <p className={"m-0 p-0 text-gray-700"}>{estimate.customerNote}</p>
          </div>

          <div className={"box-border w-full px-8 pb-8"}>
            <div className={"h-[1px] w-full bg-gray-300"} />
          </div>
        </>
      )}

      {estimate.termsAndCondition && (
        <>
          <div className={"box-border w-full px-8 pb-8"}>
            <p className={"m-0 mb-2 p-0 font-medium"}>Terms and Conditions</p>
            <p className={"m-0 p-0 text-gray-700"}>
              {estimate.termsAndCondition}
            </p>
          </div>

          <div className={"box-border w-full px-8 pb-8"}>
            <div className={"h-[1px] w-full bg-gray-300"} />
          </div>
        </>
      )}

      {estimate.referenceNumber && (
        <>
          <div className={"box-border w-full px-8 pb-8"}>
            <p className={"m-0 mb-2 p-0 font-medium"}>Reference Number</p>
            <p className={"m-0 p-0 text-gray-700"}>
              {estimate.referenceNumber}
            </p>
          </div>

          <div className={"box-border w-full px-8 pb-8"}>
            <div className={"h-[1px] w-full bg-gray-300"} />
          </div>
        </>
      )}

      {template?.footerText && (
        <>
          <div className={"mx-1 box-border w-full px-8 pb-8 text-center"}>
            <p className={"m-0 mb-2 p-0 font-medium"}>
              <Trans></Trans>
            </p>
            <p className={"m-0 p-0 text-gray-700"}>{template?.footerText}</p>
          </div>
        </>
      )}
    </div>
  )
}

export default EstimatePreview
