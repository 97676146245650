import React, { FC } from "react"
import "./Login.css"
import LanguageSelect from "../components/LanguageSelect"
import { Trans } from "react-i18next"
import { APP_VERSION } from "../constants/Config"

const LoginLayout: FC = ({ children }) => (
  <div className="login-layout">
    {children}
    {window.location.pathname != "/sign-up" ? (
      <div>
        <div className={"flex items-center"}>
          <p className={"mr-4 font-bold text-gray-300"}>
            <Trans>Select Language</Trans>
          </p>
          <LanguageSelect
            selectClassName={"font-bold"}
            containerClassName={"my-4"}
            hideLabel
            optionClassName={"text-gray-900"}
          />
        </div>
        <p className={"text-white font-bold text-center"}>{APP_VERSION}</p>
      </div>
    ) : null}
  </div>
)

export default LoginLayout
