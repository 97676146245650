import React from "react"
import { useHistory } from "react-router-dom"
import { Divider, Tooltip } from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import parse from "html-react-parser"
import InfoOutlined from "@mui/icons-material/InfoOutlined"
import ToolTipComponent from "../ToolTipComponent"

interface Props {
  title: string
  hasDivider?: boolean
  hasGoBack?: boolean
  goBackLink?: string
  variant?: string
  toolTipContent?: string
}

const Component = ({
  title,
  hasDivider,
  hasGoBack,
  goBackLink,
  variant,
  toolTipContent,
}: Props): JSX.Element => {
  const history = useHistory()
  return (
    <div>
      <div className="flex flex-row items-center">
        {hasGoBack && (
          <ArrowBackIcon
            onClick={() =>
              goBackLink ? history.push(goBackLink) : history.goBack()
            }
            style={{ marginRight: 16, fontSize: 25, cursor: "pointer" }}
          />
        )}
        {(!variant || (variant && variant === "primary")) && (
          <h1 className="my-0 font-body text-4xl font-extrabold xl:mr-auto xl:mb-0">
            {title}
          </h1>
        )}
        {variant === "secondary" && (
          <h1 className={"my-0 font-body text-2xl xl:mr-auto xl:mb-0"}>
            {title}
          </h1>
        )}
        {toolTipContent && (
          <ToolTipComponent
            toolTipContent={toolTipContent}
            toolTipPosition={"right"}
          />
        )}
      </div>
      {hasDivider && <Divider sx={{ marginBottom: 0 }} />}
    </div>
  )
}

Component.defaultProps = {
  hasDivider: false,
  hasGoBack: false,
}

export default Component
