import React, { useState } from "react"
import { Link, useHistory } from "react-router-dom"
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined"
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined"
import Square_Blue_WhiteLogo from "../../../assets/loginPage/Square_Blue&WhiteLoo.png"
import OtpInput from "react-otp-input"
import {
  confirmSignUp,
  login,
  logout,
  resendConfirmationCode,
  signUp,
} from "../../../utils/authProvider"
import { forceDeleteUser } from "../../../lib/auth"
import { t } from "i18next"
import { Trans } from "react-i18next"
import parse from "html-react-parser"
import Modal from "../../../components/Modal"
import TermsAndConditions from "../../../components/TermsAndConditions"
import { registerPushTokenWithServer } from "../../../lib/firebase"
import { useShowSnackbar } from "../../../components/Snackbar"
import { useIntercom } from "react-use-intercom"
import ToolTipComponent from "../../../components/ToolTipComponent"
import GettingStarted from "../GettingStarted"
import useClearAllCache from "../../../hooks/use-clear-all-cache"
import Input from "../../../components/Input"
import IconButton from "../../../components/IconButton"
import Checkbox from "../../../components/Checkbox"
import Button from "../../../components/Button"

declare global {
  interface Window {
    gtag: any
  }
}

function SignUp(): React.ReactElement {
  const history = useHistory()
  const intercom = useIntercom()
  const clearAllCache = useClearAllCache()

  const [body, setBody] = useState({
    phone_or_email: "",
    password: "",
    confirmPassword: "",
    agreeToTerms: false,
  })
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [language, setLanguage] = useState("English")
  const [phoneCode, setPhoneCode] = useState("+1")
  const [isError, setIsError] = useState({ v: false, m: "" })
  const [isValid, setIsValid] = useState({
    phone_or_email: { v: false, m: "" },
    password: { v: false, m: "" },
    confirmPassword: { v: false, m: "" },
  })
  const [view, setView] = useState("signup")
  const [OTP, setOTP] = useState("")
  const [signupSuccess, setSignupSuccess] = useState(false)
  const [username, setUsername] = useState("")
  const [showTermsModal, setShowTermsModal] = useState(false)
  const [user, setUser] = useState<any>({})

  const validatePassword = (p: string): void => {
    setBody({ ...body, password: p })
    if (p.length >= 6) {
      setIsValid({ ...isValid, password: { v: true, m: "" } })
    } else {
      setIsValid({
        ...isValid,
        password: {
          v: false,
          m: t("Password must be at least 6 characters long"),
        },
      })
    }
  }

  const validateConfirmPassword = (cp: string): void => {
    setBody({ ...body, confirmPassword: cp })
    if (cp === body.password) {
      setIsValid({ ...isValid, confirmPassword: { v: true, m: "" } })
    } else {
      setIsValid({
        ...isValid,
        confirmPassword: { v: false, m: t("Passwords do not match") },
      })
    }
  }

  const handleChangeLanguage = (event: any): void => {
    setLanguage(event.target.value)
  }

  const handleChangePhoneCode = (event: any): void => {
    setPhoneCode(event.target.value)
  }

  const languageOptionList = [
    { value: "English", title: "English" },
    { value: "Vietnamese", title: "Vietnamese" },
    { value: "Filipino", title: "Filipino" },
  ]

  const renderLanguageOption = languageOptionList.map((item: any) => (
    <option key={item.value} value={item.value}>
      {item.title}
    </option>
  ))

  const handleClick = async () => {
    setIsError({ v: false, m: "" })

    if (body.phone_or_email.trim() === "") {
      setIsValid({
        ...isValid,
        phone_or_email: { v: false, m: t("Email must be filled") },
      })
    } else if (isValid.confirmPassword.v && isValid.password.v) {
      const isValidEmail: any = validateEmail(body.phone_or_email)
      //const isValidPhone: any = validatePhone(body.phone_or_email)

      // if (
      //   isValidPhone &&
      //   !(
      //     body.phone_or_email.startsWith("+65") ||
      //     body.phone_or_email.startsWith("+63")
      //   )
      // ) {
      //   setIsError({
      //     v: true,
      //     m: t("Phone numbers are only allowed in Philippine and Singapore"),
      //   })
      //   return
      // }

      if (isValidEmail !== null) {
        const username = isValidEmail[0]
        try {
          const res: any = await signUp(username.trim(), body.password)
          if (res.user && res.user.username) {
            if (process.env.NODE_ENV === "production") {
              window.gtag("event", "conversion", {
                send_to: "AW-10829576507/_9BzCNm0yIsDELvy-Kso",
              })
              window.gtag("event", "business_signup")
            }

            setUser(res.user)
            setUsername(res.user.username)
            setView("OTP")
          }
        } catch (e: any) {
          setIsError({ ...isError, v: true, m: e.message })
          console.log(e)
        }
      } else {
        setIsError({
          ...isError,
          v: true,
          m: t("Please enter valid email address"),
        })
      }
    } else {
      setIsError({ ...isError, v: true, m: t("Please validate your input") })
    }
  }

  const validateEmail = (email: string) => {
    return email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  }

  const validatePhone = (phone: string) => {
    return phone.match(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g)
  }

  const handleOTPchange = (otp: any) => {
    setOTP(otp)
  }

  const showSnackBar = useShowSnackbar()

  const handleVerify = async () => {
    await confirmSignUp(username, OTP)
      .then((res) => {
        if (res === "SUCCESS") {
          setSignupSuccess(true)
          // alert(t("Sign Up Success!"))
          showSnackBar(
            "Congratulations! Your account has been successfully created.",
            "success"
          )
          handleReLogin()
          //setView('business-profile-form');
        }
      })
      .catch((e) => {
        setIsError({ ...isError, v: true, m: e.message })
        setOTP("")
        console.log(e)
      })
  }

  const handleResendOTP = async () => {
    await resendConfirmationCode(username.trim())
      .then((res: any) => {
        if (view !== "OTP") {
          setView("OTP")
        } else {
          alert(t("OTP sent"))
        }
      })
      .catch((e) => {
        setIsError({ ...isError, v: true, m: e.message })
        window.console.log(e)
      })
  }

  const handleCancel = () => {
    history.push("/")
  }

  const handleAgreeToTermsChange = () => {
    setBody({ ...body, agreeToTerms: !body.agreeToTerms })
  }

  const handleReLogin = async () => {
    setIsError({ ...isError, v: false })
    try {
      const res: any = await login(username.trim(), body.password)
      setUser(res)
      setView("business-profile-form")
      // if (res.attributes["custom:businessId"]) {
      //   await clearAllCache()
      //   history.push(HOMEPAGE_URL")
      // } else {
      //   setUser(res)
      //   setView("business-profile-form")
      // }
    } catch (e: any) {
      setIsError({ ...isError, v: true, m: e.message })
      window.console.log(e)
    }
  }

  if (view === "business-profile-form") {
    return <GettingStarted username={username.trim()} body={body} />
  }

  return (
    <div className="flex w-[80%] flex-col items-center justify-center md:w-[45rem] md:flex-row">
      <div className="flex h-full w-full flex-col items-center justify-center rounded-t-3xl bg-primary-900 p-6 md:w-1/2 md:rounded-l-3xl md:rounded-tr-none">
        <img
          src={Square_Blue_WhiteLogo}
          className="h-56 w-auto"
          alt="dizlog logo"
        />
      </div>
      {view === "signup" && (
        <div className="flex w-full flex-col items-center justify-center gap-4 rounded-b-3xl bg-white p-6 md:w-1/2 md:rounded-r-3xl md:rounded-bl-none">
          <h1 className={"text-3xl font-bold"}>
            <Trans>Sign Up</Trans>
          </h1>
          <Input
            type="text"
            label={t("Email")}
            value={body.phone_or_email}
            onChange={(event): void => {
              setBody({ ...body, phone_or_email: event.target.value })
            }}
            toolTipContent={parse(t("Enter valid email address"))}
            toolTipPosition="right"
            required
            errorMessage={
              !isValid.phone_or_email.v &&
              isValid.phone_or_email.m !== "" &&
              body.phone_or_email.trim() === ""
                ? isValid.phone_or_email.m
                : undefined
            }
            containerClassName="w-full"
          />
          <Input
            label={t("Password")}
            type={showPassword ? "text" : "password"}
            value={body.password}
            onChange={(event): void => validatePassword(event.target.value)}
            trailingElement={
              <IconButton
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <VisibilityOffOutlinedIcon />
                ) : (
                  <VisibilityOutlinedIcon />
                )}
              </IconButton>
            }
            containerClassName="w-full"
            errorMessage={
              !isValid.password.v && body.password.trim() !== ""
                ? isValid.password.m
                : undefined
            }
          />
          <Input
            label={t("Confirm Password")}
            type={showConfirmPassword ? "text" : "password"}
            value={body.confirmPassword}
            onChange={(event): void => {
              validateConfirmPassword(event.target.value)
            }}
            trailingElement={
              <IconButton
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                onMouseDown={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? (
                  <VisibilityOffOutlinedIcon />
                ) : (
                  <VisibilityOutlinedIcon />
                )}
              </IconButton>
            }
            containerClassName="w-full"
            errorMessage={
              !isValid.confirmPassword.v && body.confirmPassword.trim() !== ""
                ? isValid.confirmPassword.m
                : undefined
            }
          />

          <Checkbox
            checked={body.agreeToTerms}
            onChange={handleAgreeToTermsChange}
            label={
              <div className="text-sm">
                I have read and agreed to Dizlog&nbsp;
                <span
                  style={{ color: "#F09000", textDecoration: "underline" }}
                  onClick={() => setShowTermsModal(true)}
                >
                  <Trans>Terms and Privacy Policy</Trans>
                </span>
              </div>
            }
          />
          {isError.v && <div className="text-red-300">**{isError.m}</div>}
          <Button
            disabled={
              body.phone_or_email === "" ||
              body.password === "" ||
              body.confirmPassword === "" ||
              body.agreeToTerms === false
            }
            onClick={handleClick}
            className="w-full"
          >
            <Trans>Send OTP</Trans>
          </Button>
          <Button variant="outlined" onClick={handleCancel} className="w-full">
            <Trans>Cancel</Trans>
          </Button>
          <p className="m-0 p-0 text-sm">
            <Trans>Already have an account?</Trans>{" "}
            <Link to="/" className={"font-bold text-primary-900 no-underline"}>
              <Trans>Sign In</Trans>
            </Link>
          </p>

          <div
            className={
              "flex cursor-pointer items-center text-sm font-bold text-[#F09000] underline"
            }
            onClick={() =>
              window.open(
                "https://helpcenter.dizlog.com/en/collections/3192279-getting-started-with-diglog",
                "_blank"
              )
            }
          >
            {t("Get Help")}
            <ToolTipComponent
              toolTipContent={parse(
                t("Find all the items that will get you started!")
              )}
              toolTipPosition="right"
            />
          </div>
        </div>
      )}
      {view === "OTP" && (
        <div className="flex h-[400px] w-full flex-col items-center justify-center gap-6 rounded-b-3xl bg-white p-6 md:w-1/2 md:rounded-r-3xl md:rounded-bl-none">
          <h1 className={"text-2xl font-bold"}>
            <Trans>Enter OTP</Trans>
          </h1>
          <OtpInput
            value={OTP}
            onChange={handleOTPchange}
            numInputs={6}
            separator={<span>-</span>}
            inputStyle={"!w-10 !h-12 border border-gray-600 rounded"}
          />
          {isError.v && (
            <div className="italic text-red-300">**{isError.m}</div>
          )}
          <Button type="button" variant="text" onClick={handleResendOTP}>
            <Trans>Resend OTP</Trans>
          </Button>
          <Button
            disabled={OTP.length === 6 ? false : true}
            onClick={handleVerify}
            className="w-full"
          >
            <Trans>Register</Trans>
          </Button>

          <div className="flex flex-row items-center">
            <p className="pr-1 text-sm">
              <Trans>Having trouble? </Trans>
            </p>
            <Button
              onClick={async () => {
                await forceDeleteUser(body.phone_or_email)
                await logout()
                setView("signup")
                setBody({
                  phone_or_email: "",
                  password: "",
                  confirmPassword: "",
                  agreeToTerms: false,
                })
                setIsError({ v: false, m: "" })
              }}
              variant="text"
            >
              <Trans>Go back and reset.</Trans>
            </Button>
          </div>
          {signupSuccess && (
            <h3 style={{ color: "green" }}>
              <Trans>Sign up success!!!</Trans>
            </h3>
          )}
        </div>
      )}
      <Modal
        open={showTermsModal}
        onClose={() => setShowTermsModal(false)}
        //title={"DizLog Terms and Conditions"}
        className="mx-4 max-w-3xl !p-0"
        titleClassName="px-8 py-5"
      >
        <div className="flex flex-col px-4 pb-4">
          <TermsAndConditions />
        </div>
      </Modal>
    </div>
  )
}

export default SignUp
