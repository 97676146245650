import React, { createContext, useEffect } from "react"
import { useHistory } from "react-router-dom"
import querystring from "query-string"
import { setToken } from "../../../utils/authProvider"
import apiProvider from "../../../utils/apiProvider"
import { HOMEPAGE_URL } from "../../../lib/models"

export const BusinessContext = createContext<any>(null)

function ForwardingToken(): React.ReactElement | null {
  const history = useHistory()

  useEffect(() => {
    const param = window.location.search
    const values: any = querystring.parse(param.substring(1, param.length))
    setToken(values.lastAuthUser, values.idToken, values.accessToken)
    apiProvider
      .getEmployeeMe()
      .then((r) => {
        if (Array.isArray(r.data)) {
          // currently this endpoint returns an array of permissions when user is a business owner
          localStorage.setItem("isBusinessOwner", "true")
        }
      })
      .catch((e) => {
        localStorage.setItem("isBusinessOwner", "true")
      })
      .finally(() => {
        history.replace(values.redirectUri || HOMEPAGE_URL)
      })
  })

  return null
}

export default ForwardingToken
