import useEmployees, { Employee } from "../../../../hooks/api/use-employees"
import PageHeader from "../../../../components/PageHeader"
import React, { FC, useEffect, useMemo, useState } from "react"
import { t } from "i18next"
import Datepicker, {
  initEndDate,
  initStartDateToday,
} from "../../../../components/atom/datepicker/datepicker"
import useTimesheets, { TimeSheet } from "../../../../hooks/api/use-timesheets"
import { add, format, parseISO, startOfDay, sub } from "date-fns"
import Big from "big.js"
import _ from "lodash"
import FileDownloadIcon from "@mui/icons-material/FileDownload"
import Button from "../../../../components/Button"
import { CSVLink } from "react-csv"
import { Trans } from "react-i18next"
import Modal from "../../../../components/Modal"
import apiProvider from "../../../../utils/apiProvider"
import { useShowSnackbar } from "../../../../components/Snackbar"
import LoadingSpinner from "../../../../components/LoadingSpinner"
import IconButton from "../../../../components/IconButton"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import Input from "../../../../components/Input"
import Select from "../../../../components/Select"
import { CircularProgress } from "@mui/material"
import useEmployeeMe from "../../../../hooks/api/use-employee-me"
import useEarnings from "../../../../hooks/api/use-earnings"
import { getUser } from "../../../../utils/authProvider"
import moment from "moment"
import useToggle from "../../../../hooks/use-toggle"
import { convertMinutesToObject } from "../../../../utils/dateTimeUtils"

const EmployeeTimesheet = () => {
  const { data: employees } = useEmployees()
  const [startDate, setStartDate] = useState<string>(initStartDateToday)
  const [endDate, setEndDate] = useState<string>(initEndDate)
  const { data: timesheets } = useTimesheets({
    startDate: moment(startDate).format("YYYY-MM-DD"),
    endDate: moment(endDate).format("YYYY-MM-DD"),
  })
  const [toDelete, setToDelete] = useState<string | null>(null)
  const [toEdit, setToEdit] = useState<{
    timesheet: TimeSheet
    selectedEmployee: Employee
  } | null>(null)
  const [imageSrc, setImageSrc] = useState<string | null>(null)
  const [selfieModalOpen, toggleSelfieModal] = useToggle()

  const employeeWithTimesheet = employees
    ?.filter((e) => e.isActive)
    ?.map((e) => {
      const timesheets_ = timesheets?.filter((t) => t.employeeId === e.id)
      return {
        ...e,
        timesheets: timesheets_,
      }
    })
    ?.sort((a, b) => a.fullName.localeCompare(b.fullName))

  const timesheetGroupByFullName = useMemo(() => {
    const groupData = _.chain(timesheets)?.groupBy("fullName")?.value()

    let newData: TimeSheet[] = []

    if (Object.keys(groupData)?.length) {
      Object.keys(groupData).map((key) => {
        const dataGrouped: any = []
        const items: TimeSheet[] = groupData?.[key]

        if (items?.length) {
          const employee = employees?.find((e) => e.id === items[0].employeeId)
          const name = employee?.fullName?.split(" ")

          const itemsTotalHours = Big(
            items?.reduce((acc, value) => {
              return acc + value.hours - value.lateHours + value.hoursOT
            }, 0)
          )
            ?.div(60)
            ?.round(2)
            ?.toFixed()

          dataGrouped.push({
            lastName: name?.[1] ?? "",
            firstName: name?.[0] ?? "",
            email: employee?.email,
            timeIn: "",
            timeOut: "",
            totalHours: itemsTotalHours,
            submittedFor: "",
          })

          items?.forEach((value, index) => {
            const totalHours = Big(
              value.hours - value.lateHours + value.hoursOT
            )
              .div(60)
              .round(2)
              .toFixed()

            // format into 06/15/2024 Saturday 07:21
            const timeIn = format(
              new Date(value.startAt),
              "yyyy-MM-dd EEEE HH:mm"
            )
            const timeOut = format(new Date(value.endAt), "yyyy-MM-dd E HH:mm")

            dataGrouped.push({
              lastName: "",
              firstName: "",
              email: "",
              timeIn: timeIn,
              timeOut: timeOut,
              totalHours: totalHours,
              submittedFor: value.submittedFor,
            })
          })
        }

        newData = newData.concat(
          dataGrouped?.sort(
            (
              a: { submittedFor: string | number | Date },
              b: { submittedFor: string | number | Date }
            ) =>
              new Date(a.submittedFor).getTime() -
              new Date(b.submittedFor).getTime()
          ) || []
        )
      })
    }

    return newData
  }, [timesheets])

  const getTotalHoursFromTimeSheet = (timesheet: TimeSheet) => {
    const hours = convertMinutesToObject(timesheet.hours)
    const lateHours = convertMinutesToObject(timesheet.lateHours)
    const hoursOT = convertMinutesToObject(timesheet.hoursOT)

    const totalHoursInMinutes =
      (hours.hours + hoursOT.hours - lateHours.hours) * 60 +
      hours.minutes +
      hoursOT.minutes -
      lateHours.minutes

    return convertMinutesToObject(totalHoursInMinutes)
  }

  return (
    <div>
      <PageHeader title={t("Employee Timesheet Report")} hasGoBack />
      <div className="my-4 flex flex-col gap-4 md:flex-row items-end">
        <Datepicker
          value={{ startDate, endDate }}
          onChange={(e: any) => {
            setStartDate(e.startDate)
            setEndDate(e.endDate)
          }}
        />

        <CSVLink
          data={timesheetGroupByFullName}
          filename={`timesheet-${startDate}-${endDate}.csv`}
          headers={[
            { label: "Last Name", key: "lastName" },
            { label: "First Name", key: "firstName" },
            { label: "Email", key: "email" },
            { label: "Time In", key: "timeIn" },
            { label: "Time Out", key: "timeOut" },
            { label: "Total Hours", key: "totalHours" },
          ]}
        >
          <Button variant={"secondaryOrange"} className="!h-[43px] ml-auto">
            <FileDownloadIcon className={"mr-2"} /> Download
          </Button>
        </CSVLink>
      </div>
      <div className={"mt-8"}>
        {employeeWithTimesheet?.map((e) => {
          const employeeTimesheets = e.timesheets
          return (
            <div key={e.id} className={"mb-8"}>
              <h2 className={"text-2xl mb-4 ml-1"}>{e.fullName}</h2>

              <div className="border w-full bg-white rounded-xl overflow-hidden">
                <table className={"w-full"}>
                  <thead className={"bg-gray-50 border-b"}>
                    <tr className={"th:text-left th:py-2 th:px-4"}>
                      <th>Day</th>
                      <th>Time In</th>
                      <th>Time Out</th>
                      <th>Total Hours</th>
                      <th className={"!text-right !pr-4"}>Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {employeeTimesheets
                      ?.sort(
                        (a, b) =>
                          new Date(a.submittedFor).getTime() -
                          new Date(b.submittedFor).getTime()
                      )
                      ?.map((t) => {
                        // monday, etc..
                        const day = format(new Date(t.submittedFor), "EEEE")

                        const totalHours = getTotalHoursFromTimeSheet(t)

                        const startAt = format(
                          new Date(t.startAt),
                          "yyyy-MM-dd HH:mm"
                        )
                        const endAt =
                          t.endAt && t.endAt !== t.startAt
                            ? format(new Date(t.endAt), "yyyy-MM-dd HH:mm")
                            : "-"

                        return (
                          <tr
                            key={t.id}
                            className="td:py-1 td:px-4 border-b last:border-b-0"
                          >
                            <td>{day}</td>
                            <td>
                              <div className="flex flex-col items-start gap-1 m-1">
                                <div>{startAt}</div>
                                {t.clockInImageUrl &&
                                  t.clockInImageUrl.includes("https") && (
                                    <Button
                                      variant="text"
                                      onClick={() => {
                                        setImageSrc(t.clockInImageUrl || null)
                                        toggleSelfieModal()
                                      }}
                                    >
                                      <Trans>View Image</Trans>
                                    </Button>
                                  )}
                              </div>
                            </td>
                            <td>
                              <div className="flex flex-col items-start gap-1 m-1">
                                <div>{endAt}</div>
                                {t.clockOutImageUrl &&
                                  t.clockOutImageUrl.includes("https") && (
                                    <Button
                                      variant="text"
                                      onClick={() => {
                                        setImageSrc(t.clockOutImageUrl || null)
                                        toggleSelfieModal()
                                      }}
                                    >
                                      <Trans>View Image</Trans>
                                    </Button>
                                  )}
                              </div>
                            </td>
                            <td>
                              {totalHours.hours}:{totalHours.minutes}
                            </td>
                            <td className={"!pl-0 !pr-4"}>
                              <div className={"flex items-end justify-end"}>
                                <IconButton
                                  onClick={() => {
                                    setToEdit({
                                      timesheet: {
                                        ...t,
                                        hours:
                                          t.startAt === t.endAt ? 0 : t.hours,
                                      },
                                      selectedEmployee: e,
                                    })
                                  }}
                                  className={
                                    t.isPaid
                                      ? "text-gray-400"
                                      : "text-primary-900"
                                  }
                                  disabled={t.isPaid}
                                >
                                  <EditIcon />
                                </IconButton>

                                <IconButton
                                  onClick={() => setToDelete(t.id)}
                                  className={
                                    t.isPaid
                                      ? "text-gray-400"
                                      : "text-primary-900"
                                  }
                                  disabled={t.isPaid}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>

                {!employeeTimesheets?.length && (
                  <p className={"text-center py-16 text-gray-500"}>
                    No timesheet found for this employee
                  </p>
                )}
              </div>
            </div>
          )
        })}
      </div>
      {toDelete && (
        <DeleteTimesheetModal id={toDelete} onClose={() => setToDelete(null)} />
      )}
      {toEdit && (
        <EditTimesheetModal
          timesheet={toEdit.timesheet}
          selectedEmployee={toEdit.selectedEmployee}
          onClose={() => setToEdit(null)}
        />
      )}
      {selfieModalOpen && imageSrc && (
        <SelfieModal
          open={selfieModalOpen}
          onClose={toggleSelfieModal}
          imageSrc={imageSrc}
        />
      )}
    </div>
  )
}

export const DeleteTimesheetModal: FC<{
  id: string
  onClose: () => void
}> = ({ id, onClose }) => {
  const showSnackbar = useShowSnackbar()
  const { mutate } = useTimesheets()

  const [loading, setLoading] = useState(false)

  const deleteTimeSheet = async () => {
    setLoading(true)
    try {
      await apiProvider.deleteEmployeeTimeSheet(id)
      await mutate()
      showSnackbar(t("Timesheet Deleted."), "success")
      setLoading(false)
      onClose()
    } catch (err) {
      showSnackbar(t("Error Deleting Timesheet."), "error")
      setLoading(false)
    }
  }

  return (
    <Modal
      open
      onClose={onClose}
      title={t("Delete Time Sheet")}
      className="mx-4 w-96 max-w-3xl !p-0"
      titleClassName="px-8 py-5"
    >
      <div className="max-h-[65vh] overflow-auto px-8 pb-4 lg:pr-5">
        <div className="w-full text-center">
          <Trans>Are you sure you want to delete this Time Sheet?</Trans>
        </div>
        <div className="flex flex-row justify-between p-4">
          <Button
            type="button"
            onClick={onClose}
            variant="outlined"
            disabled={loading}
          >
            <Trans>CANCEL</Trans>
          </Button>
          <Button type="button" onClick={deleteTimeSheet} disabled={loading}>
            {loading && <LoadingSpinner className={"mr-2"} />}
            <Trans>DELETE</Trans>
          </Button>
        </div>
      </div>
    </Modal>
  )
}

const defaultHours = {
  hours: 0,
  minutes: 0,
}

const attendanceTypes = ["Present", "Absent", "Paid Holiday", "Unpaid"]

const EditTimesheetModal: FC<{
  timesheet: TimeSheet
  selectedEmployee: Employee
  onClose: () => void
}> = ({ selectedEmployee, onClose, timesheet }) => {
  const showSnackbar = useShowSnackbar()
  const { data: employeeDetails } = useEmployeeMe()
  const { data: employees } = useEmployees()
  const { data: earnings } = useEarnings()
  const { mutate } = useTimesheets()

  const [timesheetDate, setTimesheetDate] = useState(
    new Date(timesheet?.startAt)
  )
  const [employee, setEmployee] = useState<Employee | null>(selectedEmployee)
  const [employmentType, setEmploymentType] = useState(
    selectedEmployee?.employmentType
  )
  const [workHours, setWorkHours] = useState(
    convertMinutesToObject(timesheet.hours)
  )
  const [OTHours, setOTHours] = useState(
    convertMinutesToObject(timesheet.hoursOT)
  )
  const [lateHours, setLateHours] = useState(
    convertMinutesToObject(timesheet.lateHours)
  )

  const [actionLoading, setActionLoading] = useState(false)
  const [OTType, setOTType] = useState(timesheet.overtimeType ?? "")
  const [activities, setActivities] = useState(timesheet.activities)
  const [attendanceType, setAttendanceType] = useState(timesheet.attendanceType)
  const [user, setUser] = useState<any>(null)

  const OTTypes =
    earnings
      ?.filter((earning) => earning.overtimeType !== null)
      .map((earning) => earning.overtimeType) ?? []

  const getTotalHours = () => {
    const workMinutes = workHours.hours * 60 + workHours.minutes
    const otMinutes = OTHours.hours * 60 + OTHours.minutes
    const lateMinutes = lateHours.hours * 60 + lateHours.minutes

    const minutesTotal = workMinutes + otMinutes - lateMinutes

    return convertMinutesToObject(minutesTotal)
  }

  const updateTimeSheet = async () => {
    setActionLoading(true)
    const totalHours = getTotalHours()
    const totalHoursInMinutes = totalHours.hours * 60 + totalHours.minutes

    if (
      (attendanceType === "Present" || attendanceType === "Paid Holiday") &&
      totalHoursInMinutes <= 0
    ) {
      showSnackbar(t("Please add work or OT or Late hours/minutes"), "error")
      setActionLoading(false)
      return
    }
    if (
      (attendanceType === "Present" || attendanceType === "Paid Holiday") &&
      totalHoursInMinutes > 1440
    ) {
      showSnackbar(t("Work Hours should not be beyond 24 Hours"), "error")
      setActionLoading(false)
      return
    }

    const startAt = startOfDay(parseISO(timesheet.startAt))
    const endAt = add(startAt, {
      hours: workHours.hours,
    })
    const params = {
      id: timesheet.id,
      attendanceType: attendanceType,
      overtimeType: OTType,
      hoursOT: OTHours.hours * 60 + OTHours.minutes,
      hours: workHours.hours * 60 + workHours.minutes,
      lateHours: lateHours.hours * 60 + lateHours.minutes,
      startAt: startAt,
      endAt: endAt,
      // submittedFor: selectedTimeSheet.submittedFor,
      isPaid: attendanceType === "Unpaid",
      activities: activities,
      submittedBy: user.username,
    }
    try {
      await apiProvider.updateEmployeeTimeSheet(timesheet.id, params)
      setActionLoading(false)
      await mutate()
      onClose()
    } catch (err) {
      showSnackbar(t("Timesheet Successfully Updated!"), "success")
      onClose()
      window.console.log(err)
      setActionLoading(false)
      showSnackbar(t("Error updating timesheet."), "error")
    }
  }

  async function getUserDetails(): Promise<any> {
    const user = await getUser()
    setUser(user)
  }

  useEffect(() => {
    getUserDetails()
  }, [])

  return (
    <Modal
      open
      onClose={onClose}
      title={t("Time Tracking Details")}
      className="mx-4 w-full !max-w-xl !p-0"
      titleClassName="px-8 py-5"
    >
      <div className="max-h-[65vh] overflow-auto bg-gray-100 px-4 pb-4 md:px-8 lg:pr-5">
        <div className="mt-4 flex flex-col">
          <Input
            label={t("Date")}
            type={"date"}
            value={format(timesheetDate || new Date(), "yyyy-MM-dd")}
            containerClassName="mb-2"
            onChange={(e) => setTimesheetDate(new Date(e.target.value))}
          />

          <Select
            label={t("Employee Name")}
            value={selectedEmployee?.id}
            onChange={(e) => {
              let employeeId = e.target.value
              if (employeeId === "selectEmployee") {
                setEmployee(null)
              } else {
                let index = null
                employees?.map((employ, idx) => {
                  if (employ.id == employeeId) {
                    index = idx
                  }
                })
                setEmployee(employees?.[index ?? 0] as any)
                setEmploymentType(employees?.[index ?? 0].employmentType || "")
              }
            }}
            containerClassName={"mb-2"}
            disabled
          >
            <option value={"selectEmployee"}>Select Employee</option>
            {employees?.map((emp) => {
              return <option value={emp.id}>{emp.fullName}</option>
            })}
          </Select>

          <Input
            label={t("Employment Type")}
            disabled
            value={
              employeeDetails ? employeeDetails.employmentType : employmentType
            }
            containerClassName={"mb-2"}
          />
          <Select
            label={t("Attendance Type")}
            value={attendanceType}
            onChange={(e) => {
              const type = e.target.value
              if (type === "Absent" || type === "Unpaid") {
                setWorkHours(defaultHours)
                setLateHours(defaultHours)
                setOTHours(defaultHours)
              }
              setAttendanceType(e.target.value)
            }}
            containerClassName={"mb-2"}
          >
            {attendanceTypes.map((type) => (
              <option value={type}>{type}</option>
            ))}
          </Select>
          {(attendanceType === "Present" ||
            attendanceType === "Paid Holiday") && (
            <>
              <Input
                label={t("Activities")}
                value={activities}
                onChange={(e) => setActivities(e.target.value)}
                containerClassName={"mb-2"}
              />
              <div className="mb-2 flex flex-col">
                <div className="mb-1 text-sm font-medium opacity-80 peer-disabled:opacity-50">
                  <Trans>Work Hours</Trans>
                </div>
                <HoursSelector
                  time={workHours}
                  onChange={(time) => setWorkHours(time)}
                />
              </div>
              <Select
                label={t("OT Type")}
                value={OTType}
                onChange={(e) => setOTType(e.target.value)}
                containerClassName="mb-2"
              >
                {!OTTypes.includes(OTType) && (
                  <option value={OTType}>{OTType}</option>
                )}
                {OTTypes.map((type) => (
                  <option value={type}>
                    {type == "NightShift" ? "Night Shift" : type}
                  </option>
                ))}
              </Select>
              <div className="mb-2 flex flex-col">
                <div className="mb-1 text-sm font-medium opacity-80 peer-disabled:opacity-50">
                  <Trans>OT Hours</Trans>
                </div>
                <HoursSelector
                  time={OTHours}
                  onChange={(time) => setOTHours(time)}
                />
              </div>
              <div className="mb-2 flex flex-col">
                <div className="mb-1 text-sm font-medium opacity-80 peer-disabled:opacity-50">
                  <Trans>Late Hours</Trans>
                </div>
                <HoursSelector
                  time={lateHours}
                  onChange={(time) => setLateHours(time)}
                />
              </div>
            </>
          )}
          <div className="mb-2 flex flex-col">
            <div className="mb-1 text-sm font-medium opacity-80 peer-disabled:opacity-50">
              <Trans>Total Hours</Trans>
            </div>
            <HoursSelector
              time={getTotalHours()}
              onChange={(time) => console.log(time)}
              disabled
            />
          </div>
        </div>
      </div>
      <div className="sticky border-x-0 border-b-0 border-t-[1px] border-solid border-gray-200">
        {actionLoading && <CircularProgress />}
        <div className="flex flex-row justify-between p-4">
          <Button type="button" onClick={onClose} variant="outlined">
            <Trans>CANCEL</Trans>
          </Button>
          <Button type="button" onClick={updateTimeSheet}>
            <Trans>UPDATE</Trans>
          </Button>
        </div>
      </div>
    </Modal>
  )
}

const HoursSelector: FC<{
  time: { hours: number; minutes: number }
  onChange: (time: { hours: number; minutes: number }) => void
  disabled?: boolean
}> = ({ time, onChange, disabled }) => {
  return (
    <div className="flex w-full flex-row items-center justify-center gap-4 rounded-xl border border-solid border-gray-200 bg-white p-4">
      <Select
        label="hours"
        hideLabel
        value={time.hours.toString()}
        onChange={(e) =>
          onChange({
            hours: parseInt(e.target.value),
            minutes: time.minutes,
          })
        }
        containerClassName="flex-1"
        disabled={disabled}
      >
        {Array.from(Array(23).keys()).map((el) => {
          return <option value={el}>{el}</option>
        })}
      </Select>
      <div className="mb-1 text-sm font-medium opacity-80 peer-disabled:opacity-50">
        <Trans>hour(s)</Trans>
      </div>

      <Select
        label="minutes"
        hideLabel
        value={time.minutes.toString()}
        onChange={(e) =>
          onChange({
            hours: time.hours,
            minutes: parseInt(e.target.value),
          })
        }
        containerClassName="flex-1"
        disabled={disabled}
      >
        {Array.from(Array(59).keys()).map((el) => {
          return <option value={el}>{el}</option>
        })}
      </Select>
      <div className="mb-1 text-sm font-medium opacity-80 peer-disabled:opacity-50">
        <Trans>minute(s)</Trans>
      </div>
    </div>
  )
}

const SelfieModal: FC<{
  open: boolean
  onClose: () => void
  imageSrc: string
}> = ({ open, onClose, imageSrc }) => {
  return (
    <Modal open={open} onClose={onClose} title={"Selfie"}>
      <div className="flex justify-center mt-4">
        <img src={imageSrc} alt="selfie" className="w-80 h-80 rounded-lg" />
      </div>
    </Modal>
  )
}

export default EmployeeTimesheet
