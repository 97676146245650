import useAPI from "./use-api"
import { SWRConfiguration } from "swr"

export type TMPOSSettings = {
  id: string
  enableECatalog: boolean
  customersOptional: boolean
  enableEstoreReg: boolean
  deliveryMethod: string
  useShippingAddress: boolean
  pickup: boolean
  tax: number
  serviceCharge: number
  createdAt: string
  updatedAt: string
  location: {
    id: string
    name: string
    addressLine1: string
    addressLine2: string
    city: string
    state: string
    zip: string
    isPrimaryLocation: boolean
    createdAt: string
    updatedAt: string
  }
  allowPartialPayment: boolean
  requiredPin: boolean
  allowManualDiscount: boolean
  allowManualPrice: boolean
  allowInvoicePayment: boolean
  allowNegativeStock: boolean
  kitchenPrinters: boolean
  kitchenDisplay: boolean
  customerDisplay: boolean
  invoicing: boolean
  enabledPaymentMethods: {
    invoice: boolean
    payLater: boolean
    online: boolean
  }
  sendPayLaterReminder: boolean
  payLaterReminderInDays: number
  sendAppointmentReminder: boolean
  appointmentReminderInDays: number
  enableCashback: boolean
  defaultCashbackPercentage: number | null
  enableOpenTables: boolean
  useTableManagement: boolean
  layout: "list" | "grid"
  cashbackExpiryDays: number
  serviceCharges: any[]
  useQueueNumber: boolean
  useSelfieVerification: boolean
  assignTicketsOnCheckout: boolean
  assignTableNumberOnCheckout: boolean
  enableSelfie: boolean
  enableShiftReport: {
    locationId: string
    emails?: string
    ccEmails: string
  }[]
  requirePinToRemoveItems: boolean
  disableCloseRegisterOnPendingOrder: boolean
}

export type MPOSSettingsResponse = Array<TMPOSSettings>

const useMposSettings = (options: SWRConfiguration = {}) => {
  return useAPI<MPOSSettingsResponse>(`/mpossetting`, undefined, options)
}

export default useMposSettings
