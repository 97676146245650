import { concat } from "lodash"
import { SWRConfiguration } from "swr"
import { DepositUnit, PRODUCT_TYPE_STRING } from "../../lib/models"
import useAPI from "./use-api"

export type GetProductsResponse = Array<{
  useProduction: boolean
  id: string
  name: string
  SKU: string
  isReturnable: boolean
  barcode: string
  qrcode: any
  description: any
  productType: PRODUCT_TYPE_STRING
  productTypeStatus: any
  serviceType: any
  serviceDurationUnit?: any
  paymentOptions: any
  MRP: number
  MRPCurrency: string
  sellingPrice: number
  sellingPriceCurrency: string
  unitOfMeasure: string
  stock: number
  status: string
  sold: number
  stockLost: number
  stockReceived: number
  stockReturned: number
  stockDamaged: number
  stockInHand: number
  reorderQuantity: number
  imageUrl: any
  expirationDate: any
  receivedDate: string
  serviceDuration: number
  createdAt: string
  updatedAt: string
  createdBy: string
  updatedBy: string
  isVisible: boolean
  autoGenerateSKU: boolean
  salesReturned: number
  isOrderEnabled: boolean
  applyToAllLocation: boolean
  isVisibleOnEstore: boolean
  isBundle: boolean
  enableBIR: boolean
  isService: boolean
  supplier: {
    id: string
  }
  locations?: Array<{
    id: string
  }>
  productLocation?: Array<{
    locationId: string
    beginningStock: number
    sellingPriceCurrency: string
    sellingPrice: number
    stockInHand?: number
    created_at?: string
    expirationDate?: any
    receivedDate?: any
    isVisible?: boolean
    isVisibleOnEstore?: boolean
    totalQuantity?: number
    deposit?: number
    depositUnitOfMeasurement?: DepositUnit
    allowPartialPayment?: boolean
    productVariantId?: string
    MRP?: number
    wholesalePrice?: number
    reorderQuantity?: number
    stockLost?: number
    stockReceived?: number
    stockReturned?: number
    stockDamaged?: number
    stockSpoiled?: number
  }>
  bundleItems?: Array<{
    id: string
    quantity: number
    nonInventory: boolean
    productLocation: Array<{
      locationId: string
      MRP?: number
      stockInHand?: number
      productVariantId?: string | null
    }>
    variant?: string
    productType?: PRODUCT_TYPE_STRING
  }>
  category?: Category
  weight: number
  height: number
  width: number
  length: number
  isAvailable?: boolean
  totalQuantity?: number
  termsCondition?: string
  nonInventory?: boolean
  taxable?: boolean
  productOptions?: Array<{
    id: string
    name: string
    values: Array<{
      id: string
      name: string
    }>
  }>
  productVariants?: Array<GetProductResponseVariant>
  wholesalePrice?: number
  images?: Array<{
    url: string
    isPrimary: boolean
  }>
  purchaseUnitOfMeasure?: string
  conversionNumber: number
  minimumQuantity: number
  recommendedQuantity: number
  productAddOnsGroup?: Array<{
    id: string
    name: string
    label: any
    minChoose: number
    maxChoose: number
    allowQuantitySelector: boolean
    productAddOns: Array<{
      id: string
      name: string
      description: any
      sellingPrice: number
      sellingPriceCurrency: string
      sold: number
      location: {
        id: string
        name: string
        addressLine1: string
        addressLine2: string
        city: string
        state: string
        zip: string
        latCoord: any
        longCoord: any
        isPrimaryLocation: boolean
        createdAt: string
        updatedAt: string
      }
    }>
  }>
  icon: string | null
  shape: string | null
  color: string | null
  halal: boolean
  recipe?: GetProductRecipe
  productToSupplier?: Array<any>
}>

export interface ProductToSupplier {
  supplier?: {
    companyName: string
  }
  supplierId?: string
}

export interface Category {
  id: string
  name: string
  description: string
  productType: PRODUCT_TYPE_STRING
  avatar: any
  code: number
  isDefault: boolean
  isLowestLevel: boolean
  createdAt: string
  updatedAt: string
  imageUrl?: string
}

type Query = Partial<{
  name: string
  sku: string
  productType: PRODUCT_TYPE_STRING
  location: string
  inventoryId?: string
  join: string | string[]
  customerId?: string
  priceListId?: string
  withoutJoin?: boolean
  productTypes?: string
  mode?: "lite" | "full"
  supplierId?: string
}>

const useProducts = (
  query?: Query,
  swrConfig?: SWRConfiguration<GetProductsResponse>
) => {
  return useAPI<GetProductsResponse>(
    "/products",
    {
      ...query,
      withoutJoin: undefined,
      mode: query?.mode ?? "full",
      join: query?.withoutJoin
        ? ["category"]
        : concat(
            ["locations", "supplier", "category", "recipe"],
            query?.join ?? []
          ),
    },
    {
      revalidateIfStale: true,
      ...swrConfig,
    }
  )
}

export const useLazyQueryProductDetail = (
  path?: string,
  query?: Query,
  swrConfig?: SWRConfiguration<GetProductsResponse>
) => {
  const swr = useAPI<GetProductsResponse>(
    path ?? null,
    {
      ...query,
      join: concat(
        ["locations", "supplier", "category", "recipe"],
        query?.join ?? []
      ),
    },
    swrConfig
  )

  return {
    ...swr,
  }
}

export type GetProductRecipe = Array<{
  id: string
  quantity: number
  isRequired: boolean
  calculatedMRP?: number
  ingredient: {
    id: string
    name: string
    SKU: string
    isReturnable: boolean
    barcode: any
    qrcode: any
    description: string
    productType: string
    productTypeStatus: any
    paymentOptions: any
    MRP: number
    MRPCurrency: string
    sellingPrice: number
    wholesalePrice: number
    sellingPriceCurrency: string
    unitOfMeasure: string
    unitOfMeasureValue: number
    purchaseUnitOfMeasure: any
    conversionNumber: number
    minimumQuantity: number
    recommendedQuantity: number
    stock: number
    beginningStock: number
    status: string
    sold: number
    stockLost: number
    stockReceived: number
    stockReturned: number
    stockDamaged: number
    stockInHand: number
    reorderQuantity: number
    imageUrl: string
    images: any
    expirationDate: any
    receivedDate: string
    isVisible: boolean
    autoGenerateSKU: boolean
    salesReturned: number
    isOrderEnabled: boolean
    isVisibleOnEstore: boolean
    isBundle: boolean
    weight: number
    width: number
    height: number
    length: number
    attachment: string
    serviceType: any
    serviceDuration: number
    serviceSlotTime: any
    gapBetweenAppointmentTime: number
    maxCustomerPerSlot: number
    reminderTime: any
    totalQuantity: number
    termsCondition: any
    isAvailable: boolean
    availableServiceDate: any
    blockServiceDate: any
    createdAt: string
    updatedAt: string
    createdBy: string
    updatedBy?: string
    deposit: number
    depositUnitOfMeasurement: string
    allowPartialPayment: boolean
    nonInventory: boolean
    priceType: string
    taxable: boolean
    taxIncluded: boolean
    serviceDurationUnit: string
    hasAddOns: boolean
    icon: string
    color?: string
    shape: string
    halal: boolean
    enableBIR: boolean
    useProduction: boolean
    isService: boolean
  }
  ingredientVariant?: {
    id: string
    SKU: string
    MRP: number
    MRPCurrency: string
    sellingPrice: number
    sellingPriceCurrency: string
    beginningStock: number
    sold: number
    stockLost: number
    stockReceived: number
    stockReturned: number
    stockDamaged: number
    stockInHand: number
    reorderQuantity: any
    salesReturned: number
    unitOfMeasure: any
    createdAt: string
    updatedAt: string
    createdBy: any
    updatedBy: any
  }
  location: {
    id: string
  }
}>

interface UpdatedProduct {
  id: string
  name: string
  SKU: string
  isReturnable: boolean
  barcode: string
  qrcode: any
  description: string
  productType: string
  productTypeStatus: any
  paymentOptions: any
  MRP: number
  MRPCurrency: string
  sellingPrice: number
  wholesalePrice: number
  sellingPriceCurrency: string
  unitOfMeasure: string
  unitOfMeasureValue: number
  purchaseUnitOfMeasure: any
  conversionNumber: number
  minimumQuantity: number
  recommendedQuantity: number
  stock: number
  beginningStock: number
  status: string
  sold: number
  stockLost: number
  stockReceived: number
  stockReturned: number
  stockDamaged: number
  stockInHand: number
  reorderQuantity: number
  imageUrl: any
  images: any
  expirationDate: string
  receivedDate: string
  isVisible: boolean
  autoGenerateSKU: boolean
  salesReturned: number
  isOrderEnabled: boolean
  isVisibleOnEstore: boolean
  isBundle: boolean
  weight: number
  width: number
  height: number
  length: number
  attachment: any
  serviceType: any
  serviceDuration: any
  serviceSlotTime: any
  gapBetweenAppointmentTime: number
  maxCustomerPerSlot: number
  reminderTime: any
  totalQuantity: number
  termsCondition: any
  isAvailable: boolean
  availableServiceDate: any
  blockServiceDate: any
  createdAt: string
  updatedAt: string
  createdBy: string
  updatedBy: any
  deposit: number
  depositUnitOfMeasurement: string
  allowPartialPayment: boolean
  nonInventory: boolean
  isService: boolean
  priceType: string
  taxable: boolean
  taxIncluded: boolean
  serviceDurationUnit: string
  hasAddOns: boolean
  icon: any
  color: any
  shape: any
  halal: boolean
}

interface UpdatedProductVariant {
  id: string
}

interface UpdatedProductLocation {
  productId: string
  locationId: string
  productVariantId: string
  sellingPrice: number
  sellingPriceCurrency: string
  unitOfMeasure: string
  unitOfMeasureValue: number
  stock: number
  beginningStock: number
  status: string
  sold: number
  stockLost: number
  stockReceived: number
  stockReturned: number
  stockDamaged: number
  stockInHand: number
  reorderQuantity: number
  expirationDate: string
  receivedDate: string
  serviceDuration: any
  isAvailable: boolean
  availableServiceDate: any
  blockServiceDate: any
  totalQuantity: any
  reminderTime: any
  maxCustomerPerSlot: any
  serviceSlotTime: any
  gapBetweenAppointmentTime: any
  createdAt: string
  updatedAt: string
  createdBy: string
  updatedBy: any
  isVisible: boolean
  salesReturned: number
  isOrderEnabled: boolean
  isVisibleOnEstore: boolean
  deposit: number
  depositUnitOfMeasurement: string
  allowPartialPayment: boolean
  termsCondition: any
  nonInventory: boolean
  MRP: number
  MRPCurrency: string
  wholesalePrice: number
}

export interface GetProductResponseVariant {
  id: string
  variantValues: Array<{
    productOption: {
      id: string
      name?: string
    }
    productOptionValue: {
      id: string
      name?: string
    }
  }>
  MRP: number
  MRPCurrency: string
  sellingPriceCurrency: string
  sellingPrice: number
  beginningStock: number
  stockReceived: number
  stockInHand: number
  SKU: string
  createdAt?: string
  productLocations?: Array<{
    locationId: string
    beginningStock: number
    sellingPriceCurrency: string
    sellingPrice: number
    stockInHand?: number
    created_at?: string
    expirationDate?: any
    receivedDate?: any
    isVisible?: boolean
    isVisibleOnEstore?: boolean
    totalQuantity?: number
    deposit?: number
    depositUnitOfMeasurement?: DepositUnit
    allowPartialPayment?: boolean
    productVariantId?: string
    MRP?: number
    wholesalePrice?: number
    stockLost?: number
    stockReceived?: number
    stockReturned?: number
    stockDamaged?: number
    stockSpoiled?: number
  }>
  variantName: string
}

// used to update get product's result without calling the API again, such as after
// creating product deliveries.
export const mutateGetProducts = (
  products: GetProductsResponse,
  product: UpdatedProduct,
  productVariant?: UpdatedProductVariant,
  productLocations?: UpdatedProductLocation[]
) => {
  // update product
  const toUpdateIdx = products.findIndex((p) => p.id === product.id)
  if (toUpdateIdx === -1) return products

  let productToUpdate = products[toUpdateIdx]
  productToUpdate = Object.assign(productToUpdate, product)

  // update all locations
  productLocations?.forEach((productLocation) => {
    productToUpdate.productLocation ??= []
    const productLocationIdx =
      productToUpdate.productLocation?.findIndex(
        (l) =>
          l.locationId === productLocation.locationId &&
          (!productVariant || l.productVariantId === productVariant?.id)
      ) ?? -1
    if (productLocationIdx === -1) return products
    productToUpdate.productLocation[productLocationIdx] = Object.assign(
      productToUpdate.productLocation[productLocationIdx],
      productLocation
    )
  })

  // update variants
  if (productVariant) {
    productToUpdate.productVariants ??= []
    const productVariantIdx = productToUpdate.productVariants?.findIndex(
      (v) => v.id === productVariant.id
    )
    if (productVariantIdx === -1) return products
    productToUpdate.productVariants[productVariantIdx] = Object.assign(
      productToUpdate.productVariants[productVariantIdx],
      productVariant
    )
  }

  return products
}

export default useProducts
