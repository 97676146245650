import useAPI from "./use-api"

export type ZReportResponse = Array<{
  openedAt: string
  closedAt: string
  employeeBreakdown: Array<any>
  transactions: number
  totalTax: number
  refundsCount: number
  payIn: number
  payOut: number
  totalTendered: number
  discount: number
  cashDiscount: number
  refunds: number
  void: number
  sales: number
  grossSales: number
  variance: number
  cashSales: number
  tenderBreakdown: Array<{
    paymentMethodCode: string
    sum: number
  }>
  lessReturn: number
  lessVat: number
  vatFromCategory: number
  vatableSales: number
  vatAmount: number
  zeroRatedSales: number
  seniorCitizenDiscount: number
  pwdDiscount: number
  nationalAthleteDiscount: number
  regularDiscount: number
  medalOfValorDiscount: number
  beginningOR: string
  endingOR: string
  startTransactionNo: string
  endingTransactionNo: string
  pwdTrans: number
  seniorCitizenTrans: number
  regularDiscountTrans: number
  zeroRatedTrans: number
  otherVATAdjustment: number
  vatOnReturn: null | number
  openingFund: number
  paymentReceived: number
  lessWithdrawal: null | number
  beginningVoid: string
  endingVoid: string
  beginningReturn: string
  endingReturn: string
  zCounter: number
  cashRegisterLog: any
}>

const useZReport = (cashRegisterId: string, logId: string) => {
  const swr = useAPI<ZReportResponse>(
    `/cashRegister/${cashRegisterId}/z-report`,
    { logId }
  )

  return {
    ...swr,
    data: swr.data?.[0],
  }
}

export default useZReport
