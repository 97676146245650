import { Box, Stack, Step, StepLabel, Stepper } from "@mui/material"
import React, { FC, useState } from "react"
import { styled } from "@mui/material/styles"
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector"
import { StepIconProps } from "@mui/material/StepIcon"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import "../index.css"
import { Trans } from "react-i18next"
import clsx from "clsx"
import DigLogLogo from "../../../../assets/Logo_DigLog.png"
import DiglogPaymentKYCForm from "../../../../components/DiglogPaymentKYCForm"
import DiglogPaymentGuide from "../../../../components/DiglogPaymentGuide"
import usePaymentMethod from "../../../../hooks/api/use-payment-method"
import { ChevronLeft } from "@mui/icons-material"
import { Link } from "react-router-dom"

const steps = [1, 2, 3, 4]

enum Page {
  Setting,
  Guide,
}

const DiglogPayment: FC<{
  onBackClicked?: () => void
  onSaved?: () => void
  enabledPaymentMethods?: Array<"bank" | "ewallet">
}> = ({ onBackClicked, onSaved, enabledPaymentMethods }) => {
  const { data: paymentMethod } = usePaymentMethod()

  const [page, setPage] = useState(Page.Setting)

  return (
    <>
      <div className={"login-layout !justify-start !pb-16 lg:!px-8 lg:!pt-16"}>
        <div className={"mb-6 flex w-full items-start"}>
          <div className="mx-auto w-full max-w-6xl overflow-hidden rounded-none bg-white shadow lg:rounded-2xl">
            <div className="border-x-0 border-b border-t-0 border-solid border-gray-200 px-8 pt-4 md:pb-8">
              <button
                onClick={onBackClicked}
                className="-ml-1 mb-3 flex items-center text-lg leading-none text-gray-700 no-underline"
              >
                <ChevronLeft className={"mr-1 h-4 w-4 text-gray-600"} />
                <span className={"mt-0.5 font-medium text-gray-600"}>
                  <Trans>Back</Trans>
                </span>
              </button>

              <div className="flex flex-col items-start gap-4 md:flex-row md:items-center">
                <div className={"flex w-full items-center"}>
                  <img
                    src={DigLogLogo}
                    alt={"PayPal Logo"}
                    className={"h-8 object-cover"}
                  />
                  <h1
                    className={
                      "my-0 ml-2 mr-auto text-2xl font-black text-gray-700"
                    }
                  >
                    <Trans>Payments</Trans>
                  </h1>

                  {paymentMethod?.diglogPayment?.paymentAccountMethods
                    ?.filter((item) => item.isEnabled)
                    .map(({ status, paymentGatewayType }) => (
                      <p
                        className={clsx(
                          "my-0 rounded-lg bg-yellow-100 px-4 py-2 text-sm capitalize text-yellow-900",
                          status === "VERIFIED" &&
                            "!bg-green-100 !text-green-900"
                        )}
                      >
                        {paymentGatewayType.toLowerCase()} |{" "}
                        {status === "VERIFIED" ? "Active" : "Awaiting KYC"}
                      </p>
                    ))}

                  {!paymentMethod?.diglogPayment && (
                    <p className="my-0 ml-auto rounded-full bg-orange-100 px-6 py-3 text-orange-900">
                      Inactive
                    </p>
                  )}
                </div>
              </div>

              <p className={"mt-3 max-w-lg text-lg text-gray-600"}>
                <Trans>
                  With DizLog Payments, you can accept POS and online payments
                  immediately with the lowest transaction fee.
                </Trans>
              </p>

              <div className={"mt-4 flex md:hidden"}>
                <button
                  className={clsx(
                    "mb-0 mr-8 border-x-0 border-b-2 border-t-0 border-solid border-primary-900 bg-transparent py-4 text-gray-900",
                    page === Page.Setting
                      ? "border-primary-900"
                      : "border-transparent"
                  )}
                  onClick={() => setPage(Page.Setting)}
                >
                  Settings
                </button>
                <button
                  className={clsx(
                    "mb-0 mr-8 border-x-0 border-b-2 border-t-0 border-solid bg-transparent py-4 text-gray-900",
                    page === Page.Guide
                      ? "border-primary-900"
                      : "border-transparent"
                  )}
                  onClick={() => setPage(Page.Guide)}
                >
                  Guide
                </button>
              </div>
            </div>

            <div className={"flex"}>
              <div
                className={clsx(
                  "w-full md:block md:w-[600px]",
                  page !== Page.Setting && "hidden"
                )}
              >
                <DiglogPaymentKYCForm
                  enabledPaymentMethods={enabledPaymentMethods}
                  onSaved={onSaved}
                />
              </div>
              <div
                className={clsx(
                  "w-full md:block",
                  page !== Page.Guide ? "hidden" : "block"
                )}
              >
                <DiglogPaymentGuide />
              </div>
            </div>
          </div>
        </div>

        <Box>
          <Stack sx={{ width: "100%", marginTop: "23px" }} spacing={2}>
            <Stepper
              alternativeLabel
              activeStep={2}
              connector={<QontoConnector />}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={QontoStepIcon}>
                    {/* {label} */}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Stack>
        </Box>
      </div>
    </>
  )
}

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {active ? (
        <div className="z-50 flex items-center justify-center rounded-full border-[1px] border-white bg-primary-500 p-3">
          <AccountCircleIcon className="rounded-full bg-white text-lg" />
        </div>
      ) : (
        <div className="QontoStepIcon-circle border-2 border-white" />
      )}
    </QontoStepIconRoot>
  )
}

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#F09000",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#F09000",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#F09000",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}))

const QontoStepIconRoot = styled("div")<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#F09000",
    display: "flex",
    height: 22,
    alignItems: "center",
    ...(ownerState.active && {
      color: "#F09000",
    }),
    "& .QontoStepIcon-completedIcon": {
      color: "#784af4",
      zIndex: 1,
      fontSize: 18,
    },
    "& .QontoStepIcon-circle": {
      width: 12,
      height: 12,
      borderRadius: "50%",
      backgroundColor: "currentColor",
    },
  })
)

export default DiglogPayment
