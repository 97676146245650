import {
  startOfYear,
  endOfYear,
  addYears,
  isSameDay,
  setMonth,
  startOfQuarter,
  endOfQuarter,
} from "date-fns"
import { defaultStaticRanges } from "react-date-range"

const customRanges = [
  ...defaultStaticRanges,
  {
    label: "Last Year",
    range: () => ({
      startDate: startOfYear(addYears(new Date(), -1)),
      endDate: endOfYear(addYears(new Date(), -1)),
    }),
    isSelected(range: any) {
      const definedRange = this.range()
      return (
        isSameDay(range.startDate, definedRange.startDate) &&
        isSameDay(range.endDate, definedRange.endDate)
      )
    },
  },
  {
    label: "This Year",
    range: () => ({
      startDate: startOfYear(new Date()),
      endDate: endOfYear(new Date()),
    }),
    isSelected(range: any) {
      const definedRange = this.range()
      return (
        isSameDay(range.startDate, definedRange.startDate) &&
        isSameDay(range.endDate, definedRange.endDate)
      )
    },
  },
  {
    label: "Q1",
    range: () => ({
      startDate: startOfQuarter(setMonth(new Date(), 1)),
      endDate: endOfQuarter(setMonth(new Date(), 1)),
    }),
    isSelected(range: any) {
      const definedRange = this.range()
      return (
        isSameDay(range.startDate, definedRange.startDate) &&
        isSameDay(range.endDate, definedRange.endDate)
      )
    },
  },
  {
    label: "Q2",
    range: () => ({
      startDate: startOfQuarter(setMonth(new Date(), 4)),
      endDate: endOfQuarter(setMonth(new Date(), 4)),
    }),
    isSelected(range: any) {
      const definedRange = this.range()
      return (
        isSameDay(range.startDate, definedRange.startDate) &&
        isSameDay(range.endDate, definedRange.endDate)
      )
    },
  },
  {
    label: "Q3",
    range: () => ({
      startDate: startOfQuarter(setMonth(new Date(), 7)),
      endDate: endOfQuarter(setMonth(new Date(), 7)),
    }),
    isSelected(range: any) {
      const definedRange = this.range()
      return (
        isSameDay(range.startDate, definedRange.startDate) &&
        isSameDay(range.endDate, definedRange.endDate)
      )
    },
  },
  {
    label: "Q4",
    range: () => ({
      startDate: startOfQuarter(setMonth(new Date(), 10)),
      endDate: endOfQuarter(setMonth(new Date(), 10)),
    }),
    isSelected(range: any) {
      const definedRange = this.range()
      return (
        isSameDay(range.startDate, definedRange.startDate) &&
        isSameDay(range.endDate, definedRange.endDate)
      )
    },
  },
]

export default customRanges
