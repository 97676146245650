import useSWR, { Middleware } from "swr"
import { getPublicAPI } from "../../lib/api"
import i18n from "i18next"
import useUser from "./use-user"

export interface Plan {
  id: number
  name: string
  description: string
  features: Feature[]
  isEnabled: boolean
  billingFrequency: string
  planId: string
  flwPlanId?: string
  stripePlanId?: string
  appleProductId: any
  price: number
  countryGroup: any
  createdAt: string
  updatedAt: string
  maxEmployeesCount?: number
  maxProductsCount?: number
  maxStoresCount?: number
  localCurrency: string
  localPrice: number
}

export interface Feature {
  id: string
  displayName: string
  availableCount?: number
}

const useSubscriptionPlans = () => {
  const { data: user } = useUser()
  const businessId = user?.attributes?.["custom:businessId"]

  const params = new URLSearchParams()
  params.append("lang", i18n.language)
  if (businessId) {
    params.append("businessId", businessId.toString())
  }

  return useSWR<Plan[]>(
    `/subscriptionPlan/new?${params.toString()}`,
    getPublicAPI,
    {
      use: [sortSubscriptionByPriceMiddleware],
    }
  )
}

const sortSubscriptionByPriceMiddleware: Middleware = (useSWRNext) => {
  return (url, fetcher, config) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const swr = useSWRNext<any>(url, fetcher, config)
    return {
      ...swr,
      data: swr.data?.sort((a: any, b: any) => a.price - b.price),
    }
  }
}

export default useSubscriptionPlans
