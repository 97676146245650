import useAPI from "./use-api"

interface GetSubscriptionPaymentMethodsResponse {
  id: string
  countryId: number
  creditCardCode: string
  eWalletCode: string
  bankTransferCode: string
  monthlySubscriptionCode: string
}

const useSubscriptionPaymentMethods = () => {
  return useAPI<GetSubscriptionPaymentMethodsResponse>(
    "/subscription/payment-method"
  )
}

export default useSubscriptionPaymentMethods
