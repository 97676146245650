import ThermalPrinter from "./model"

export default class UsbThermalPrinter implements ThermalPrinter {
  printer: USBDevice | null = null
  interfaceNumber: number | null = null
  endpointOut: number | null = null

  async getConnectedDeviceIds(): Promise<string[]> {
    // Retrieve devices the user has previously authorized
    const devices = await navigator.usb.getDevices()
    return devices.map(
      (device) => device.serialNumber || device.productId.toString()
    )
  }

  async connect(): Promise<void> {
    await this.connectUSB()
  }

  async print(data: Uint8Array): Promise<void> {
    if (!this.printer || this.endpointOut === null) {
      throw new Error("PRINTER_NOT_CONNECTED")
    }

    const result = await this.printer.transferOut(this.endpointOut, data)
    if (result.status !== "ok") {
      throw new Error(`TRANSFER_FAILED: ${result.status}`)
    }
  }

  async disconnect(): Promise<void> {
    if (this.printer) {
      if (this.interfaceNumber !== null) {
        await this.printer.releaseInterface(this.interfaceNumber)
      }
      await this.printer.close()
      this.printer = null
      this.interfaceNumber = null
      this.endpointOut = null
    }
  }

  getId(): string {
    return (
      this.printer?.serialNumber || this.printer?.productId.toString() || ""
    )
  }

  onDisconnect(callback: { (): void }) {}

  async reconnect(deviceId: string): Promise<void> {
    // Find the device by serial number or product ID
    const devices = await navigator.usb.getDevices()
    const printer = devices.find(
      (device) =>
        device.serialNumber === deviceId ||
        device.productId.toString() === deviceId
    )

    if (!printer) {
      throw new Error("PRINTER_NOT_FOUND")
    }

    await this.connectUSB(printer)
  }

  async connectUSB(device: USBDevice | null = null): Promise<void> {
    if (device) {
      this.printer = device
    } else {
      // Prompt the user to select a USB device
      this.printer = await navigator.usb.requestDevice({
        filters: [
          // { vendorId: 0xXXXX, productId: 0xXXXX }, // Replace with your printer's IDs
        ],
      })
    }

    if (!this.printer) {
      throw new Error("PRINTER_NOT_FOUND")
    }

    await this.printer.open()

    // Select the first configuration if none is selected
    if (this.printer.configuration === null) {
      await this.printer.selectConfiguration(1)
    }

    // Find the printer interface (usually with class code 7 for printers)
    const configurationInterfaces = this.printer.configuration?.interfaces ?? []
    let printerInterface: USBInterface | null = null

    for (const iface of configurationInterfaces) {
      for (const alternate of iface.alternates) {
        if (alternate.interfaceClass === 0x07) {
          printerInterface = iface
          break
        }
      }
      if (printerInterface) break
    }

    if (!printerInterface) {
      throw new Error("PRINTER_INTERFACE_NOT_FOUND")
    }

    this.interfaceNumber = printerInterface.interfaceNumber

    await this.printer.claimInterface(this.interfaceNumber)

    // Find the OUT endpoint to send data to the printer
    let endpointOut: number | null = null
    const endpoints = printerInterface.alternates[0].endpoints
    for (const endpoint of endpoints) {
      if (endpoint.direction === "out") {
        endpointOut = endpoint.endpointNumber
        break
      }
    }

    if (endpointOut === null) {
      throw new Error("PRINTER_ENDPOINT_OUT_NOT_FOUND")
    }

    this.endpointOut = endpointOut
  }
}
