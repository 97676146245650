import Select from "./Select"
import { t } from "i18next"
import useCashRegister from "../hooks/api/use-cash-register"
import { FC } from "react"
import useEmployeeMe from "../hooks/api/use-employee-me"

const CashRegisterSelect: FC<{
  disabled?: boolean
  required?: boolean
  value?: string
  onChange?: (locationId: string) => void
  containerClassName?: string
  selectClassName?: string
  hideLabel?: boolean
  productType?: any
  /**specify locations that will be included in the options */
  iconClassName?: string
  location?: string
  showAllAsOption?: boolean
  allowedLocations?: string[]
}> = ({
  required,
  value,
  onChange,
  containerClassName,
  selectClassName,
  hideLabel,
  disabled,
  iconClassName,
  location,
  showAllAsOption,
  allowedLocations,
}) => {
  const { data: cashRegisters } = useCashRegister()

  const filtered = cashRegisters
    ?.filter((value) => {
      return value.location?.id === location || location === "all" || !location
    })
    ?.filter((value) => {
      return (
        allowedLocations?.includes(value.location?.id || "") ||
        !allowedLocations
      )
    })

  return (
    <Select
      id="location"
      name="location"
      label={t(`Cash Register`)}
      disabled={disabled}
      value={value}
      required={required}
      onChange={(e) => {
        if (onChange) {
          onChange(e.target.value)
        }
      }}
      containerClassName={containerClassName}
      selectClassName={selectClassName}
      hideLabel={hideLabel}
      iconClassName={iconClassName}
    >
      <option disabled value={""}>
        {t("Select a Cash Register")}
      </option>
      {showAllAsOption && <option value={"all"}>{t("All")}</option>}
      {filtered?.map((value) => (
        <option key={value.id} value={value.id}>
          {value.name}
        </option>
      ))}
    </Select>
  )
}

export default CashRegisterSelect
