import React from "react"
import { Box } from "@mui/material"
import PageHeader from "../PageHeader"
import { Trans } from "react-i18next"

function NotFoundPage(): React.ReactElement {
  return (
    <Box p={3}>
      <PageHeader title="Go Back" hasGoBack hasDivider />
      <div
        className="App"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1>
          <Trans>PAGE NOT FOUND 404</Trans>
        </h1>
      </div>
    </Box>
  )
}

export default NotFoundPage
