import useSWR from "swr"
import { getPublicAPI } from "../../lib/api"
import { TProductVariant } from "../../views/BusinessSettings/PosLayout/index.types"

type GetEstimateShareableDetailsResponse = {
  id: string
  currency: string
  customerNote: string
  businessNote: string
  termsAndCondition: string
  referenceNumber: string
  estimateNumber: number
  totalPrice: number
  shippingCost: number
  tax: number
  otherCost: number
  otherCostDetails: any
  customFixedDiscount: number
  customPercentageDiscount: number
  createdAt: string
  updatedAt: string
  estimateDate: string
  status: string
  business: {
    id: string
    name: string
    logoUrl: string
    phone: string
    email: string
  }
  estimateItems: Array<{
    id: string
    quantity: number
    sellingPrice: number
    tax: number
    createdAt: string
    updatedAt: string
    product: {
      id: string
      name: string
      SKU: string
      isReturnable: boolean
      barcode: any
      qrcode: any
      description: string
      productType: string
      productTypeStatus: any
      paymentOptions: any
      MRP: number
      MRPCurrency: string
      sellingPrice: number
      wholesalePrice: number
      sellingPriceCurrency: string
      unitOfMeasure: any
      unitOfMeasureValue: number
      purchaseUnitOfMeasure: any
      conversionNumber: number
      minimumQuantity: number
      recommendedQuantity: number
      stock: number
      beginningStock: number
      status: string
      sold: number
      stockLost: number
      stockReceived: number
      stockReturned: number
      stockDamaged: number
      stockInHand: number
      reorderQuantity: any
      imageUrl: any
      images: any
      expirationDate: any
      receivedDate: any
      isVisible: boolean
      autoGenerateSKU: boolean
      salesReturned: number
      isOrderEnabled: boolean
      isVisibleOnEstore: boolean
      isBundle: boolean
      weight: number
      width: number
      height: number
      length: number
      attachment: any
      serviceType: string
      serviceDuration: number
      serviceSlotTime: {
        monday: {
          startTime: string
          endTime: string
          blockTimes: Array<any>
        }
        tuesday: {
          startTime: string
          endTime: string
          blockTimes: Array<any>
        }
        wednesday: {
          startTime: string
          endTime: string
          blockTimes: Array<any>
        }
        thursday: {
          startTime: string
          endTime: string
          blockTimes: Array<any>
        }
        friday: {
          startTime: string
          endTime: string
          blockTimes: Array<any>
        }
        saturday: {
          startTime: string
          endTime: string
          blockTimes: Array<any>
        }
        sunday: {
          startTime: string
          endTime: string
          blockTimes: Array<any>
        }
      }
      gapBetweenAppointmentTime: number
      maxCustomerPerSlot: number
      reminderTime: number
      totalQuantity: number
      termsCondition: any
      isAvailable: boolean
      availableServiceDate: any
      blockServiceDate: any
      createdAt: string
      updatedAt: string
      createdBy: string
      updatedBy: any
      deposit: number
      depositUnitOfMeasurement: string
      allowPartialPayment: boolean
      nonInventory: boolean
      priceType: string
      taxable: boolean
      taxIncluded: boolean
      serviceDurationUnit: string
      hasAddOns: boolean
      icon: any
      color: any
      shape: any
      halal: boolean
      enableBIR: boolean
      useProduction: boolean
      isService: boolean
    }
    productVariant: {
      id: string
      SKU: string
      MRP: number
      MRPCurrency: string
      sellingPrice: number
      sellingPriceCurrency: string
      beginningStock: number
      sold: number
      stockLost: number
      stockReceived: number
      stockReturned: number
      stockDamaged: number
      stockInHand: number
      reorderQuantity: any
      salesReturned: number
      unitOfMeasure: any
      createdAt: string
      updatedAt: string
      createdBy: any
      updatedBy: any
      variantValues: Array<{
        id: string
        productOptionValue: {
          id: string
          name: string
          description: string
        }
      }>
      variantName: string
    }
  }>

  customer: {
    id: string
    firebaseId: string
    stripeCustomerId: any
    phone: string
    email: string
    fullName: string
    dob: string
    createdAt: string
    updatedAt: string
    lang: string
    referralCode: any
  }
  location: {
    id: string
    name: string
    addressLine1: string
    addressLine2: string
    city: string
    state: string
    zip: string
    isPrimaryLocation: boolean
    createdAt: string
    updatedAt: string
  }
  subtotal: number
  orderedProducts: Array<{
    id: string
    name: string
    quantity: number
    sellingPrice: number
  }>
}

const useGetEstimateShareableDetails = (id: string) => {
  return useSWR<GetEstimateShareableDetailsResponse>(
    id ? `/estimate/${id}/share/details` : null,
    getPublicAPI
  )
}

export default useGetEstimateShareableDetails
