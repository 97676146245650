export const USER_POOL_WEB_CLIENT_ID =
  process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID || ""
export const USER_POOL_ID = process.env.REACT_APP_USER_POOL_ID || ""
export const REGION = process.env.REACT_APP_REGION || ""
export const BACKEND_API = process.env.REACT_APP_BACKEND_API || ""
export const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID || ""
export const BUX_API = process.env.REACT_APP_BUX_API || ""
export const WEB_PORTAL = process.env.REACT_APP_WEB_PORTAL || ""

export const IS_PROD =
  BACKEND_API === "https://api-payroll2.dizlog.com" ||
  BACKEND_API === "https://api-payroll.dizlog.com"
// export const PAYPAL_CLIENT_ID = IS_PROD
//   ? "AQJI22-F7-2nUeYZS92Wkh2rOsqOfFYGxWhI8HA7sTy6kkh9A1m3z5C7897wE2AcpMvOJI3bgIzRIPw2"
//   : "AXOtEDghOtxisJyeoB5QrC24fL_xs2W5nEYkgqedRO1i5FHtPY2bkPRL8XSCInKrNunjv6NWmliy7YHB"

export const PAYPAL_CLIENT_ID = IS_PROD
  ? "AQJI22-F7-2nUeYZS92Wkh2rOsqOfFYGxWhI8HA7sTy6kkh9A1m3z5C7897wE2AcpMvOJI3bgIzRIPw2"
  : "AXOtEDghOtxisJyeoB5QrC24fL_xs2W5nEYkgqedRO1i5FHtPY2bkPRL8XSCInKrNunjv6NWmliy7YHB"

export const BASE_URL =
  process.env.NODE_ENV === "production"
    ? `https://${window.location.hostname}`
    : `http://${window.location.hostname}:${window.location.port}`

export const APP_VERSION = "v1.0"
