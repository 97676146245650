import React from "react"
import MenuIcon from "@mui/icons-material/Menu"
import "./index.css"
import logo from "../../assets/Horizontal_logo_new.png"
import CloseIcon from "@mui/icons-material/Close"
import { Link } from "react-router-dom"
import { Notifications } from "@mui/icons-material"

interface Props {
  showMenuIcon: boolean
  toggleSideBar: () => void
}

export const MobileTopBar: React.FC<Props> = ({
  showMenuIcon,
  toggleSideBar,
}) => {
  const isPOS = window.location.pathname.startsWith(
    "/dashboard/mpos/business-catalog"
  )

  const isTransaction = window.location.pathname.startsWith(
    "/dashboard/mpos/transactions"
  )

  return (
    <div className="mobileTopBar">
      <Link
        to={"/dashboard/notifications"}
        className="ml-4 flex !h-12  !w-12 items-center justify-center rounded-lg bg-white/10 text-white transition-colors duration-200 hover:text-primary-900"
      >
        <Notifications className={"h-6 w-6"} />
      </Link>

      <img src={logo} alt="logo" className={"m-0 h-10 object-scale-down p-0"} />

      <button
        onClick={toggleSideBar}
        className="m-0 mr-4 flex !h-12 !w-12 items-center justify-center rounded-lg border-none bg-transparent bg-white/10 text-white transition-colors duration-200 hover:text-primary-900"
        disabled={isPOS || isTransaction}
      >
        {showMenuIcon && <MenuIcon />}
        {!showMenuIcon && <CloseIcon />}
      </button>
    </div>
  )
}
