import useLocations from "../hooks/api/use-locations"
import { Trans } from "react-i18next"
import { ExpandMore } from "@mui/icons-material"
import React, { FC, useRef, useState } from "react"
import { alpha, styled } from "@mui/material/styles"
import Menu, { MenuProps } from "@mui/material/Menu"
import { MenuItem } from "@mui/material"
import useToggle from "../hooks/use-toggle"
import Checkbox from "./Checkbox"
import Input from "./Input"
import Button from "./Button"

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      paddingBottom: "0px",
      paddingTop: "0px",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}))

const MultiLocationSelect: FC<{
  value: string[]
  onChange: (value: string[]) => void
}> = ({ value, onChange }) => {
  const { data: locations } = useLocations()

  const anchorEl = useRef<HTMLButtonElement>(null)
  const [showMenu, toggleMenu] = useToggle()
  const [search, setSearch] = useState("")

  const filteredLocations = locations?.filter(
    (location) =>
      !search || location.name.toLowerCase().includes(search.toLowerCase())
  )

  const selectedLocations = locations?.filter((location) =>
    value.includes(location.id)
  )

  return (
    <>
      <div className={"flex flex-col"}>
        <label className={"mb-1 text-sm ml-1"}>Locations</label>
        <button
          ref={anchorEl}
          className="pl-4 m-0 bg-white border border-gray-300 rounded-lg h-[43px] shadow-sm flex items-center"
          onClick={toggleMenu}
        >
          {!!selectedLocations?.length &&
            selectedLocations.length !== locations?.length && (
              <div className={"flex items-center flex-shrink-0"}>
                {selectedLocations?.map((l) => l.name).join(", ")}
              </div>
            )}

          {selectedLocations?.length === locations?.length && <p>All</p>}
          {value?.length === 0 && <p>No location selected</p>}

          <ExpandMore className={"ml-8 text-gray-500 flex-shrink-0"} />
        </button>
      </div>

      <StyledMenu
        open={showMenu}
        onClose={toggleMenu}
        anchorEl={anchorEl.current}
        disableAutoFocusItem
      >
        <div className={"p-4 border-b bg-gray-50 mb-1"}>
          <Input
            label={"Search"}
            hideLabel
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={"Search locations"}
            onKeyDown={(e) => e.stopPropagation()}
          />
        </div>

        {search && !filteredLocations?.length && (
          <p className={"px-4 py-8 text-center text-gray-500"}>
            No location found
          </p>
        )}

        {filteredLocations?.map((location) => {
          const checked = value.includes(location.id)
          return (
            <MenuItem
              key={location.id}
              disableRipple
              onClick={() => {
                onChange(
                  checked
                    ? value.filter((v) => v !== location.id)
                    : [...value, location.id]
                )
              }}
            >
              <div className={"flex items-center"}>
                <Checkbox
                  label={""}
                  checked={checked}
                  containerClassName={""}
                />
                <p>{location.name}</p>
              </div>
            </MenuItem>
          )
        })}

        <div className={"p-4 border-t mt-1"}>
          <Button variant={"small"} className={"w-full"} onClick={toggleMenu}>
            Done
          </Button>
        </div>
      </StyledMenu>
    </>
  )
}

export default MultiLocationSelect
