import useAPIMutation from "./use-api-mutation"

const useIncrementDownloadCount = (id: string) => {
  return useAPIMutation(
    `/cartTransaction/ordered-product/${id}/increment-download`,
    "POST"
  )
}

export default useIncrementDownloadCount
