import Logo from "../../assets/Logo_DigLog.png"
import { Link } from "react-router-dom"
import { Trans } from "react-i18next"

const VerifySubscriptionFailed = () => {
  return (
    <div className="flex min-h-full flex-col bg-white pb-12">
      <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
        <div className="flex flex-shrink-0 justify-center">
          <a href="/" className="inline-flex">
            <span className="sr-only">DizLog</span>
            <img className="h-12 w-auto" src={Logo} alt="" />
          </a>
        </div>
        <div className="py-16">
          <div className="flex flex-col text-center">
            <p className="p-0 font-body font-semibold uppercase tracking-wide text-primary-900">
              <Trans>Verifying Payment</Trans>
            </p>
            <h1 className="mt-2 mb-4 font-body text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
              <Trans>
                Payment <br /> Verification Failed
              </Trans>
            </h1>
            <p className="mx-auto mt-2 max-w-xs p-0 font-body text-base text-gray-500">
              <Trans>
                Sorry, we can't seem to verify your subscription payment. Please
                try again.
              </Trans>
            </p>
            <Link
              to={"/subscriptions"}
              className="bg-p mx-auto mt-6 flex w-auto rounded-full bg-primary-900 py-3 px-8 text-sm font-bold text-white no-underline shadow-sm"
            >
              <Trans>Back to Subscription Page</Trans>
            </Link>
          </div>
        </div>
      </main>
    </div>
  )
}

export default VerifySubscriptionFailed
