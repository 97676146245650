import useBusiness from "./api/use-business"

const useBusinessCurrency = () => {
  const { data: business } = useBusiness()

  if (!business) return undefined

  return business
    ? business.businessCurrency
      ? business.businessCurrency
      : business.country.currency
    : "USD"
}

export default useBusinessCurrency
