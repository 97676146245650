import { FC, FormEventHandler, useState } from "react"
import { useHistory } from "react-router-dom"
import { patchBusiness } from "../lib/business"
import { subscribeWithCash } from "../lib/subscriptions"
import Modal from "./Modal"
import Input from "./Input"
import clsx from "clsx"
import Button from "./Button"
import useSubscription from "../hooks/api/use-subscription"
import { Trans } from "react-i18next"
import { t } from "i18next"
import { HOMEPAGE_URL } from "../lib/models"

const CheckoutWithCashModal: FC<{
  businessId: string
  planId: string
  onClose: () => void
  originalCode?: string | null
}> = ({ planId, businessId, onClose, originalCode }) => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [affiliateCode, setAffiliateCode] = useState(originalCode || "")
  const [error, setError] = useState<string | null>(null)
  const { mutate } = useSubscription()

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      setError(null)
      await patchBusiness(businessId, { affiliateCode })
      await subscribeWithCash(planId)
      await mutate()
      await history.push(HOMEPAGE_URL)
    } catch (e: any) {
      console.log(e)
      setError(e.response.data.message)
      setLoading(false)
    }
  }

  return (
    <Modal
      open={true}
      onClose={onClose}
      className="w-full !max-w-md"
      title="Pay with Cash"
    >
      <form className={"mt-0"} onSubmit={handleSubmit}>
        <p className="mt-4 mb-6 p-0 text-gray-500">
          <Trans>You can pay with cash through one of our affiliates.</Trans>
        </p>

        <Input
          required
          toolTipContent={"Learn More"}
          label={t("Affiliate Code")}
          placeholder={t("Enter affiliate code.")}
          value={affiliateCode}
          onChange={(e) => {
            setAffiliateCode(e.target.value)
          }}
          disabled
        />

        {error && (
          <p
            className={clsx(
              "mt-4 rounded-lg border border-solid border-red-200 bg-red-50 py-2 px-4 text-center text-sm text-red-900",
              loading && "opacity-50"
            )}
          >
            {error}
          </p>
        )}

        <Button type="submit" className={"mt-4 w-full"} disabled={loading}>
          {loading ? t("Loading...") : t("Continue")}
        </Button>
      </form>
    </Modal>
  )
}

export default CheckoutWithCashModal
