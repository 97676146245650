import React, { FC, useState } from "react"
import Steps from "./Steps"
import Input from "./Input"
import Button from "./Button"
import { Trans } from "react-i18next"
import CheckoutDetailsTable from "./CheckoutDetailsTable"
import { useShowSnackbar } from "./Snackbar"
import LoadingSpinner from "./LoadingSpinner"

const steps = [
  { id: "Step 1", name: "Customer Info" },
  { id: "Step 2", name: "Address" },
  { id: "Step 3", name: "Checkout" },
]

const IpayCompleteCheckoutForm: FC<{
  actionURL: string
  onSubmit: (data: {
    idCardNumber: string
    location: {
      city?: string
      state?: string
      country?: string
      zip?: string
      addressLine1?: string
      addressLine2?: string
    }
    name?: string
    email?: string
    phone?: string
  }) => void
  signedFormValues?: Record<string, string> | null
  disableIdCardNumber?: boolean
  defaultValues?: {
    name?: string
    email?: string
    phone?: string
    idCardNumber?: string
    location?: {
      city?: string
      state?: string
      country?: string
      zip?: string
      addressLine1?: string
      addressLine2?: string
    }
  }
}> = ({
  onSubmit,
  signedFormValues,
  actionURL,
  disableIdCardNumber,
  defaultValues,
}) => {
  const showSnackbar = useShowSnackbar()

  const [loading, setLoading] = useState(false)
  const [name, setName] = useState(defaultValues?.name ?? "")
  const [email, setEmail] = useState(defaultValues?.email ?? "")
  const [phone, setPhone] = useState(defaultValues?.phone ?? "")
  const [idCardNumber, setIdCardNumber] = useState("")
  const [location, setLocation] = useState(
    defaultValues?.location ?? {
      city: "",
      state: "",
      country: "",
      zip: "",
      addressLine1: "",
      addressLine2: "",
    }
  )
  const [currStep, setCurrStep] = useState(0)

  if (currStep === 0) {
    return (
      <form
        key={"customer-form"}
        onSubmit={(e) => {
          e.preventDefault()
          setCurrStep(1)
        }}
      >
        <Steps currStep={currStep} steps={steps} />
        <Input
          label={"Name"}
          value={name}
          onChange={(e) => setName(e.target.value)}
          containerClassName={"mb-4"}
          required
          autocomplete={"name"}
        />

        {!disableIdCardNumber && (
          <Input
            label={"ID Card"}
            value={idCardNumber}
            onChange={(e) => setIdCardNumber(e.target.value)}
            containerClassName={"mb-4"}
            required
          />
        )}

        <Input
          label={"Email"}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          containerClassName={"mb-4"}
          required
          autocomplete={"email"}
        />

        <Input
          label={"Phone"}
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          containerClassName={"mb-4"}
          required
          autocomplete={"tel"}
        />

        <Button type={"submit"} className={"!mt-8 w-full"}>
          Continue
        </Button>
      </form>
    )
  }

  if (!signedFormValues || currStep === 1) {
    return (
      <form
        key={"address-form"}
        onSubmit={async (e) => {
          e.preventDefault()
          if (onSubmit) {
            try {
              setLoading(true)
              await onSubmit({
                name,
                email,
                phone,
                idCardNumber,
                location,
              })
              setCurrStep(2)
            } catch (e) {
              showSnackbar("Something went wrong, please try again.", "error")
            } finally {
              setLoading(false)
            }
          }
        }}
      >
        <Steps currStep={currStep} steps={steps} />

        <Input
          label={"City"}
          containerClassName={"mb-4"}
          required
          value={location?.city}
          onChange={(e) => {
            if (location) {
              setLocation({ ...location, city: e.target.value })
            }
          }}
        />

        <Input
          label={"State"}
          containerClassName={"mb-4"}
          required
          value={location?.state}
          onChange={(e) => {
            if (location) {
              setLocation({ ...location, state: e.target.value })
            }
          }}
        />

        <Input
          label={"Country"}
          containerClassName={"mb-4"}
          required
          value={location?.country}
          onChange={(e) => {
            if (location) {
              setLocation({ ...location, country: e.target.value })
            }
          }}
        />

        <Input
          label={"Zip"}
          containerClassName={"mb-4"}
          required
          value={location?.zip}
          onChange={(e) => {
            if (location) {
              setLocation({ ...location, zip: e.target.value })
            }
          }}
        />

        <Input
          label={"Address Line 1"}
          containerClassName={"mb-4"}
          required
          value={location?.addressLine1}
          onChange={(e) => {
            if (location) {
              setLocation({ ...location, addressLine1: e.target.value })
            }
          }}
        />

        <Input
          label={"Address Line 2 (Optional)"}
          containerClassName={"mb-4"}
          value={location?.addressLine2}
          onChange={(e) => {
            if (location) {
              setLocation({ ...location, addressLine2: e.target.value })
            }
          }}
        />

        <Button type={"submit"} className={"!mt-8 w-full"} disabled={loading}>
          {loading && <LoadingSpinner className={"mr-2"} />}
          Continue
        </Button>
      </form>
    )
  }

  return (
    <form method={"POST"} action={actionURL} key={"checkout-form"}>
      <Steps currStep={currStep} steps={steps} />

      <CheckoutDetailsTable
        className={"mb-4"}
        details={[
          { label: "Name", value: name },
          { label: "ID Card", value: idCardNumber, hide: disableIdCardNumber },
          { label: "Email", value: email },
          { label: "Phone", value: phone },
        ]}
      />

      <CheckoutDetailsTable
        details={[
          { label: "City", value: location.city },
          { label: "State", value: location.state },
          { label: "Country", value: location.country },
          { label: "Zip", value: location.zip },
          { label: "Address Line 1", value: location.addressLine1 },
          { label: "Address Line 2", value: location.addressLine2 },
        ]}
      />

      <div className={"hidden"}>
        {Object.entries(signedFormValues).map(([key, value]) => (
          <input type="hidden" key={key} name={key} value={value.toString()} />
        ))}
      </div>

      <Button
        type="submit"
        className={"sticky bottom-6 mt-4 w-full lg:bottom-0"}
      >
        <Trans>Checkout</Trans>
      </Button>
    </form>
  )
}

export default IpayCompleteCheckoutForm
