import useAPI, { UseAPIQueryParams } from "./use-api"
import useEmployeeMe from "./use-employee-me"
import { SWRConfiguration } from "swr"

export type GetCashRegisterResponse = {
  id: string
  name: string
  licenseKey: string
  controlToken: string
  createdAt: string
  updatedAt: string
  business: {
    id: string
    name: string
  }
  location?: {
    id: string
    name: string
    addressLine1: string
    addressLine2: string
    city: string
    state: string
    zip: string
    isPrimaryLocation: boolean
    createdAt: string
    updatedAt: string
    companyName?: string
    isVatRegistered?: string
    tin?: string
    birEnabled?: boolean
  }
  isOpened?: boolean
  clockedInEmployees: Array<{
    id: string
    avatar: string
    pin: string
    phone: string
    fullName: string
    dob: string
    addressLine1: string
    nationalId: string
  }>
  min: string
  ptu: string
  serialNo: string
}

const useOneCashRegister = (
  id: string,
  options?: {
    query?: { join: string[] }
    config?: SWRConfiguration
  }
) => {
  return useAPI<GetCashRegisterResponse>(
    `/cashRegister/${id}`,
    {
      join: ["clockedInEmployees", ...(options?.query?.join ?? [])],
    },
    options?.config
  )
}

export default useOneCashRegister
