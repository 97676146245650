import { deleteAPI, postAPI, putAPI } from "./api"

export const postNewPaymentMethod = (body: {
  method: string
  credential?: string
  returnUrl?: string

  // ipay88 body
  merchantCode?: string
  merchantKey?: string

  // ipay malaysia new fields
  merchantCodeQR?: string
  merchantKeyQR?: string
  merchantCodeOTP?: string
  merchantKeyOTP?: string
  merchantKeyEstore?: string
  merchantCodeEstore?: string
}) => {
  return postAPI<{ redirectUrl?: string }>("/businesses/payment-method", body)
}

export const deletePaymentMethod = async (id: string) => {
  return deleteAPI("/businesses/payment-method", id)
}

export const putPaymentMethod = (
  id: string,
  body: {
    method: string
    credential?: string

    ipay?: {
      merchantCodeQR?: string
      merchantKeyQR?: string
      merchantCodeOTP?: string
      merchantKeyOTP?: string
      merchantKeyEstore?: string
      merchantCodeEstore?: string
    }
  }
) => {
  return putAPI("/businesses/payment-method", id, body)
}

export const putCustomPaymentMethod = (id: string, body: any) => {
  return putAPI("/businesses/payment-method", id, body)
}
