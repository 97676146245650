import useAPI from "./use-api"

export type ZReportReading = Array<{
  id: string
  openingBalance: number
  closingBalance: number
  openedAt: string
  closedAt: string
  notes: string
  openedBy: any
  closedBy: any
  zCounter: number
  locationName: string
  name: string
  totalTransactions: number
  grossSales: number
}>

const useZReportReading = (
  cashRegisterId: string,
  startDate: string,
  endDate: string
) => {
  return useAPI<ZReportReading>(
    `/cashRegister/${cashRegisterId}/z-report-reading`,
    {
      startDate: startDate,
      endDate: endDate,
    }
  )
}

export default useZReportReading
