import useAPI from "./use-api"
import { addDays, startOfDay } from "date-fns"

export type Notification = {
  id: string
  name: string
  customerId?: string
  orderId?: string
  message: string
  lastNotificationSentAt: string
  eventType: string
  createdAt: string
  updatedAt: string
  read?: boolean
}

const useMposNotifications = (
  businessId: string | undefined,
  isRead: boolean
) => {
  const swr = useAPI<Notification[]>("/mpos-notification", {
    fromDate: addDays(startOfDay(new Date()), -14).toISOString(),
  })
  if (!businessId)
    return {
      ...swr,
      data: [],
    }
  const oldNotificationsStorage = JSON.parse(
    localStorage.getItem("notifications") || "{}"
  )
  let oldNotifications = [] as Notification[]
  if (
    oldNotificationsStorage.businessId &&
    oldNotificationsStorage.businessId === businessId
  ) {
    oldNotifications = oldNotificationsStorage.notifications
  }
  const allOldNotificationIds = oldNotifications.map(
    (el: Notification) => el.id
  )
  const newNotificationsArray = swr.data?.filter(
    (el: Notification) => !allOldNotificationIds.includes(el.id)
  )
  newNotificationsArray?.map((el: Notification) => {
    el.read = isRead
  })
  oldNotifications?.map((el: Notification) => {
    if (isRead) el.read = isRead
  })
  let allNotifications = newNotificationsArray
    ? oldNotifications.concat(newNotificationsArray)
    : oldNotifications

  allNotifications = allNotifications.slice(-1000)
  localStorage.setItem(
    "notifications",
    JSON.stringify({
      notifications: allNotifications,
      businessId: businessId,
    })
  )

  return {
    ...swr,
    data: allNotifications || [],
  }
}

export default useMposNotifications
