import ThermalPrinterEncoder, {
  ThermalPrinterEncoderOption,
} from "thermal-printer-encoder"
import { printBorder } from "./helper"

export const encodeBIRZReport = (
  report: {
    storeName: string
    operatedBy: string
    address: string
    vatRegTin: string
    min: string
    serialNo: string
    reportDate: string
    reportTime: string
    startDate: string
    endDate: string

    begSI: string
    endSI: string
    begVoid: string
    endVoid: string

    resetCounterNo: string
    zCounterNo: number

    // ---

    presentAccumulatedSales: string
    previousAccumulatedSales: string
    salesForTheDay: string

    // ---
    // Breakdown of Sales

    vatableSales: string
    vatAmount: string
    vatExemptSales: string
    zeroRatedSales: string

    // ---

    grossAmount: string
    lessDiscount: string
    lessReturn: string
    lessVoid: string
    lessVatAdjustment: string
    netAmount: string

    // ---
    // Discount Summary

    scDisc: string
    pwdDisc: string
    naacDisc: string
    soloParentDisc: string
    otherDisc: string

    // ---
    // Sales Adjustment

    void: string

    // ---
    // Vat Adjustment

    scTrans: string
    pwdTrans: string
    regDiscTrans: string
    zeroRatedTrans: string
    vatOnReturn: string
    otherVatAdjustments: string

    // ---
    // Transaction Summary

    cashInDrawer: string
    // cheque: string
    // creditCard: string
    // giftCert: string
    tenderBreakdown: Array<{
      paymentMethodCode: string
      sum: number
    }>
    openingFund: string
    lessWithdrawal: string
    paymentReceived: string

    // ---
    shortOver: string
    // ---
    country?: string
  },
  options?: {
    customEncoder?: ThermalPrinterEncoder
    width?: number
    hideHeader?: boolean
  }
) => {
  const width = options?.width || 32

  let encoder =
    options?.customEncoder ||
    new ThermalPrinterEncoder({
      language: "esc-pos",
      width: options?.width,
    }).initialize()

  if (!options?.hideHeader) {
    encoder = encoder
      .bold(true)
      .align("center")
      .line(report.storeName)
      .line("Operated by: " + report.operatedBy)
      .line(report.address)
    if (report.country === "PH") {
      encoder = encoder
        .line(
          report.vatRegTin
            ? "VAT REG TIN" + report.vatRegTin
            : "NON VAT REG TIN"
        )
        .line("MIN: " + report.min)
        .line("S/N:" + report.serialNo)
    }
  }

  // header
  encoder = encoder
    .newline()
    .align("center")
    .bold(true)
    .line("Z-READING REPORT")
    .bold(false)
    .align("left")
    .newline()

  encoder = encoder
    .table(
      [
        { width: width / 2 - 1, marginRight: 2, align: "left" },
        { width: width / 2 - 1, align: "right" },
      ],
      [
        ["Report Date:", report.reportDate],
        ["Report Time:", report.reportTime],
        ["", ""],
        ["Start Date & Time:", report.startDate],
        ["End Date & Time:", report.endDate],
      ]
    )
    .newline()

  encoder = encoder
    .table(
      [
        { width: width / 2 - 1, marginRight: 2, align: "left" },
        { width: width / 2 - 1, align: "right" },
      ],
      [
        ["Beg. SI:", report.begSI],
        ["End SI:", report.endSI],
        ["Beg. Void:", report.begVoid],
        ["End Void:", report.endVoid],
      ]
    )
    .newline()
  encoder = encoder.table(
    [
      { width: width / 2 - 1, marginRight: 2, align: "left" },
      { width: width / 2 - 1, align: "right" },
    ],
    [
      ["Reset Counter No.:", report.resetCounterNo],
      ["Z Counter No.:", report.zCounterNo.toString()],
    ]
  )

  encoder = printBorder(encoder, width, { noSpacing: true })
  encoder = encoder.table(
    [
      { width: width / 2 - 1, marginRight: 2, align: "left" },
      { width: width / 2 - 1, align: "right" },
    ],
    [
      ["Present Accumulated Sales:", report.presentAccumulatedSales],
      ["Previous Accumulated Sales:", report.previousAccumulatedSales],
      ["Sales for the Day:", report.salesForTheDay],
    ]
  )

  encoder = printBorder(encoder, width, { noSpacing: true })
  encoder = encoder
    .line("Breakdown of Sales")
    .newline()
    .table(
      [
        { width: width / 2 - 1, marginRight: 2, align: "left" },
        { width: width / 2 - 1, align: "right" },
      ],
      [
        ["Vatable Sales:", report.vatableSales],
        ["Vat Amount:", report.vatAmount],
        ["Vat Exempt Sales:", report.vatExemptSales],
        ["Zero Rated Sales:", report.zeroRatedSales],
      ]
    )

  encoder = printBorder(encoder, width, { noSpacing: true })
  encoder = encoder.table(
    [
      { width: width / 2 - 1, marginRight: 2, align: "left" },
      { width: width / 2 - 1, align: "right" },
    ],
    [
      ["Gross Amount:", report.grossAmount],
      ["Less Discount:", report.lessDiscount],
      ["Less Return:", report.lessReturn],
      ["Less Void:", report.lessVoid],
      ["Less VAT Adjustment:", report.lessVatAdjustment],
      ["Net Amount:", report.netAmount],
    ]
  )

  encoder = printBorder(encoder, width, { noSpacing: true })
  encoder = encoder
    .line("Discount Summary")
    .newline()
    .table(
      [
        { width: width / 2 - 1, marginRight: 2, align: "left" },
        { width: width / 2 - 1, align: "right" },
      ],
      [
        ["SC Disc:", report.scDisc],
        ["PWD Disc:", report.pwdDisc],
        ["NAAC Disc:", report.naacDisc],
        ["Solo Parent Disc:", report.soloParentDisc],
        ["Other Disc:", report.otherDisc],
      ]
    )

  encoder = printBorder(encoder, width, { noSpacing: true })
  encoder = encoder
    .line("Sales Adjustment")
    .newline()
    .table(
      [
        { width: width / 2 - 1, marginRight: 2, align: "left" },
        { width: width / 2 - 1, align: "right" },
      ],
      [["VOID:", report.void]]
    )

  encoder = printBorder(encoder, width, { noSpacing: true })
  encoder = encoder
    .line("Vat Adjustment")
    .newline()
    .table(
      [
        { width: width / 2 - 1, marginRight: 2, align: "left" },
        { width: width / 2 - 1, align: "right" },
      ],
      [
        ["SC TRANS. :", report.scTrans],
        ["PWD TRANS. :", report.pwdTrans],
        ["Reg. Disc. TRANS :", report.regDiscTrans],
        ["ZERO-RATED TRANS. :", report.zeroRatedTrans],
        ["VAT on Return:", report.vatOnReturn],
        ["Other VAT Adjustments:", report.otherVatAdjustments],
        ["", ""],
      ]
    )

  encoder = printBorder(encoder, width, { noSpacing: true })
  encoder = encoder
    .line("Transaction Summary")
    .newline()
    .table(
      [
        { width: width / 2 - 1, marginRight: 2, align: "left" },
        { width: width / 2 - 1, align: "right" },
      ],
      [
        ["Cash in Drawer:", report.cashInDrawer],
        ...report.tenderBreakdown.map((tender) => [
          tender.paymentMethodCode + ":",
          tender.sum.toFixed(2),
        ]),
        ["Opening Fund:", report.openingFund],
        ["Less Withdrawal:", report.lessWithdrawal],
        ["Payment Received:", report.paymentReceived],
        ["", ""],
      ]
    )
  encoder = printBorder(encoder, width, { noSpacing: true })
  encoder = encoder.table(
    [
      { width: width / 2 - 1, marginRight: 2, align: "left" },
      { width: width / 2 - 1, align: "right" },
    ],
    [["Short/Over:", report.shortOver]]
  )
  encoder = printBorder(encoder, width, { noSpacing: true })

  return encoder.newline().newline().newline()
}
