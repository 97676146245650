import clsx from "clsx"
import { ChangeEvent, FC, ReactNode } from "react"

const Checkbox: FC<{
  label: ReactNode
  id?: string
  name?: string
  containerClassName?: string
  checked?: boolean
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
  required?: boolean
  labelClassName?: string
  inputClassName?: string
}> = ({
  name,
  label,
  id,
  containerClassName,
  checked,
  onChange,
  disabled,
  required,
  labelClassName,
  inputClassName,
}) => (
  <label
    htmlFor={id}
    className={clsx("relative flex items-center", containerClassName)}
  >
    <input
      id={id}
      aria-describedby="candidates-description"
      name={name}
      type="checkbox"
      className={clsx(
        "peer m-0 h-4 w-4 rounded border-gray-300 text-primary-900 accent-primary-900 focus:ring-primary-500",
        inputClassName
      )}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      required={required}
    />
    <span
      className={clsx(
        "pl-3 font-body font-medium text-gray-700 peer-disabled:opacity-50",
        labelClassName
      )}
    >
      {label}
    </span>
  </label>
)

export default Checkbox
