import Big from "big.js"
import { GetCartTransactionResponse } from "../hooks/api/use-cart-transaction"

export const calculateBIRReceiptDetails = (trx: GetCartTransactionResponse) => {
  const customerLocation =
    trx?.customerLocation ?? trx.customer?.customerLocations?.[0]

  const totalTax =
    trx?.taxes
      ?.reduce((acc, tax) => acc.add(tax.amount), Big(0))
      .round(2)
      .toNumber() ?? 0
  const totalTaxExemption =
    trx?.taxExemptions?.reduce((acc, tax) => acc + tax.amount, 0) ?? 0
  const totalTaxApplied = totalTax - totalTaxExemption

  let vatSales = 0
  trx.orderedProducts
    .filter((p) => {
      if (trx.taxExemptions?.length && p.enableBIR) {
        return false
      }
      return p.taxable
    })
    .forEach((p) => {
      vatSales += p.sellingPrice * p.quantity
    })
  vatSales = vatSales
    ? Big(vatSales).minus(trx.discount).round(2).toNumber()
    : 0

  let vatExemptSales = 0
  if (trx.taxExemptions?.length) {
    trx.orderedProducts
      .filter((p) => p.enableBIR)
      .forEach((p) => {
        vatExemptSales += p.sellingPrice * p.quantity
      })
    vatExemptSales = Big(vatExemptSales).minus(trx.discount).round(2).toNumber()
  }

  let zeroRatesSales = 0
  trx.orderedProducts
    .filter((p) => !p.taxable)
    .forEach((p) => {
      zeroRatesSales += p.sellingPrice * p.quantity
    })

  const originalReceipt = trx.receiptHistory?.find(
    (el) => el.type === "ORIGINAL"
  )
  const originalTransNumber = originalReceipt?.seriesNumber
  const originalORNumber = originalReceipt?.ORNumber
  const totalIncludedTaxes =
    trx.taxes
      ?.filter((t) => t.type === "INCLUDED")
      ?.reduce((acc, tax) => acc + tax.amount, 0) || 0

  return {
    customerLocation,
    totalTax,
    totalTaxExemption,
    totalTaxApplied,
    vatSales,
    vatExemptSales,
    zeroRatesSales,
    originalTransNumber,
    originalORNumber,
    totalIncludedTaxes,
  }
}
