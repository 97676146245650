import useSWR, { SWRConfiguration } from "swr"
import useSWRMutation from "swr/mutation"
import { getAPI } from "../../lib/api"

export type UseAPIQueryParams<T extends string> = Record<
  T,
  string | string[] | boolean | number | undefined
>

const getQueries = (params?: any) => {
  const urlSearchParams = new URLSearchParams()
  for (const key in params) {
    // param value
    let query = params[key]
    if (!query) continue

    if (Array.isArray(query)) {
      if (key === "sort") {
        urlSearchParams.append(key, query.join(","))
      } else {
        query?.forEach((value: string) => urlSearchParams.append(key, value))
      }
    } else {
      urlSearchParams.append(key, query.toString())
    }
  }

  return urlSearchParams.toString()
}

const useAPI = <T, K extends string = string>(
  path: string | null,
  params?: UseAPIQueryParams<K>,
  config: SWRConfiguration = {}
) => {
  const queries = getQueries(params)

  let apiPath = params ? `${path}?${queries}` : path

  return useSWR<T>(path ? apiPath : null, getAPI, {
    ...config,
  })
}

const useLazyQueryAPI = <T, K extends string = string>(
  path: string | null,
  params?: UseAPIQueryParams<K>
  // config: SWRConfiguration = {}
) => {
  const queries = getQueries(params)

  let apiPath = params ? `${path}?${queries}` : path
  const { trigger } = useSWRMutation<T>(path ? apiPath : null, getAPI)
}

export default useAPI
