import ThermalPrinter from "./model"

export default class SerialThermalPrinter implements ThermalPrinter {
  private port: SerialPort | null = null
  private writer: WritableStreamDefaultWriter | null = null
  private reader: ReadableStreamDefaultReader | null = null
  private disconnectCallback: (() => void) | null = null
  private deviceId: string = ""

  async connect(): Promise<void> {
    try {
      // Request the serial port
      this.port = await navigator.serial.requestPort()

      // Open the serial port with the desired settings
      await this.port.open({
        baudRate: 9600, // Adjust to match your printer's baud rate
        dataBits: 8,
        stopBits: 1,
        parity: "none",
        flowControl: "none",
      })

      this.deviceId = this.getPortId(this.port)

      // Set up the writer
      const encoder = new TextEncoderStream()
      encoder.readable.pipeTo(this.port.writable!)
      this.writer = encoder.writable.getWriter()

      // Set up the reader for disconnect detection
      this.reader = this.port.readable!.getReader()
      this.readLoop()

      // Handle disconnect event
      navigator.serial.addEventListener("disconnect", this.handleDisconnect)
    } catch (error) {
      console.error("Failed to connect to the printer:", error)
      throw error
    }
  }

  async reconnect(deviceId: string): Promise<void> {
    try {
      // Get a list of available ports
      const ports = await navigator.serial.getPorts()

      // Find the port with the matching deviceId
      this.port =
        ports.find((port) => this.getPortId(port) === deviceId) || null

      if (!this.port) {
        throw new Error("PRINTER_NOT_FOUND")
      }

      // Open the serial port
      await this.port.open({
        baudRate: 9600, // Adjust to match your printer's baud rate
        dataBits: 8,
        stopBits: 1,
        parity: "none",
        flowControl: "none",
      })

      // Set up the writer
      const encoder = new TextEncoderStream()
      encoder.readable.pipeTo(this.port.writable!)
      this.writer = encoder.writable.getWriter()

      // Set up the reader for disconnect detection
      this.reader = this.port.readable!.getReader()
      this.readLoop()

      // Handle disconnect event
      navigator.serial.addEventListener("disconnect", this.handleDisconnect)
    } catch (error) {
      console.error("Failed to reconnect to the printer:", error)
      throw error
    }
  }

  async print(data: Uint8Array): Promise<void> {
    if (!this.writer) {
      throw new Error("PRINTER_NOT_CONNECTED")
    }

    try {
      // Write data to the printer
      await this.writer.write(data)
    } catch (error) {
      console.error("Failed to print data:", error)
      throw error
    }
  }

  async getConnectedDeviceIds(): Promise<string[]> {
    const ports = await navigator.serial.getPorts()
    return ports.map((port) => this.getPortId(port))
  }

  async disconnect(): Promise<void> {
    try {
      if (this.writer) {
        await this.writer.close()
        this.writer = null
      }

      if (this.reader) {
        await this.reader.cancel()
        await this.reader.releaseLock()
        this.reader = null
      }

      if (this.port) {
        await this.port.close()
        this.port = null
      }

      navigator.serial.removeEventListener("disconnect", this.handleDisconnect)
    } catch (error) {
      console.error("Failed to disconnect from the printer:", error)
      throw error
    }
  }

  getId(): string {
    return this.deviceId
  }

  onDisconnect(callback: () => void): void {
    this.disconnectCallback = callback
  }

  // Helper methods

  private getPortId(port: SerialPort): string {
    // The Web Serial API doesn't provide a unique identifier for ports.
    // You can use the serialNumber if available, or other properties.
    // Here, we generate an ID based on the port's properties.
    const info = port.getInfo()
    return `usb-${info.usbVendorId || 0}-${info.usbProductId || 0}`
  }

  private handleDisconnect = (event: Event) => {
    const port = (event as any).port
    if (this.port && this.getPortId(this.port) === this.getPortId(port)) {
      console.log("Printer disconnected.")
      this.disconnect()

      if (this.disconnectCallback) {
        this.disconnectCallback()
      }
    }
  }

  private async readLoop() {
    while (this.reader) {
      try {
        const { value, done } = await this.reader.read()
        if (done) {
          // Reader has been canceled
          break
        }
        // Process incoming data if necessary
      } catch (error) {
        console.error("Error while reading from the port:", error)
        break
      }
    }
  }
}
