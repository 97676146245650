import useAPI from "./use-api"

type GetFlutterwaveBanksResponse = Array<{
  id: number
  name: string
  code: string
}>

const useFlutterwaveBanks = (countryCode: string) => {
  return useAPI<GetFlutterwaveBanksResponse>(
    `/flutterwave/banks/${countryCode}`
  )
}

export default useFlutterwaveBanks
