import useSWRMutation, { SWRMutationConfiguration } from "swr/mutation"
import axios, { AxiosResponse } from "axios"
import authProvider from "../../utils/authProvider"
import { BACKEND_API } from "../../constants/Config"

const createFetcher =
  (method: string) =>
  async <T, K = object>(path: string, data: { arg: K }) => {
    const token = await authProvider.getToken()
    return axios.request<T>({
      url: `${BACKEND_API}${path}`,
      data: data.arg,
      headers: { id_token: token },
      method,
    })
  }

const methodFetcher = {
  GET: createFetcher("GET"),
  POST: createFetcher("POST"),
  PUT: createFetcher("PUT"),
  PATCH: createFetcher("PATCH"),
  DELETE: createFetcher("DELETE"),
}

function useAPIMutation<T, K = undefined>(
  path: string | null,
  method: keyof typeof methodFetcher,
  config?: SWRMutationConfiguration<AxiosResponse<T>, any, string | null, K>
) {
  return useSWRMutation<AxiosResponse<T>, any, string | null, K>(
    path,
    methodFetcher[method],
    config
  )
}

export default useAPIMutation
