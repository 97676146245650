import { useLocation } from "react-router-dom"

const useQueryString = (key: string) => {
  const { search } = useLocation()

  const searchParam = new URLSearchParams(search)

  const param = searchParam.get(key)
  if (Array.isArray(param)) {
    return param[0]
  }
  return param
}

export default useQueryString
