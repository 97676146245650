import ThermalPrinterEncoder, {
  ThermalPrinterEncoderOption,
} from "thermal-printer-encoder"
import { printBorder } from "./helper"
import { format, parseISO } from "date-fns"

export const encodeShiftReport = (
  shiftReport: any,
  encoderOptions: ThermalPrinterEncoderOption = {
    language: "esc-pos",
    width: 32,
  }
) => {
  const width = encoderOptions.width || 32

  let encoder = new ThermalPrinterEncoder({
    ...encoderOptions,
    imageMode: "raster",
  }).initialize()

  encoder = encoder.align("center").bold(true).line("SHIFT REPORT").newline()

  encoder = encoder
    .align("left")
    .line(`Shift Number: 1`)
    .line(`Store: ${shiftReport.cashRegister?.location?.name || ""}`)
    .line(`Register Name: ${shiftReport?.cashRegister?.name || ""}`)
    .line(`Shift Opened: ${shiftReport?.openedBy?.fullName || ""}`)
    .line(
      `${shiftReport?.openedAt ? format(parseISO(shiftReport?.openedAt), "MMMM dd, yyyy, hh:mm:ss a") : "N/A"}`
    )
    .line(`Shift Closed: ${shiftReport?.closedBy?.fullName || ""}`)
    .line(
      `${shiftReport?.closedAt ? format(parseISO(shiftReport?.closedAt), "MMMM dd, yyyy, hh:mm:ss a") : "N/A"}`
    )
    .newline()

  //border
  encoder = printBorder(encoder, width, { noSpacing: true })

  //title
  encoder = encoder.align("left").bold(true).line("Cash Drawer").bold(false)

  //table
  encoder = encoder.table(
    [
      { width: 20 - 1, marginRight: 2, align: "left" },
      { width: width - 20 - 1, align: "right" },
    ],
    [["Starting Cash:", `${shiftReport.startingCash?.toFixed(2) || 0}`]]
  )

  shiftReport.tenderBreakdown.forEach(
    (p: { paymentMethodCode: string; sum: number }) => {
      encoder = encoder.table(
        [
          { width: 20 - 1, marginRight: 2, align: "left" },
          { width: width - 20 - 1, align: "right" },
        ],
        [
          [
            `${p.paymentMethodCode?.toLowerCase() || ""}:`,
            `${p.sum?.toFixed(2) || 0}`,
          ],
        ]
      )
    }
  )

  //table
  encoder = encoder.table(
    [
      { width: 20 - 1, marginRight: 2, align: "left" },
      { width: width - 20 - 1, align: "right" },
    ],
    [
      ["Cash Refunds:", `${shiftReport.refunds?.toFixed(2) || 0}`],
      ["Pay In:", `${shiftReport.payIn?.toFixed(2) || 0}`],
      ["Pay Out:", `${shiftReport.payOut?.toFixed(2) || 0}`],
      ["Difference:", `${shiftReport.variance?.toFixed(2) || 0}`],
    ]
  )

  //border
  encoder = printBorder(encoder, width, { noSpacing: true })

  //title
  encoder = encoder.align("left").bold(true).line("Sales Summary").bold(false)

  //table
  encoder = encoder.table(
    [
      { width: 20 - 1, marginRight: 2, align: "left" },
      { width: width - 20 - 1, align: "right" },
    ],
    [
      ["Gross Sales:", `${shiftReport.grossSales?.toFixed(2) || 0}`],
      ["Refunds:", `${shiftReport.refunds?.toFixed(2) || 0}`],
      ["Discounts:", `${shiftReport.cashDiscount?.toFixed(2) || 0}`],
      ["Net Sales:", `${shiftReport.totalTendered?.toFixed(2) || 0}`],
      ["Cash:", `${shiftReport.cashSales?.toFixed(2) || 0}`],
      ["Taxes:", `${shiftReport.totalTax?.toFixed(2) || 0}`],
    ]
  )

  //border
  encoder = printBorder(encoder, width, { noSpacing: true })

  //title
  encoder = encoder
    .align("left")
    .bold(true)
    .line("Sales Breakdowns")
    .bold(false)

  //table
  shiftReport.employeeBreakdown.forEach(
    (p: { created_at: string; createdBy: string; totalCartAmount: number }) => {
      encoder = encoder.table(
        [
          { width: 20 - 1, marginRight: 2, align: "left" },
          { width: width - 20 - 1, align: "right" },
        ],
        [
          [
            `${p.created_at ? format(parseISO(p.created_at), "HH:mm:ss a") : "N/A"} - ${p.createdBy}`,
            `${p.totalCartAmount?.toFixed(2) || 0}`,
          ],
        ]
      )
    }
  )

  encoder = encoder.newline().newline()

  return encoder.newline().newline().newline().cut().encode()
}
