import React, { FC, useState } from "react"
import useBusiness from "../../hooks/api/use-business"
import useToggle from "../../hooks/use-toggle"
import clsx from "clsx"
import { Trans } from "react-i18next"
import useSubscriptionPlanDetails from "../../hooks/api/use-subscription-plan-details"
import Modal from "./../Modal"
import Select from "./../Select"
import IpayIDOTPForm from "./IpayIDOTPForm"

const IpayIDSubscribeButton: FC<{
  planId: string
  subscriptionPlanId?: number
  className?: string
}> = ({ planId, subscriptionPlanId, className }) => {
  const { data: business } = useBusiness()
  const [showIPay88Modal, toggleModal] = useToggle()

  return (
    <>
      <button
        className={clsx(
          "mx-0 block w-full rounded-xl border-0 border-solid border-primary-800 bg-primary-900 py-4 py-3 text-center text-sm font-semibold text-white text-white no-underline hover:bg-primary-700 disabled:cursor-not-allowed disabled:opacity-50",
          className
        )}
        onClick={toggleModal}
      >
        {/* <Trans>Checkout with Local Methods</Trans> */}
        <Trans>Subscribe</Trans>
      </button>

      {business && subscriptionPlanId && showIPay88Modal && (
        <CheckoutWithIpayIDModal
          planId={planId}
          businessId={business.id}
          onClose={toggleModal}
          subscriptionPlanId={subscriptionPlanId}
        />
      )}
    </>
  )
}

const CheckoutWithIpayIDModal: FC<{
  planId: string
  businessId: string
  onClose: () => void
  subscriptionPlanId: number
}> = ({ businessId, onClose, planId, subscriptionPlanId }) => {
  const { data: plan } = useSubscriptionPlanDetails(
    subscriptionPlanId.toString()
  )

  return (
    <Modal
      open={true}
      onClose={onClose}
      title={"Subscribe " + plan?.name}
      className="max-h-screen w-full !max-w-lg overflow-auto !p-0 lg:overflow-hidden"
      titleClassName={"px-6 pt-6"}
    >
      <div className={"px-6 pb-4"}>
        <p className="mt-4 p-0 text-sm text-gray-500">
          <Trans>
            Checkout using local payment methods available in Indonesia.
          </Trans>
        </p>
      </div>

      <div className="border-x-0 border-t border-b-0 border-solid border-gray-200 bg-zinc-50 p-6 lg:relative lg:max-h-[700px] lg:overflow-auto">
        <IpayIDOTPForm planId={planId} businessId={businessId} />
      </div>
    </Modal>
  )
}

export default IpayIDSubscribeButton
