import ThermalPrinterEncoder, {
  ThermalPrinterEncoderOption,
} from "thermal-printer-encoder"
import { printBorder } from "./helper"
import { format, parseISO } from "date-fns"
import { StockTransfer } from "../../hooks/api/use-stock-transfers"

export const encodeTransferOrder = (
  transferOrder: StockTransfer,
  items: Array<{
    itemName: string
    quantity: number
    pricePerQuantity: number
    totalPrice: number
  }>,
  encoderOptions: ThermalPrinterEncoderOption = {
    language: "esc-pos",
    width: 32,
  }
) => {
  const width = encoderOptions.width || 32

  let encoder = new ThermalPrinterEncoder({
    ...encoderOptions,
    imageMode: "raster",
  }).initialize()

  encoder = encoder
    .align("center")
    .bold(true)
    .line("TRANSFER ORDER")
    .line(transferOrder.orderNumber.toString())
    .newline()
    .newline()

  encoder = printBorder(encoder, width, { noSpacing: true })

  encoder = encoder
    .align("left")
    .line(
      `Date: ${format(parseISO(transferOrder.createdAt), "MM/dd/yyyy HH:mm:ss")}`
    )
    .line(`Ordered By: ${transferOrder?.createdBy || "-"}`)
    .newline()
    .bold(true)
    .line(`Source Location:`)
    .bold(false)
    .line(`${transferOrder?.items?.[0]?.originalLocation?.name}`)
    .line(`${transferOrder?.items?.[0]?.originalLocation?.addressLine1}`)
    .line(`${transferOrder?.items?.[0]?.originalLocation?.addressLine2}`)
    .newline()
    .bold(true)
    .line(`Destination Location:`)
    .bold(false)
    .line(`${transferOrder?.items?.[0]?.destinationLocation?.name}`)
    .line(`${transferOrder?.items?.[0]?.destinationLocation?.addressLine1}`)
    .line(`${transferOrder?.items?.[0]?.destinationLocation?.addressLine2}`)

  encoder = printBorder(encoder, width, { noSpacing: true })

  encoder = encoder
    .table(
      [
        { width: 20 - 1, marginRight: 2, align: "left" },
        { width: width - 20 - 1, align: "right" },
      ],
      [["Item(s)", items.reduce((acc, p) => acc + p.quantity, 0).toString()]]
    )
    .newline()

  items.forEach((p) => {
    encoder = encoder.table(
      [
        { width: 20 - 1, marginRight: 2, align: "left" },
        { width: width - 20 - 1, align: "right" },
      ],
      [
        [
          `${p.itemName} - ${p.quantity}x @${p.pricePerQuantity}`,
          p.totalPrice.toFixed(2),
        ],
      ]
    )
    encoder = encoder.newline()
  })

  encoder = encoder.newline().newline()

  encoder = encoder
    .table(
      [
        { width: 20 - 1, marginRight: 2, align: "left" },
        { width: width - 20 - 1, align: "right" },
      ],
      [["Total", items.reduce((acc, p) => acc + p.totalPrice, 0).toFixed(2)]]
    )
    .newline()

  encoder = printBorder(encoder, width, { noSpacing: true })

  //notes
  encoder = encoder
    .newline()
    .line("Notes:")
    .line(transferOrder.notes || "-")

  return encoder.newline().newline().newline().cut().encode()
}
