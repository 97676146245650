import { useEffect } from "react"
import { useIntercom } from "react-use-intercom"
import i18n from "../i18n"
import useAPI from "./api/use-api"

const useIntercomIdVerification = () => {
  const intercom = useIntercom()
  const userDetails = useAPI<{
    userId: string
    email: string
    name: string
    phone: string
    userHash: string
  }>("/intercom/user")

  useEffect(() => {
    if (userDetails.data) {
      intercom.update({ ...userDetails.data, languageOverride: i18n.language })
    }
  }, [intercom, userDetails.data])
}

export default useIntercomIdVerification
