import useAPI from "./use-api"
import useUser from "./use-user"
import { SWRConfiguration } from "swr"
import * as Sentry from "@sentry/react"

export interface Business {
  id: string
  name: string
  type: string
  typeGroup: any
  email: string
  admin: any
  logoUrl: string
  isFaceRecognitionEnable: boolean
  isGPSLocationTrackingEnable: boolean
  isSelfManagementEnabled: boolean
  isNotificationsEnable: boolean
  isQRcodeEnabled: boolean
  isAttendancePresentByDefault: boolean
  weekWorkdays: number
  salaryCycle: string
  workingDays: number
  createdAt: string
  updatedAt: string
  contactName: string
  registeredCompanyName: string
  phone: string
  targetArn: string
  statutoryCompliance: boolean
  catalogCode: any
  website: string
  description: string
  startTime: string
  endTime: string
  workDayTime: WorkDayTime
  gapBetweenAppointmentTime: number
  multiWalletEnabled: boolean
  storeName: string
  defaultDomain: string
  onlyCashPayment: boolean
  code: number
  lang: string
  registrationLocation: any
  referralCode: any
  deviceLongitude: any
  deviceLatitude: any
  deviceId: any
  affiliateCode?: null | string
  country: Country
  businessCurrency: string
  pin?: string
  businessType: any
  affiliate?: {
    isCashAllowed: boolean
  }
  supplierAccount?: {
    id: string
    contactName: string
    billingInfo: string
    paymentTerms: string
    businessId: string
    companyName: string
    companyPhone: string
    contactEmail: string
    contactPhone: string
    isActive: boolean
    created_at: string
    updated_at: string
    createdAt: string
    productsCount: number
    type: string
  } | null
  manuallyApproveCustomer: boolean
  payslipTemplateSettings: {
    logo: string
    showBusinessAddress: boolean
    showEmployeeId: boolean
    showYTDValues: boolean
    showWorkLocation: boolean
    showDesignation: boolean
    showBankAccount: boolean
    business: any
    createdAt: string
    updatedAt: string
    id: any
  }
}

export interface WorkDayTime {
  monday?: Monday
  tuesday?: Tuesday
  wednesday?: Wednesday
  thursday?: Thursday
  friday?: Friday
  saturday?: Saturday
  sunday?: Sunday
  custom?: Custom
}

export interface Monday {
  startTime: string
  endTime: string
  breakStartTime: string
  breakEndTime: string
}

export interface Tuesday {
  startTime: string
  endTime: string
  breakStartTime: string
  breakEndTime: string
}

export interface Wednesday {
  startTime: string
  endTime: string
  breakStartTime: string
  breakEndTime: string
}

export interface Thursday {
  startTime: string
  endTime: string
  breakStartTime: string
  breakEndTime: string
}

export interface Friday {
  startTime: string
  endTime: string
  breakStartTime: string
  breakEndTime: string
}

export interface Saturday {
  startTime: string
  endTime: string
  breakStartTime: string
  breakEndTime: string
}

export interface Sunday {
  startTime: string
  endTime: string
  breakStartTime: string
  breakEndTime: string
}

export interface Custom {
  startTime: string
  endTime: string
  breakStartTime: string
  breakEndTime: string
}

export interface Country {
  id: number
  name: string
  label: string
  code: string
  phoneCode: string
  currency: string
  codeAlpha2: string
  tax: number
  serviceCharge: number
}

const useBusiness = (config?: SWRConfiguration) => {
  const { data: user } = useUser({ suspense: config?.suspense })
  const businessId = user?.attributes?.["custom:businessId"]

  return useAPI<Business>(
    businessId ? `/businesses/${businessId}` : null,
    undefined,
    {
      onSuccess: (data) => {
        Sentry.setUser({
          email: user?.attributes?.email,
          businessId: user?.attributes?.["custom:businessId"],
          businessName: data.name,
        })
      },
      ...config,
    }
  )
}

export default useBusiness
