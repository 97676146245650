export enum DELIVERECT_ORDER_TYPE {
  PICKUP = 1,
  DELIVERY = 2,
  EAT_IN = 3,
}

export const DELIVERECT_ORDER_TYPE_LABELS = {
  [DELIVERECT_ORDER_TYPE.PICKUP]: "Pickup",
  [DELIVERECT_ORDER_TYPE.DELIVERY]: "Delivery",
  [DELIVERECT_ORDER_TYPE.EAT_IN]: "Eat in",
}
