import { t } from "i18next"
import React, { FC, useState } from "react"
import Datepicker, {
  initEndDate,
  initStartDate,
} from "../../../../components/atom/datepicker/datepicker"
import LocationSelect from "../../../../components/LocationSelect"
import CashRegisterSelect from "../../../../components/CashRegisterSelect"
import EmployeeSelect from "../../../../components/EmployeeSelect"
import Select from "../../../../components/Select"
import Button from "../../../../components/Button"
import { useImmer } from "use-immer"
import CustomerSelectDropdown from "../../../../components/customerSelectDropdown"
import useBirJournal from "../../../../hooks/api/use-bir-journal"
import { Link } from "react-router-dom"
import { ChevronLeft } from "@mui/icons-material"
import clsx from "clsx"
import { uniq } from "lodash"
import { BIRJournalFilter } from "../../../../lib/models"
import ReportPreview from "./ReportPreview"

const BIRJournal = () => {
  const [appliedFilter, setAppliedFilter] = useState<BIRJournalFilter>(
    generateDefaultFilter()
  )

  return (
    <div className={"pb-16 "}>
      <div className={"bg-white border rounded-xl "}>
        <div className={"p-6 border-b"}>
          <Link
            to={"/dashboard/mpos/report"}
            className="flex items-center text-primary-900 mb-1 font-bold -ml-1.5"
          >
            <ChevronLeft className={"mb-0.5"} /> Back
          </Link>
          <h1 className={"text-3xl font-bold mb-2"}>Journal</h1>
          <span className={clsx("mt-2 text-sm text-gray-600")}>
            Learn more about generating your E-Journal for BIR reporting.
          </span>
        </div>

        <div className={"flex"}>
          <FilterForm
            defaultValues={appliedFilter}
            onGenerateClick={setAppliedFilter}
          />
          <ReportPreview filter={appliedFilter} />
        </div>
      </div>
    </div>
  )
}

const generateDefaultFilter = (): BIRJournalFilter => ({
  startDate: initStartDate(),
  endDate: initEndDate(),
  location: "all",
  cashRegister: "all",
  employee: "all",
  transaction: "all",
  transactionType: "all",
  customer: "all",
  paymentMethod: "all",
})

const FilterForm: FC<{
  defaultValues: BIRJournalFilter
  onGenerateClick: (filter: BIRJournalFilter) => void
}> = ({ defaultValues, onGenerateClick }) => {
  const [
    {
      startDate,
      endDate,
      location,
      cashRegister,
      employee,
      transaction,
      transactionType,
      customer,
      paymentMethod,
    },
    setState,
  ] = useImmer(defaultValues)
  const { data: transactions } = useBirJournal({
    location: location === "all" ? undefined : location,
    endDate: endDate,
    startDate: startDate,
  })
  const availableMethods = uniq(
    transactions?.map((t) =>
      t.paymentMethodCode === "CUSTOM" ? t.paymentChannel : t.paymentMethodCode
    )
  )

  return (
    <div className={"border-r p-6 w-1/3 bg-gray-50 flex flex-col gap-4"}>
      <h2 className={"text-xl font-bold"}>Filter options:</h2>
      <Datepicker
        value={{ startDate, endDate }}
        onChange={(e) => {
          setState((draft) => {
            draft.startDate = e.startDate
            draft.endDate = e.endDate
          })
        }}
      />
      <LocationSelect
        value={location}
        onChange={(location) => {
          setState((draft) => {
            draft.location = location
          })
        }}
      />
      <CashRegisterSelect
        showAllAsOption
        value={cashRegister}
        onChange={(e) => {
          setState((draft) => {
            draft.cashRegister = e
          })
        }}
      />
      <EmployeeSelect
        label={t("Staff")}
        enableAll
        value={employee}
        onChange={(e) => {
          setState((draft) => {
            draft.employee = e
          })
        }}
      />
      <Select
        label={"Transaction"}
        value={transaction}
        onChange={(e) => {
          setState((draft) => {
            draft.transaction = e.target.value
          })
        }}
      >
        <option value="all">All transactions</option>
        <option value="ORIGINAL">Sale</option>
        <option value="VOID">Void</option>
        <option value="REFUND">Refund</option>
        <option value="REPRINT">Reprint</option>
      </Select>
      <Select
        label={"Transaction Type"}
        value={transactionType}
        onChange={(e) => {
          setState((draft) => {
            draft.transactionType = e.target.value
          })
        }}
      >
        <option value="all">All</option>
        <option value={"DONE"}>Done</option>
        <option value={"VOID"}>Void</option>
        <option value={"RETURN_ACCEPTED"}>Return Accepted</option>
        <option value={"PENDING"}>Pending</option>
      </Select>
      <CustomerSelectDropdown
        label={t("Customer")}
        enableAll
        onChange={(e) => {
          setState((draft) => {
            draft.customer = e
          })
        }}
        value={customer}
      />
      <Select
        label={"Payment Method"}
        value={paymentMethod}
        onChange={(e) => {
          setState((draft) => {
            draft.paymentMethod = e.target.value
          })
        }}
      >
        <option value="all"> All</option>
        {availableMethods.map((method) => (
          <option value={method} className={"capitalize"}>
            {method?.toLowerCase()}
          </option>
        ))}
      </Select>
      <div className={"flex mt-8"}>
        <Button
          variant={"primaryWhite"}
          onClick={() => {
            const defaultFilter = generateDefaultFilter()
            setState(defaultFilter)
            onGenerateClick(defaultFilter)
          }}
        >
          Reset Filter
        </Button>
        <Button
          className={"ml-auto"}
          onClick={() =>
            onGenerateClick({
              startDate,
              endDate,
              location,
              cashRegister,
              employee,
              transaction,
              transactionType,
              customer,
              paymentMethod,
            })
          }
        >
          Generate
        </Button>
      </div>
    </div>
  )
}

export default BIRJournal
