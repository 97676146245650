import useAPI from "./use-api"

type GetFlutterwaveBanksBranchesResponse = Array<{
  id: number
  branch_code: string
  branch_name: string
  swift_code: string
  bank_id: number
  bic: string
}>

const useFlutterwaveBanksBranches = (bankId: string) => {
  return useAPI<GetFlutterwaveBanksBranchesResponse>(
    `/flutterwave/bank-branches/${bankId}`
  )
}

export default useFlutterwaveBanksBranches
