import ThermalPrinterEncoder, {
  ThermalPrinterEncoderOption,
} from "thermal-printer-encoder"
import moment from "moment/moment"
import { printBorder, printSplitTable } from "./helper"
import { Business, KitchenPrinterOptions } from "../models"

export type KitchenPrinterItem = {
  products: Array<{
    id: string
    product: string
    quantity: number
    orderDate: string
    discount: number
    outstanding: number
    taxIncluded: boolean
    name: string
    sellingPrice: number
    originalSellingPrice?: number
    notes?: string
    productOptionValues: Array<{
      id: string
      name: string
      productOptionId: string
      productOptionName: string
    }>
    orderedProductAddOns: Array<{
      name: string
      id: string
      quantity: number
      sellingPrice: number
    }>
    categoryId: string
  }>
  location: {
    birEnabled: boolean
  }
  tax: number
  serviceCharge: number
  discount: number
  totalCartAmount: number
  totalItemQuantity: number
  cashbookTransactionId: string
  creditbookTransactionId: string
  buxRequestId: string
  buxPaymentStatus: string
  orderStatus: string
  customerName: string
  shippingCost: number
  paymentMethodCode: string
  isMpos: boolean
  isServiceOrder: boolean
  business: string
  locationId: string
  outstanding: number
  notes: string
  isWalkIn: boolean
  createdBy: string
  cashReceived: number
  cashChange: number
  cashRegister: string
  taxes: Array<{
    id: string
    rate: number
    name: string
    type: string
    applyTo: string
    option: string
    accountType: any
    amount: number
  }>
  source: string
  reward: any
  cashback: number
  isB2B: boolean
  priceList: string
  wholesale: boolean
  taxExemptions: Array<any>
  isPickup: boolean
  pickupTime: any
  id: string
  orderNumber: string
  receiptHistory: Array<{
    id: string
    seriesNumber: string
    ORNumber: string
    type: string
    createdAt: string
    updatedAt: string
    printed: boolean
  }>
  orderType?: string
}

export const encodeKitchenPrinter = (
  trx: KitchenPrinterItem,
  activeUser: string,
  business: Business,
  kitchenPrinterOptions?: KitchenPrinterOptions,
  encoderOptions: ThermalPrinterEncoderOption = {
    language: "esc-pos",
    width: 32,
  }
) => {
  const width = 32
  let encoder = new ThermalPrinterEncoder(encoderOptions)
    .initialize()
    .align("center")
    .bold(true)

  if (kitchenPrinterOptions?.type === "REPRINT") {
    encoder = encoder.line("*** REPRINT ***")
  } else if (kitchenPrinterOptions?.type === "EDITED") {
    encoder = encoder.line("*** EDITED ***")
  }

  encoder = encoder.line(`#${trx.orderNumber}`)

  if (kitchenPrinterOptions?.type !== "REPRINT") {
    encoder = encoder.line(`Trans No: 000001`)
  } else {
    encoder = encoder.line(
      `Reprint Date and Time: ${moment(Date.now()).format("D MMM YYYY h:mm a")}`
    )
  }

  encoder = encoder
    .line(business.name)
    .newline()
    .align("left")
    .bold(false)
    .line(moment(Date.now()).format("D MMM YYYY h:mm a"))

  if (trx.location?.birEnabled) {
    const originalSeriesNumber = trx.receiptHistory?.find(
      (el: any) => el.type === "ORIGINAL"
    )?.seriesNumber
    encoder = encoder.line(`Trans No.: ${originalSeriesNumber}`)
  }

  if (trx.orderType) {
    encoder = encoder.line(`Order Type: ${trx.orderType}`)
  }

  encoder = printBorder(encoder, width, { noSpacing: true })
  encoder = printSplitTable(encoder, width, 8, [
    ["Item(s)", trx.products.length.toString()],
  ])
  encoder = printBorder(encoder, width, { noSpacing: true })

  trx.products.forEach((p) => {
    encoder = encoder.bold(true).line(`${p.quantity} x ${p.name}`).bold(false)

    if (p.productOptionValues) {
      if (kitchenPrinterOptions?.variant === "ONE_LINE") {
        encoder = encoder.line(
          p.productOptionValues
            .map((v) => `${v.productOptionName}: ${v.name}`)
            .join(", ")
        )
      } else {
        p.productOptionValues.forEach((v) => {
          encoder = encoder.line(`${v.productOptionName}: ${v.name}`)
        })
      }
    }

    if (p.orderedProductAddOns?.length) {
      encoder = encoder.line("Add-ons")
      p.orderedProductAddOns.forEach((v) => {
        encoder = encoder.line(`- ${v.name}`)
      })
    }

    if (p.notes) {
      encoder = encoder.line(`Notes: ${p.notes}`)
    }
  })

  encoder = printBorder(encoder, width, { noSpacing: true })
  if (trx.notes) {
    encoder = encoder.line(`Notes: ${trx.notes}`)
  }
  encoder = encoder
    .align("center")
    .line(`Cashier: [${activeUser}]`)
    .bold(true)
    .line(trx.customerName)
    .newline()

  if (trx.location?.birEnabled) {
    encoder = encoder
      .align("center")
      .line("THIS DOCUMENT IS NOT VALID FOR CLAIM OF INPUT TAX")
  }

  return encoder.newline().newline().newline().cut().encode()
}

export const encodeFullKitchenPrinter = (
  trx: KitchenPrinterItem,
  activeUser: string,
  business: Business,
  kitchenPrinterOptions?: KitchenPrinterOptions,
  encoderOptions: ThermalPrinterEncoderOption = {
    language: "esc-pos",
    width: 32,
  }
) => {
  const width = 32
  let encoder = new ThermalPrinterEncoder(encoderOptions)
    .initialize()
    .align("center")
    .bold(true)

  if (kitchenPrinterOptions?.type === "REPRINT") {
    encoder = encoder.line("*** REPRINT ***")
  } else if (kitchenPrinterOptions?.type === "EDITED") {
    encoder = encoder.line("*** EDITED ***")
  }

  encoder = encoder
    .line(`#${trx.orderNumber}`)
    .line(business.name)
    .line(trx.customerName)
    .newline()
    .align("left")
    .bold(false)
    .line(moment(Date.now()).format("D MMM YYYY h:mm a"))
    .line(`Cashier: [${activeUser}]`)

  if (trx.location?.birEnabled) {
    const originalSeriesNumber = trx.receiptHistory?.find(
      (el: any) => el.type === "ORIGINAL"
    )?.seriesNumber
    encoder = encoder.line(`Trans No.: ${originalSeriesNumber}`)
  }

  if (trx.orderType) {
    encoder = encoder.line(`Order Type: ${trx.orderType}`)
  }

  encoder = printBorder(encoder, width, { noSpacing: true })
  encoder = printSplitTable(encoder, width, 8, [
    ["Item(s)", trx.products.length.toString()],
  ])
  encoder = printBorder(encoder, width, { noSpacing: true })

  trx.products.map((p) => {
    let sellingPrice = p.sellingPrice
    if (p.taxIncluded && p.originalSellingPrice)
      sellingPrice = p.originalSellingPrice

    const totalAddOnPrice =
      p.orderedProductAddOns?.reduce(
        (acc, v) => acc + v.sellingPrice * v.quantity,
        0
      ) || 0
    sellingPrice += totalAddOnPrice

    let name = `${p.quantity} x ${p.name}`

    encoder = printSplitTable(encoder, width, width / 2, [
      [name, (p.quantity * sellingPrice).toFixed(2)],
    ])

    if (p.productOptionValues) {
      if (kitchenPrinterOptions?.variant === "ONE_LINE") {
        encoder = encoder.line(
          p.productOptionValues.map((v) => `${v.name}`).join(", ")
        )
      } else {
        p.productOptionValues.forEach((v) => {
          encoder = encoder.line(`${v.productOptionName}: ${v.name}`)
        })
      }
    }

    if (p.orderedProductAddOns?.length) {
      encoder = encoder.line("Add-ons")
      p.orderedProductAddOns.forEach((v) => {
        encoder = printSplitTable(encoder, width, width / 2, [
          [`- ${v.name}`, (v.quantity * v.sellingPrice).toFixed(2)],
        ])
      })
    }

    if (p.notes) {
      encoder = encoder.line(`Notes: ${p.notes || ""}`)
    }
  })

  encoder = printBorder(encoder, width, { noSpacing: true })
  encoder = printSplitTable(encoder, width, 16, [
    ["Discount", trx.discount?.toFixed(2) || "0"],
    ["Service Charge", trx.serviceCharge?.toFixed(2) || "0"],
    ["Tax", trx.tax?.toFixed(2) || "0"],
  ])
  encoder = printBorder(encoder, width, { noSpacing: true })
  encoder = printSplitTable(encoder, width, 10, [
    [
      "Subtotal",
      (
        (trx.totalCartAmount || 0) -
        (trx.discount || 0) +
        (trx.serviceCharge || 0) +
        (trx.tax || 0)
      )?.toFixed(2) || "0",
    ],
  ])
  encoder = printBorder(encoder, width, { noSpacing: true })
  encoder = encoder.line(`Notes: ${trx.notes}`).newline()

  if (trx.location?.birEnabled) {
    encoder = encoder
      .align("center")
      .line("THIS DOCUMENT IS NOT VALID FOR CLAIM OF INPUT TAX")
  }

  return encoder.newline().newline().cut("partial").encode()
}
