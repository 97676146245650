import React, { memo } from "react"
import { Box, Typography } from "@mui/material"
import Loading from "../../assets/svgs/loading.svg"
import { Trans } from "react-i18next"

interface Props {
  color?: string
  withoutText?: boolean
}

const Component = ({ color, withoutText }: Props): any => (
  <Box
    style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
  >
    {withoutText ? null : (
      <Typography mr={2} style={{ color: color || "" }}>
        <Trans>Loading</Trans>
      </Typography>
    )}
    <img src={Loading} alt="" />
  </Box>
)

Component.defaultProps = {
  color: undefined,
  withoutText: false,
}

export default memo(Component)
