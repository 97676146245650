import React, { ChangeEventHandler, FC } from "react"
import ShareIcon from "@mui/icons-material/Share"
import {
  EmailShareButton,
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  EmailIcon,
  WhatsappIcon,
  FacebookIcon,
} from "react-share"
import Tooltip from "@mui/material/Tooltip"
import IconButton from "./"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import { indexOf } from "lodash"
import Popover from "@mui/material/Popover"
import Paper from "@mui/material/Paper"
import clsx from "clsx"

const ShareButtonComponent: FC<{
  mail: {
    url: string
    title: string
    body: string
  }
  whatsApp: {
    url: string
    title: string
  }
  facebook: {
    url: string
    quote: string
  }
  className?: string
}> = ({ mail, whatsApp, facebook, className }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? "simple-popover-share-button" : undefined

  let iconSize = 27
  return (
    <div
      className={clsx(
        "flex hover:bg-orange-100 items-center justify-center rounded-full text-primary-900 outline-none",
        className
      )}
    >
      <Tooltip title={"Share Link"}>
        <button className="border-none m-0" onClick={handleClick}>
          <ShareIcon className="h-[43px] w-[43px] " />
        </button>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Paper className="p-2">
          <EmailShareButton
            url={mail.url}
            subject={mail.title}
            body={mail.body}
            className="Demo__some-network__share-button"
          >
            <EmailIcon size={iconSize} round />
          </EmailShareButton>

          <WhatsappShareButton
            url={whatsApp.url}
            title={whatsApp.title}
            className="Demo__some-network__share-button"
          >
            <WhatsappIcon size={iconSize} round />
          </WhatsappShareButton>

          <FacebookShareButton
            url={facebook.url}
            quote={facebook.quote}
            className="Demo__some-network__share-button"
          >
            <FacebookIcon size={iconSize} round />
          </FacebookShareButton>
        </Paper>
      </Popover>
    </div>
  )
}

export default ShareButtonComponent
