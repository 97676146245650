import { Link, useParams } from "react-router-dom"
import useBusiness from "../../../../../hooks/api/use-business"
import { FC, useRef, useState } from "react"
import { useReactToPrint } from "react-to-print"
import { Trans } from "react-i18next"
import ShareButtonComponent from "../../../../../components/ShareButton"
import { Tooltip } from "@mui/material"
import IconButton from "../../../../../components/IconButton"
import { ArrowBack, ContentCopy, Edit, LinkSharp } from "@mui/icons-material"
import PrintIcon from "@mui/icons-material/Print"
import { formatNumberToCurrency } from "../../../../../lib/currency"
import usePublicPoDetails from "../../../../../hooks/use-public-po-details"
import PurchaseOrderPreview from "../../../../../components/PurchaseOrderPreview"
import useBusinessCurrency from "../../../../../hooks/use-business-currency"
import Modal from "../../../../../components/Modal"
import Input from "../../../../../components/Input"
import clsx from "clsx"
import Button from "../../../../../components/Button"
import {
  acceptPurchaseOrder,
  rejectPurchaseOrder,
} from "../../../../../lib/purchase-order"
import { PurchaseOrderStatus } from "../../../../../lib/models"

const baseUrl =
  process.env.NODE_ENV === "production"
    ? `https://${window.location.hostname}`
    : `http://${window.location.hostname}:${window.location.port}`

export const generatePurchaseOrderShareableLink = (id: string) => {
  return `${baseUrl}/share/purchase-order/${id}`
}

const PurchaseOrderDetails = () => {
  const currency = useBusinessCurrency()

  const { id } = useParams<{ id: string }>()
  const { data: po, mutate } = usePublicPoDetails(id)
  const { data: business } = useBusiness()

  const [showLinkShareModal, setShowLinkShareModal] = useState(false)
  const componentRef = useRef(null)
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  if (!business || !po) return null

  let shareableURL = generatePurchaseOrderShareableLink(id)

  return (
    <div className={"w-full"}>
      <div className="px-b mx-auto box-border flex min-h-screen w-full max-w-[1200px] flex-col md:px-8">
        <div className="mb-4 flex flex-wrap items-center px-2 pt-8 lg:items-end">
          <div>
            <Link
              to={"/dashboard/inventory/stock-catalog/purchase-orders/"}
              className={"text-primary-900 mb-2 block -ml-1"}
            >
              <ArrowBack className={"mr-1"} /> Back
            </Link>
            <p className="m-0 p-0 text-2xl font-medium">
              <Trans>Purchase Order #{{ purchaseOrder: po.PONumber }}</Trans>
            </p>
          </div>

          <div className="ml-auto flex h-[43px] items-center justify-center p-2">
            <ShareButtonComponent
              mail={{
                url: shareableURL,
                title: `Purchase Order ${po.PONumber}`,
                body: "Hi, take a look on this one",
              }}
              whatsApp={{
                url: shareableURL,
                title: `Purchase Order ${po.PONumber}`,
              }}
              facebook={{
                url: shareableURL,
                quote: `Purchase Order ${po.PONumber}`,
              }}
            />
          </div>

          <Tooltip title={"Print Purchase Order"}>
            <IconButton
              className="ml-4 h-[43px] w-[43px] text-primary-900 hover:bg-orange-100"
              onClick={handlePrint}
            >
              <PrintIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title={"Share Purchase Order"}>
            <IconButton
              className="ml-4 h-[43px] w-[43px] text-primary-900 hover:bg-orange-100"
              onClick={() => setShowLinkShareModal(true)}
            >
              <LinkSharp />
            </IconButton>
          </Tooltip>

          <Tooltip title={"Edit"}>
            <Link
              to={`/dashboard/inventory/stock-catalog/purchase-orders/${id}`}
              className="ml-4 mr-2 flex h-[43px] w-[43px] items-center justify-center rounded-full text-primary-900 hover:bg-orange-100"
            >
              <Edit />
            </Link>
          </Tooltip>
        </div>

        <div className="relative flex w-full flex-col-reverse items-start gap-4 pb-32 lg:flex-row ">
          <div
            ref={componentRef}
            className="w-full overflow-hidden lg:basis-3/4"
          >
            <PurchaseOrderPreview
              po={po}
              business={business}
              location={po.location}
              currency={currency}
            />
          </div>
          <div className="top-4 mx-auto w-full lg:sticky lg:w-[320px]">
            <div className="mb-4 rounded-xl border border-solid border-gray-200 bg-white shadow-sm">
              <div className="flex items-center p-4">
                <p className={"m-0 p-0 text-lg font-medium"}>
                  <Trans>Total Amount</Trans>
                </p>
                <p className={"my-0 ml-auto p-0 text-2xl font-medium"}>
                  {formatNumberToCurrency(currency, po.totalPrice)}
                </p>
              </div>

              <div className="mt-3 flex items-center justify-center p-4 border-t">
                {po.status === "NEW" ? (
                  <div className={"w-full"}>
                    <Button
                      onClick={async () => {
                        if (!po) return

                        await acceptPurchaseOrder(po.id)
                        await mutate()
                      }}
                      className={"w-full mb-2"}
                    >
                      Confirm Purchase Order
                    </Button>

                    <Button
                      onClick={async () => {
                        if (!po) return

                        await rejectPurchaseOrder(po.id)
                        await mutate()
                      }}
                      className="w-full !text-red-600 !border-red-600 hover:!text-white "
                      variant={"outlined"}
                    >
                      Reject Purchase Order
                    </Button>
                  </div>
                ) : (
                  <Button
                    className={clsx(
                      "w-full capitalize",
                      po.status === PurchaseOrderStatus.CONFIRMED &&
                        "!bg-green-600 !text-white",
                      po.status === PurchaseOrderStatus.REJECTED &&
                        "!bg-red-600 !text-white"
                    )}
                    disabled
                  >
                    Purchase Order {po.status.toLowerCase()}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {showLinkShareModal && (
        <SharePOLinkModal
          poId={po.id}
          onClose={() => setShowLinkShareModal(false)}
        />
      )}
    </div>
  )
}

const SharePOLinkModal: FC<{
  poId: string
  onClose: () => void
}> = ({ poId, onClose }) => {
  const [showTextCopied, setShowTextCopied] = useState(false)
  const link = generatePurchaseOrderShareableLink(poId)

  const canShare = !!navigator.share && link

  const handleShare = async () => {
    if (canShare) {
      await navigator.share({
        title: "Purchase Order",
        url: link,
      })
    } else {
      window.open(link, "_blank")
    }
  }

  const handleCopy = async () => {
    setShowTextCopied(false)
    try {
      await navigator.clipboard.writeText(link)
      setShowTextCopied(true)
    } catch (e: any) {
      setShowTextCopied(false)
    }
  }

  return (
    <Modal
      open
      onClose={onClose}
      title={"Share Purchase Order Link"}
      className={"w-full max-w-sm"}
    >
      <div className={"mt-2 flex items-end"}>
        <Input
          value={link}
          label={"Link"}
          disabled
          inputClassName={"!opacity-100 text-gray-500"}
          hideLabel={true}
          containerClassName={"w-full"}
        />
        <IconButton
          className="ml-2 h-[43px] w-[43px] !rounded-lg !border !border-solid !border-gray-300 shadow-sm"
          onClick={handleCopy}
        >
          <ContentCopy />
        </IconButton>
      </div>

      <p
        className={clsx(
          "mb-0 mt-2 text-center text-sm text-green-800 opacity-0",
          showTextCopied && "opacity-100 transition-opacity"
        )}
      >
        <Trans>Link copied!</Trans>
      </p>

      <div className={"mt-0 flex"}>
        <Button variant={"outlined"} className={"mr-auto"} onClick={onClose}>
          <Trans>Close</Trans>
        </Button>
        <Button onClick={handleShare}>
          {canShare ? <Trans>Share</Trans> : <Trans>Open</Trans>}
        </Button>
      </div>
    </Modal>
  )
}

export default PurchaseOrderDetails
