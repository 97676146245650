import Logo from "../../assets/Logo_DigLog.png"
import { Trans } from "react-i18next"
import { useEffect, useRef } from "react"
import useQueryString from "../../hooks/use-query-string"

// {"MerchantCode":"M14589","RefNo":"SUB-lU3N7_YS1gRHT0t","FirstPaymentDate":"27092022","Currency":"MYR","Amount":"1.00","NumberOfPayments":"9999","Frequency":"5","Desc":"Payroll + HRMS yearly","CC_Ic":"123321","CC_Name":"Malayshiop","CC_Email":"malay@chrsep.dev","CC_Phone":"+6060606060","P_Name":"Malayshiop","P_Email":"malay@chrsep.dev","P_Phone":"+6060606060","P_Addrl1":"40 Jalan Nunis","P_Addrl2":"","P_City":"Seremban","P_State":"Negeri Sembilan","P_Zip":"70000","P_Country":"Malaysia","BackendURL":"https://dev-payroll.diglog.sg/shared/webhook/ipay/recurring/backendURL","ResponseURL":"https://dev-payroll.diglog.sg/shared/webhook/ipay/recurring/responseURL","Signature":"F975B+edOE5GHSFifckMHL5J4lM="}

// Useed by mobile app to forward data to ipay malaysia checkout page.
const IpayRecurringForwardingCheckout = () => {
  const form = useRef<HTMLFormElement>(null)

  const MerchantCode = useQueryString("MerchantCode")
  const RefNo = useQueryString("RefNo")
  const FirstPaymentDate = useQueryString("FirstPaymentDate")
  const Currency = useQueryString("Currency")
  const Amount = useQueryString("Amount")
  const NumberOfPayments = useQueryString("NumberOfPayments")
  const Frequency = useQueryString("Frequency")
  const Desc = useQueryString("Desc")
  const CC_Ic = useQueryString("CC_Ic")
  const CC_Name = useQueryString("CC_Name")
  const CC_Email = useQueryString("CC_Email")
  const CC_Phone = useQueryString("CC_Phone")
  const P_Name = useQueryString("P_Name")
  const P_Email = useQueryString("P_Email")
  const P_Phone = useQueryString("P_Phone")
  const P_Addrl1 = useQueryString("P_Addrl1")
  const P_Addrl2 = useQueryString("P_Addrl2")
  const P_City = useQueryString("P_City")
  const P_State = useQueryString("P_State")
  const P_Zip = useQueryString("P_Zip")
  const P_Country = useQueryString("P_Country")
  const BackendURL = useQueryString("BackendURL")
  const ResponseURL = useQueryString("ResponseURL")
  const Signature = useQueryString("Signature")

  useEffect(() => {
    if (form.current) form.current.submit()
  }, [])

  return (
    <div className="flex min-h-full flex-col bg-white pb-12">
      <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-0 sm:px-6 lg:px-8">
        <div className="flex flex-shrink-0 justify-center">
          <div className="inline-flex">
            <span className="sr-only">DizLog</span>
            <img className="h-12 w-auto" src={Logo} alt="" />
          </div>
        </div>
        <div className="py-16">
          <div className="flex flex-col text-center">
            <p className="p-0 font-body font-semibold uppercase tracking-wide text-primary-900">
              <Trans>Local Checkout</Trans>
            </p>
            <h1 className="mt-2 mb-4 font-body text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
              <Trans>
                Setting Up <br />
                Payment Page
              </Trans>
            </h1>
            <p className="mx-auto mt-2 max-w-xs p-0 font-body text-base text-gray-500">
              <Trans>
                Please wait a moment while we redirect you to the payment page.
              </Trans>
            </p>
            <p className="bg-p mx-auto mt-6 flex w-auto rounded-full bg-primary-900 py-3 px-4 text-sm font-bold text-white shadow-sm">
              <svg
                className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <Trans>Loading</Trans>
            </p>
          </div>
        </div>
      </main>

      <form
        method={"POST"}
        action="https://payment.ipay88.com.my/recurringpayment2.0/subscription.asp"
        ref={form}
        className={"hidden"}
      >
        <input name={"MerchantCode"} value={MerchantCode} />
        <input name={"RefNo"} value={RefNo} />
        <input name={"FirstPaymentDate"} value={FirstPaymentDate} />
        <input name={"Currency"} value={Currency} />
        <input name={"Amount"} value={Amount} />
        <input name={"NumberOfPayments"} value={NumberOfPayments} />
        <input name={"Frequency"} value={Frequency} />
        <input name={"Desc"} value={Desc} />
        <input name={"CC_Ic"} value={CC_Ic} />
        <input name={"CC_Name"} value={CC_Name} />
        <input name={"CC_Email"} value={CC_Email} />
        <input name={"CC_Phone"} value={CC_Phone} />
        <input name={"P_Name"} value={P_Name} />
        <input name={"P_Email"} value={P_Email} />
        <input name={"P_Phone"} value={P_Phone} />
        <input name={"P_Addrl1"} value={P_Addrl1} />
        <input name={"P_Addrl2"} value={P_Addrl2} />
        <input name={"P_City"} value={P_City} />
        <input name={"P_State"} value={P_State} />
        <input name={"P_Zip"} value={P_Zip} />
        <input name={"P_Country"} value={P_Country} />
        <input name={"BackendURL"} value={BackendURL} />
        <input name={"ResponseURL"} value={ResponseURL} />
        <input name={"Signature"} value={Signature} />
      </form>
    </div>
  )
}

export default IpayRecurringForwardingCheckout
