import React, { FC, useEffect } from "react"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import Square_Blue_WhiteLogo from "../../../../assets/enterPinLogo.png"
import LogoDiglog from "../../../../assets/Logo_DigLog.png"
import Big from "big.js"
import { generateVariantNameFromVariantValues } from "../../../../lib/variants"
import { formatNumberToCurrency } from "../../../../lib/currency"
import { Cart } from "../../../../lib/models"
import { PostCheckoutResponseBody } from "../../../../hooks/api/use-checkout"
import { useIntercom } from "react-use-intercom"
import { Business } from "../../../../hooks/api/use-business"
import { CheckCircleOutline } from "@mui/icons-material"
import LoadingSpinner from "../../../../components/LoadingSpinner"
import useCDSCartListener, {
  useSyncCDS,
} from "../../../../hooks/use-cds-cart-listener"
import { Trans } from "react-i18next"

const CustomerDisplay: FC = () => {
  useSyncCDS()
  const intercom = useIntercom()
  const state = useCDSCartListener()

  useEffect(() => {
    intercom.shutdown()
  }, [])

  if (!state.connected) {
    return <ConnectPage />
  }

  const customer = state.customer || state.receipt?.customer

  return (
    <div className="flex h-full">
      <div className="relative flex w-1/2 flex-col items-center justify-center">
        <div className="absolute top-16 flex flex-col items-center">
          {state.business?.logoUrl && (
            <img
              src={state.business?.logoUrl}
              className={"mb-6 h-24 w-24 rounded-xl object-cover"}
            />
          )}
          <span className="text-5xl font-semibold text-white">
            {state.business?.name}
          </span>
          {state.cashier && (
            <span className="mt-2 text-xl font-bold text-white">
              Served by {state.cashier.name}
            </span>
          )}
        </div>

        <div className="mt-6 flex w-3/4 max-w-[400px] flex-col items-center justify-center rounded-xl bg-white p-8 shadow-md">
          <div className="flex w-full items-center">
            <div>
              <AccountCircleIcon sx={{ fontSize: "50px" }} />
            </div>
            <div className="ml-4 flex flex-col">
              <span className="text-xl font-semibold ">
                {customer?.fullName ?? "Walk In"}
              </span>
              {!!customer?.points && (
                <span className="text-sm ">
                  <Trans>Points balance:</Trans> {customer.points}
                </span>
              )}

              {!!customer?.cashback && (
                <span className="text-sm ">
                  <Trans>Cashback:</Trans> {customer.cashback}
                </span>
              )}
            </div>
          </div>

          {/*<div className="mt-6 flex w-full flex-col">*/}
          {/*  <button className="mt-4 h-12 w-full border-none bg-primary-900 text-white outline-none">*/}
          {/*    SEND RECEIPT{" "}*/}
          {/*  </button>*/}
          {/*</div>*/}
        </div>
      </div>

      {!state.receipt && (
        <div className="flex w-1/2 flex-col bg-white font-mono text-2xl">
          {state.cart && state.checkout && state.cart.items.length > 0 ? (
            <TransactionDetails
              cart={state.cart}
              checkout={state.checkout}
              business={state.business}
              posType={state.posType}
            />
          ) : (
            <div className="mt-16 flex items-center justify-center text-gray-500">
              <Trans>Cart is Empty</Trans>
            </div>
          )}
        </div>
      )}

      {state.receipt && state.business && (
        <div className="flex w-1/2 flex-col bg-white text-2xl">
          <div className={"flex flex-col items-center py-16 text-green-500"}>
            <CheckCircleOutline className={"mb-4 !h-40 !w-40"} />
            <h2 className={"font-bold"}>
              <Trans>Transaction successful</Trans>
            </h2>
          </div>

          <div className={"mx-auto mb-16 flex gap-16"}>
            <div className={"text-center "}>
              <h3 className={"mb-2  text-3xl text-gray-500"}>
                <Trans>Total Paid</Trans>
              </h3>
              <p className={"text-4xl font-bold"}>
                {formatNumberToCurrency(
                  state.business.businessCurrency,
                  state.receipt.cashReceived
                )}
              </p>
            </div>

            <div className={"text-center text-4xl"}>
              <h3 className={"mb-2  text-3xl text-gray-500"}>
                <Trans>Change</Trans>
              </h3>
              <p className={"font-bold"}>
                {formatNumberToCurrency(
                  state.business.businessCurrency,
                  state.receipt.cashChange
                )}
              </p>
            </div>
          </div>

          {!!state.receipt.rewardedPoints && (
            <p className={"text-center"}>
              <Trans>Points earned:</Trans> {state.receipt.rewardedPoints}
            </p>
          )}
          {!!state.receipt.rewardedCashback && (
            <p className={"text-center"}>
              <Trans>Cashback earned:</Trans> {state.receipt.rewardedCashback}
            </p>
          )}
        </div>
      )}
    </div>
  )
}

const TransactionDetails: FC<{
  cart: Cart
  checkout: PostCheckoutResponseBody
  business?: Business
  posType?: string
}> = ({ cart, checkout, business, posType }) => {
  const currency = business?.businessCurrency ?? "USD"

  return (
    <div className="flex h-full w-full flex-shrink-0 flex-col border-l bg-gray-50 shadow">
      <div className="flex border-b bg-white p-4">
        <p>
          <Trans>Ticket</Trans>
        </p>
        <span className="ml-auto">{posType}</span>
      </div>

      <div className={"flex-1 overflow-y-auto"}>
        {cart.items.map((item) => {
          const totalPrice = Big(item?.sellingPrice ?? 0)
            .times(item.qty)
            .round(2)
            .toNumber()

          let variantName = ""
          if (item?.productVariant) {
            const variant = item.product?.productVariants?.find(
              (x: any) => x.id === item?.productVariant
            )
            variantName = generateVariantNameFromVariantValues(
              variant?.variantValues
            )
          }

          return (
            <div
              key={item.id}
              className="w-full border-b bg-white p-4 hover:bg-primary-50"
            >
              <div className={"w-full text-xl"}>
                <div className={"flex"}>
                  <div className={"mr-2 font-bold"}>{item.product?.name}</div>
                  <span className={"mx-2 text-gray-500"}>x</span>
                  <div className={"text-gray-500"}>{item?.qty}</div>

                  <div className={"ml-auto "}>
                    {formatNumberToCurrency(currency, totalPrice)}
                  </div>
                </div>
                {variantName && (
                  <div className={"flex text-gray-500"}>
                    <div>{variantName}</div>
                  </div>
                )}

                {item?.orderedProductAddOns &&
                  item?.orderedProductAddOns.length > 0 && (
                    <div className={"text-lg text-gray-500"}>
                      <p className={"mt-4 font-bold"}>Add Ons:</p>
                      {item?.orderedProductAddOns?.map((el) => {
                        return (
                          <div key={el.productAddOns.id} className={"flex"}>
                            <p className={""}>{el.productAddOns.name}</p>
                            <p className={"ml-auto"}>
                              {formatNumberToCurrency(
                                currency,
                                el.productAddOns.sellingPrice
                              )}
                            </p>
                          </div>
                        )
                      })}
                    </div>
                  )}
              </div>
            </div>
          )
        })}
      </div>

      <div className={"sticky bottom-0 w-full bg-white"}>
        <div className={"border-t bg-primary-50 text-xl"}>
          <div className={"px-4 py-2"}>
            <div className={"mb-2 flex font-bold"}>
              <p>
                <Trans>Subtotal</Trans>
              </p>
              <p className={"ml-auto"}>
                {formatNumberToCurrency(
                  currency,
                  checkout.totalAmountWithoutDiscount
                )}
              </p>
            </div>

            <div className={"flex font-bold"}>
              <p>
                <Trans>Tax</Trans>
              </p>
              <p className={"ml-auto"}>
                {formatNumberToCurrency(currency, checkout.taxAmount)}
              </p>
            </div>

            {/*<ul className="list-disc mt-2 ">*/}
            {/*  {checkout?.taxes?.map((t) => {*/}
            {/*    return (*/}
            {/*      <li className="flex pb-1 text-gray-700">*/}
            {/*        <p className="flex w-full font-[Roboto]">*/}
            {/*          <span className={"ml-2 mr-3"}>•</span> {t.name}*/}
            {/*          {t.type === "INCLUDED" && (*/}
            {/*            <span className={"ml-2 capitalize"}>*/}
            {/*              ({t.type.toLowerCase()})*/}
            {/*            </span>*/}
            {/*          )}*/}
            {/*        </p>*/}
            {/*        <p className="flex w-full justify-end">*/}
            {/*          {formatNumberToCurrency(currency, t.amount)}*/}
            {/*        </p>*/}
            {/*      </li>*/}
            {/*    )*/}
            {/*  })}*/}
            {/*</ul>*/}

            <div className={"mt-2 flex font-bold"}>
              <p>
                <Trans>Discount</Trans>
              </p>
              <p className={"ml-auto"}>
                {formatNumberToCurrency(
                  currency,
                  (checkout.totalDiscount ?? 0) * -1
                )}
              </p>
            </div>
          </div>

          <div className={"flex border-t px-4 py-3 font-bold"}>
            <p>
              <Trans>Total</Trans>
            </p>
            <p className={"ml-auto"}>
              {formatNumberToCurrency(
                currency,
                checkout.totalAmountWithDiscount
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

const ConnectPage = () => {
  const state = useCDSCartListener()

  return (
    <div className={"h-full w-full lg:flex "}>
      <div className={"m-16 flex flex-1 flex-col items-center justify-center"}>
        <div className={"rounded-3xl bg-white p-16 shadow md:p-32"}>
          <p className={"text-center text-2xl font-bold text-primary-900"}>
            <Trans>CDS ID</Trans>
          </p>
          <p className={"mt-2 text-center font-mono text-5xl font-bold"}>
            {state.id ?? (
              <div className="flex h-[44px] w-[216px] items-center justify-center">
                <LoadingSpinner className={"mx-auto my-3 text-primary-900"} />
              </div>
            )}
          </p>
          <p className={"text mt-2 text-center text-gray-500"}>
            <Trans>Use the code above to connect.</Trans>
          </p>
        </div>
      </div>

      <div
        className={"prose w-full max-w-full bg-white p-8 shadow-lg lg:max-w-md"}
      >
        <img src={LogoDiglog} className="h-8 w-auto" alt="dizlog logo" />

        <h2>
          <Trans>Connecting Customer Display System (CDS) to POS:</Trans>
        </h2>
        <ol>
          <li>
            <Trans>
              Sign in to the Dizlog Web App on the secondary device intended for
              your POS system.
            </Trans>
          </li>

          <li>
            <Trans>
              While on the POS &gt; Catalog screen, tap the monitor icon located
              in the upper right corner of the page.
            </Trans>
          </li>

          <li>
            <Trans>
              Copy the <b>CDS ID</b> displayed on this page.
            </Trans>
          </li>

          <li>
            <p>
              <Trans>
                Paste the copied <b>CDS ID</b> into the designated field within
                the pop-up window on the POS &gt; Catalog screen.
              </Trans>
            </p>

            <p>
              <Trans>
                This will establish the connection, enabling seamless
                transaction viewing and purchase confirmation for your customers
                through the Customer Display view.
              </Trans>
            </p>
          </li>
        </ol>
      </div>
    </div>
  )
}

export default CustomerDisplay
