import { useSWRConfig } from "swr"
import useCashRegisterStore from "./use-cash-register-store"

const useClearAllCache = () => {
  const cashRegisterStore = useCashRegisterStore()
  const { mutate } = useSWRConfig()
  return async () => {
    await mutate(() => true, undefined, false)
    cashRegisterStore.setCashRegisterId("")
  }
}

export default useClearAllCache
