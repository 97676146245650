import React, { FC } from "react"
import IpayIDSubscribeButton from "./IpayIDSubscribeButton"

// iPay88 Subscription Button
const IpaySubscribeButton: FC<{
  planId: string
  subscriptionPlanId?: number
  className?: string
}> = ({ planId, subscriptionPlanId, className }) => {
  // if (!IS_PROD) {
  //   return (
  //     <IpayMYSubscribeButton
  //       planId={planId}
  //       subscriptionPlanId={subscriptionPlanId}
  //       className={className}
  //     />
  //   )
  // }

  return (
    <IpayIDSubscribeButton
      planId={planId}
      subscriptionPlanId={subscriptionPlanId}
      className={className}
    />
  )

  return null
}

export default IpaySubscribeButton
