import React, { FC, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material"
import "../../Auth/Login/index.css"
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined"
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined"
import Square_Blue_WhiteLogo from "../../../assets/loginPage/eniaga-logo.png"
import { login } from "../../../utils/authProvider"
import SimpleLoading from "../../../components/SimpleLoading"
import { t } from "i18next"
import { Trans } from "react-i18next"
import { registerPushTokenWithServer } from "../../../lib/firebase"
import useClearAllCache from "../../../hooks/use-clear-all-cache"
import LanguageSelect from "../../../components/LanguageSelect"

/** Checks if Eniaga Rahmah demo is enabled. */
export const isEniagaRahmah = () => {
  return localStorage.getItem("eniaga-rahmah") === "true"
}

export const enableEniagaRahmah = () => {
  localStorage.setItem("eniaga-rahmah", "true")
}

/** Eniaha Rahmah is a demo of monitoring features for one of DizLog's Partner.
 *  When a user login using this page, all features will be disabled, except for the
 *  product monitoring features.*/
const ENiaga: FC = () => {
  const history = useHistory()
  const clearAllCache = useClearAllCache()

  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const [error, setError] = useState({
    isError: false,
    message: "",
  })
  const [isLoading, setIsLoading] = useState(false)

  const handleClick = async () => {
    setError({ ...error, isError: false })
    setIsLoading(true)

    try {
      await login(username.trim(), password)
      await clearAllCache()
      await registerPushTokenWithServer(username, password)
      enableEniagaRahmah()
      history.push("/dashboard/monitor/add-products")
      setIsLoading(false)
    } catch (e: any) {
      setIsLoading(false)
      setError({ ...error, isError: true, message: e.message })
      window.console.log(error)
    }
  }

  return (
    <div className="login-box">
      <div className="login-logo !bg-[#009540]">
        <div className="flex h-40 w-40 items-center justify-center rounded-xl bg-white">
          <img
            src={Square_Blue_WhiteLogo}
            className="w-auto"
            alt="dizlog logo"
          />
        </div>

        <h1 className={"mt-6 !font-body text-3xl text-white"}>
          <span className={"font-black"}>eNiaga</span> Rahmah
        </h1>
      </div>

      <div className={`"w-[55%] login-form !py-16`}>
        <h1 className={"text-2xl font-bold"}>
          <Trans>Sign in</Trans>
        </h1>
        <Box
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            padding: "2rem",
            paddingBottom: 0,
          }}
        >
          <TextField
            id="standard-number"
            label={t("Email")}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            sx={{ width: "100%", marginBottom: 0 }}
            value={username}
            onChange={(event: any): void => {
              setUsername(event.target.value)
              setError({ ...error, isError: false })
            }}
            className="!mb-6  w-full"
          />

          <TextField
            id="standard-text-password"
            label={t("Password")}
            type={showPassword ? "text" : "password"}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            sx={{ width: "100%" }}
            value={password}
            onChange={(event: any): void => {
              setPassword(event.target.value)
              setError({ ...error, isError: false })
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <VisibilityOffOutlinedIcon />
                    ) : (
                      <VisibilityOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            className="!mb-6  w-full"
          />
        </Box>

        {error.isError && (
          <p className="mb-4 text-sm italic text-red-600">{error.message}</p>
        )}

        <Link
          to="/forgot-password"
          className="ml-auto mr-8 w-full pl-[2rem] font-bold text-primary-900 no-underline"
        >
          <Trans>Forgot password?</Trans>
        </Link>

        <Button
          variant="contained"
          disableElevation
          sx={{
            textTransform: "none",
            borderRadius: 200,
            paddingY: 1,
            width: "80%",
            backgroundColor: "#F2994A",
            color: "white",
            "&:hover": {
              backgroundColor: "#F9CA48",
            },
            boxShadow: "1px 2px 7px 0 rgba(0,0,0,0.15)",
          }}
          disabled={isLoading}
          onClick={handleClick}
          className="!mt-2"
        >
          {isLoading ? (
            <SimpleLoading color="#000000" withoutText />
          ) : (
            <Trans>Sign in</Trans>
          )}
        </Button>
      </div>
    </div>
  )
}

export const ENiagaLayout: FC = ({ children }) => (
  <div className="login-layout !bg-[#05642E]">
    {children}
    {window.location.pathname != "/sign-up" ? (
      <div className={"flex items-center"}>
        <p className={"mr-4 font-bold text-gray-300"}>Select Language</p>
        <LanguageSelect
          selectClassName={"font-bold"}
          containerClassName={"my-4"}
          hideLabel
          optionClassName={"text-gray-900"}
        />
      </div>
    ) : null}
  </div>
)

export default ENiaga
