import DigLogLogo from "../../assets/Logo_DigLog.png"
import { Link, useHistory, useParams } from "react-router-dom"
import { useState } from "react"
import apiProvider from "../../utils/apiProvider"
import { formatNumberToCurrency } from "../../lib/currency"
import { Alert, AlertColor, Snackbar } from "@mui/material"
import { BACKEND_API } from "../../constants/Config"
import useGetEstimateShareableDetails from "../../hooks/api/use-estimate-shareable-details"
import { Trans } from "react-i18next"
import EstimatePreview from "../../components/EstimatePreview"

const CustomerQuotationView = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const { data: estimate, mutate: mutateEstimate } =
    useGetEstimateShareableDetails(id)

  const [snackbar, setSnackBar] = useState(false)
  const [snackbarMessage, setSnackBarMessage] = useState({
    severity: "success",
    message: "",
  })

  const acceptEstimate = async () => {
    await apiProvider.EstimateAccept(id)
    setSnackBar(true)
    setSnackBarMessage({
      severity: "success",
      message: "Quotation accepted",
    })
    await mutateEstimate()
    setTimeout(() => {
      setSnackBar(false)
    }, 5000)
  }

  if (!estimate) return null

  return (
    <div className="bg-slate-100 min-h-screen overflow-auto">
      <Snackbar
        onClose={() => setSnackBar(false)}
        open={snackbar}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          severity={snackbarMessage.severity as AlertColor}
          sx={{ width: "100%" }}
        >
          {snackbarMessage.message}
        </Alert>
      </Snackbar>

      <div className="sticky top-0 z-20 border-x-0 border-b border-t-0 border-solid border-gray-200 bg-white py-4">
        <div className={"mx-auto flex max-w-[1400px] items-center px-6"}>
          <Link to="/" className={"flex"}>
            <img
              src={DigLogLogo}
              alt="DizLog"
              className={"h-8 w-auto object-cover"}
            />
          </Link>

          {/* <LanguageSelect
              hideLabel
              containerClassName={"ml-auto"}
              selectClassName={"!bg-gray-50 font-bold"}
            /> */}
        </div>
      </div>

      <div className="w-full mt-8">
        <div className="px-b mx-auto mb-20 box-border flex w-full max-w-[1200px] flex-col md:px-4">
          <div className="mb-4 flex flex-wrap items-center px-2 pt-4 lg:items-end">
            <p className={"m-0 p-0 text-2xl font-medium"}>
              Quotation from {estimate.business.name}
              {/* Quotation from {estimate.customer.fullName} */}
            </p>

            {/* <button
                className="ml-auto cursor-pointer rounded-md border-none bg-orange-400
          p-3 font-semibold text-white hover:bg-orange-500"
              >
                CREATE INVOICE
              </button> */}
            <a
              href={`${BACKEND_API}/estimate/${estimate.id}/share/pdf`}
              className="ml-auto font-bold text-primary-900 no-underline hover:underline"
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              Download PDF
            </a>
          </div>

          <div className="main_content flex flex-1">
            <EstimatePreview
              business={estimate.business}
              location={estimate.location}
              estimate={estimate}
            />

            <div className="left-content-main-div ml-7 h-56 w-3/12">
              <div className="flex h-full flex-1 flex-col rounded-lg bg-white p-4 shadow-sm border">
                <div
                  className="flex flex-1 items-center justify-center"
                  style={{ borderBottom: "1px solid #cbd2d6" }}
                >
                  <span className="text-md flex w-full flex-1 font-medium">
                    <Trans> Total Amount</Trans>:
                  </span>
                  <span className="flex w-full flex-1 text-2xl font-semibold">
                    {formatNumberToCurrency(
                      estimate.currency,
                      estimate.totalPrice
                    )}
                  </span>
                </div>
                <div className="mt-3 flex flex-1 items-center justify-center">
                  {estimate.status === "ACCEPTED" ||
                  estimate.status === "INVOICED" ? (
                    <button className=" text-md h-12 w-full rounded-full border-none bg-orange-400 font-bold tracking-wider text-white opacity-60 hover:bg-orange-500">
                      Quotation Accepted{" "}
                    </button>
                  ) : (
                    <button
                      onClick={() => acceptEstimate()}
                      className=" text-md h-12 w-full cursor-pointer rounded-full border-none bg-orange-400 font-bold tracking-wider text-white hover:bg-orange-500"
                    >
                      Accept Quotation
                    </button>
                  )}
                </div>
                {/* <div className="flex flex-1 items-center justify-center">
                    {estimate.status == "ACCEPTED" ||
                    estimate.status == "INVOICED" ? (
                      <span className="cursor-pointer font-semibold text-orange-400 hover:text-orange-500">
                        {" "}
                      </span>
                    ) : (
                      <span className="cursor-pointer font-semibold text-orange-400 hover:text-orange-500">
                        Request changes
                      </span>
                    )}
                  </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CustomerQuotationView
