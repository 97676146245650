import {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  forwardRef,
  ForwardRefRenderFunction,
} from "react"
import clsx from "clsx"

type Props = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>

const IconButton: ForwardRefRenderFunction<HTMLButtonElement, Props> = (
  { className, ...props },
  ref
) => (
  <button
    type={"button"}
    ref={ref}
    className={clsx(
      className,
      "m-0 flex w-auto cursor-pointer items-center justify-center rounded-full border-none bg-transparent p-2 transition-colors duration-150 ease-in-out hover:bg-gray-100"
    )}
    {...props}
  />
)

export default forwardRef(IconButton)
