import React, { FC, FormEventHandler, useState } from "react"
import { useShowSnackbar } from "./Snackbar"
import {
  postPayPalSubscriptionOTP,
  postPayPalSubscriptionRecurring,
  postSubscribePayPalCapture,
} from "../lib/subscriptions"
import { t } from "i18next"
import clsx from "clsx"
import Modal from "./Modal"
import { Trans } from "react-i18next"
import Select from "./Select"
import Button from "./Button"
import {
  PayPalButtons,
  PayPalScriptProvider,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js"
import LoadingSpinner from "./LoadingSpinner"
import { PAYPAL_CLIENT_ID } from "../constants/Config"
import { AxiosError } from "axios"
import { useHistory } from "react-router-dom"
import { HOMEPAGE_URL } from "../lib/models"

const PayPalSubscribeButton: FC<{
  planId: string
  className?: string
  disableRecurring?: boolean
  disableOTP?: boolean
}> = ({ planId, className, disableRecurring, disableOTP }) => {
  const showSnackBar = useShowSnackbar()

  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleRecurringCheckout = async () => {
    setLoading(true)
    try {
      const result = await postPayPalSubscriptionRecurring(planId)
      if (result.data.approvalUrl) {
        window.location.href = result.data.approvalUrl
      }
    } catch (e: any) {
      if (e.response?.data?.message) {
        showSnackBar(e.response.data.message, "error")
      } else {
        showSnackBar(
          t("Oops! Something went wrong. Please try again."),
          "error"
        )
      }
      setLoading(false)
    }
  }

  return (
    <>
      <button
        className={clsx(
          "mx-0 block w-full rounded-xl border border-solid border-primary-800 bg-primary-800 py-3 text-center text-sm font-semibold text-white no-underline hover:bg-primary-900 disabled:cursor-not-allowed disabled:opacity-50",
          className
        )}
        onClick={async () => {
          if (disableOTP) {
            await handleRecurringCheckout()
          } else {
            setShowModal(true)
          }
        }}
      >
        {loading ? <Trans>Loading...</Trans> : <Trans>Pay with PayPal</Trans>}
      </button>

      {showModal && (
        <PayPalSubscribeModal
          planId={planId}
          onClose={() => setShowModal(false)}
          disableRecurring={disableRecurring}
        />
      )}
    </>
  )
}

enum PaymentType {
  Recurring = "Recurring",
  OneTime = "OneTime",
}

const PayPalSubscribeModal: FC<{
  planId: string
  onClose?: () => void
  disableRecurring?: boolean
}> = ({ planId, onClose, disableRecurring }) => {
  const showSnackBar = useShowSnackbar()

  const [showPayPalButtons, setShowPayPalButtons] = useState(
    disableRecurring ?? false
  )
  const [loading, setLoading] = useState(false)
  const [paymentType, setPaymentType] = useState(PaymentType.Recurring)

  const handleCheckout: FormEventHandler = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      if (paymentType === PaymentType.Recurring) {
        const result = await postPayPalSubscriptionRecurring(planId)
        if (result.data.approvalUrl) {
          window.location.href = result.data.approvalUrl
        }
      } else {
        setShowPayPalButtons(true)
      }
    } catch (e: any) {
      if (e.response?.data?.message) {
        showSnackBar(e.response.data.message, "error")
      } else {
        showSnackBar(
          t("Oops! Something went wrong. Please try again."),
          "error"
        )
      }
      setLoading(false)
    }
  }

  return (
    <Modal
      open
      title={"PayPal Checkout"}
      className={"w-full max-w-sm !overflow-hidden !p-0"}
      titleClassName={"p-6"}
      onClose={() => {
        if (onClose) onClose()
      }}
    >
      <PayPalScriptProvider options={{ "client-id": PAYPAL_CLIENT_ID }}>
        {showPayPalButtons ? (
          <PayPalOTPCheckout planId={planId} />
        ) : (
          <form
            className="border-x-0 border-t border-b-0 border-solid border-gray-200 bg-gray-50 p-6"
            onSubmit={handleCheckout}
          >
            <Select
              label={"Type of Payment"}
              onChange={(e) => setPaymentType(e.target.value as PaymentType)}
              value={paymentType}
              containerClassName={"mb-4"}
            >
              <option value={PaymentType.Recurring}>{t("Recurring")}</option>
              <option value={PaymentType.OneTime}>
                {t("One time payment")}
              </option>
            </Select>

            <div className={"mt-6 flex"}>
              <Button variant={"outlined"} onClick={onClose}>
                <Trans>Cancel</Trans>
              </Button>
              <Button className={"ml-auto"} type={"submit"}>
                {loading ? <Trans>Loading...</Trans> : <Trans>Checkout</Trans>}
              </Button>
            </div>
          </form>
        )}
      </PayPalScriptProvider>
    </Modal>
  )
}

const PayPalOTPCheckout: FC<{
  planId: string
}> = ({ planId }) => {
  const history = useHistory()
  const [paypal] = usePayPalScriptReducer()
  const [error, setError] = useState("")

  return (
    <>
      {error && (
        <div className="box-border w-full border-y border-x-0 border-solid border-gray-200 p-4">
          <p className="m-0 rounded-lg border border-solid border-yellow-400 bg-yellow-50 px-4 py-2 text-yellow-900">
            {error}
          </p>
        </div>
      )}

      {paypal.isPending ? (
        <div className="flex justify-center p-4 font-medium">
          <LoadingSpinner />
        </div>
      ) : (
        <PayPalButtons
          className={"ml-auto mb-4 box-border w-full p-4"}
          onError={(e) => {
            setError("Something went wrong. Please try again.")
          }}
          createOrder={async () => {
            const res = await postPayPalSubscriptionOTP(planId)
            return res.data.orderId
          }}
          onApprove={async ({ orderID }, actions) => {
            try {
              await postSubscribePayPalCapture(orderID)
              history.replace(`${HOMEPAGE_URL}?subscribed=true`)
            } catch (e) {
              if (e instanceof AxiosError) {
                if (e.response?.data.key === "INSTRUMENT_DECLINED") {
                  actions.restart()
                } else {
                  setError(
                    t(
                      "Sorry, we failed to capture the payment. Please try again."
                    )
                  )
                }
              } else {
                setError(t("Oops! Something went wrong. Please try again."))
              }
            }
          }}
        />
      )}
    </>
  )
}

export default PayPalSubscribeButton
