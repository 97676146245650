export const generateVariantName = (
  variantValues: Array<{
    productOption: {
      id: string
    }
    productOptionValue: {
      id: string
    }
  }> = [],
  productOptions: Array<{
    id: string
    name: string
    values: Array<{
      id: string
      name: string
    }>
  }> = []
) => {
  return (
    variantValues
      // ?.sort((a, b) => a.productOption.id.localeCompare(b.productOption.id))
      .map((vv) => {
        const option = productOptions?.find(
          (op) => op.id === vv.productOption.id
        )
        return (
          option?.values.find((v) => v.id === vv.productOptionValue.id)?.name ||
          ""
        )
      })
      .join(" / ")
  )
}

export const generateVariantNameFromVariantValues = (
  variantValues: Array<{
    productOptionValue: {
      id: string
      name?: string
    }
  }> = []
) => {
  return variantValues
    .map((el) => {
      return el.productOptionValue.name
    })
    .join(" / ")
}
