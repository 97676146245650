import { create } from "zustand"
import { combine, persist } from "zustand/middleware"

const useCashRegisterStore = create(
  persist(
    combine({ cashRegisterId: "" }, (set) => ({
      setCashRegisterId: (id: string) => {
        set({ cashRegisterId: id })
      },
    })),
    { name: "cash-register-store" }
  )
)

export default useCashRegisterStore
