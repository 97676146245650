import Highcharts from "highcharts"

const metrics = {
  numberOfTransactions: "Transactions",
  cost: "Cost",
  grossSales: "Gross Sales",
  netSales: "Net Sales",
  grossProfit: "Gross Profit",
  netProfit: "Net Profit",
  averageSalePerTransactions: "Average Sale per Transaction",
  grossMargin: "Gross Margin",
  averageUnitsOrdered: "Average Units Ordered",
  totalSales: `Total Sales`,
  totalTransactions: "Total Transactions",
  totalDiscount: "Total Discount",
  totalTax: "Total Tax",
  totalServiceCharge: "Total Service Chagre",
  totalReturned: "Total Returned",
  grossProfitPercentage: "Gross Profit %",
}

const yAxisLabelByMetric = (currency: string | undefined) => {
  return {
    numberOfTransactions: "Transactions",
    cost: currency,
    grossSales: currency,
    netSales: currency,
    grossProfit: currency,
    netProfit: currency,
    averageSalePerTransactions: currency,
    grossMargin: "Gross Margin (%)",
    averageUnitsOrdered: "",
    totalSales: `Total Sales (${currency})`,
    totalTransactions: "Total Transactions",
    totalDiscount: `Total Discount (${currency})`,
    totalTax: `Total Tax (${currency})`,
    totalServiceCharge: `Total Service Charge (${currency})`,
    totalReturned: "Total Returned",
    grossProfitPercentage: "Gross Profit %",
  }
}

const POSdashboardOpt = (
  metric: string,
  currency: string | undefined,
  data: { x: any; y: any }
): Highcharts.Options => {
  return {
    chart: {
      type: "column",
    },
    title: {
      text: metrics[metric as keyof typeof metrics],
      align: "left",
    },
    xAxis: {
      categories: data.x,
      crosshair: true,
      accessibility: {
        description: "Date",
      },
    },
    yAxis: {
      min: 0,
      title: {
        text:
          yAxisLabelByMetric(currency)[
            metric as keyof typeof yAxisLabelByMetric
          ] || "",
      },
    },
    tooltip: {
      valueSuffix:
        ` ${
          yAxisLabelByMetric(currency)[
            metric as keyof typeof yAxisLabelByMetric
          ]
        }` || "",
    },
    plotOptions: {
      column: {
        pointPadding: 0,
        borderWidth: 0,
      },
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        name: metrics[metric as keyof typeof metrics],
        data: data.y,
        color: "#F09000",
        type: "column",
      },
    ],
  }
}

const loyaltyOpt = (data: { x: any; y: any }): Highcharts.Options => {
  return {
    chart: {
      type: "column",
    },
    title: {
      text: "Customer Retention",
      align: "left",
    },
    xAxis: {
      categories: data.x,
      crosshair: true,
      accessibility: {
        description: "Date",
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "Number of loyalty customer",
      },
    },
    tooltip: {
      valueSuffix: " transactions",
    },
    plotOptions: {
      column: {
        pointPadding: 0,
        borderWidth: 0,
      },
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        name: "Transactions",
        data: data.y,
        color: "#F09000",
        type: "column",
      },
    ],
  }
}

const salesOverViewOpt = (
  metric: string,
  currency: string | undefined,
  data: { x: any; y: any },
  series?: any[]
): Highcharts.Options => {
  return {
    chart: {
      type: "line",
    },
    title: {
      text: metrics[metric as keyof typeof metrics],
      align: "left",
    },
    xAxis: {
      categories: data.x,
      crosshair: true,
      accessibility: {
        description: "Date",
      },
    },
    yAxis: {
      min: 0,
      title: {
        text:
          yAxisLabelByMetric(currency)[
            metric as keyof typeof yAxisLabelByMetric
          ] || "",
      },
    },
    tooltip: {
      valueSuffix:
        ` ${
          yAxisLabelByMetric(currency)[
            metric as keyof typeof yAxisLabelByMetric
          ]
        }` || "",
    },
    plotOptions: {
      column: {
        pointPadding: 0,
        borderWidth: 0,
      },
    },
    legend: {
      enabled: true,
    },
    series: series || [
      {
        name: metrics[metric as keyof typeof metrics],
        data: data.y,
        color: "#F09000",
        type: "line",
      },
    ],
  }
}

const peakSalesTimeOpt = (
  metric: string,
  currency: string | undefined,
  data: { x: any; y: any },
  series?: any[]
): Highcharts.Options => {
  return {
    chart: {
      type: "line",
      backgroundColor: "transparent",
    },
    title: {
      text: "Peak Sales Time",
      align: "left",
      margin: 50,
    },
    xAxis: {
      categories: data.x,
      crosshair: true,
      accessibility: {
        description: "Date",
      },
    },
    yAxis: {
      min: 0,
      title: {
        text:
          yAxisLabelByMetric(currency)[
            metric as keyof typeof yAxisLabelByMetric
          ] || "",
      },
    },
    tooltip: {
      valueSuffix:
        ` ${
          yAxisLabelByMetric(currency)[
            metric as keyof typeof yAxisLabelByMetric
          ]
        }` || "",
    },
    plotOptions: {
      column: {
        pointPadding: 0,
        borderWidth: 0,
      },
    },
    legend: {
      enabled: true,
    },
    series: series || [
      {
        name: metrics[metric as keyof typeof metrics],
        data: data.y,
        color: "#F09000",
        type: "line",
      },
    ],
  }
}

const productsSoldTimeGraphOpt = (
  categories: any[],
  series?: any[]
): Highcharts.Options => {
  return {
    chart: {
      type: "spline",
      backgroundColor: "transparent",
      height: 400,
    },
    title: {
      text: "Products Sold Over Time",
      align: "left",
      margin: 50,
    },
    xAxis: {
      categories: categories,
    },
    yAxis: {
      title: {
        text: "Sold",
      },
    },
    series: series || [],
    tooltip: {
      valueSuffix: " units",
    },
  }
}

const chartHelper = {
  POSdashboardOpt,
  loyaltyOpt,
  salesOverViewOpt,
  peakSalesTimeOpt,
  productsSoldTimeGraphOpt,
}

export default chartHelper
