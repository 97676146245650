import { NumberFormat } from "xlsx"

const numberFormats: Record<string, Intl.NumberFormat> = {}

type CurrencyDisplayValues = "code" | "symbol" | "name"

const getNumberFormats = (
  currency: string,
  currencyDisplay: CurrencyDisplayValues = "symbol"
) => {
  const key = currency + currencyDisplay
  if (!numberFormats[key]) {
    numberFormats[key] = Intl.NumberFormat(undefined, {
      style: "currency",
      currency: currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      currencyDisplay: currencyDisplay,
    })
  }

  return numberFormats[key]
}

export const formatNumberToCurrency = (
  currency = "USD",
  value = 0,
  currencyDisplay?: CurrencyDisplayValues
) => {
  const result = getNumberFormats(currency, currencyDisplay).format(value || 0)

  return result.replace(/\u00A0/g, " ")
}
