import React, { FC, useState } from "react"
import { useHistory } from "react-router-dom"
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined"
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined"
import Square_Blue_WhiteLogo from "../../../assets/loginPage/Square_Blue&WhiteLoo.png"
import {
  confirmSignUp,
  login,
  resendConfirmationCode,
} from "../../../utils/authProvider"
import SimpleLoading from "../../../components/SimpleLoading"
import OtpInput from "react-otp-input"
import { getCurrentSubscription } from "../../../lib/subscriptions"
import moment from "moment"
import { t } from "i18next"
import { Trans, useTranslation } from "react-i18next"
import useQueryString from "../../../hooks/use-query-string"
import apiProvider from "../../../utils/apiProvider"
import parse from "html-react-parser"
import { registerPushTokenWithServer } from "../../../lib/firebase"
import ToolTipComponent from "../../../components/ToolTipComponent"
import useClearAllCache from "../../../hooks/use-clear-all-cache"
import GettingStarted from "../GettingStarted"
import Input from "../../../components/Input"
import IconButton from "../../../components/IconButton"
import clsx from "clsx"
import Button from "../../../components/Button"
import { FREE_PLAN_ID, HOMEPAGE_URL } from "../../../lib/models"
import { Error } from "@mui/icons-material"
import { getAPI } from "../../../lib/api"
import { MPOSSettingsResponse } from "../../../hooks/api/use-mpos-settings"

declare global {
  interface Window {
    gtag: any
  }
}

const validateEmail = (email: string) => {
  return email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}

const validatePhone = (phone: string) => {
  return phone.match(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g)
}

// function Login(isPOSscreen: boolean): React.ReactElement {
const Login: FC<{
  isPOSscreen?: boolean
  onClose?: () => void
}> = ({ isPOSscreen, onClose }) => {
  const { i18n } = useTranslation()

  // "mode=direct-checkout" will throw user to subscription checkout instead of to dashboard
  const loginMode = useQueryString("mode")

  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [OTP, setOTP] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const [error, setError] = useState({
    isError: false,
    message: "",
  })
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const [view, setView] = useState("login")
  const [user, setUser] = useState<any>({})
  const clearAllCache = useClearAllCache()

  const validateInput = (u: string, p: string): any => {
    //const regex = /\S*(^\+?\d{8,})/;
    const validationError = { isError: false, message: "" }
    if (u.trim() === "" || p.trim() === "") {
      validationError.isError = true
      validationError.message = t("Phone number and password must be filled")
    }

    const validPhone = validatePhone(username)
    if (validPhone !== null) setUsername(validPhone[0])

    const validEmail = validateEmail(username)
    if (validEmail !== null) setUsername(validEmail[0])

    if (
      validPhone === null &&
      validEmail === null &&
      !validationError.isError
    ) {
      validationError.isError = true
      if (validPhone === null)
        validationError.message = t("Phone number format is not valid")
      if (validEmail === null)
        validationError.message = t("Email format is not valid")
    }

    return validationError
  }

  const handleLogin = async () => {
    localStorage.clear()
    setError({ ...error, isError: false })
    setIsLoading(true)
    const validate = validateInput(username, password)
    if (validate?.isError) {
      setError({
        ...error,
        isError: validate.isError,
        message: validate.message,
      })
      setIsLoading(false)
    } else {
      try {
        const res: any = await login(username.trim(), password)
        // when employee first login, they'll need to set new password.
        if (res.challengeName === "NEW_PASSWORD_REQUIRED") {
          return history.push("/new-password", { username, password })
        }

        await clearAllCache()
        await registerPushTokenWithServer(username, password)

        if (res.attributes["custom:businessId"]) {
          const currentSub = await getCurrentSubscription()
          await clearAllCache()
          if (
            (moment(currentSub.expirationDate).isSame(
              moment("2023-04-16", "YYYY-MM-DD")
            ) &&
              moment(currentSub.createdAt).isBefore(
                moment("2023-01-01", "YYYY-MM-DD")
              )) ||
            loginMode === "direct-checkout"
          ) {
            getEmployeeRole(
              "/subscriptions",
              currentSub?.planId === FREE_PLAN_ID
            )
          } else {
            getEmployeeRole(HOMEPAGE_URL, currentSub?.planId === FREE_PLAN_ID)
          }

          // alert("success")
        } else {
          setUser(res)
          setView("business-profile-form")
        }
        setIsLoading(false)
      } catch (e: any) {
        setIsLoading(false)
        setError({ ...error, isError: true, message: e.message })
        window.console.log(error)
        if (e.message === "User is not confirmed.") {
          await handleResendOTP()
          setError({ ...error, isError: false, message: "" })
        }
      }
    }
  }

  const getEmployeeRole = async (nextPage: string, isFreePlan: boolean) => {
    try {
      const r = await apiProvider.getEmployeeMe()
      const mposSettings = await getAPI<MPOSSettingsResponse>(`/mpossetting`)

      //if isEmploye and isFreePlan
      if (r.data?.id && isFreePlan) {
        setError({
          ...error,
          isError: true,
          message: t(
            "User role does not have access to web app. Please contact your supervisor or management."
          ),
        })
        return
      }

      if (
        r.data?.permission &&
        r.data?.permission?.find((p: any) => p.permission.startsWith("pos"))
      ) {
        history.push(
          `/dashboard/mpos/business-catalog${mposSettings?.[0]?.requiredPin ? "?view=enterPin" : ""}`
        )
        localStorage.setItem("isBusinessOwner", "false")
      } else if (
        r.data?.role?.length === 1 &&
        r.data?.role?.[0]?.roleName === "Employee"
      ) {
        if (!isPOSscreen) {
          history.push(
            `/dashboard/employees/manage-employees/employee/${r?.data?.id}/details`
          )
        }
        localStorage.setItem("isBusinessOwner", "false")
      } else {
        if (!isPOSscreen) {
          history.push(nextPage)
        }
        localStorage.setItem("isBusinessOwner", "false")
      }
      if (onClose && isPOSscreen) {
        onClose()
      }
    } catch (e) {
      if (!isPOSscreen) {
        history.push(nextPage)
      }
      if (onClose && isPOSscreen) {
        onClose()
      }
      localStorage.setItem("isBusinessOwner", "true")
    }
  }

  const handleResendOTP = async () => {
    await resendConfirmationCode(username.trim())
      .then((res: any) => {
        setIsLoading(false)
        if (view !== "OTP") {
          setView("OTP")
        } else {
          alert(t("OTP sent"))
        }
      })
      .catch((e) => {
        setIsLoading(false)
        setError({ ...error, isError: true, message: e.message })
        window.console.log(error)
      })
  }

  const handleOTPchange = (otp: any) => {
    setOTP(otp)
  }

  const handleVerify = async () => {
    await confirmSignUp(username, OTP)
      .then((res) => {
        if (res === "SUCCESS") {
          alert(t("Account Confirmation Success!"))
          handleReLogin()
          setError({ ...error, isError: false, message: "" })
        }
      })
      .catch((e) => {
        setError({ ...error, isError: true, message: e.message })
        setOTP("")
        console.log(e)
      })
  }

  const handleReLogin = async () => {
    setError({ ...error, isError: false })
    try {
      const res: any = await login(username.trim(), password)
      setView("business-profile-form")
      // if (res.attributes["custom:businessId"]) {
      //   await clearAllCache()
      //   history.push("/dashboard/inventory/dashboard?onboarding=true")
      // } else {
      //   setUser(res)
      //   setView("business-profile-form")
      // }
    } catch (e: any) {
      setError({ ...error, isError: true, message: e.message })
      window.console.log(e)
    }
  }

  if (view === "business-profile-form") {
    return (
      <GettingStarted
        username={username.trim()}
        body={{ password: password }}
      />
    )
  }

  return (
    <div className="flex w-[80%] flex-col items-center justify-center md:w-[45rem] md:flex-row">
      {!isPOSscreen ? (
        <div className="flex w-full flex-col items-center justify-center rounded-t-3xl bg-primary-900 p-6 md:h-full md:w-1/2 md:rounded-l-3xl md:rounded-tr-none">
          <img
            src={Square_Blue_WhiteLogo}
            className="h-56 w-auto"
            alt="dizlog logo"
          />
        </div>
      ) : null}
      {view === "login" && (
        <form
          className={clsx(
            "flex flex-col items-center justify-center gap-4 rounded-b-3xl bg-white p-6 md:rounded-r-3xl md:rounded-bl-none",
            isPOSscreen ? "w-full" : "w-full md:w-1/2"
          )}
          onSubmit={(e) => {
            e.preventDefault()
            handleLogin()
          }}
        >
          <h1 className={"text-2xl font-bold"}>
            <Trans>Sign in</Trans>
          </h1>
          <div className="flex w-full flex-col gap-4">
            <Input
              label={t("Email")}
              value={username}
              onChange={(event: any): void => {
                setUsername(event.target.value)
                setError({ ...error, isError: false })
              }}
              toolTipContent={t("Enter registered email address")}
              toolTipPosition={"right"}
            />
            <Input
              label={t("Password")}
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(event: any): void => {
                setPassword(event.target.value)
                setError({ ...error, isError: false })
              }}
              trailingElement={
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <VisibilityOffOutlinedIcon />
                  ) : (
                    <VisibilityOutlinedIcon />
                  )}
                </IconButton>
              }
            />
          </div>
          {error.isError && (
            <div className="text-center text-sm italic text-red-600">
              {error.message}
            </div>
          )}
          {!isPOSscreen ? (
            <div className="flex w-full flex-row justify-start py-2">
              <Button
                variant="text"
                onClick={() => history.push("/forgot-password")}
              >
                <Trans>Forgot password?</Trans>
              </Button>
            </div>
          ) : null}
          <Button disabled={isLoading} className="w-full" type={"submit"}>
            {isLoading ? (
              <SimpleLoading color="#000000" withoutText />
            ) : (
              <Trans>Sign in</Trans>
            )}
          </Button>
          {!isPOSscreen ? (
            <div>
              <div className="flex flex-row items-center justify-center gap-2 pt-6">
                <p className="text-sm">
                  <Trans>Don’t have an account yet?</Trans>
                </p>
                <Button variant="text" onClick={() => history.push("/sign-up")}>
                  <Trans>Sign Up</Trans>
                </Button>
              </div>
              <div
                className="flex cursor-pointer items-center text-sm font-bold text-[#F09000] underline"
                onClick={() =>
                  window.open(
                    "https://helpcenter.dizlog.com/en/collections/3192279-getting-started-with-diglog",
                    "_blank"
                  )
                }
              >
                {t("Get Help")}
                <ToolTipComponent
                  toolTipContent={parse(
                    t("Find all the items that will get you started!")
                  )}
                  toolTipPosition="right"
                />
              </div>
            </div>
          ) : null}
        </form>
      )}
      {view === "OTP" && (
        <div className="flex w-full flex-col items-center justify-center gap-6 rounded-b-3xl bg-white p-6 md:h-[400px] md:w-1/2 md:rounded-r-3xl md:rounded-bl-none">
          <h1 className={"text-center text-2xl font-bold"}>
            <Trans>Enter OTP</Trans>
          </h1>
          <OtpInput
            value={OTP}
            onChange={handleOTPchange}
            numInputs={6}
            separator={<span>-</span>}
            inputStyle={"!w-10 !h-12 border border-gray-600 rounded"}
          />
          {error.isError && (
            <div className="italic text-red-300">{error.message}</div>
          )}
          <Button variant="text" onClick={handleResendOTP}>
            <Trans>Resend OTP</Trans>
          </Button>
          <Button
            disabled={OTP.length === 6 ? false : true}
            onClick={handleVerify}
          >
            <Trans>Confirm</Trans>
          </Button>
        </div>
      )}
    </div>
  )
}

export default Login
