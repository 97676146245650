import { ChangeEventHandler, FC, MouseEventHandler } from "react"
import clsx from "clsx"
import { ReactComponent as ChevronDownIcon } from "../assets/icons/chevron-down.svg"
import Tooltip from "@mui/material/Tooltip"
import * as React from "react"
import parse from "html-react-parser"
import InfoOutlined from "@mui/icons-material/InfoOutlined"
import { t } from "i18next"
import ToolTipComponent from "./ToolTipComponent"
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded"

export interface SelectProps {
  value?: string
  onChange?: ChangeEventHandler<HTMLSelectElement>
  label: string
  id?: string
  name?: string
  containerClassName?: string
  disabled?: boolean
  selectClassName?: string
  hideLabel?: boolean
  toolTipContent?: any
  toolTipPosition?: any
  required?: boolean
  className?: string
  onClick?: MouseEventHandler<HTMLSelectElement>
  iconClassName?: string
}

const Select: FC<SelectProps> = ({
  id,
  label,
  disabled,
  name,
  value,
  containerClassName,
  children,
  onChange,
  selectClassName,
  hideLabel,
  toolTipContent,
  toolTipPosition,
  required,
  className,
  onClick,
  iconClassName,
}) => (
  <label
    htmlFor={id}
    className={clsx("relative flex flex-col-reverse", containerClassName)}
  >
    <select
      name={name}
      id={id}
      value={value}
      onChange={onChange}
      required={required}
      className={`${clsx(
        "peer h-[43px] appearance-none rounded-lg border border-gray-600 border-opacity-20 bg-white px-3 pr-12 shadow-sm focus:outline-primary-900 active:outline-primary-900 disabled:bg-gray-300 focus:ring-primary-900",
        selectClassName
      )} ${className} bg-slate-600`}
      disabled={disabled}
      onClick={onClick}
      data-intercom-target={label + " select"}
    >
      {children}
    </select>

    {!hideLabel && (
      <span
        className={clsx(
          "mb-1 text-sm font-medium text-primary-black opacity-80 peer-disabled:opacity-50",
          hideLabel && "!opacity-0"
        )}
      >
        {label}{" "}
        {toolTipContent && (
          <ToolTipComponent
            toolTipContent={toolTipContent}
            toolTipPosition={toolTipPosition}
          />
        )}
      </span>
    )}
    {/* <ChevronDownIcon
      className={clsx(
        "pointer-events-none absolute bottom-[16px] right-[16px] ",
        iconClassName
      )}
      fill={getComputedStyle(document.documentElement)
        .getPropertyValue("--primary-900")
        .trim()}
    /> */}
    {/*<KeyboardArrowDownRoundedIcon*/}
    {/*  className={`pointer-events-none absolute ${*/}
    {/*    label ? "bottom-[11px]" : "bottom-[16px]"*/}
    {/*  }  right-[16px] text-primary-900 ${iconClassName}`}*/}
    {/*/>*/}
  </label>
)

export default Select
