import { useState } from "react"

const useToggle = (defaultValue = false) => {
  const [state, setState] = useState(defaultValue)

  const toggle = () => setState(!state)

  return [state, toggle] as const
}

export default useToggle
