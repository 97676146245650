import { useTranslation } from "react-i18next"
import Select from "./Select"
import React, { FC } from "react"

const languages = [
  { code: "ar", name: "العربية" },
  { code: "bn", name: "বাংলা" },
  { code: "cs", name: "Čeština" },
  { code: "da", name: "Dansk" },
  { code: "de", name: "Deutsch" },
  { code: "el", name: "Ελληνικά" },
  { code: "en", name: "English" },
  { code: "es", name: "Español" },
  { code: "fi", name: "Suomi" },
  { code: "fr", name: "Français" },
  { code: "he", name: "עברית" },
  { code: "hi", name: "हिन्दी" },
  { code: "hr", name: "Hrvatski" },
  { code: "hu", name: "Magyar" },
  { code: "id", name: "Bahasa Indonesia" },
  { code: "it", name: "Italiano" },
  { code: "ja", name: "日本語" },
  { code: "ka", name: "ქართული" },
  { code: "km", name: "ភាសាខ្មែរ" },
  { code: "ko", name: "한국어" },
  { code: "lo", name: "ລາວ" },
  { code: "lt", name: "Lietuvių" },
  { code: "lv", name: "Latviešu" },
  { code: "mk", name: "Македонски" },
  { code: "mn", name: "Монгол" },
  { code: "ms", name: "Bahasa Melayu" },
  { code: "nl", name: "Nederlands" },
  { code: "no", name: "Norsk" },
  { code: "pl", name: "Polski" },
  { code: "pt", name: "Português" },
  { code: "ro", name: "Română" },
  { code: "sq", name: "Shqip" },
  { code: "sr", name: "Српски" },
  { code: "sv", name: "Svenska" },
  { code: "te", name: "తెలుగు" },
  { code: "th", name: "ไทย" },
  { code: "tl", name: "Tagalog" },
  { code: "tr", name: "Türkçe" },
  { code: "ur", name: "اردو" },
  { code: "uz", name: "O'zbek" },
  { code: "vi", name: "Tiếng Việt" },
  { code: "zh", name: "中文" },
]

const LanguageSelect: FC<{
  optionClassName?: string
  selectClassName?: string
  containerClassName?: string
  hideLabel?: boolean
  iconClassName?: string
}> = ({
  hideLabel,
  optionClassName,
  containerClassName,
  selectClassName,
  iconClassName,
}) => {
  const { i18n } = useTranslation()

  return (
    <Select
      label={"Language"}
      selectClassName={selectClassName}
      containerClassName={containerClassName}
      hideLabel={hideLabel}
      value={i18n.language}
      onChange={async (e) => {
        await i18n.changeLanguage(e.target.value)
        window.location.reload()
      }}
      iconClassName={iconClassName}
    >
      {languages.map((language) => (
        <option
          key={language.code}
          value={language.code}
          className={optionClassName}
        >
          {language.name}
        </option>
      ))}
    </Select>
  )
}

export default LanguageSelect
