import { CartTransaction } from "../../lib/models"
import useAPI from "./use-api"

export type CustomersResponse = Array<{
  id: string
  phone: string
  email: string
  fullName: string
  dob: string
  created_at: string
  updated_at: string
  firebaseId: string
  stripeCustomerId: string
  lang: string
  referralCode: any
  customerLocations: CustomerLocation[]
  customerType: string
  termType: string
  taxId: string
  points: number
  enableCashback: boolean
  cashbackPercentage: number
  cashback: number
  paymentTerm?: {
    id: string
    name: string
    days: number
    isDefault: boolean
    isEnabled: boolean
    createdAt: string
    updatedAt: string
  }
  approvalStatus: "APPROVED" | "PENDING" | "REJECTED"
  openingBalance: number
  businessLocations: { id: string; name: string }[]
  membershipCode: string
  membershipExpiryDate: string
  seniorCitizenId: string
  projects: Array<{
    id: string
    name: string
  }>
  notes: string
  contactName: string | null
  totalSpend?: number
  cartTransactions?: CartTransaction[]
}>

type CustomerLocation = {
  addressLine1: string
  addressLine2: string
  city: string
  id: string
  isPrimaryLocation: boolean
  latitude: number
  longitude: number
  name: string
  phone: string | null
  province: string
  zip: string
}

type Query = Partial<{
  businessId: string
  join?: string[]
  mode?: "lite" | "full"
}>

const useCustomers = (query?: Query) => {
  return useAPI<CustomersResponse>("/customer", query)
}

export default useCustomers
