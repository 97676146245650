import useAPI, { UseAPIQueryParams } from "./use-api"
import useEmployeeMe from "./use-employee-me"

export type GetCashRegisterResponse = Array<{
  id: string
  name: string
  licenseKey: string
  controlToken: string
  createdAt: string
  updatedAt: string
  business: {
    id: string
    name: string
  }
  location?: {
    id: string
    name: string
  }
  isOpened?: boolean
  clockedInEmployees: Array<{
    id: string
    avatar: string
    pin: string
    phone: string
    fullName: string
    dob: string
    addressLine1: string
    nationalId: string
  }>
  min: string
  ptu: string
  serialNo: string
}>

interface Query extends UseAPIQueryParams<string> {
  filter?: string
}

const useCashRegister = (query: Query = {}) => {
  const { data: me } = useEmployeeMe()

  const swr = useAPI<GetCashRegisterResponse>("/cashRegister", {
    join: ["cashRegisterLogs", "clockedInEmployees"],
    ...query,
  })

  let data = swr.data

  if (me?.locations?.length) {
    data = data?.filter((cashRegister) => {
      return me.locations.some((location) => {
        return location.id === cashRegister.location?.id
      })
    })
  }

  return { ...swr, data }
}

export default useCashRegister
