import { postAPI, putAPI, uploadFile } from "./api"

export const uploadBusinessDocument = async (file: File) => {
  return uploadFile<{ url: string }>("/businesses/kyc-document", file)
}

export const postDiglogPaymentsMethod = (body: {
  registeredCompanyName?: string
  companyRegistrationNumber?: string
  monthlyRevenue?: string
  businessDocumentUrl?: string
  externalId?: string
  bankAccountNumber?: string
  bankName?: string
  routingNumber?: string
  swiftCode?: string
  branchCode?: string
  paymentAccountMethods?: Array<{
    paymentGatewayType: string
  }>
  paymentDetails: Array<{
    walletName: string
    email: string
    phone: string
    accountNumber: string
  }> | null
}) => {
  return postAPI("/businesses/payment-method", {
    method: "diglogpayment",
    ...body,
  })
}

export const putDiglogPaymentsMethod = (
  id: string,
  body: {
    registeredCompanyName?: string
    companyRegistrationNumber?: string
    monthlyRevenue?: string
    businessDocumentUrl?: string
    externalId?: string
    bankAccountNumber?: string
    bankName?: string
    routingNumber?: string
    swiftCode?: string
    branchCode?: string
    paymentAccountMethods?: Array<{
      paymentGatewayType: string
    }>
    paymentDetails: Array<{
      walletName: string
      email: string
      phone: string
      accountNumber: string
    }> | null
  }
) => {
  return putAPI<{
    errors?: {
      FLUTTERWAVE: { message: string }
      IPAY_AFRICA: { message: string }
    }
  }>("/businesses/payment-method", id, {
    method: "diglogpayment",
    diglogPayment: body,
  })
}
