import React, { FC, useState } from "react"
import { Link } from "react-router-dom"
import "./index.css"
import { login, logout } from "../../../utils/authProvider"
import { t } from "i18next"
import { registerPushTokenWithServer } from "../../../lib/firebase"
import { useShowSnackbar } from "../../../components/Snackbar"
import usePlacesAutocomplete, {
  getGeocode,
  getZipCode,
} from "use-places-autocomplete"
import { HOMEPAGE_URL, PlacesSuggestions } from "../../../lib/models"
import useCountries from "../../../hooks/api/use-countries"
import useBusinessTypes from "../../../hooks/api/use-business-types"
import { flatMap, sortBy } from "lodash"
import apiProvider from "../../../utils/apiProvider"
import useClearAllCache from "../../../hooks/use-clear-all-cache"
import useQueryString from "../../../hooks/use-query-string"
import PhoneInput2 from "react-phone-input-2"
import useCashRegister from "../../../hooks/api/use-cash-register"
import useProductMeasurements from "../../../hooks/api/use-product-measurements"
import { patchUnitOfMeasureToggle } from "../../../lib/measurement-units"
import Button from "../../../components/Button"
import Input from "../../../components/Input"
import AutoComplete from "../../../components/AutoComplete"
import LoadingSpinner from "../../../components/LoadingSpinner"
import { Trans } from "react-i18next"
import { IS_PROD } from "../../../constants/Config"
import GooglePlay from "../../../assets/gplay.png"
import AppStore from "../../../assets/appstore.png"

const PRODUCT_TOUR_ID = IS_PROD ? "457081" : "35518994"

type View = "ABOUT" | "CONGRATS"

const defaultLocation = {
  addressLine1: "",
  city: "",
  state: "",
  zip: "",
  longCoord: "",
  latCoord: "",
}

interface Country {
  id: number
  label: string
  phoneCode: string
  codeAlpha2: any
}

const OnboardingLinks: Record<string, string> = {
  "Food and Beverage":
    "https://helpcenter.dizlog.com/en/collections/4338320-how-to-setup-and-manage-your-f-b-business-w-dizlog-pos",
  Retail:
    "https://helpcenter.dizlog.com/en/collections/4338019-how-to-setup-and-manage-your-retail-business-w-dizlog-pos",
  Services:
    "https://helpcenter.dizlog.com/en/collections/4338336-how-to-setup-and-manage-your-service-business-w-dizlog-pos",
  Agriculture:
    "https://helpcenter.dizlog.com/en/collections/6170882-agricultural-management",
}

const GettingStarted: FC<{
  username: any
  body: any
}> = ({ username, body }) => {
  const clearAllCache = useClearAllCache()
  const showSnackBar = useShowSnackbar()
  const afmc = useQueryString("afmc")
  const placesAutocomplete = usePlacesAutocomplete({ debounce: 300 })

  const { data: measurements } = useProductMeasurements()
  const { mutate: mutateRegisters } = useCashRegister()
  const { data: businessTypes } = useBusinessTypes()
  const { data: countries } = useCountries({ suspense: false })

  const [isLoading, setIsLoading] = useState(false)
  const [businessName, setBusinessName] = useState("")
  const [locationName, setLocationName] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [location, setLocation] = useState(defaultLocation)
  const [country, setCountry] = useState<Country | null>(null)
  const [view, setView] = useState<View>("ABOUT")
  const [businessType, setBusinessType] = useState({
    group: "Food and Beverage",
    type: "Coffee Shop / Café",
  })

  const places =
    (placesAutocomplete.suggestions.data as PlacesSuggestions) ?? []

  const phoneCodeOptionList =
    countries?.map(({ name, phoneCode }) => {
      return {
        value: phoneCode,
        title: phoneCode + " - " + name,
      }
    }) || []

  const reLogin = async () => {
    try {
      await logout()
      await login(username, body.password)
      await registerPushTokenWithServer(username, body.password)
    } catch (e: any) {
      console.log(e)
    }
  }

  const validateBusinessInfo = () => {
    if (!businessName) {
      showSnackBar(t("Business name is required"), "error")
      return false
    }
    if (!phoneNumber) {
      showSnackBar(t("Phone number is required"), "error")
      return false
    }
    if (!locationName) {
      showSnackBar(t("Location name is required"), "error")
      return false
    }
    if (!location.city) {
      showSnackBar(t("Select Address"), "error")
      return false
    }
    if (phoneNumber.length <= 6) {
      showSnackBar(t("Mobile number minimum length of 6 digits"), "error")
      return false
    }
    return true
  }

  const handleClickNext = async (currentView: View) => {
    if (!validateBusinessInfo()) return

    try {
      setIsLoading(true)
      const createdBusiness = await apiProvider.createBusinessProfile({
        name: businessName,
        type: businessType.type,
        email: username,
        phone: phoneNumber,
        country: country,
        affiliateCode: afmc || "",
      })
      await reLogin()
      const [createdLocation, updatedRegisters] = await Promise.all([
        await apiProvider.createLocation({
          addressLine1: location.addressLine1 ?? "",
          addressLine2: "",
          city: location.city ?? "",
          isPrimaryLocation: true,
          name: locationName ?? "",
          state: location.state ?? "",
          zip: location.zip ?? "",
          longCoord: location.longCoord ?? "",
          latCoord: location.latCoord ?? "",
        }),
        mutateRegisters(),
        measurements ? await patchUnitOfMeasureToggle(measurements) : null,
      ])
      const firstRegister = updatedRegisters?.[0]
      if (firstRegister) {
        await apiProvider.updateCashRegister(firstRegister.id, {
          name: firstRegister.name,
          location: createdLocation?.data.id,
          business: createdBusiness?.data.id,
          isActive: true,
        })
      }
      await clearAllCache()
      setView("CONGRATS")
    } finally {
      setIsLoading(false)
    }
    return
  }
  const sendEvent = () => {
    window.gtag("event", "conversion", {
      send_to: "AW-10829576507/_9BzCNm0yIsDELvy-Kso",
    })
  }

  return (
    <div className="sign-up">
      {view === "ABOUT" ? (
        <div className="flex flex-col gap-4 rounded-2xl bg-white p-12">
          <div>
            <h2 className="text-3xl font-bold text-[#00215D]  text-[Raleway]">
              {t("Tell us about your Business")}
            </h2>

            <p className="mb-3 text-lg text-[#646566] text-[Poppins]">
              {t("For the purpose of transparency, your details are required.")}
            </p>
          </div>

          <Input
            label={t("Enter Business Name")}
            value={businessName}
            onChange={(event): void => setBusinessName(event.target.value)}
            required
          />

          <AutoComplete
            label={t("Business Type")}
            disablePortal
            options={flatMap(
              Object.keys(businessTypes?.businessTypesByGroup ?? {}),
              (key) => {
                const types = businessTypes?.businessTypesByGroup?.[key]
                return (
                  types?.map((type) => ({ group: key, type: type.name })) ?? []
                )
              }
            )}
            groupBy={(option) => option.group}
            getOptionLabel={(option) => option.type}
            value={businessType}
            onChange={(event: any, newValue: any) => {
              setBusinessType(newValue)
            }}
          />

          <Input
            value={locationName}
            onChange={(e) => setLocationName(e.target.value)}
            label={t("Enter Location Name")}
            required
          />

          <AutoComplete
            label={t("Enter Address")}
            value={location.addressLine1}
            options={places.map(({ description }) => description) ?? []}
            isOptionEqualToValue={(option, value) => option === value}
            onChange={async (event, value) => {
              const geocode = await getGeocode({ address: value })

              if (geocode.length > 0) {
                let countryCode:
                  | undefined
                  | {
                      long_name: string
                      short_name: string
                      types: string[]
                    } = geocode[0].address_components.find(
                  (address: { types: any[] }) => {
                    return address.types.includes("country")
                  }
                )
                const country = countries?.find(
                  (country) => country.codeAlpha2 === countryCode?.short_name
                )
                const state = geocode[0].address_components.find(
                  (address: { types: any[] }) => {
                    return address.types.includes("administrative_area_level_1")
                  }
                )
                const city = geocode[0].address_components.find(
                  (address: { types: any[] }) => {
                    return (
                      address.types.includes("locality") ||
                      address.types.includes("administrative_area_level_2")
                    )
                  }
                )
                const zip = await getZipCode(geocode[0], false)

                const phoneCode = phoneCodeOptionList.find(
                  (option) => option.value === country?.phoneCode
                )
                if (country && phoneCode) {
                  setCountry(country)
                  setPhoneNumber(phoneCode.value)
                  console.log(country)
                  console.log(phoneCode)
                }

                setLocation({
                  addressLine1: value || "",
                  zip: zip || "",
                  city: city?.long_name,
                  state: state?.long_name,
                  latCoord: geocode[0]?.geometry?.location?.lat
                    ? geocode[0].geometry.location.lat()
                    : "",
                  longCoord: geocode[0]?.geometry?.location?.lng
                    ? geocode[0].geometry.location.lng()
                    : "",
                })
              }
            }}
            renderInputOnChange={(event) =>
              placesAutocomplete.setValue(event.target.value)
            }
          />

          <div className="flex flex-row gap-4">
            <AutoComplete
              label={t("Enter Country")}
              value={country}
              options={sortBy(countries, (c) => c.label.toLowerCase()) ?? []}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) => option === value}
              onChange={(event, value) => {
                setCountry(value)
              }}
              disabled
              className="flex-1"
            />
            <Input
              value={location.city}
              label={t("Enter City Name")}
              containerClassName="flex-1"
            />
          </div>

          <div>
            <p className="w-full md:mb-0 md:w-48 mb-1 text-sm text-gray-400">
              {t("Mobile Phone Number")}*
            </p>
            <PhoneInput2
              // country={country?.codeAlpha2?.toLowerCase()}
              value={phoneNumber}
              inputProps={{ required: true }}
              onChange={setPhoneNumber}
              inputClass={"!w-full !h-[43px] !rounded-lg"}
              // countryCodeEditable={false}
              // disableDropdown
            />
          </div>

          <Button
            onClick={() => handleClickNext("ABOUT")}
            className="w-full md:w-2/3 mx-auto mt-6"
            disabled={isLoading}
          >
            {isLoading && <LoadingSpinner className={"mr-2"} />}
            {t("Next")}
          </Button>
        </div>
      ) : null}

      {view === "CONGRATS" && (
        <div className="w-[600px] rounded-2xl bg-white p-10 font-[Roboto]">
          <div className="text-center">
            <h2 style={{ fontSize: "36px", fontWeight: "bold" }}>
              <Trans>
                Welcome to Diz<span className={"text-primary-900"}>Log</span>!
              </Trans>
            </h2>
            <p>
              <Trans>
                🎉 Hello there! We're thrilled to have you join us on this
                journey towards enhanced efficiency and organization. Here are a
                couple of things to get you started:
              </Trans>
            </p>
          </div>
          <div className="rounded-lg p-4 shadow-xl">
            <p>
              <Trans>
                📚 Discover <b>step by step guide</b> with articles{" "}
                <b>tailored to your {businessType.group} business.</b>
              </Trans>
            </p>
            <div className="grid justify-end">
              <div className="flex col-2 gap-2 ">
                <a
                  href={OnboardingLinks[businessType.group]}
                  className={
                    "mx-auto !no-underline flex items-center justify-center"
                  }
                  target={"_blank"}
                >
                  <Button>
                    <Trans>Onboarding Guide</Trans>
                  </Button>
                </a>

                <a
                  href={
                    "https://helpcenter.dizlog.com/en/collections/6277756-accounting"
                  }
                  className={
                    "mx-auto !no-underline flex items-center justify-center"
                  }
                  target={"_blank"}
                >
                  <Button>
                    <Trans>Accounting Guide</Trans>
                  </Button>
                </a>
              </div>
            </div>
          </div>
          <div className="rounded-lg p-4 shadow-xl">
            <p>
              <Trans>
                📆 Take advantage of <b>free demo with our DizLog</b> team for
                expert onboarding and feature insights.
              </Trans>
            </p>
            <div className="grid justify-end">
              <a
                href={"https://calendly.com/dizlog-productdemo/1hr"}
                className={"!no-underline"}
                target={"_blank"}
              >
                <Button className={"mx-auto "}>
                  <Trans>Schedule a Free Demo</Trans>
                </Button>
              </a>
            </div>
          </div>
          <div className="rounded-lg p-4 shadow-xl">
            <p>
              <Trans>
                💼 Achieve{" "}
                <b>
                  hassle-free setup, seamless onboarding, and dedicated support
                </b>{" "}
                with our <b>small one-time payment.</b>
              </Trans>
            </p>
            <div className="grid justify-end">
              <Link
                to="/dashboard/business-settings/subscription-and-billings"
                className={"!no-underline"}
                target={"_blank"}
              >
                <Button className={"mx-auto"}>
                  <Trans>Account Set-Up</Trans>
                </Button>
              </Link>
            </div>
          </div>
          <p className={"text-left mt-8 flex items-center"}>
            <Trans>Ready to get started? </Trans>
            <Link
              to={`${HOMEPAGE_URL}?product_tour-id=${PRODUCT_TOUR_ID}`}
              className={"text-primary-900 font-bold ml-2 !no-underline"}
            >
              <Button variant="small" className={"!p-4 !bg-secondary-900"}>
                <Trans>Continue to Web App</Trans>
              </Button>
            </Link>
            <div className="px-6">OR</div>
            <div>
              <div className="text-center">
                <Trans>Download our Mobile App</Trans>
              </div>
              <div className="flex gap-2">
                <a
                  id="google-store-btn"
                  href="https://play.google.com/store/apps/details?id=com.epaytime.diglog"
                  target={"_blank"}
                  className="w-[100px] h-[30px]"
                  rel="noreferrer"
                  onClick={sendEvent}
                >
                  <img
                    src={GooglePlay}
                    className="rounded-md img-fluidw-[100px] h-[30px] "
                    alt="googleplay"
                  />
                </a>

                <a
                  id="apple-store-btn"
                  // className="no-underline"
                  href="https://apps.apple.com/sg/app/diglog-pos-inventory-tracker/id1577649880"
                  target={"_blank"}
                  className="w-[100px] h-[30px]"
                  rel="noreferrer"
                  onClick={sendEvent}
                >
                  <img
                    src={AppStore}
                    className="img-fluid w-[100px] h-[30px]"
                    alt="appstore"
                  />
                </a>
              </div>
            </div>
          </p>
        </div>
      )}
    </div>
  )
}

export default GettingStarted
