import { formatNumberToCurrency } from "../lib/currency"
import React, { FC } from "react"
import { Business, Location, PurchaseOrder } from "../lib/models"
import { MailOutlined, PhoneOutlined } from "@mui/icons-material"
import Placeholder from "../assets/images/placeholder.png"
import moment from "moment"
import { Trans } from "react-i18next"
import { generateTemplateCSSVar } from "../lib/templates"
import useTemplatePublic from "../hooks/api/use-template-public"

const PurchaseOrderPreview: FC<{
  business: Business
  location: Location
  po: PurchaseOrder
  currency?: string
}> = ({ location, business, po, currency = "USD" }) => {
  const { data: template } = useTemplatePublic(business.id)

  const logo = template?.logoUrl || business?.logoUrl

  let totalCostOfItems = po?.orderedProducts.reduce(
    (acc, { quantity, MRP }) => {
      return acc + quantity * MRP
    },
    0
  )

  return (
    <div
      className="w-full overflow-hidden rounded-xl border border-solid border-gray-200 bg-white shadow-sm lg:basis-3/4"
      style={generateTemplateCSSVar(template)}
    >
      <div className={"flex flex-col lg:flex-row"}>
        <div className={"m-8 mb-0"}>
          {logo && (
            <div className="-ml-2 -mt-2 mb-4 flex h-20 w-20 flex-shrink-0 items-center justify-center rounded-lg bg-gray-50 ring-primary-900">
              <img
                src={logo ?? Placeholder}
                className={"h-full w-full rounded-lg object-cover"}
                alt={""}
              />
            </div>
          )}
          <p className={"mx-0 mb-1 mt-0 p-0 text-sm"}>{business.name}</p>
          <p className={"mx-0 mb-1 mt-0  p-0 text-sm"}>
            {location.addressLine1}
          </p>
          {location.addressLine2 && (
            <p className={"mx-0 mb-1 mt-0  p-0 text-sm"}>
              {location.addressLine2}
            </p>
          )}
          <p className={"mx-0 mb-1 mt-0 p-0 text-sm"}>
            {location.city}, {location.state}, {location.zip}
          </p>
        </div>

        <div className={"m-8 mb-0 lg:ml-auto lg:text-right"}>
          <p className={"mx-0 mb-1 mt-0 p-0 text-sm"}>
            Purchase Order # {po.PONumber}
          </p>
          <p className={"mx-0 mb-1 mt-0 p-0 text-sm text-gray-500"}>
            Order Date:{" "}
            <span className={"font-medium"}>
              {moment(po.orderDate).format("dddd MMMM D YYYY")}
            </span>
          </p>
          <p className={"mx-0 mb-1 mt-0 p-0 text-sm text-gray-500"}>
            Expected Delivery:{" "}
            <span className={"font-medium"}>
              {moment(po.shipmentDate).format("dddd MMMM D YYYY")}
            </span>
          </p>
        </div>
      </div>

      <div className={"m-8 flex items-end mt-12"}>
        <div className={"col-span-3 pt-8"}>
          <p className={"font-bold"}>Purchase Order to: </p>
          {po.suppliers.map((s) => {
            return <p>{s.companyName}</p>
          })}
          {po.project && <p>Project {po.project.name}</p>}
        </div>

        {/*<div className={"col-span-3 px-6 pt-8"}>*/}
        {/*  <p className={"font-bold"}>Destination Store: </p>*/}
        {/*  <p className={"font-medium  text-gray-600"}>{po.location?.name}</p>*/}
        {/*  <p className={"font-medium  text-gray-600"}>*/}
        {/*    {po.location?.addressLine1}*/}
        {/*  </p>*/}
        {/*  {po.location?.addressLine2 && (*/}
        {/*    <p className={"font-medium  text-gray-600"}>*/}
        {/*      {po.location?.addressLine2}*/}
        {/*    </p>*/}
        {/*  )}*/}
        {/*  <p className={"font-medium  text-gray-600"}>*/}
        {/*    {po.location?.city}, {po.location?.state} {po.location?.zip}*/}
        {/*  </p>*/}
        {/*</div>*/}

        <p className={"text-2xl font-bold ml-auto"}>
          {formatNumberToCurrency(currency, totalCostOfItems)}
        </p>
      </div>

      <div className="flex items-center border-y border-template-primary/20 bg-template-primary/10 px-8 py-3">
        <div className={"flex items-center text-gray-700 no-underline"}>
          <p className={"m-0 p-0 text-sm"}>{business.name}</p>
        </div>
        <p className={"mx-4 p-0 text-gray-400"}>•</p>
        {business.phone && (
          <a
            className={"flex items-center text-gray-700 no-underline"}
            href={`tel:${business.phone}`}
          >
            <PhoneOutlined className={"mr-2 !h-4 !w-4 opacity-70"} />
            <p className={"m-0 p-0 text-sm"}>{business.phone}</p>
          </a>
        )}
        {business.email && business.phone && (
          <p className={"mx-4 p-0 text-gray-400"}>•</p>
        )}
        {business.email && (
          <a
            className={"flex items-center text-gray-700 no-underline"}
            href={`mailto:${business.email}`}
          >
            <MailOutlined className={"mr-2 !h-4 !w-4 opacity-70"} />
            <span className={"m-0 p-0 text-sm"}>{business.email}</span>
          </a>
        )}
      </div>

      <div>
        <h3 className={"mt-3 p-6 px-8 text-2xl"}>Items</h3>

        <table className={"w-full"}>
          <thead className={"border-y"}>
            <tr className={"whitespace-nowrap bg-gray-50 text-left"}>
              <th className={"px-8 py-3 font-normal"}>Item</th>
              <th className={"px-8  py-3 text-right font-normal"}>Quantity</th>
              <th className="whitespace-nowrap px-6 py-3 text-right font-normal">
                Purchase Cost
              </th>
            </tr>
          </thead>

          <tbody>
            {po?.orderedProducts?.map((item) => {
              return (
                <tr key={item.id} className={"whitespace-nowrap border-b"}>
                  <td className={"w-full px-8 py-3"}>
                    {item.product.name}
                    {item.productVariant && (
                      <span> Variant: {item.productVariant?.variantName}</span>
                    )}
                  </td>
                  <td className={"px-8 py-3 text-right"}>{item.quantity}</td>
                  <td className={"px-8 py-3 text-right"}>
                    {formatNumberToCurrency(currency, item.MRP)}
                  </td>
                </tr>
              )
            })}
            <tr>
              <td className={"w-full px-6 py-3"}></td>
              <td className={"px-8 py-3 text-right font-bold"}>Total</td>
              <td className={"px-8 py-3 text-right font-bold"}>
                {formatNumberToCurrency(currency, totalCostOfItems)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className={"box-border w-full px-8 pb-8"}>
        <div className={"h-[1px] w-full bg-gray-300"} />
      </div>

      {/*{purchaseOrder.deliveryNotes && (*/}
      {/*  <>*/}
      {/*    <div className={"box-border w-full px-8 pb-8"}>*/}
      {/*      <p className={"m-0 mb-2 p-0 font-bold"}>Note to Customer</p>*/}
      {/*      <p className={"m-0 p-0 text-gray-700"}>*/}
      {/*        {purchaseOrder.deliveryNotes}*/}
      {/*      </p>*/}
      {/*    </div>*/}

      {/*    <div className={"box-border w-full px-8 pb-8"}>*/}
      {/*      <div className={"h-[1px] w-full bg-gray-300"} />*/}
      {/*    </div>*/}
      {/*  </>*/}
      {/*)}*/}

      {/*{estimate.termsAndCondition && (*/}
      {/*  <>*/}
      {/*    <div className={"box-border w-full px-8 pb-8"}>*/}
      {/*      <p className={"m-0 mb-2 p-0 font-medium"}>Terms and Conditions</p>*/}
      {/*      <p className={"m-0 p-0 text-gray-700"}>*/}
      {/*        {estimate.termsAndCondition}*/}
      {/*      </p>*/}
      {/*    </div>*/}

      {/*    <div className={"box-border w-full px-8 pb-8"}>*/}
      {/*      <div className={"h-[1px] w-full bg-gray-300"} />*/}
      {/*    </div>*/}
      {/*  </>*/}
      {/*)}*/}

      {/*{estimate.referenceNumber && (*/}
      {/*  <>*/}
      {/*    <div className={"box-border w-full px-8 pb-8"}>*/}
      {/*      <p className={"m-0 mb-2 p-0 font-medium"}>Reference Number</p>*/}
      {/*      <p className={"m-0 p-0 text-gray-700"}>*/}
      {/*        {estimate.referenceNumber}*/}
      {/*      </p>*/}
      {/*    </div>*/}

      {/*    <div className={"box-border w-full px-8 pb-8"}>*/}
      {/*      <div className={"h-[1px] w-full bg-gray-300"} />*/}
      {/*    </div>*/}
      {/*  </>*/}
      {/*)}*/}

      {template?.footerText && (
        <>
          <div className={"mx-1 box-border w-full px-8 pb-8 text-center"}>
            <p className={"m-0 mb-2 p-0 font-medium"}>
              <Trans></Trans>
            </p>
            <p className={"m-0 p-0 text-gray-700"}>{template?.footerText}</p>
          </div>
        </>
      )}
    </div>
  )
}

export default PurchaseOrderPreview
