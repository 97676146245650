import useAvailablePaymentMethods from "../hooks/api/use-available-payment-methods"
import DOMPurify from "dompurify"

const DiglogPaymentGuide = () => {
  const { data: pos } = useAvailablePaymentMethods("pos")
  const { data: online } = useAvailablePaymentMethods("onlineCheckout")
  const payments = pos && online ? [...pos, ...online] : []
  const diglogPaymentDetails = payments.find(
    (i) => i.identifier === "diglogPayment"
  )

  // sanitize the terms and conditions to be injected using dangerouslySetInnerHTML
  // to prevent script being injected
  const guide = DOMPurify.sanitize(
    diglogPaymentDetails?.termsAndCondition
      // remove empty paragraphs and headers
      .replace(/<p><br><\/p>/g, "")
      .replace(/<h.><br><\/h.>/g, "") || ""
  )

  return (
    <div className={"h-full"}>
      <div className={"prose p-8 pt-2"}>
        <div dangerouslySetInnerHTML={{ __html: guide }} />

        {diglogPaymentDetails?.fees ? (
          <>
            <h3>Fees</h3>
            <ul>
              {diglogPaymentDetails.fees.bankTransfer && (
                <li>Bank Transfer: {diglogPaymentDetails.fees.bankTransfer}</li>
              )}
              {diglogPaymentDetails.fees.cards && (
                <li>Cards: {diglogPaymentDetails.fees.cards}</li>
              )}
              {diglogPaymentDetails.fees.eWallet && (
                <li>eWallet: {diglogPaymentDetails.fees.eWallet}</li>
              )}
            </ul>
          </>
        ) : null}
      </div>
    </div>
  )
}
export default DiglogPaymentGuide
