import { Trans } from "react-i18next"
import Button from "./Button"
import Select from "./Select"
import Modal from "./Modal"
import { FC, FormEventHandler, useState } from "react"
import clsx from "clsx"
import { useShowSnackbar } from "./Snackbar"
import {
  subscribeWithStripeOTP,
  subscribeWithStripeRecurring,
} from "../lib/subscriptions"
import { t } from "i18next"

const StripeSubscribeButton: FC<{
  stripePlanId?: string
  className?: string
  disableRecurring?: boolean
  disableOTP?: boolean
}> = ({ stripePlanId, className, disableRecurring, disableOTP }) => {
  const showSnackBar = useShowSnackbar()
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const handleOTPCheckout = async () => {
    if (!stripePlanId) {
      showSnackBar(t("Plan has no 'Stripe Plan ID' set"), "error")
      return
    }

    setLoading(true)
    try {
      const result = await subscribeWithStripeOTP(stripePlanId)
      if (result.data.url) {
        window.location.href = result.data.url
      }
    } catch (e: any) {
      if (e.response?.data?.message) {
        showSnackBar(e.response.data.message, "error")
      } else {
        showSnackBar(
          t("Oops! Something went wrong. Please try again."),
          "error"
        )
      }
      setLoading(false)
    }
  }

  const handleRecurringCheckout = async () => {
    if (!stripePlanId) {
      showSnackBar(t("Plan has no 'Stripe Plan ID' set"), "error")
      return
    }

    setLoading(true)
    try {
      const result = await subscribeWithStripeRecurring(stripePlanId)
      if (result.data.url) {
        window.location.href = result.data.url
      }
    } catch (e: any) {
      if (e.response?.data?.message) {
        showSnackBar(e.response.data.message, "error")
      } else {
        showSnackBar(
          t("Oops! Something went wrong. Please try again."),
          "error"
        )
      }
      setLoading(false)
    }
  }

  return (
    <>
      <button
        className={clsx(
          "mx-0 block w-full rounded-xl border border-solid border-primary-800 bg-primary-800 py-3 text-center text-sm font-semibold text-white no-underline hover:bg-primary-900 disabled:cursor-not-allowed disabled:opacity-50",
          className
        )}
        onClick={async () => {
          if (disableRecurring) {
            await handleOTPCheckout()
          } else if (disableOTP) {
            await handleRecurringCheckout()
          } else {
            setShowModal(true)
          }
        }}
        disabled={loading}
      >
        {loading ? <Trans>Loading...</Trans> : <Trans>Pay with Stripe</Trans>}
      </button>
      {showModal && stripePlanId && (
        <StripeSubscribeModal
          stripePlanId={stripePlanId}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  )
}

enum PaymentType {
  Recurring = "Recurring",
  OneTime = "OneTime",
}

const StripeSubscribeModal: FC<{
  stripePlanId: string
  onClose?: () => void
}> = ({ stripePlanId, onClose }) => {
  const showSnackBar = useShowSnackbar()

  const [loading, setLoading] = useState(false)
  const [paymentType, setPaymentType] = useState(PaymentType.OneTime)

  const handleCheckout: FormEventHandler = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      if (paymentType === PaymentType.OneTime) {
        const result = await subscribeWithStripeOTP(stripePlanId)
        if (result.data.url) {
          window.location.href = result.data.url
        }
      } else {
        const result = await subscribeWithStripeRecurring(stripePlanId)
        if (result.data.url) {
          window.location.href = result.data.url
        }
      }
    } catch (e: any) {
      if (e.response?.data?.message) {
        showSnackBar(e.response.data.message, "error")
      } else {
        showSnackBar(
          t("Oops! Something went wrong. Please try again."),
          "error"
        )
      }
      setLoading(false)
    }
  }

  return (
    <Modal
      open
      title={"Local Payment Checkout"}
      className={"max-w-lg !overflow-hidden !p-0"}
      titleClassName={"p-6"}
      onClose={() => {
        if (onClose) onClose()
      }}
    >
      <p className={"m-0 !max-w-sm p-0 px-6 pb-6 text-gray-700"}>
        <Trans>
          Pay through credit cards and other payment methods with Stripe.
        </Trans>
      </p>
      <form
        className="border-x-0 border-t border-b-0 border-solid border-gray-200 bg-gray-50 p-6"
        onSubmit={handleCheckout}
      >
        <Select
          label={"Type of Payment"}
          onChange={(e) => setPaymentType(e.target.value as PaymentType)}
          value={paymentType}
          containerClassName={"mb-4"}
        >
          <option value={PaymentType.Recurring}>
            Recurring with Credit Card
          </option>
          <option value={PaymentType.OneTime}>One time payment</option>
        </Select>

        <div className={"mt-6 flex"}>
          <Button variant={"outlined"} onClick={onClose}>
            <Trans>Cancel</Trans>
          </Button>
          <Button className={"ml-auto"} type={"submit"}>
            {loading ? <Trans>Loading...</Trans> : <Trans>Checkout</Trans>}
          </Button>
        </div>
      </form>
    </Modal>
  )
}

export default StripeSubscribeButton
