import useAPI, { UseAPIQueryParams } from "./use-api"
import { DEFAULT_TEMPLATE } from "../../lib/models"

type GetTemplatesResponse = {
  id?: string
  headerText: string
  footerText: string
  primaryColor: string
  defaultNoteInvoice: string
  defaultNoteEstimates: string
  defaultNoteGRN: string
  defaultNotePurchaseOrder: string
  logoUrl: string
  fontType: string
  fontSize: string
  address: string
  email: string
  phone: string
  createdAt: string
  updatedAt: string
  paymentDetails: string
}

interface QueryParam extends UseAPIQueryParams<string> {
  id?: string
}

const useTemplate = (query?: QueryParam) => {
  const swr = useAPI<GetTemplatesResponse[]>("/templates", query)

  return {
    ...swr,
    data: !swr.isLoading ? swr.data?.[0] ?? DEFAULT_TEMPLATE : undefined,
  }
}

export default useTemplate
