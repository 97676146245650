import createPeer, { createPeerAsync } from "../lib/peer-js"
import Peer, { DataConnection } from "peerjs"
import { create } from "zustand"
import { combine, devtools } from "zustand/middleware"
import { useEffect } from "react"
import useCart from "./cart/use-cart"

let peer: Peer | null = null

const generateDefaultState = () => ({
  connection: undefined as DataConnection | undefined,
  getPeer: async () => {
    if (peer) {
      return peer
    }

    let newPeer = await createPeerAsync()
    peer = newPeer

    return newPeer
  },
})

// this is the peer js instance used on POS to send data to the CDS peer.
const useCDSConnection = create(
  devtools(
    combine(generateDefaultState(), (set, get) => ({
      connect: async (id: string) => {
        const peer = await get().getPeer()

        return new Promise<Boolean>((resolve, reject) => {
          const conn = peer?.connect(id)

          conn?.on("open", () => {
            resolve(true)
          })

          conn?.on("error", (e) => {
            reject(e)
          })

          peer?.on("error", (e) => {
            reject(e)
          })

          set({ connection: conn })
        })
      },
      send: (data: {
        type:
          | "CHECKOUT"
          | "CART"
          | "BUSINESS"
          | "CUSTOMER"
          | "CASHIER"
          | "RECEIPT"
          | "POS_TYPE"
        payload: any
      }) => {
        get().connection?.send(data)
      },
    })),
    { name: "cds-connection" }
  )
)

export const useSyncCDSCart = () => {
  const { send } = useCDSConnection()

  useEffect(() => {
    const unsub = useCart.subscribe(() => {
      const state = useCart.getState()
      send({
        type: "CART",
        payload: {
          items: state.items,
          promotion: state.promotion,
          manualDiscount: state.manualDiscount,
        },
      })
    })

    return () => unsub()
  })
}

export default useCDSConnection
