import { DBSchema, IDBPDatabase, openDB } from "idb"
import { Cart, Customer, PaymentTerm, PRODUCT_TYPE, Reward } from "../models"
import { POS_TYPE } from "../../views/Mpos/BusinessCatalog/NewDesign/POSTypeSelect"
import { PostCheckoutResponseBody } from "../../hooks/api/use-checkout"
import { undefined } from "zod"
import { GetCashRegisterResponse } from "../../hooks/api/use-cash-register"

let DB: IDBPDatabase<OfflineOrdersDB>

const getDB = async () => {
  if (!DB) {
    DB = await openDB<OfflineOrdersDB>("offline-pos", 1, {
      upgrade(db) {
        db.createObjectStore("offline-orders", { keyPath: "id" })
      },
    })
  }
  return DB
}

export const getOfflineOrders = async () => {
  const db = await getDB()
  return db.getAll("offline-orders")
}

export const getOfflineOrder = async (id: string) => {
  const db = await getDB()
  return db.get("offline-orders", id)
}

export const saveOfflineOrder = async (order: OfflineOrder) => {
  const db = await getDB()

  const existingOrder = await getOfflineOrder(order.id)
  if (existingOrder) {
    await db.delete("offline-orders", order.id)
  }
  const result = await db.add("offline-orders", order)

  const event = new Event("offline-order-change")
  window.dispatchEvent(event)

  return result
}

export const deleteOfflineOrder = async (id: string) => {
  const db = await getDB()
  const result = db.delete("offline-orders", id)

  const event = new Event("offline-order-change")
  window.dispatchEvent(event)

  return result
}

interface OfflineOrdersDB extends DBSchema {
  "offline-orders": {
    key: string
    value: OfflineOrder
  }
}

export interface OfflineOrder {
  id: string
  cartTransaction: CartTransaction
  originalData: OriginalData
}

interface CartTransaction {
  id: string
  isMpos: boolean
  isServiceOrder: boolean
  orderNumber: number
  orderStatus: string
  buxPaymentStatus: string
  buxRequestId: string
  createdAt: string
  createdBy: string
  tax: number
  discount: number
  totalCartAmount: number
  cashChange: number
  paymentMethodCode: string
  cashReceived: number
  serviceCharge: number
  grandTotal: number
  totalItemQuantity: number
  orderedProducts: OrderedProduct[]
  paymentMethodName: string
  pickupTime: string
  shipment: any
  shippingCost: number
  updatedAt: string
  updatedBy: string
  notes: string
  isWalkIn: boolean
  paymentId: string
  nextStatus: string
  location: Location
  promotions: any
  promotion: any
  isB2B: boolean
  source: string
  dueDate: string
  outstanding: number
  customerName: string
  taxes: PostCheckoutResponseBody["taxes"]
  paymentChannel: string
}

interface OrderedProduct {
  id: string
  quantity: number
  productId: string
  cartTransactionsId: string
  businessId: string
  created_at: string
  discount: number
  orderDate: string
  purchaseOrderId: string
  sellingPrice: number
  totalPrice: number
  unitOfMeasure: string
  name: string
  SKU: string
  updated_at: string
  productOptionValues: any[]
  isReturnable: boolean
  productType: PRODUCT_TYPE
  orderedProductAddOns: Array<{
    name: string
    id: string
    sellingPrice: number
    quantity: number
    groupName: string
  }>
  taxIncluded: boolean
  imageUrl: string
  images: string[]
}

interface Location {
  id: string
  name: string
  addressLine1: string
  addressLine2: string
  city: string
  state: string
  zip: string
  isPrimaryLocation: boolean
  createdAt: string
  updatedAt: string
  latCoord: any
  longCoord: any
}

interface OriginalData {
  cart: Cart
  businessId: string
  paymentMethodCode: string
  dueDate: any
  tax: number
  serviceCharge: number
  productPromotion: Record<string, { discountAmount: number }>
  discount: number
  totalOutstanding: number
  notes: string
  createdBy: string
  totalCartAmount: number
  isWalkIn?: boolean
  isOpenOrder?: boolean
  customerName?: string
  customer?: Customer
  cashRegister?: string
  cashReceived: number
  cashChange: number
  taxes?: PostCheckoutResponseBody["taxes"]
  paymentChannel?: string
  reward?: Reward | null
  cashback?: number
  isB2B?: boolean
  customerLocationId?: string
  paymentTerm?: PaymentTerm
  priceList?: string
  wholesale?: boolean
  taxExemptions?: Array<{
    id: string
    amount: number
    name: string
    type: string
  }>
  isPickup?: boolean
  pickupTime?: Date | null
  tables?: any[]
  autoPrint?: boolean
  posType?: (typeof POS_TYPE)[number]["key"]
}
