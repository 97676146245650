import Logo from "../../assets/Logo_DigLog.png"
import { Trans } from "react-i18next"
import { useEffect, useRef } from "react"
import useQueryString from "../../hooks/use-query-string"

// Useed by mobile app to forward data to ipay malaysia checkout page.
const IpayForwardingCheckout = () => {
  const form = useRef<HTMLFormElement>(null)

  const MerchantCode = useQueryString("MerchantCode")
  const PaymentID = useQueryString("PaymentID")
  const RefNo = useQueryString("RefNo")
  const Amount = useQueryString("Amount")
  const Currency = useQueryString("Currency")
  const ProdDesc = useQueryString("ProdDesc")
  const UserName = useQueryString("UserName")
  const UserEmail = useQueryString("UserEmail")
  const SignatureType = useQueryString("SignatureType")
  const Signature = useQueryString("Signature")
  const BackendURL = useQueryString("BackendURL")
  const ResponseURL = useQueryString("ResponseURL")

  useEffect(() => {
    if (form.current) form.current.submit()
  }, [])

  return (
    <div className="flex min-h-full flex-col bg-white pb-12">
      <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-0 sm:px-6 lg:px-8">
        <div className="flex flex-shrink-0 justify-center">
          <div className="inline-flex">
            <span className="sr-only">DizLog</span>
            <img className="h-12 w-auto" src={Logo} alt="" />
          </div>
        </div>
        <div className="py-16">
          <div className="flex flex-col text-center">
            <p className="p-0 font-body font-semibold uppercase tracking-wide text-primary-900">
              <Trans>Local Checkout</Trans>
            </p>
            <h1 className="mt-2 mb-4 font-body text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
              <Trans>
                Setting Up <br />
                Payment Page
              </Trans>
            </h1>
            <p className="mx-auto mt-2 max-w-xs p-0 font-body text-base text-gray-500">
              <Trans>
                Please wait a moment while we redirect you to the payment page.
              </Trans>
            </p>
            <p className="bg-p mx-auto mt-6 flex w-auto rounded-full bg-primary-900 py-3 px-4 text-sm font-bold text-white shadow-sm">
              <svg
                className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <Trans>Loading</Trans>
            </p>
          </div>
        </div>
      </main>

      <form
        method={"POST"}
        action="https://payment.ipay88.com.my/epayment/entry.asp"
        ref={form}
        className={"hidden"}
      >
        <input name={"MerchantCode"} value={MerchantCode} />
        <input name={"PaymentID"} value={PaymentID} />
        <input name={"RefNo"} value={RefNo} />
        <input name={"Amount"} value={Amount} />
        <input name={"Currency"} value={Currency} />
        <input name={"ProdDesc"} value={ProdDesc} />
        <input name={"UserName"} value={UserName} />
        <input name={"UserEmail"} value={UserEmail} />
        <input name={"SignatureType"} value={SignatureType} />
        <input name={"Signature"} value={Signature} />
        <input name={"BackendURL"} value={BackendURL} />
        <input name={"ResponseURL"} value={ResponseURL} />
      </form>
    </div>
  )
}

export default IpayForwardingCheckout
