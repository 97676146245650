import { PRODUCT_TYPE } from "./models"
import { patchAPI, postAPI } from "./api"

export const postNewInventory = async (data: {
  name: string
  productType: PRODUCT_TYPE
  isVisible: boolean
}) => {
  interface Response {
    id: string
    productType: PRODUCT_TYPE
  }

  return await postAPI<Response>(`/inventory`, {
    ...data,
    origin: "web",
  })
}

export const patchInventory = async (
  id: string,
  data: {
    name: string
    isVisible: boolean
  }
) => {
  interface Response {
    id: string
    productType: PRODUCT_TYPE
  }

  return await patchAPI<Response>(`/inventory`, id, data)
}
