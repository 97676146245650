import { Trans } from "react-i18next"
import CloseIcon from "@mui/icons-material/Close"
import React, { FC } from "react"
import Modal from "./Modal"
import { t } from "i18next"

const TermsAndConditionModal: FC<{
  onClose: () => void
}> = ({ onClose }) => (
  <Modal
    open
    onClose={onClose}
    title={t("Terms and Privacy Policy")}
    className={"mx-8 max-w-md font-body"}
  >
    <div style={{ display: "flex", flexDirection: "column" }}>
      <p className={"mt-4 p-0 leading-normal"}>
        <Trans>
          DizLog ("we," "our," or "us") is committed to protecting your privacy.
          This Privacy Policy explains how your personal information is
          collected, used, and disclosed by DizLog.
        </Trans>
      </p>
      <br />
      <p className={"p-0 leading-normal"}>
        <Trans>
          This Privacy Policy applies to our website, https://www.dizlog.com/,
          and its associated subdomains (collectively, our "Service") alongside
          our application, DizLog. By accessing or using our Service, you
          signify that you have read, understood, and agree to our collection,
          storage, use, and disclosure of your personal information as described
          in this Privacy Policy and our Terms of Service.
        </Trans>
      </p>
    </div>
  </Modal>
)

export default TermsAndConditionModal
