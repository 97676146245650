import authProvider from "../utils/authProvider"
import { BACKEND_API } from "../constants/Config"
import axios, { AxiosRequestConfig, AxiosResponse } from "axios"

export interface ErrorResponse {
  key: string
  message: string
}

/**
 * @deprecated move to the new axios based getAPI, which will properly throw error when fails
 * useful for using suspense.
 */
export const oldGetAPI = async <T = {}>(
  path: string,
  method = "GET",
  body?: string
): Promise<T> => {
  const token = await authProvider.getToken()
  const res = await fetch(`${BACKEND_API}${path}`, {
    headers: { id_token: token },
    method,
    body,
  })
  return res.json()
}

export const getPublicAPI = async <T = {}>(path: string): Promise<T> => {
  const res = await axios.get<T>(`${BACKEND_API}${path}`)
  return res.data
}

export const postPublicAPI = async <T = {}, K = {}>(
  path: string,
  body?: K
): Promise<AxiosResponse<T>> => {
  return await axios.post<T>(`${BACKEND_API}${path}`, body)
}

export const getAPI = async <T = {}>(
  path: string,
  params?: any
): Promise<T> => {
  const token = await authProvider.getToken()
  const res = await axios.get<T>(`${BACKEND_API}${path}`, {
    headers: { id_token: token },
    params: params,
  })
  return res.data
}

export const getPlainStringAPI = async <T = string>(path: string) => {
  const token = await authProvider.getToken()
  return await axios.get<T>(`${BACKEND_API}${path}`, {
    headers: { id_token: token },
  })
}

export const postAPI = async <T, K = object>(
  path: string,
  body?: K,
  config?: AxiosRequestConfig
) => {
  const token = await authProvider.getToken()
  return await axios.post<T>(`${BACKEND_API}${path}`, body, {
    ...config,
    headers: {
      id_token: token,
      ...config?.headers,
    },
  })
}

export const uploadFile = async <T>(
  path: string,
  file: File,
  config?: AxiosRequestConfig
) => {
  const token = await authProvider.getToken()

  const formData = new FormData()
  formData.append("file", file)

  return await axios.post<T>(`${BACKEND_API}${path}`, formData, {
    ...config,
    headers: {
      ...config?.headers,
      id_token: token,
      "Content-Type": "multipart/form-data",
    },
  })
}

export const deleteAPI = async <T>(
  path: string,
  id: string,
  locationId?: string
) => {
  const token = await authProvider.getToken()
  return await axios.delete<T>(
    `${BACKEND_API}${path}/${id}${
      locationId ? `?locationId=${locationId}` : ""
    }`,
    {
      headers: { id_token: token },
    }
  )
}

export const patchAPI = async <T>(
  path: string,
  id: string,
  body?: object,
  params?: any
) => {
  const token = await authProvider.getToken()
  return await axios.patch<T>(`${BACKEND_API}${path}/${id}`, body, {
    headers: { id_token: token },
    params: params,
  })
}

export const putAPI = async <T>(path: string, id: string, body?: object) => {
  const token = await authProvider.getToken()
  return await axios.put<T>(`${BACKEND_API}${path}/${id}`, body, {
    headers: { id_token: token },
  })
}
