import React, { FC, lazy, Suspense, useEffect } from "react"
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
} from "react-router-dom"
import { IntercomProvider } from "react-use-intercom"
import routes from "./routes"
import NotFoundPage from "./components/NotFoundPage"
import Dashboard from "./views/Dashboard"
import { INTERCOM_APP_ID } from "./constants/Config"
import Subscriptions from "./views/Subscriptions"
import VerifySubscription from "./views/VerifySubscription"
import VerifySubscriptionFailed from "./views/VerifySubscriptionFailed"
import TopLevelErrorBoundary from "./components/TopLevelErrorBoundary"
import InvoicePayment from "./views/Share/Invoice"
import DirectCheckout from "./views/DirectCheckout"
import CustomerQuotationView from "./views/CustomerQuotationView"
import ActivatePaypal from "./views/ActivatePayPal"
import Snackbar from "./components/Snackbar"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/lab"
import PublicReceipt from "./views/Share/Receipt"
import IpayForwardingCheckout from "./views/IpayForwardingCheckout"
import IpayRecurringForwardingCheckout from "./views/IpayRecurringForwardingCheckout"
import { useCacheProvider } from "@piotr-cz/swr-idb-cache"
import LoadingSpinner from "./components/LoadingSpinner"
import { SWRConfig } from "swr"
import { OnlineStatusProvider } from "./hooks/use-online-status"
import PWAUpdateNotification from "./components/PWAUpdateNotification"
import DiglogPayment from "./views/Auth/GettingStarted/DiglogPayment"
import "react-phone-input-2/lib/style.css"
import "./main.css"
import { overrideDefaultColorsOnTailwind } from "./utils/themes"
import VerifyXero from "./views/VerifyXero"
import { defaultIntercomProps } from "./lib/intercom"
import ShareablePurchaseOrder from "./views/Share/PurchaseOrder"
import * as Sentry from "@sentry/react"
import { AxiosError } from "axios"

const Preview = lazy(
  () => import("./views/BusinessSettings/Customization/Preview")
)
const ThemePreview = lazy(() => import("./views/ThemePreview"))

const App: FC = () => {
  const cacheProvider = useCacheProvider({
    dbName: "diglog-webapp",
    storeName: "swr-cache",
    version: 3,
  })

  const locationPathname = window.location.pathname
  useEffect(() => {
    overrideDefaultColorsOnTailwind()
  }, [locationPathname])

  if (!cacheProvider) {
    return (
      <div className={"flex h-screen w-screen items-center justify-center"}>
        <LoadingSpinner className={"text-primary-900"} />
      </div>
    )
  }

  return (
    <LocalizationProvider className="p-0" dateAdapter={AdapterDateFns}>
      <OnlineStatusProvider>
        <SWRConfig
          value={{
            provider: cacheProvider,
            revalidateOnFocus: false,
            onError: (error, key) => {
              // ignore 403 and 404 errors, network errors
              if (
                error.status !== 403 &&
                error.status !== 404 &&
                error.code !== "ERR_NETWORK"
              ) {
                if (error instanceof AxiosError) {
                  const url = new URL(error.config?.url || "")

                  // get search params as object
                  const params: Record<string, string> = {}
                  url.searchParams.forEach((value, key) => {
                    params[key] = value
                  })

                  // set context for sentry
                  Sentry.setContext("Request", {
                    url: url.pathname,
                    method: error.config?.method || "???",
                    params: params,
                  })
                  if (error.config?.headers) {
                    Sentry.setContext("Headers", error.config?.headers)
                  }
                }

                Sentry.setContext("SWR", { key })
                Sentry.captureException(error)
              }
            },
          }}
        >
          <IntercomProvider
            appId={INTERCOM_APP_ID}
            autoBoot
            autoBootProps={defaultIntercomProps}
          >
            <Suspense
              fallback={
                <div className="flex h-screen w-screen items-center justify-center">
                  <LoadingSpinner className={"text-primary-900"} />
                </div>
              }
            >
              <TopLevelErrorBoundary>
                <Switch>
                  {routes.map((route) => (
                    <Route
                      key={route.id}
                      path={route.path}
                      exact={route.exact}
                      component={() => (
                        <route.layout>
                          <route.component />
                        </route.layout>
                      )}
                    />
                  ))}
                  <Route path="/dashboard" render={() => <Dashboard />} />
                  <Route
                    path="/activate-paypal*"
                    render={() => <ActivatePaypal />}
                  />
                  <Route
                    path="/onboarding/diglog-payment"
                    render={() => <DiglogPayment />}
                  />
                  <Route
                    path="/forward/ipay-checkout"
                    render={() => <IpayForwardingCheckout />}
                  />
                  <Route
                    path="/forward/ipay-recurring"
                    render={() => <IpayRecurringForwardingCheckout />}
                  />
                  <Route
                    path="/verify-subscription-failed"
                    render={() => <VerifySubscription />}
                  />
                  <Route path="/verify-xero" render={() => <VerifyXero />} />
                  <Route
                    path="/verify-subscription-failed"
                    render={() => <VerifySubscriptionFailed />}
                  />
                  <Route path="/subscriptions">
                    <Suspense fallback={<div>Loading...</div>}>
                      <Subscriptions />
                    </Suspense>
                  </Route>
                  <Route path={"/direct-checkout"}>
                    <DirectCheckout />
                  </Route>
                  <Route path={"/customer-quotation/view/:id/"}>
                    <CustomerQuotationView />
                  </Route>
                  <Route path={"/share/invoice/:id"}>
                    <Suspense fallback={<></>}>
                      <InvoicePayment />
                    </Suspense>
                  </Route>
                  <Route path={"/share/purchase-order/:id"}>
                    <ShareablePurchaseOrder />
                  </Route>
                  <Route path={"/share/receipt/:orderId"}>
                    <PublicReceipt />
                  </Route>
                  <Route path={"/estore-preview"}>
                    <Suspense fallback={<></>}>
                      <Preview />
                    </Suspense>
                  </Route>
                  <Route path={"/theme-preview"}>
                    <Suspense fallback={<></>}>
                      <ThemePreview />
                    </Suspense>
                  </Route>
                  <Route component={NotFoundPage} />
                </Switch>
              </TopLevelErrorBoundary>
            </Suspense>

            <Snackbar />
            <PWAUpdateNotification />
          </IntercomProvider>
        </SWRConfig>
      </OnlineStatusProvider>
    </LocalizationProvider>
  )
}

export default App
