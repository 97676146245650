import { useCallback, useEffect } from "react"
import { MessagePayload, onMessage } from "firebase/messaging"
import { messaging } from "../lib/firebase"
import { create } from "zustand"
import { combine } from "zustand/middleware"

const defaultFCMCallbackQueueState = {
  queue: [] as Array<(payload: MessagePayload) => Promise<any>>,
}

const useFCMCallbackQueue = create(
  combine(defaultFCMCallbackQueueState, (set) => ({
    subscribe: (fn: (payload: MessagePayload) => Promise<void>) =>
      set((state) => ({ queue: [...state.queue, fn] })),
    unsubscribe: (fn: (payload: MessagePayload) => Promise<void>) =>
      set((state) => ({
        queue: state.queue.filter((f) => f !== fn),
      })),
  }))
)

const useFCMOnMessage = (
  fn: (payload: MessagePayload) => Promise<any>,
  deps: any[] = []
) => {
  const { subscribe, unsubscribe } = useFCMCallbackQueue()

  useEffect(() => {
    subscribe(fn)
    return () => unsubscribe(fn)
  }, deps)
}

export const useExecuteFCMCallbackQueue = () => {
  const { queue } = useFCMCallbackQueue()

  useEffect(() => {
    if (!messaging) return

    const unsubscribe = onMessage(messaging, async (m) => {
      for (const fn of queue) {
        await fn(m)
      }
    })
    return () => unsubscribe()
  }, [queue])
}

// shows native push notification when web app is on the foreground
export const useShowFCMPushNotification = () => {
  useFCMOnMessage(async (m) => {
    const permission = await Notification.requestPermission()
    if (permission === "granted") {
      const registration = await navigator.serviceWorker.ready
      await registration.showNotification(m.data?.title ?? "Order updated.", {
        body: m.data?.body || "Order updated.",
        icon: "/DigLogLogoV1.png",
      })
    }
  }, [])
}

export default useFCMOnMessage
