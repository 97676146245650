import ThermalPrinterEncoder from "thermal-printer-encoder"
import { Business } from "../../hooks/api/use-business"
import * as Sentry from "@sentry/react"

export const printBorder = (
  e: ThermalPrinterEncoder,
  width: number,
  options?: {
    noSpacing?: boolean
  }
) => {
  const border = "-".repeat(width)
  let result = e
  if (!options?.noSpacing) {
    result = result.newline()
  }
  result = e.line(border)
  if (!options?.noSpacing) {
    result = result.newline()
  }
  return result
}

export const twoToneText =
  (text: string, value: string) => (e: ThermalPrinterEncoder) => {
    return e.bold(true).text(text).bold(false).text(value)
  }

// print to thermal printer by 512 bytes chunks due to Bluetooth LE limitation
export const printToThermal = async (
  characteristic: BluetoothRemoteGATTCharacteristic,
  data: Uint8Array
) => {
  const dataLength = data.length
  // split into 512 bytes
  const chunkSize = 256
  const chunks = []
  for (let i = 0; i < dataLength; i += chunkSize) {
    chunks.push(data.slice(i, i + chunkSize))
  }
  for (let chunk of chunks) {
    Sentry.setContext("characteristic", characteristic)
    // check if the characteristic is writable
    if (!characteristic.properties.write) {
      throw new Error("Characteristic is not writable")
    }
    if (characteristic.writeValueWithResponse) {
      await characteristic.writeValueWithResponse(chunk)
    } else {
      await characteristic.writeValue(chunk)
    }
  }
  // sleep for 1s before sending the next chunk
  // for (let chunk of chunks) {
  //   await characteristic.writeValue(chunk)
  //   await new Promise((resolve) => setTimeout(resolve, 1000))
  // }
}

export const encodeBirHeader = (
  encoder: ThermalPrinterEncoder,
  details: {
    business: Business
    location?: {
      addressLine1?: string
      isVatRegistered?: string
      tin?: string
      companyName?: string
    }
    cashRegister?: {
      min: string
      serialNo: string
    }
  }
) => {
  let result = encoder
    .bold(true)
    .align("center")
    .line(details.business.name)
    .line("Operated by: " + details.location?.companyName)
    .line(details.location?.addressLine1 || "")

  if (details.business.country.codeAlpha2 === "PH") {
    result = result
      .line(
        details.location?.isVatRegistered === "Yes"
          ? "VAT REG TIN: " + details.location.tin
          : "NON VAT REG TIN"
      )
      .line("MIN: " + details.cashRegister?.min)
      .line("S/N: " + details.cashRegister?.serialNo)
  }

  return result.align("left")
}

export const encodeBirFooter = (encoder: ThermalPrinterEncoder) => {
  return encoder
    .align("center")
    .line("POS System Provider")
    .line("DizLog - RigelSoft PH")
    .line("91 2ND FLR. 25D ZETA II BLDG SALCEDO ST. LEGASPI VILLAGE N.A SAN")
    .line("LORENZO, CITY OF MAKATI, FOURTH DISTRICT, NCR, 1229")
    .line("VAT REG TIN 103-286-698-0003")
    .line("BIR Accreditation #036-103286608-000508")
    .line("Issued: 03/31/2010 - Until : 07/31/2023")
    .line("PTU No. 00000-0000-0000000-0000")
}

export const printSplitTable = (
  encoder: ThermalPrinterEncoder,
  width: number,
  leftWidth: number,
  data: Array<[string, string]>
) => {
  return encoder.table(
    [
      { width: leftWidth - 1, marginRight: 2, align: "left" },
      { width: width - leftWidth - 1, align: "right" },
    ],
    data
  )
}
