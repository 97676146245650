import useAPI from "./use-api"

const useAvailablePaymentMethods = (
  feature: "subscription" | "pos" | "onlineCheckout",
  disabled?: boolean
) => {
  return useAPI<
    Array<{
      code: string
      name: string
      identifier: string
      label?: string
      options?: string[]
      fees: {
        paymentCode: string
        countryCode: string
        cards: string
        eWallet: string
        bankTransfer: string
      }
      termsAndCondition: string
    }>
  >(disabled ? null : "/businesses/available-payment-method", { feature })
}

export default useAvailablePaymentMethods
