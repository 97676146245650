import Select from "./Select"
import { FCWithLoading } from "../lib/models"
import { t } from "i18next"
import useCustomers from "../hooks/api/use-customers"

const CustomerSelectDropdown: FCWithLoading<
  {
    label?: string
    disabled?: boolean
    required?: boolean
    allSuppliers?: boolean
    value?: string
    onChange?: (value: string) => void
    containerClassName?: string
    selectClassName?: string
    hideLabel?: boolean
    allowedSuppliers?: string[]
    disableNew?: boolean
    enableAll?: boolean
    toolTipContent?: string
  },
  { containerClassName?: string; selectClassName?: string; hideLabel?: boolean }
> = ({
  value,
  onChange,
  required,
  containerClassName,
  selectClassName,
  hideLabel,
  disabled,
  label,
  allowedSuppliers,
  disableNew,
  enableAll,
  allSuppliers,
  toolTipContent,
}) => {
  const { data: customers } = useCustomers()

  return (
    <Select
      id="supplier"
      name="supplier"
      label={label || t("Supplier (Optional)")}
      toolTipContent={
        toolTipContent || "Connect your item with your supplier details"
      }
      disabled={disabled}
      containerClassName={containerClassName}
      selectClassName={selectClassName}
      value={value}
      hideLabel={hideLabel}
      required={required}
      onChange={(e) => {
        if (onChange) onChange(e.target.value)
      }}
    >
      <option value="" disabled>
        Select a customer
      </option>
      {enableAll && <option value="all">All</option>}
      {customers?.map((value) => (
        <option key={value.id} value={value.id}>
          {value.fullName}
        </option>
      ))}
      {!disableNew && <option value="new">Add new customer...</option>}
    </Select>
  )
}

CustomerSelectDropdown.Loading = ({ containerClassName, selectClassName }) => (
  <Select
    id="customer"
    name="customer"
    label="Customer"
    containerClassName={containerClassName}
    selectClassName={selectClassName}
  >
    <option>{t("Loading...")}</option>
  </Select>
)

export default CustomerSelectDropdown
