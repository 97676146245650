import React, { useContext, useEffect, useRef, useState } from "react"
import { Badge, IconButton, MenuItem, Popover, Tooltip } from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import "./index.css"
import { SelectChangeEvent } from "@mui/material/Select"
import { ApiContext } from "../../views/Dashboard/context"
import { Link, useHistory } from "react-router-dom"
import {
  Dashboard,
  LogoutOutlined,
  Notifications,
  Reorder,
} from "@mui/icons-material"
import LanguageSelect from "../LanguageSelect"
import useEmployeeMe from "../../hooks/api/use-employee-me"
import profile_picture from "../../assets/images/profile_picture.png"
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded"
import useBusiness from "../../hooks/api/use-business"
import { useIntercom } from "react-use-intercom"
import SettingsIcon from "@mui/icons-material/Settings"
import { logout } from "../../utils/authProvider"
import { ReactComponent as PlusOutlined } from "../../assets/icons/plus-outlined.svg"
import { alpha, styled } from "@mui/material/styles"
import Menu, { MenuProps } from "@mui/material/Menu"
import useMposNotifications, {
  Notification,
} from "../../hooks/api/use-mpos-notifications"
import useToggle from "../../hooks/use-toggle"
import { Trans } from "react-i18next"
import HelpButton from "../HelpButton"
import moment from "moment"
import { useShowSnackbar } from "../Snackbar"
import useAuthorizationControl from "../../hooks/use-authorization-control"

interface Props {
  showMenuIcon: boolean
  toggleSideBar: () => void
}

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}))

export const TopBar: React.FC<Props> = ({ showMenuIcon, toggleSideBar }) => {
  const context = useContext(ApiContext)
  const [location, setLocation] = useState("")
  const { data: employee } = useEmployeeMe()
  const { data: business } = useBusiness()
  const { show } = useIntercom()
  const [anchorEl3, setAnchorEl3] = React.useState<null | HTMLElement>(null)
  const open3 = Boolean(anchorEl3)
  const showSnackbar = useShowSnackbar()
  const { data: notifications, mutate: mutateNotifications } =
    useMposNotifications(business?.id, false)

  const getUniqueNotifications = () => {
    let unique = [] as Notification[]
    let uniquePromoName = [] as string[]
    notifications
      .sort((a, b) => {
        return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      })
      .map((el) => {
        if (el.eventType === "PROMO_IS_EXPIRED") {
          if (!uniquePromoName.includes(el.name)) {
            unique.push(el)
            uniquePromoName.push(el.name)
          }
        } else {
          unique.push(el)
        }
      })
    return unique
  }

  const unreadNotifications =
    getUniqueNotifications()?.filter((el: Notification) => !el.read) || []

  useEffect(() => {
    const todayLogInOut = unreadNotifications
      ?.filter(
        (el) => el.eventType === "CLOCK_IN" || el.eventType === "CLOCK_OUT"
      )
      ?.filter((el) => {
        const today = moment().format("YYYY-MM-DD")
        const createdAt = moment(el.createdAt).format("YYYY-MM-DD")
        return today === createdAt
      })

    // Show notifications with a delay between each
    const showNotificationsSequentially = async () => {
      for (let i = 0; i < todayLogInOut.length; i++) {
        await showNotificationsWithDelay(todayLogInOut[i], i * 3000) // 3 seconds delay between each
      }
    }

    showNotificationsSequentially()
  }, [unreadNotifications])

  const showNotificationsWithDelay = (
    el: Notification,
    delay: number
  ): Promise<void> => {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        showSnackbar(
          `<a href="/dashboard/employees/report/employee-timesheet">${el.message} ${moment(el.createdAt).format("hh:mm a")}<a>`,
          "info"
        )
        setTimeout(() => {
          setToRead(el)
          resolve()
        }, 2000) // 2 seconds delay before marking as read
      }, delay)
    })
  }

  const setToRead = (item: Notification) => {
    const oldNotificationsStorage = JSON.parse(
      localStorage.getItem("notifications") || "{}"
    )
    let oldNotifications = []
    if (
      oldNotificationsStorage.businessId &&
      oldNotificationsStorage.businessId === business?.id
    ) {
      oldNotifications = oldNotificationsStorage.notifications
    }
    const index = oldNotifications.findIndex((x: any) => x.id === item.id)
    if (index !== -1) {
      oldNotifications[index].read = true
    }
    localStorage.setItem(
      "notifications",
      JSON.stringify({
        notifications: oldNotifications,
        businessId: business?.id,
      })
    )
  }

  //refetch the notifications every 5 minutes
  useEffect(() => {
    const interval = setInterval(() => {
      mutateNotifications()
    }, 300000)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    setLocation(context.currLocation)
  }, [context])
  const id3 = open3 ? "simple-popover" : undefined

  const handleClick3 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl3(event.currentTarget)
  }

  const history = useHistory()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
    setAnchorEl3(null)
  }
  const handleLogout = (): void => {
    logout()
      .then(() => {
        localStorage.clear()
        history.push("/")
      })
      .catch((err) => {
        window.console.log(err)
      })
  }

  const handleBecomeAPartner = () => {
    window.open("https://www.dizlog.com/partner-program", "_blank")
  }

  let currentUrl = window.location.pathname

  if (currentUrl == "/dashboard/mpos/receipts") {
    return null
  }

  return (
    <div className="hidden bg-primary-900 py-1 sm:block md:block lg:block xl:block">
      <div className=" flex">
        {currentUrl == "/dashboard/inventory/stock-catalog/manage" ||
        currentUrl == "/dashboard/inventory/category" ||
        currentUrl == "/dashboard/inventory/stock-catalog/purchase-orders" ||
        currentUrl == "/dashboard/inventory/stock-catalog/suppliers" ? (
          <button
            className="m-0 border-none bg-primary-900 pr-2 outline-none"
            onClick={(e) => handleClick3(e)}
          >
            <Reorder className="text-2xl text-white" />
          </button>
        ) : null}
        <div className="topbar w-full !py-6 px-8">
          {/* <div className={showMenuIcon ? "show-menu-icon" : "hide-menu-icon"}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleSideBar}
            >
              <MenuIcon
                sx={{
                  justifyContent: "space-between",
                  fill: "white",
                }}
              />
            </IconButton>
          </div> */}
          <Popover
            id={id3}
            open={open3}
            anchorEl={anchorEl3}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Link to="/dashboard/inventory/stock-catalog/manage?modal=new-item">
              <MenuItem
                onClick={() => {
                  handleClose()
                }}
                className="hover:bg-orange-300"
              >
                <div className="flex h-12  p-2">
                  <span className="flex flex-1 items-center justify-start text-sm">
                    New Product / Services
                  </span>
                </div>
              </MenuItem>
            </Link>
            <Link to="/dashboard/inventory/stock-catalog/manage">
              <MenuItem
                onClick={() => {
                  handleClose()
                }}
                className="hover:bg-orange-300"
              >
                <div className="flex h-12  p-2">
                  <span className="flex flex-1 items-center justify-start text-sm">
                    Items
                  </span>
                </div>
              </MenuItem>
            </Link>
            <Link to="/dashboard/inventory/category?open=category">
              <MenuItem
                onClick={() => {
                  handleClose()
                }}
                className="hover:bg-orange-300"
              >
                <div className="flex h-12  p-2">
                  <span className="flex flex-1 items-center justify-start text-sm">
                    <Trans>Category</Trans>
                  </span>
                </div>
              </MenuItem>
            </Link>
            <Link to="/dashboard/business-settings/promotions">
              <MenuItem
                onClick={() => {
                  handleClose()
                }}
                className="hover:bg-orange-300"
              >
                <div className="flex h-12  p-2">
                  <span className="flex flex-1 items-center justify-start text-sm">
                    Discount
                  </span>
                </div>
              </MenuItem>
            </Link>

            <Link to="/dashboard/inventory/bulk-upload?modal=items">
              <MenuItem
                onClick={() => {
                  handleClose()
                }}
                className="hover:bg-orange-300"
              >
                <div className="flex h-12 p-2">
                  <span className="flex flex-1 items-center justify-start text-sm">
                    Bulk Upload Items
                  </span>
                </div>
              </MenuItem>
            </Link>

            <MenuItem
              onClick={() => {
                handleClose()
              }}
              className="hover:bg-orange-300"
            >
              <Link to="/dashboard/inventory/bulk-upload?modal=images">
                <div className="flex h-12 p-2">
                  <span className="flex flex-1 items-center justify-start text-sm">
                    Bulk Upload Images
                  </span>
                </div>
              </Link>
            </MenuItem>
          </Popover>
          <img
            src={business?.logoUrl || profile_picture}
            className="mr-2 h-[30px] w-[30px] rounded-md object-fill"
          />
          <h3 className="busName text-left">
            {context.businessProfile ? context.businessProfile.name : " "}
          </h3>
          <HelpButton />
          <QuickAccessButton />
          {/* <Tooltip
        title={
          "Become a partner earn from 15% to 40% commission for each paid referral"
        }
      >
        <div
          className="blink_me text-md mr-2 cursor-pointer font-bold text-[#00215D] hover:underline"
          onClick={handleBecomeAPartner}
        >
          $ Earn <span className="text-lg">15%</span> to{" "}
          <span className="text-lg">40%</span> Commission
        </div>
      </Tooltip> */}
          <Tooltip title="Notifications">
            <Link
              to={"/dashboard/notifications"}
              className="flex items-center justify-center rounded-lg bg-white p-2 text-primary-900 duration-200 hover:scale-105"
            >
              <Badge
                badgeContent={unreadNotifications?.length || null}
                color="primary"
              >
                <Notifications />
              </Badge>
            </Link>
          </Tooltip>
          {/* {employee?.id ? (
            <Tooltip title={"View Profile"}>
              <Link
                to={`/dashboard/employees/manage-employees/employee/${employee?.id}/details`}
                className="ml-2 flex h-[36px] flex-row items-center bg-white/20 px-2 py-1 text-sm font-bold text-white hover:scale-105"
              >
                <div className="mr-1">
                  {employee?.fullName || "Employee Profile"}
                </div>
                <img
                  src={employee?.avatar || profile_picture}
                  className="h-[30px] w-[30px] rounded-full object-fill"
                />
                <KeyboardArrowDownRoundedIcon style={{ fill: "white" }} />
              </Link>
            </Tooltip>
          ) : ( */}
          <>
            {/* <Tooltip title={"View Profile"}>
            <Link
              to={"/dashboard/business-settings/profile"}
              className="ml-2 flex flex-row items-center bg-white/10 px-2 py-1 text-sm font-bold text-white hover:scale-105"
            >
              <div className="mr-1">{"Merchant Profile"}</div>
              <img
                src={business?.logoUrl || profile_picture}
                className="h-[30px] w-[30px] rounded-full object-fill"
              />
              <KeyboardArrowDownRoundedIcon style={{ fill: "white" }} />
            </Link>
          </Tooltip> */}

            <div>
              <div className="ml-2 flex h-[36px] flex-row items-center rounded-lg bg-white px-2 py-1 text-sm font-bold text-white hover:scale-105">
                <Tooltip title={"Profile"}>
                  {employee?.id ? (
                    <img
                      src={employee?.avatar || profile_picture}
                      className="h-[30px] w-[30px] rounded-full object-fill"
                    />
                  ) : (
                    <img
                      src={business?.logoUrl || profile_picture}
                      className="h-[30px] w-[30px] rounded-full border-[1px]  border-primary-900 object-fill"
                    />
                  )}
                </Tooltip>
                {employee?.id ? (
                  <div className="mr-1 pl-2 font-normal text-black">
                    {employee?.fullName || "Employee Profile"}
                  </div>
                ) : null}
                <div className="cursor-pointer" onClick={handleClick}>
                  <KeyboardArrowDownRoundedIcon className="text-primary-900" />
                </div>
              </div>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                {employee?.id ? (
                  <Link
                    to={`/dashboard/employees/manage-employees/employee/${employee?.id}/details`}
                    className=""
                  >
                    <MenuItem onClick={handleClose} disableRipple>
                      <SettingsIcon />
                      Settings
                    </MenuItem>
                  </Link>
                ) : (
                  <Link
                    to={"/dashboard/business-settings/profile"}
                    className=""
                  >
                    <MenuItem onClick={handleClose} disableRipple>
                      <SettingsIcon />
                      Settings
                    </MenuItem>
                  </Link>
                )}

                <MenuItem onClick={handleLogout}>
                  <LogoutOutlined />
                  Logout
                </MenuItem>
              </StyledMenu>
            </div>
          </>
          {/* )} */}
          <LanguageSelect
            hideLabel
            containerClassName="ml-2"
            selectClassName="h-[30px] rounded-full text-sm"
            iconClassName="!bottom-[10px] text-primary-900"
          />
          {/* <ButtonComponent
            onClick={() => show()}
            className="ml-2 rounded-md !p-2"
          >
            Get Help
          </ButtonComponent> */}
          {/* <div className="status">
        <Trans>Active</Trans>
      </div> */}
          {/**<FormControl
           variant="standard"
           sx={{
           ml: 'auto', my: 'auto', minWidth: 100, mr: 3, display: 'flex', flexDirection: 'row',
           }}
           >
           <LocationOnOutlinedIcon style={{ color: 'white', alignSelf: 'center' }} />
           <Select
           value={context.currLocation ?? ''}
           displayEmpty
           key={location}
           onChange={(event): void => handleLocationChange(event)}
           defaultValue=""
           sx={{
           minWidth: 100,
           pl: 0.25,
           fontSize: 14,
           fontWeight: 200,
           color: 'white',
           }}
           disableUnderline
           >
           {Object.entries(context.businessLocations ?? {}).map(([k, v]: any) => (
           <MenuItem value={v.name} sx={{ fontSize: 16, fontWeight: 200 }} key={v.name}>
           {v.name}
           </MenuItem>
           ))}
           </Select>
           </FormControl>**/}
        </div>
      </div>
    </div>
  )
}

const QuickAccessButton = () => {
  const [showMenu, toggleMenu] = useToggle()
  const anchorEl = useRef<HTMLButtonElement>(null)
  const { hasFeatureAccess, hasRolePermission } = useAuthorizationControl()

  return (
    <div className={"ml-auto mr-1"}>
      <button
        className="flex h-[36px] w-[36px] flex-row items-center rounded-lg bg-white px-2 py-1 text-sm font-bold text-primary-900"
        ref={anchorEl}
        onClick={toggleMenu}
      >
        <PlusOutlined />
      </button>

      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        open={showMenu}
        onClose={toggleMenu}
        anchorEl={anchorEl.current}
      >
        <p className={"px-4 py-2 text-sm text-gray-500"}>
          <Trans>Create New </Trans>
        </p>
        {hasRolePermission("inventory:itemMaintenance:manageItem") && (
          <Link to={"/dashboard/inventory/stock-catalog/manage?modal=new-item"}>
            <MenuItem onClick={toggleMenu} disableRipple>
              <Trans>Product / Service</Trans>
            </MenuItem>
          </Link>
        )}

        {hasRolePermission("inventory:itemMaintenance:manageItem") && (
          <Link to={"/dashboard/inventory/category/new"}>
            <MenuItem onClick={toggleMenu}>
              <Trans>Category</Trans>
            </MenuItem>
          </Link>
        )}

        {hasFeatureAccess("pos") && (
          <Link to={"/dashboard/mpos/business-catalog"}>
            <MenuItem onClick={toggleMenu}>
              <Trans>Orders</Trans>
            </MenuItem>
          </Link>
        )}

        {hasRolePermission("invoice:invoice:manageInvoice") && (
          <Link to={"/dashboard/invoicing/invoices/new"}>
            <MenuItem onClick={toggleMenu}>
              <Trans>Invoices</Trans>
            </MenuItem>
          </Link>
        )}

        {hasRolePermission("inventory:supplier:manageSupplier") && (
          <Link to={"/dashboard/suppliers/my-suppliers?modal=new"}>
            <MenuItem onClick={toggleMenu}>
              <Trans>Suppliers</Trans>
            </MenuItem>
          </Link>
        )}

        {hasRolePermission("inventory:purchaseOrder:managePurchaseOrder") && (
          <Link to="/dashboard/inventory/stock-catalog/purchase-orders?modal=new">
            <MenuItem onClick={toggleMenu}>
              <Trans>Purchase Orders</Trans>
            </MenuItem>
          </Link>
        )}

        {hasRolePermission("hrms:employee:manageEmployee") && (
          <Link to={"/dashboard/employee-management/employee/new"}>
            <MenuItem onClick={toggleMenu}>
              <Trans>Employee</Trans>
            </MenuItem>
          </Link>
        )}

        {hasRolePermission("pos:customer:manageCustomer") && (
          <Link to={"/dashboard/customers/list?modal=new"}>
            <MenuItem onClick={toggleMenu}>
              <Trans>Customer</Trans>
            </MenuItem>
          </Link>
        )}
      </StyledMenu>
    </div>
  )
}
