import useAPI from "./use-api"
import { Middleware, useSWRConfig } from "swr"
import { AxiosError } from "axios"
import { RolePermissions } from "../../lib/models"

export type GetEmployeeMeResponseBody = {
  id: string
  phone: string
  pushToken: any
  targetArn: any
  employmentType: string
  avatar: any
  fullName: string
  email: string
  nationalId: string
  staffId: string
  dob: any
  addressLine1: string
  addressLine2: any
  city: string
  state: any
  zip: string
  designation: any
  isManualAddTimesheet: boolean
  isManager: boolean
  isActive: boolean
  startDate: any
  lastDate: any
  lang: string
  deletedAt: any
  createdAt: string
  updatedAt: string
  userId: string
  payroll: any
  pin: string
  country: {
    id: number
    name: string
    label: string
    code: string
    phoneCode: string
    currency: string
    codeAlpha2: string
    tax: number
    serviceCharge: number
    countryGroup: number
  }
  locations: Array<{
    id: string
    name: string
    addressLine1: string
    addressLine2: string
    city: string
    state: string
    zip: string
    isPrimaryLocation: boolean
    createdAt: string
    updatedAt: string
  }>
  workLocation: {
    id: string
    name: string
    addressLine1: string
    addressLine2: string
    city: string
    state: string
    zip: string
    isPrimaryLocation: boolean
    createdAt: string
    updatedAt: string
  }
  role: Array<{
    id: string
    roleName: string
    description: any
    isDefault: boolean
    createdAt: string
    updatedAt: string
    permissions: Array<{
      id: string
      create: boolean
      read: boolean
      update: boolean
      delete: boolean
      createdAt: string
      updatedAt: string
      service: {
        id: string
        displayName: string
        createdAt: string
        updatedAt: string
      }
    }>
  }>
  permission: Array<{
    permission: RolePermissions
    displayName: string
  }>
}
const useEmployeeMe = () => {
  const { mutate } = useSWRConfig()

  return useAPI<GetEmployeeMeResponseBody | null>("/employee/me", undefined, {
    onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
      if (error instanceof AxiosError) {
        if (error.response?.status === 403) {
          localStorage.setItem("isBusinessOwner", "true")
          mutate("/employee/me", {}, false)
          return
        }
      }

      // Retry after 5 seconds.
      setTimeout(() => revalidate({ retryCount }), 5000)
    },
    revalidateIfStale: false,
    use: [businessOwnerCheckMiddleware],
  })
}

const businessOwnerCheckMiddleware: Middleware =
  (useSWRNext) => (key, fn, config) => {
    const swr = useSWRNext(key, fn, config)

    let data: any = swr.data

    if (Array.isArray(data)) {
      // currently this endpoint returns an array of permissions when user is a
      // business owner
      localStorage.setItem("isBusinessOwner", "true")
      data = {
        permission: data,
      }
    }

    return {
      ...swr,
      data,
    }
  }

export default useEmployeeMe
