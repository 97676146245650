import useSWR from "swr"
import { getPublicAPI } from "../../lib/api"

type GetTemplatesResponse = {
  id?: string
  headerText: string
  footerText: string
  primaryColor: string
  defaultNoteInvoice: string
  defaultNoteEstimates: string
  defaultNoteGRN: string
  defaultNotePurchaseOrder: string
  logoUrl: string
  fontType: string
  fontSize: string
  address: string
  email: string
  phone: string
  createdAt: string
  updatedAt: string
  paymentDetails: string
}

const useTemplatePublic = (businessId?: string) => {
  return useSWR<GetTemplatesResponse>(
    businessId ? `/templates/by-business-id/${businessId}` : null,
    getPublicAPI
  )
}

export default useTemplatePublic
