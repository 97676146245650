import useAPI from "./use-api"

interface GetXeroAccountMeResponse {
  exists: boolean
  hasTokenSet: any
  refreshTokenExpiresAt: any
  tenantId: any
  needsToLoginAgain: boolean
  accountTypesMap: AccountTypesMap | null
}

export interface AccountTypesMap {
  bank_account_sales?: {
    id: string
    code: string
  }
  bank_account_online_payment?: {
    id: string
    code: string
  }
  cash_sales?: {
    id: string
    code: string
  }
  online_payment_sales?: {
    id: string
    code: string
  }
  sales_discount?: {
    id: string
    code: string
  }
  goods_cost?: {
    id: string
    code: string
  }
  inventory?: {
    id: string
    code: string
  }
  account_receivable?: {
    id: string
    code: string
  }
}

const useXeroAccountMe = () => {
  return useAPI<GetXeroAccountMeResponse>("/xero-account/me")
}

export default useXeroAccountMe
