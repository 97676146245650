import { ButtonHTMLAttributes, DetailedHTMLProps, forwardRef } from "react"
import clsx from "clsx"

type Props = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  variant?:
    | "text"
    | "outlined"
    | "primary"
    | "small"
    | "primarySquare"
    | "outlinedSquare"
    | "outlinedSmall"
    | "primaryWhite"
    | "square"
    | "primarySquare1"
    | "secondaryOrange"
    | "secondaryWhite"
    | "tertiary"
}

const primary =
  "!bg-primary-900 text-white border-none hover:enabled:!bg-[#D78000] rounded-lg py-4 px-8 h-[32px] md:h-[40px] lg:h-[48px]"
const primaryWhite =
  "bg-white text-primary-900 border border-gray-300 shadow-sm hover:enabled:bg-primary-50 rounded-lg py-4 px-8 h-[43px] capitalize"
const primarySquare =
  "!bg-primary-900 text-white border-none hover:enabled:!bg-primary-600 rounded-xl py-4 px-8 h-[43px] capitalize"
const primarySquare1 =
  "!bg-primary-900 text-white border-none hover:enabled:!bg-primary-600 py-4 px-8 h-[43px] capitalize"
const outlined =
  "border-2 border-solid border-primary-900 text-primary-900 hover:enabled:!bg-primary-50 rounded-lg py-4 px-8 h-[43px] capitalize"
const outlinedSquare =
  "border-2 border-solid border-primary-900 text-primary-900 hover:enabled:!bg-primary-50 rounded-xl py-4 px-8 h-[43px] capitalize"
const text =
  "!bg-transparent text-primary-900 hover:enabled:underline border-none !p-0 !h-auto opacity-90 hover:enabled:opacity-100 capitalize text-base py-4 px-8 h-[43px] hover:enabled:bg-gray-100"
const small =
  "px-4 h-10 flex-shrink-0 !bg-primary-900 text-white border-none hover:enabled:!bg-primary-600 rounded-md p-2 capitalize text-xs"
const outlinedSmall =
  "border-2 border-solid border-primary-900 text-primary-900 hover:enabled:!bg-primary-50 rounded-lg p-2 px-4 capitalize h-10"
const square =
  "flex flex-shrink-0 items-center justify-center rounded-lg border border-solid border-gray-300 hover:bg-gray-100 bg-white p-2 p-0 font-bold text-primary-900 shadow-sm hover:enabled:bg-primary-50 disabled:opacity-50 capitalize"
const secondaryOrange =
  "hover:enabled:!bg-[#D78000] hover:enabled:text-white bg-transparent border-2 border-solid border-primary-900 text-primary-900 rounded-lg py-4 px-8 h-[32px] md:h-[40px] lg:h-[48px]"
const secondaryWhite =
  "hover:enabled:!bg-transparent/20 hover:enabled:text-white bg-transparent border-2 border-solid border-white text-white rounded-lg py-4 px-8 h-[32px] md:h-[40px] lg:h-[48px]"
const tertiary =
  "hover:enabled:!bg-slate-300 bg-white text-primary-900 rounded-lg py-4 px-8 h-[32px] md:h-[40px] lg:h-[48px]"

const Button = forwardRef<HTMLButtonElement, Props>(
  ({ type = "button", variant, className, ...props }, ref) => (
    <button
      type={type}
      className={clsx(
        "m-0 flex items-center justify-center whitespace-nowrap bg-transparent font-body text-sm font-bold leading-none transition-colors duration-150 ease-in-out enabled:cursor-pointer ",
        "disabled:opacity-50",
        (variant === undefined || variant === "primary") && primary,
        variant === "outlined" && secondaryOrange,
        variant === "text" && text,
        variant === "small" && small,
        variant === "primarySquare" && primarySquare,
        variant === "outlinedSquare" && outlinedSquare,
        variant === "outlinedSmall" && outlinedSmall,
        variant === "primaryWhite" && primaryWhite,
        variant === "square" && square,
        variant === "primarySquare1" && primarySquare1,
        variant === "secondaryOrange" && secondaryOrange,
        variant === "secondaryWhite" && secondaryWhite,
        variant === "tertiary" && tertiary,
        className
      )}
      {...props}
      ref={ref}
    />
  )
)

export default Button
