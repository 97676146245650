import { getPublicAPI } from "../lib/api"
import useSWR from "swr"
import { PurchaseOrderStatus } from "../lib/models"

interface GetPublicPODetails {
  id: string
  totalPrice: number
  shippingCost: number
  otherCost: number
  PONumber: string
  revisionNumber: any
  orderDate: string
  releasedDate: any
  receivedDate: any
  cancelledDate: any
  closedOrderDate: any
  changedOrderDate: any
  status: PurchaseOrderStatus
  shipmentDate: string
  companyAddress: string
  companyContactNumber: any
  cancelNotes: any
  paymentType: string
  createdAt: string
  updatedAt: string
  orderedProducts: Array<{
    id: string
    deliveryDetails: any
    totalPrice: number
    sellingPrice: number
    MRP: number
    quantity: number
    quantityReceived: number
    notes: string
    downloadCount: number
    discount: number
    orderDate: string
    createdAt: string
    updatedAt: string
    product: {
      id: string
      name: string
      SKU: string
      isReturnable: boolean
      barcode: any
      qrcode: any
      description: string
      productType: string
      productTypeStatus: any
      paymentOptions: any
      MRP: number
      MRPCurrency: string
      sellingPrice: number
      wholesalePrice: number
      sellingPriceCurrency: string
      unitOfMeasure: string
      unitOfMeasureValue: number
      purchaseUnitOfMeasure: any
      conversionNumber: number
      minimumQuantity: number
      recommendedQuantity: number
      stock: number
      beginningStock: number
      status: string
      sold: number
      stockLost: number
      stockReceived: number
      stockReturned: number
      stockDamaged: number
      stockInHand: number
      reorderQuantity: any
      imageUrl: any
      images: any
      expirationDate: any
      receivedDate: any
      isVisible: boolean
      autoGenerateSKU: boolean
      salesReturned: number
      isOrderEnabled: boolean
      isVisibleOnEstore: boolean
      isBundle: boolean
      weight: number
      width: number
      height: number
      length: number
      attachment: any
      serviceType: any
      serviceDuration: any
      serviceSlotTime: any
      gapBetweenAppointmentTime: number
      maxCustomerPerSlot: number
      reminderTime: any
      totalQuantity: number
      termsCondition: any
      isAvailable: boolean
      availableServiceDate: any
      blockServiceDate: any
      createdAt: string
      updatedAt: string
      createdBy: string
      updatedBy: string
      deposit: number
      depositUnitOfMeasurement: string
      allowPartialPayment: boolean
      nonInventory: boolean
      priceType: string
      taxable: boolean
      taxIncluded: boolean
      serviceDurationUnit: string
      hasAddOns: boolean
      icon: any
      color: any
      shape: any
      halal: boolean
      enableBIR: boolean
      useProduction: boolean
      isService: boolean
    }
    productVariant: any
  }>
  suppliers: Array<{
    id: string
    companyName: string
    companyPhone: string
    contactEmail: string
    contactName: string
    contactPhone: string
    billingInfo: string
    paymentTerms: string
    isActive: boolean
    createdAt: string
    updatedAt: string
    paymentDetails: any
    type: string
    registeredBusinessAccount: {
      id: string
      name: string
      businessType: string
      type: string
      typeGroup: any
      email: string
      admin: any
      logoUrl: string
      isFaceRecognitionEnable: boolean
      isGPSLocationTrackingEnable: boolean
      isSelfManagementEnabled: boolean
      isNotificationsEnable: boolean
      isQRcodeEnabled: boolean
      isAttendancePresentByDefault: boolean
      weekWorkdays: number
      salaryCycle: string
      workingDays: number
      createdAt: string
      updatedAt: string
      contactName: any
      phone: string
      targetArn: any
      statutoryCompliance: boolean
      catalogCode: any
      website: any
      description: any
      startTime: any
      endTime: any
      workDayTime: {
        monday: {
          startTime: string
          endTime: string
          breakStartTime: string
          breakEndTime: string
        }
        tuesday: {
          startTime: string
          endTime: string
          breakStartTime: string
          breakEndTime: string
        }
        wednesday: {
          startTime: string
          endTime: string
          breakStartTime: string
          breakEndTime: string
        }
        thursday: {
          startTime: string
          endTime: string
          breakStartTime: string
          breakEndTime: string
        }
        friday: {
          startTime: string
          endTime: string
          breakStartTime: string
          breakEndTime: string
        }
        saturday: {
          startTime: string
          endTime: string
          breakStartTime: string
          breakEndTime: string
        }
        sunday: {
          startTime: string
          endTime: string
          breakStartTime: string
          breakEndTime: string
        }
      }
      gapBetweenAppointmentTime: number
      multiWalletEnabled: boolean
      storeName: string
      defaultDomain: string
      onlyCashPayment: boolean
      code: number
      lang: string
      registrationLocation: any
      affiliateCode: string
      referralCode: string
      deviceLongitude: any
      deviceLatitude: any
      deviceId: any
      businessCurrency: string
      taxInvoiceNumber: any
      isActive: boolean
      pin: string
      productType: string
      payslipTemplate: any
      manuallyApproveCustomer: boolean
      financialMonth: string
      taxMonth: string
      accountingMethod: string
      enableBIR: boolean
      registeredCompanyName: string
    }
  }>
  location: {
    id: string
    name: string
    addressLine1: string
    addressLine2: any
    city: string
    state: string
    zip: string
    latCoord: number
    longCoord: number
    isPrimaryLocation: boolean
    isVatRegistered: string
    tin: any
    companyName: any
    birAccredited: boolean
    birIndustryType: string
    birEnabled: boolean
    createdAt: string
    updatedAt: string
  }
  business: {
    id: string
    name: string
    phone: string
    logoUr: string
    email: string
    businessCurrency: string
    country: {
      currency: string
    }
  }
  project: any
}

const usePublicPODetails = (id: string) => {
  return useSWR<GetPublicPODetails>(
    id ? `/purchase-order/${id}/share/details` : null,
    getPublicAPI
  )
}

export default usePublicPODetails
