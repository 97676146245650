import useAPI from "./use-api"
import { Middleware } from "swr"
import { Feature } from "./use-subscription-plans"
import { isNumber } from "lodash"
import { AxiosError } from "axios"

export interface GetSubscriptionResponse {
  id: string
  expirationDate: string
  createdAt: string
  updatedAt: string
  billingFrequency: string
  planId: string
  subscriptionId: string
  status: string
  paymentMethod: string
  shippingAddress: any
  shippingStatus: any
  shippingCost: number
  lastPaymentSuccessAt: any
  paymentChannel: any
  name: string
  addOnServices: Array<{
    id: string
    billingFrequency: string
    planId: string
  }>

  // used by legacy subscription plans, it is not flexible enough to filter all
  // features.
  subService: any[]
  includedServices: IncludedService[]

  // features restrictions used by new subscription plans
  features: Feature[]

  // generated by swr middleware
  isLegacyPlan: boolean
}

export interface IncludedService {
  id: number
  name: string
}

const useSubscription = () => {
  const swr = useAPI<GetSubscriptionResponse | null>(
    "/subscription",
    undefined,
    {
      use: [disableLegacyRestrictions],
      shouldRetryOnError: (error: AxiosError) => {
        return error.response?.status !== 404
      },
      onError: (error: AxiosError, key, config) => {
        console.log(error)
        if (error.response?.status === 404) {
          swr.mutate(null, { revalidate: false })
        }
      },
    }
  )

  return swr
}

const ALL_SUBSCRIPTION_SERVICE = [
  { id: 1, name: "Cash Book" },
  { id: 2, name: "Credit Book" },
  { id: 4, name: "Payroll" },
  { id: 5, name: "Inventory" },
  { id: 6, name: "POS" },
  { id: 7, name: "Easy Loan" },
  { id: 8, name: "HRMS" },
  { id: 9, name: "Online Store" },
  { id: 10, name: "Invoicing" },
]

/** Disables legacy subscription restriction when new plan is used. */
const disableLegacyRestrictions: Middleware = (useSWRNext) => {
  return (url, fetcher, config) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const swr = useSWRNext<any>(url, fetcher, config)

    const isLegacyPlan =
      swr.data &&
      (!swr.data?.features ||
        swr.data?.features?.length === 0 ||
        isNumber(swr.data.features[0]))
    if (swr.data) {
      if (!isLegacyPlan) {
        swr.data.includedServices = ALL_SUBSCRIPTION_SERVICE
      }
      swr.data.isLegacyPlan = isLegacyPlan
    }

    return swr
  }
}

export default useSubscription
