import apiProvider from "../utils/apiProvider"
import {
  CachedTransactionData,
  Customer,
  ServiceCartItem,
  Transaction,
} from "./models"
import { addMinutes, format, parse, set } from "date-fns"
import { getAPI } from "./api"
import moment from "moment"

export const createServiceAppointment = async (
  customer: Customer | undefined,
  item: ServiceCartItem,
  businessId: string,
  paymentMethodCode: string,
  slots: string[]
) => {
  let servicesProvided =
    item.product.serviceType !== "DAILY"
      ? slots.map((el) => {
          return {
            productService: item.product.id,
            productVariant: item.productVariant,
            status: "PENDING",
            startDate: moment(item.details?.startDate).format("YYYY-MM-DD"),
            endDate: moment(item.details?.endDate).format("YYYY-MM-DD"),
            startTime: el,
            endTime:
              item.details?.duration && item.details?.duration > 0
                ? getEndTime(el, item?.details?.duration)
                : null,
            quantity: item.qty,
            employee: item.details?.employee || undefined,
          }
        })
      : slots.map((el) => {
          return {
            productService: item.product.id,
            productVariant: item.productVariant,
            status: "PENDING",
            startDate: moment(el).format("YYYY-MM-DD"),
            endDate: moment(el).add(1, "days").format("YYYY-MM-DD"),
            startTime: "12:00:00",
            endTime: "12:00:00",
            quantity: item.qty,
            employee: item.details?.employee || undefined,
          }
        })

  if (item.details.multiEmployee && item.product.bundleItems) {
    let bundleServicesProvided: typeof servicesProvided = []
    item.product.bundleItems.forEach((bi) => {
      const employee = item.details.bundleEmployees?.find(
        (e) => e.product === bi.id && (e.variant === bi.variant || !bi.variant)
      )
      servicesProvided.forEach((sp) => {
        bundleServicesProvided.push({
          ...sp,
          employee: employee?.employee,
          productService: bi.id,
          productVariant: bi.variant,
        })
      })
    })
    servicesProvided = bundleServicesProvided
  }

  const { data } = await apiProvider.createAppointment({
    customer: customer?.id,
    customerName: customer?.fullName,
    customerContact: customer?.phone,
    location: item.details.location,
    employee:
      item.details?.employee ||
      item.details?.bundleEmployees[0]?.employee ||
      undefined,
    startDate: moment(item.details?.startDate).format("YYYY-MM-DD"),
    startTime: item.details.startTime,
    endDate: moment(item.details?.endDate).format("YYYY-MM-DD"),
    endTime:
      item.details?.duration && item.details?.duration > 0
        ? item.details.endTime
        : null,
    priceFull: item.sellingPrice * item.qty,
    priceFinal: item.sellingPrice * item.qty,
    status: "PENDING",
    isMpos: true,
    numberOfCustomer: item.qty,
    discount: 0,
    servicesProvided,
    paymentType:
      paymentMethodCode === "CASH"
        ? "CASH"
        : paymentMethodCode === "PAY LATER"
          ? "PAY_LATER"
          : "ONLINE",
    businessId,
  })

  return data.id
}

export const getEndTime = (startTime: string, duration: number) => {
  const timeArray = startTime.split(":")
  const date = set(new Date(), {
    hours: parseInt(timeArray[0]),
    minutes: parseInt(timeArray[1]),
    seconds: parseInt(timeArray[2] || "00"),
  })
  const newDate = addMinutes(date, duration)
  return format(newDate, "HH:mm:ss")
}

export const setCreatedAppointmentsAsSaved = async ({
  buxPaymentStatus,
  id,
  isServiceOrder,
}: Transaction) => {
  if (!(buxPaymentStatus === "PAID" && isServiceOrder)) return

  // appointment IDs were cached to local storage when checking out.
  const data: CachedTransactionData = JSON.parse(
    sessionStorage.getItem("transaction") || "{}"
  )
  if (data.id !== id) return

  await Promise.all(
    data.appointmentIds.map(async (appointments) => {
      await apiProvider.updateAppointment(appointments, {
        businessId: data.businessId,
        status: "PAID",
      })
    })
  )
}

export const getServiceSchedules = (
  id: string,
  startDate: string,
  location: string,
  businessId: string
) => {
  return getAPI(
    `/products/services/${id}/schedules?id=${id}&businessId=${businessId}&startDate=${startDate}&startAt=null&location=${location}`
  )
}
