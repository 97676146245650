import useAPI from "./use-api"

type Project = {
  createdAt: string
  endDate: string
  id: string
  name: string
  startDate: string
  status: string
  updatedAt: string
}

export type TimeSheet = {
  id: string
  overtimeType: string
  hoursOT: number
  hours: number
  lateHours: number
  submittedBy: string
  created_at: string
  updated_at: string
  businessId: string
  employeeId: string
  submittedFor: string
  startAt: string
  endAt: string
  isPaid: boolean
  attendanceType: string
  activities: string
  projectActivityId: any
  projectId: any
  fullName: string
  phone: string
  employmentType: string
  project?: Project
  isTimeSet?: boolean
  avatar?: string | null
  clockInImageUrl?: string | null
  clockOutImageUrl?: string | null
  status?: string
}

type GetTimesheetsResponse = Array<TimeSheet>

type Query = {
  startDate?: string
  endDate?: string
  employeeId?: string
  location?: string
}

const useTimesheets = (query?: Query) => {
  return useAPI<GetTimesheetsResponse>("/time-sheets", query)
}

export default useTimesheets
