import useSubscriptionPlans from "../../hooks/api/use-subscription-plans"
import { formatNumberToCurrency } from "../../lib/currency"
import React, { FC, Fragment, Suspense, useEffect, useState } from "react"
import { Trans } from "react-i18next"
import DigLogLogo from "../../assets/Logo_DigLog.png"
import { Link } from "react-router-dom"
import useUser from "../../hooks/api/use-user"
import LanguageSelect from "../../components/LanguageSelect"
import useQueryString from "../../hooks/use-query-string"
import { useShowSnackbar } from "../../components/Snackbar"
import SubscriptionButtons from "../../components/SubscriptionButtons"
import wallet from "../../assets/wallet.svg"
import checkSVG from "../../assets/checkSVG.svg"
import minusLine from "../../assets/minusLine.svg"
import { FREE_PLAN_ID, HOMEPAGE_URL, SubscriptionPlan } from "../../lib/models"
import { prefetchSubscriptionPlanDetails } from "../../hooks/api/use-subscription-plan-details"
import { t } from "i18next"
import { ChevronLeft } from "@mui/icons-material"
import { getCurrentSubscription } from "../../lib/subscriptions"
import { uniq } from "lodash"
import useFindSubscriptionPlanNormalPrice from "../../hooks/use-find-subscription-plan-normal-price"
import clsx from "clsx"
import useBusiness from "../../hooks/api/use-business"

const DIZLOG_STARTER_FEATURES = [
  "Point of Sale (POS)",
  "Basic Inventory management", // max
  "Custom eCommerce site",
  "Invoicing & Quotations",
  "Real time centralised dashboard & reports",
  "Multi-device - mobile, tablet, laptop, desktop, POS HW",
  "Local payments methods - ewallet, cards, transfers",
  "Supports all industry types",
  "Ideal for retail, restaurants, beauty, health, professional services, rental, education",
  "Offline POS",
  "Kitchen Display system",
  "Customer display system",
  "Supports all business types (Manufacturer, Distributor, Wholsaler, Retailer)",
  "Supplier Management",
  "CRM",
  "Coupons, discounts,",
  "Multi-store and multi register",
  "Use your own hardware",
  "Contact less ordering",
  "Localization",
  "Dedicated account manager",
  "Guided onboarding, account and online store setup",
  "24/7 support (whatsapp, chat, & ticket)",
]

const DIZLOG_STARTER_PLAN = {
  id: 0,
  name: "DizLog Starter",
  description: "subscriptionPlanNew.Free Trial",
  features: [],
  isEnabled: false,
  billingFrequency: "free",
  planId: FREE_PLAN_ID,
  appleProductId: null,
  price: 0,
  countryGroup: null,
  createdAt: "2023-07-05T11:01:16.131Z",
  updatedAt: "2023-07-05T11:01:16.131Z",
  maxEmployeesCount: 0,
  maxProductsCount: 200,
  maxStoresCount: 1,
  localCurrency: "USD",
  localPrice: 0,
}

const Subscriptions = () => {
  const showSnackBar = useShowSnackbar()
  const errorDesc = useQueryString("errorDesc")
  const { data: user } = useUser()
  const [frequency, setFrequency] = useState<string>("year")
  const subscriptionId = new URLSearchParams(window?.location?.search).get("id")
  const { data: plans } = useSubscriptionPlans()

  const planExists = plans?.find(
    (el) => subscriptionId && el.id === parseInt(subscriptionId)
  )

  useEffect(() => {
    if (errorDesc) {
      showSnackBar(errorDesc, "error")
    }
  }, [errorDesc, showSnackBar])

  const [isSubscribed, setisSubscribed] = useState(true)
  const currentSub = async () => {
    let fetch = await getCurrentSubscription()
      .then((r) => {
        console.log(r.message)
        if (r.message == "Active subscription not found") {
          setisSubscribed(false)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    currentSub()
  }, [])

  return (
    <div className={"bg-white"}>
      <div className="sticky top-0 z-20 border-x-0 border-b border-t-0 border-solid border-gray-200 bg-white py-2">
        <div className={"mx-auto flex max-w-[1400px] items-center px-6"}>
          <Link
            to={user && isSubscribed ? HOMEPAGE_URL : "/"}
            className={"flex"}
          >
            <img
              src={DigLogLogo}
              alt="DizLog"
              className={"h-8 w-auto object-cover"}
            />
          </Link>

          <LanguageSelect
            hideLabel
            containerClassName={"ml-auto"}
            selectClassName={"!bg-gray-50 font-bold"}
          />
        </div>
      </div>

      {subscriptionId && !planExists ? (
        <div className="flex h-screen w-full flex-row items-center justify-center">
          <div className="text-sm text-gray-600">
            {t("Subscription Plan not found.")}{" "}
            <span className="text-primary-900">
              <Link to={"/subscriptions"}>{t("See all plans")}</Link>
            </span>
          </div>
        </div>
      ) : (
        <div className="mx-auto max-w-[1400px] flex-col items-start px-6 pb-16 pt-6">
          <div className="sm:align-center mx-auto mt-8 sm:flex sm:flex-col">
            {isSubscribed && user && (
              <div>
                <Link
                  to={"/dashboard/business-settings/subscription-and-billings"}
                  className="mb-6 flex items-center justify-center text-center text-lg text-gray-500 no-underline hover:underline"
                >
                  <ChevronLeft />
                  Back to Settings
                </Link>
              </div>
            )}
            <div className="mb-2 flex items-center justify-center">
              <img src={wallet} className="mr-2 h-5 w-auto" />
              <span className="font-sansation text-2xl text-primary-900">
                Best value for your money!
              </span>
            </div>
            <span className="my-0 mb-6 flex w-full justify-center font-raleway text-5xl text-gray-900 sm:text-center">
              <strong>Subscription </strong>{" "}
              <span className={"ml-2"}>Model</span>
            </span>
            {!subscriptionId ? (
              <div className="relative mt-2 flex self-center rounded-lg border border-solid border-gray-200 bg-gray-50 p-0.5">
                <button
                  type="button"
                  onClick={() => setFrequency("year")}
                  className={
                    frequency === "year"
                      ? "relative m-0 w-1/3 rounded-lg border-solid border-gray-200 bg-orange-400 py-2 text-base font-semibold text-white shadow-sm focus:z-10 focus:outline-none focus:ring-2 focus:ring-primary-500 sm:w-auto sm:px-8 sm:text-lg md:w-56 md:whitespace-nowrap"
                      : "bg-gray-70 relative m-0 w-1/3 rounded-lg border-solid border-gray-200 py-2 text-base font-semibold text-gray-700 shadow-sm focus:z-10 focus:outline-none focus:ring-2 focus:ring-primary-500 sm:w-auto sm:px-8 sm:text-lg md:w-56 md:whitespace-nowrap"
                  }
                >
                  <Trans>Annually</Trans>
                </button>
                <button
                  type="button"
                  onClick={() => setFrequency("lifetime")}
                  className={
                    frequency === "lifetime"
                      ? "relative m-0 w-1/3 rounded-lg border-solid border-gray-200 bg-orange-400 py-2 text-base font-semibold text-white shadow-sm focus:z-10 focus:outline-none focus:ring-2 focus:ring-primary-500 sm:w-auto sm:px-8 sm:text-lg md:w-56 md:whitespace-nowrap"
                      : "bg-gray-70 relative m-0 w-1/3 rounded-lg border-solid border-gray-200 py-2 text-base font-semibold text-gray-700 shadow-sm focus:z-10 focus:outline-none focus:ring-2 focus:ring-primary-500 sm:w-auto sm:px-8 sm:text-lg md:w-56 md:whitespace-nowrap"
                  }
                >
                  <Trans>Lifetime Deals</Trans>
                </button>
              </div>
            ) : null}

            <Suspense fallback={<PlanListsLoadingPlaceholder />}>
              <PricingList
                frequency={frequency}
                subscriptionId={subscriptionId}
              />
            </Suspense>

            <h3 className={"mb-6 mt-12 text-center text-4xl font-bold"}>
              Features
            </h3>

            <div
              className={clsx(
                "mb-8 space-y-4 rounded-t-lg pl-0 pr-0 sm:grid sm:grid-cols-1 sm:space-y-0 sm:pl-1 sm:pr-1 md:grid-cols-1 md:pl-1 md:pr-1 lg:mx-0 lg:max-w-none lg:grid-cols-1 lg:pl-1 lg:pr-1 xl:mx-0 xl:max-w-none xl:grid-cols-4 xl:pl-12 xl:pr-12 ",
                subscriptionId && "xl:!grid-cols-2"
              )}
            >
              <Suspense fallback={<PlanListsLoadingPlaceholder />}>
                <PlanFeatureList
                  frequency={frequency}
                  subscriptionId={subscriptionId}
                />
              </Suspense>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const PricingList: FC<{
  frequency: string
  subscriptionId: string | null
}> = ({ frequency, subscriptionId }) => {
  const { data: plans } = useSubscriptionPlans()

  const filteredPlans = plans
    ?.filter(
      (p) =>
        p.price !== 0 &&
        p.planId !== "account-service" &&
        !p.planId.includes("add-store")
    ) // hides free trial plans.
    ?.filter((p) => {
      if (subscriptionId) return p.id === parseInt(subscriptionId)
      return frequency === p.billingFrequency
    })

  return (
    <div className="mt-16 flex flex-col border border-solid border-gray-200 xl:mx-12 xl:flex-row">
      {filteredPlans?.map((p) => <Pricing key={p.id} plan={p} />)}
    </div>
  )
}

const Pricing: FC<{
  plan: SubscriptionPlan
}> = ({ plan }) => {
  const { data: business } = useBusiness()
  const { data: user } = useUser()
  const { localPrice: originalLocalPrice } =
    useFindSubscriptionPlanNormalPrice(plan)

  let totalMonths = 1
  if (plan.billingFrequency === "year") totalMonths = 12
  else if (plan.billingFrequency === "every_2_year") totalMonths = 34

  const monthlyLocalPrice = plan.localPrice / totalMonths
  const monthlyUSDPrice = plan.price / totalMonths

  const hidePrice = business?.affiliate?.isCashAllowed

  return (
    <div className="w-full border-x-0 border-y-0 border-r border-solid border-gray-200 last:border-none">
      <div className={"bg-secondary-900 py-6 text-center"}>
        <h2 className={"m-0 p-0 text-xl font-bold text-white"}>
          {cleanUpSubscriptionName(plan.name, plan.billingFrequency)}
        </h2>
      </div>

      {!hidePrice && (
        <div className="flex flex-col items-center justify-center border-x-0 border-b border-t-0 border-solid border-gray-200 py-3">
          {monthlyLocalPrice > 0 ? (
            <div className={"mb-1"}>
              <span
                className={`cursor-help ${
                  plan.billingFrequency == "year" ? "text-3xl" : "text-3xl"
                } font-extrabold text-[#00215D] underline decoration-gray-400 decoration-dotted decoration-2`}
                title={`USD ${formatNumberToCurrency("USD", monthlyUSDPrice)}`}
              >
                {formatNumberToCurrency(plan.localCurrency, monthlyLocalPrice)}
              </span>{" "}
              <span className="text-xl font-medium text-gray-500">
                {plan.billingFrequency != "lifetime" ? (
                  <Trans>/month</Trans>
                ) : null}
              </span>
            </div>
          ) : (
            <div className={"mb-1"}>
              <span
                className={`cursor-help ${
                  plan.billingFrequency == "year" ? "text-3xl" : "text-3xl"
                } font-extrabold text-[#00215D] underline decoration-gray-400 decoration-dotted decoration-2`}
                title={`USD ${formatNumberToCurrency("USD", monthlyUSDPrice)}`}
              >
                <Trans>FREE</Trans>
              </span>
            </div>
          )}

          {plan.billingFrequency === "year" && (
            <>
              <span className="font-roboto rounded-full px-2.5  pt-1.5 font-bold text-red-800 line-through">
                {/* {formatNumberToCurrency(plan.localCurrency, originalLocalPrice)} */}
              </span>
              <span className="font-roboto mt-1 font-semibold">
                Total:{" "}
                {formatNumberToCurrency(plan.localCurrency, plan.localPrice)}{" "}
                {"  "} for 12 mo
              </span>
              <span className={"opacity-60"}>Billed annually</span>
            </>
          )}

          {plan.billingFrequency === "lifetime" && (
            <>
              <span className="font-roboto mt-1 font-semibold">
                One time Payment
              </span>
            </>
          )}

          {plan.billingFrequency === "every_2_year" && (
            <>
              <span className="font-roboto rounded-full px-2.5  pt-1.5 font-bold text-red-800 line-through">
                {formatNumberToCurrency(plan.localCurrency, originalLocalPrice)}
              </span>
              <span className="font-roboto mt-1 font-semibold">
                Total:{" "}
                {formatNumberToCurrency(plan.localCurrency, plan.localPrice)}{" "}
                for 3 years
              </span>
            </>
          )}
        </div>
      )}

      <div className={"p-6"}>
        {user && <SubscriptionButtons plan={plan} />}
        {!user && (
          <div className={"-mx-2"}>
            <Link
              to={`/direct-checkout?id=${plan.id}`}
              className="sticky top-28 block w-full rounded-xl border border-solid border-primary-800 bg-primary-800 py-3 text-center text-sm font-semibold text-white no-underline shadow-lg hover:bg-primary-900 disabled:cursor-not-allowed disabled:opacity-50"
              onMouseEnter={() => prefetchSubscriptionPlanDetails(plan.id)}
            >
              <Trans>Subscribe</Trans>{" "}
              {formatNumberToCurrency(plan.localCurrency, plan.localPrice)}
              <span className="font-medium opacity-70">
                {plan.billingFrequency === "every_2_year" && t("/3 years")}
                {plan.billingFrequency === "year" && t("/12 months")}
                {plan.billingFrequency === "month" && t("/month")}
              </span>
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

const PlanListsLoadingPlaceholder = () => {
  return (
    <>
      <div className="flex h-[942px] animate-pulse flex-col divide-y divide-gray-200 rounded-2xl border border-solid border-gray-200 bg-gray-50 shadow-sm"></div>
      <div className="flex h-[942px] animate-pulse flex-col divide-y divide-gray-200 rounded-2xl border border-solid border-gray-200 bg-gray-50 shadow-sm delay-100"></div>
      <div className="flex h-[942px] animate-pulse flex-col divide-y divide-gray-200 rounded-2xl border border-solid border-gray-200 bg-gray-50 shadow-sm delay-200"></div>
      <div className="flex h-[942px] animate-pulse flex-col divide-y divide-gray-200 rounded-2xl border border-solid border-gray-200 bg-gray-50 shadow-sm delay-300"></div>
    </>
  )
}

const PlanFeatureList: FC<{
  frequency: string
  subscriptionId: string | null
}> = ({ frequency, subscriptionId }) => {
  const { data: plans } = useSubscriptionPlans()

  const filteredPlans = plans
    ?.filter(
      (p) =>
        p.price !== 0 &&
        p.planId !== "account-service" &&
        !p.planId.includes("add-store")
    )
    ?.filter((p) => {
      if (subscriptionId) return p.id === parseInt(subscriptionId)
      return frequency === p.billingFrequency
    })

  const allFeatures = uniq(
    filteredPlans?.flatMap((p) => {
      return p.description
        .split("\n")
        .map((d) => d.replace("subscriptionPlan.", "").replace("lifetime", ""))
    })
  )

  return (
    <>
      <div className="hidden flex-col divide-x-0 divide-y divide-solid divide-gray-200 border border-solid border-gray-200 shadow-sm sm:hidden md:hidden lg:hidden xl:flex">
        <div className=" flex h-20 items-center bg-[#00215D] pl-3">
          <span className="text-sm font-semibold text-white"></span>
        </div>

        {allFeatures.map((feature) => (
          <div key={feature} className=" flex h-20 items-center pl-3 pr-1 ">
            <span className="font-roboto text-base text-[#686868]">
              {feature}
            </span>
          </div>
        ))}
      </div>

      {filteredPlans?.map((item) => {
        const planFeatures = item.description
          .split("\n")
          .map((d) =>
            d.replace("subscriptionPlan.", "").replace("lifetime", "")
          )

        return (
          <FeatureList
            key={item.id}
            name={item.name}
            frequency={frequency}
            featureList={allFeatures.map((f) => {
              return { name: f, value: planFeatures.includes(f) }
            })}
            plan={item}
          />
        )
      })}
    </>
  )
}

const FeatureList: FC<{
  name: string
  frequency: string
  featureList: Array<{
    name: string
    value: boolean
  }>
  plan: SubscriptionPlan
}> = ({ name, frequency, featureList, plan }) => {
  const planName = plan.name.toLocaleLowerCase()

  return (
    <div className="flex flex-col divide-x-0 divide-y divide-solid divide-gray-200 border border-solid border-gray-200 shadow-sm">
      <div className="flex h-20 items-center justify-center bg-[#00215D]">
        <span className="text-center text-xl font-bold text-white">
          {cleanUpSubscriptionName(name, frequency)}
        </span>
      </div>

      <div className=" flex h-20 items-center justify-start pl-2 text-base font-bold  text-[#00215D] sm:flex md:flex lg:flex xl:hidden">
        FEATURES
      </div>
      {featureList.map((feature) => {
        const featureName = feature.name.toLocaleLowerCase()
        let desktop = (
          <>
            {feature.value && (
              <img src={checkSVG} className="h-6 w-auto" alt={"Included"} />
            )}
            {!feature.value && (
              <img src={minusLine} className="h-7 w-8" alt={"Not included"} />
            )}
          </>
        )

        let mobile = (
          <>
            {feature.value && (
              <img
                src={checkSVG}
                className=" h-3 w-auto sm:h-4 md:h-4 lg:h-4 xl:h-4"
                alt={"Included"}
              />
            )}
            {!feature.value && (
              <img
                src={minusLine}
                className="w-4 sm:w-5 md:w-5 lg:w-5 xl:w-5"
                alt={"Not included"}
              />
            )}
          </>
        )

        if (feature.name === "Basic Inventory management") {
          desktop = plan.maxProductsCount ? (
            <p>Max {plan.maxProductsCount} SKUs</p>
          ) : (
            <p>Unlimited</p>
          )
          mobile = desktop
        }

        if (
          plan.name !== "DizLog Starter" &&
          feature.name === "Advanced Inventory Management"
        ) {
          desktop = plan.maxProductsCount ? (
            <p>Max {plan.maxProductsCount} SKUs</p>
          ) : (
            <p>Unlimited</p>
          )
          mobile = desktop
        }

        if (
          (featureName === "employee management" ||
            featureName.includes("payroll")) &&
          feature.value
        ) {
          desktop = plan.maxEmployeesCount ? (
            <p>Max {plan.maxEmployeesCount} staff</p>
          ) : (
            <p>Unlimited</p>
          )
          mobile = desktop
        }

        if (featureName === "multi-store and multi register") {
          desktop = (
            <p className={"text-center"}>
              1 store included.
              <br /> Additional store available as add on
            </p>
          )
          mobile = desktop
        }

        if (
          featureName === "dedicated account manager" ||
          featureName.includes("guided onboarding")
        ) {
          desktop = planName.includes("pro") ? (
            <p>Included</p>
          ) : (
            <p>$15 One time fee</p>
          )
          mobile = desktop
        }

        return (
          <Fragment key={feature.name}>
            <div className="hidden h-20 items-center justify-center sm:hidden md:hidden lg:hidden xl:flex">
              {desktop}
            </div>

            <div className="flex h-12 items-center justify-center sm:flex md:flex lg:flex xl:hidden">
              <div className="flex h-full flex-1 items-center border-r-2 p-1">
                <span className="pl-2 text-xs">{feature.name}</span>
              </div>
              <div className="flex  h-full  w-40 items-center justify-center">
                {mobile}
              </div>
            </div>
          </Fragment>
        )
      })}
    </div>
  )
}

const cleanUpSubscriptionName = (string: string, frequency: string) => {
  let newString = string.replace("subscriptionPlan.", "")
  if (frequency.toLocaleLowerCase() === "month") {
    return newString.replace("monthly", "")
  } else if (frequency.toLowerCase() === "every_2_year") {
    return newString.replace("36 months", "")
  } else if (frequency.toLowerCase() === "lifetime") {
    return newString.replace("lifetime", "")
  } else {
    return newString.replace("yearly", "")
  }
}

export default Subscriptions
