import createPeer, { createPeerAsync } from "../lib/peer-js"
import { Cart, Customer } from "../lib/models"
import { PostCheckoutResponseBody } from "./api/use-checkout"
import Peer, { DataConnection } from "peerjs"
import { create } from "zustand"
import { combine, devtools } from "zustand/middleware"
import { useEffect, useRef } from "react"
import { Business } from "./api/use-business"
import { CashRegisterActiveUserResponse } from "./api/use-cash-register-active-user"
import { GetCartTransactionResponse } from "./api/use-cart-transaction"

let peer: Peer | null = null

const generateDefaultState = () => {
  return {
    id: null as string | null,
    cart: undefined as Cart | undefined,
    checkout: undefined as PostCheckoutResponseBody | undefined,
    connected: false,
    connection: undefined as DataConnection | undefined,
    business: undefined as Business | undefined,
    customer: undefined as Customer | undefined,
    cashier: undefined as CashRegisterActiveUserResponse | undefined,
    receipt: undefined as GetCartTransactionResponse | undefined | null,
    posType: undefined as string | undefined,
    getPeer: async () => {
      if (peer) {
        return peer
      }

      let newPeer = await createPeerAsync()
      peer = newPeer

      return newPeer
    },
  }
}

// this hook is used on CDS to connect to a POS instance and receive cart
// updates.
const useCDSCartListener = create(
  devtools(
    combine(generateDefaultState(), (set, get) => ({
      setConnected: (connected: boolean) => {
        set({ connected })
      },
      setCart: (cart: Cart) => {
        set({ cart })
      },
      setCheckout: (checkout: PostCheckoutResponseBody) => {
        set({ checkout })
      },
      setConnection: (connection: DataConnection) => {
        set({ connection })
      },
      setBusiness: (business: Business) => {
        set({ business })
      },
      setCustomer: (customer: Customer) => {
        set({ customer })
      },
      setCashier: (cashier: CashRegisterActiveUserResponse) => {
        set({ cashier })
      },
      setReceipt: (receipt: GetCartTransactionResponse | null) => {
        set({ receipt })
      },
      setId: (id: string | null) => {
        set({ id })
      },
      setPosType: (posType: string) => {
        set({ posType })
      },
    })),
    { name: "cds-cart-listener" }
  )
)

// This hooks is used to sync state with peer.
// THIS SHOULD ONLY BE CALLED ONCE
export const useSyncCDS = () => {
  const state = useCDSCartListener()

  useEffect(() => {
    ;(async () => {
      const peer = await state.getPeer()
      state.setId(peer?.id || null)

      peer?.on("connection", (conn) => {
        state.setConnection(conn)

        conn.on("open", () => {
          state.setConnected(true)
        })

        conn.on("data", (data: any) => {
          if (data.type === "CHECKOUT") {
            state.setCheckout(data.payload)
          } else if (data.type === "CART") {
            state.setCart(data.payload)
            state.setReceipt(null)
          } else if (data.type === "BUSINESS") {
            state.setBusiness(data.payload)
          } else if (data.type === "CUSTOMER") {
            state.setCustomer(data.payload)
          } else if (data.type === "CASHIER") {
            state.setCashier(data.payload)
          } else if (data.type === "RECEIPT") {
            state.setReceipt(data.payload)
          } else if (data.type === "POS_TYPE") {
            state.setPosType(data.payload)
          }
        })
      })

      peer?.on("disconnected", () => {
        state.setConnected(false)
      })
    })()
  }, [])
}

export default useCDSCartListener
