import { patchAPI, postAPI, postPublicAPI } from "./api"
import { PurchaseOrderStatus } from "./models"

export const postNewPurchaseOrder = (data: {
  suppliers: Array<{ id: string }>
  orderDate: string
  shipmentDate: string
  companyAddress: string
  PONumber: number
  location: string
  products: Array<{
    product: string
    totalPrice: number
    quantity: number
    quantityReceived: number
    discount: number
    orderDate: string
    MRP: number
  }>
  project?: string
  // paymentType: string
  // shippingCost: number
  // cancelNotes: "" | null
  // revisionNumber: number | null
  // otherCost: number
  // status: string
  // companyContactNumer: string
}) => {
  return postAPI(`/purchase-order`, {
    ...data,
    companyContactNumer: "",
    paymentType: "CASH",
    status: "NEW",
    cancelNotes: null,
    otherCost: 0,
    shippingCost: 0,
    revisionNumber: null,
  })
}

export const cancelPurchaseOrder = (id: string) => {
  return postPublicAPI(`/purchase-order/status/${id}`, { status: "CANCELLED" })
}

export const acceptPurchaseOrder = (id: string) => {
  return postPublicAPI(`/purchase-order/status/${id}`, { status: "CONFIRMED" })
}

export const rejectPurchaseOrder = (id: string) => {
  return postPublicAPI(`/purchase-order/status/${id}`, { status: "REJECTED" })
}

export const updatePurchaseOrder = (
  id: string,
  data: {
    suppliers: Array<{ id: string }>
    companyAddress: string
    products: Array<{
      product: string // productId
      totalPrice: number
      quantity: number
      quantityReceived: number
      discount: number
      orderDate: string // ISO Date string
      MRP: number
    }>
    status: PurchaseOrderStatus
    location: string // locationId
    shipmentDate: string // ISO date string
    releaseDate: string // ISO date string
    orderDate: string
    releasedDate: string
    receivedDate: string
    cancelledDate: string
    cancelNotes: string | null
    totalPrice: number
    project?: string
    // closedOrderDate: string
    // changedOrderDate: string
    // id: "be59d097-a10f-4657-9293-25d25456e317"
    // PONumber: number
    // shippingCost: 0
    // otherCost: 0
    // companyContactNumber: string
    // paymentType: PaymentMethod
  }
) => {
  return patchAPI(`/purchase-order`, id, {
    ...data,
    paymentType: "CASH",
  })
}
