const themes = [
  {
    name: "default",
    colors: [
      {
        name: "--primary-white",
        value: "#ffff",
      },
      {
        name: "--primary-posProduct",
        value: "#ffff",
      },
      {
        name: "--primary-posNumber",
        value: "#f09000",
      },
      {
        name: "--primary-black",
        value: "#000000",
      },
      {
        name: "--primary-50",
        value: "#fdf5ea",
      },
      {
        name: "--primary-100",
        value: "#fbdeb3",
      },
      {
        name: "--primary-200",
        value: "#f9d399",
      },
      {
        name: "--primary-300",
        value: "#f8c880",
      },
      {
        name: "--primary-400",
        value: "#f6bc66",
      },
      {
        name: "--primary-500",
        value: "#f5b14d",
      },
      {
        name: "--primary-600",
        value: "#f3a633",
      },
      {
        name: "--primary-700",
        value: "#f3a633",
      },
      {
        name: "--primary-800",
        value: "#f29b1a",
      },
      {
        name: "--primary-900",
        value: "#f09000",
      },
      {
        name: "--primary-POS_900",
        value: "#f09000",
      },
      {
        name: "--primary-POS_500",
        value: "#f5b14d",
      },
      {
        name: "--primary-business_catalog",
        value: "#f6f6ff",
      },
    ],
  },
  {
    name: "dark",
    colors: [
      {
        name: "--primary-white",
        value: "rgb(15 23 42)",
      },
      {
        name: "--primary-business_catalog",
        value: "rgb(15 23 42)",
      },
      {
        name: "--primary-posProduct",
        value: "rgb(71 85 105)",
      },
      {
        name: "--primary-black",
        value: "#ffff",
      },
      {
        name: "--primary-posNumber",
        value: "#ffff",
      },
      {
        name: "--primary-50",
        value: "rgb(249 250 251)",
      },
      {
        name: "--primary-100",
        value: "rgb(243 244 246)",
      },
      {
        name: "--primary-200",
        value: "rgb(229 231 235)",
      },
      {
        name: "--primary-300",
        value: "rgb(209 213 219)",
      },
      {
        name: "--primary-400",
        value: "rgb(156 163 175)",
      },
      {
        name: "--primary-500",
        value: "rgb(107 114 128)",
      },
      {
        name: "--primary-600",
        value: "rgb(75 85 99)",
      },
      {
        name: "--primary-700",
        value: "rgb(55 65 81)",
      },
      {
        name: "--primary-800",
        value: "rgb(31 41 55)",
      },
      {
        name: "--primary-900",
        value: "rgb(31 41 55)",
      },
      {
        name: "--primary-POS_900",
        value: "#f09000",
      },
      {
        name: "--primary-POS_500",
        value: "#f5b14d",
      },
    ],
  },
]

export const overrideDefaultColorsOnTailwind = () => {
  let themeName = window.localStorage.getItem("themeName") || "default"
  if (themeName) {
    let theme = themes.find((item) => item.name == themeName)
    if (theme) {
      theme.colors.map((item) => {
        document.documentElement.style.setProperty(item.name, item.value)
      })
    }
  }
}
