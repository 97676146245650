import { createContext, FC, useContext, useEffect, useState } from "react"

const OnlineStatusContext = createContext(true)

export const OnlineStatusProvider: FC = ({ children }) => {
  const [isOnline, setIsOnline] = useState(navigator?.onLine ?? true)

  useEffect(() => {
    const handleOnline = () => setIsOnline(true)
    const handleOffline = () => setIsOnline(false)

    window.addEventListener("online", handleOnline)
    window.addEventListener("offline", handleOffline)

    return () => {
      window.removeEventListener("online", handleOnline)
      window.removeEventListener("offline", handleOffline)
    }
  }, [])

  return (
    <OnlineStatusContext.Provider value={isOnline}>
      {children}
    </OnlineStatusContext.Provider>
  )
}

const useOnlineStatus = () => {
  return useContext(OnlineStatusContext)
}

export default useOnlineStatus
