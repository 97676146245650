import React, { FC } from "react"

const Steps: FC<{
  steps: { id: string; name: string }[]
  currStep: number
  onStepChange?: (value: number) => void
}> = ({ steps, onStepChange, currStep = 0 }) => {
  const s = steps.map((s, idx) => {
    if (idx === currStep) {
      return { ...s, status: "current" }
    } else if (idx < currStep) {
      return { ...s, status: "complete" }
    } else {
      return { ...s, status: "upcoming" }
    }
  })

  return (
    <nav aria-label="Progress" className={"my-0 mb-6"}>
      <ol className="my-0 list-none space-y-4 p-0 md:flex md:space-y-0 md:space-x-8">
        {s.map((step, idx) => (
          <li key={step.name} className="md:flex-1">
            {step.status === "complete" ? (
              <button
                className="group my-0 ml-0 mt-0 flex w-full flex-col items-start border-b-0 border-t-0 border-r-0 border-l-4 border-solid border-primary-600 bg-transparent py-2 pl-4 text-inherit no-underline hover:border-primary-800 md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0"
                type={"button"}
                onChange={() => {
                  if (onStepChange) {
                    onStepChange(idx)
                  }
                }}
              >
                <span className="text-xs font-bold uppercase tracking-wide text-primary-900 group-hover:text-primary-900">
                  {step.id}
                </span>
                <span className="text-sm font-medium">{step.name}</span>
              </button>
            ) : step.status === "current" ? (
              <button
                className="my-0 ml-0 mt-0 flex w-full flex-col items-start border-b-0 border-r-0 border-t-0  border-l-4 border-solid border-primary-600 bg-transparent py-2 pl-4 text-inherit no-underline md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0"
                aria-current="step"
                type={"button"}
                onChange={() => {
                  if (onStepChange) {
                    onStepChange(idx)
                  }
                }}
              >
                <span className="text-xs font-bold uppercase tracking-wide !text-primary-900">
                  {step.id}
                </span>
                <span className="text-sm font-medium">{step.name}</span>
              </button>
            ) : (
              <button
                className="group my-0 ml-0 mt-0 flex w-full flex-col items-start border-l-4 border-b-0 border-r-0 border-t-0  border-solid border-gray-200 bg-transparent py-2 pl-4 text-inherit no-underline hover:border-gray-300 md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0"
                type={"button"}
                onChange={() => {
                  if (onStepChange) {
                    onStepChange(idx)
                  }
                }}
              >
                <span className="text-xs font-bold uppercase tracking-wide text-gray-500 group-hover:text-gray-700">
                  {step.id}
                </span>
                <span className="text-sm font-medium">{step.name}</span>
              </button>
            )}
          </li>
        ))}
      </ol>
    </nav>
  )
}

export default Steps
