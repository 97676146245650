import React, { FC } from "react"
import { BusinessType } from "../lib/models"
import useBusinessTypes from "../hooks/api/use-business-types"
import { Autocomplete, TextField } from "@mui/material"
import { t } from "i18next"

const BusinessTypeSelect: FC<{
  onChange: (value: BusinessType) => void
  value: BusinessType | null
}> = ({ onChange, value }) => {
  const { data: businessTypes } = useBusinessTypes()

  return (
    <Autocomplete
      value={value}
      disablePortal
      options={businessTypes?.allTypes ?? []}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option === value}
      onChange={(event, value) => {
        if (value) onChange(value)
      }}
      sx={{ width: "100%", fontSize: "0.3rem", mb: 3 }}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label={t("Business Type")} />
      )}
    />
  )
}

export const BusinessTypeSelectLoading: FC = () => (
  <Autocomplete
    disablePortal
    options={[]}
    getOptionLabel={() => "loading..."}
    disabled
    sx={{ width: "100%", fontSize: "0.3rem", mb: 3 }}
    renderInput={(params) => (
      <TextField {...params} variant="outlined" label={t("Business Type")} />
    )}
  />
)

export default BusinessTypeSelect
