import useLocations from "./use-locations"

const usePrimaryLocation = () => {
  const locations = useLocations()

  return {
    ...locations,
    data: locations.data?.find((location) => location.isPrimaryLocation),
  }
}

export default usePrimaryLocation
