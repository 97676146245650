import { useEffect, useState } from "react"
import "react-date-range/dist/styles.css"
import "react-date-range/dist/theme/default.css"
import {
  DateRangePicker,
  DateRange,
  defaultStaticRanges,
} from "react-date-range"
import { Card, Modal } from "@mui/material"
import "./datepicker.css"
import moment from "moment"
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined"
import customRanges from "./customRange"
import { endOfDay, formatISO, startOfDay, subDays, toDate } from "date-fns"
import Button from "../../Button"
import { Trans } from "react-i18next"

interface Props {
  onChange: (value: { startDate: string; endDate: string }) => void
  value: {
    startDate: string
    endDate: string
  }
  customStaticRange?: boolean
}

const initStartDateToday = (): string => {
  return formatISO(startOfDay(new Date()))
}

const initStartDate = (): string => {
  return formatISO(subDays(startOfDay(new Date()), 30))
}

const initEndDate = (): string => {
  return formatISO(endOfDay(new Date()))
}

function Datepicker({ onChange, value, customStaticRange }: Props) {
  const [state, setState] = useState([
    {
      startDate:
        moment(value?.startDate).toDate() || moment().add(-7, "days").toDate(),
      endDate: moment(value?.endDate).toDate() || moment().toDate(),
      key: "selection",
    },
  ])
  // eslint-disable-next-line no-restricted-globals
  const [viewWidth, setViewWidth] = useState(screen.width)
  const [open, setOpen] = useState(false)

  const updateViewWidth = () => {
    const width = document.getElementsByTagName("body")[0].offsetWidth
    setViewWidth(width)
  }

  const getClassNameByWidth = () => {
    if (viewWidth < 450) return "datepicker-s"
    if (viewWidth < 700) return "datepicker-m"
    return "datepicker-l"
  }

  useEffect(() => {
    updateViewWidth()
  }, [])

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    removeEventListener("resize", updateViewWidth)
    // eslint-disable-next-line no-restricted-globals
    addEventListener("resize", updateViewWidth)
    onChange({
      ...state[0],
      startDate: formatISO(startOfDay(toDate(state[0].startDate))),
      endDate: formatISO(endOfDay(toDate(state[0].endDate))),
    })
  }, [state, onChange])

  const renderDateRange = () => {
    if (viewWidth < 450) {
      return (
        <div style={{ width: "450px" }}>
          <DateRange
            editableDateInputs={true}
            onChange={(item: any) => setState([item.selection])}
            moveRangeOnFirstSelection={false}
            ranges={state}
          />
        </div>
      )
    }

    if (viewWidth < 700) {
      return (
        <div style={{ width: "650px" }}>
          <DateRangePicker
            editableDateInputs={true}
            onChange={(item: any) => setState([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={state}
            direction="horizontal"
          />
        </div>
      )
    }

    return (
      <DateRangePicker
        editableDateInputs={true}
        onChange={(item: any) => setState([item.selection])}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        retainEndDateOnFirstSelection={true}
        months={2}
        ranges={state}
        direction="horizontal"
        // inputRanges={[]} // use this code to hide input range
        staticRanges={customStaticRange ? customRanges : defaultStaticRanges}
      />
    )
  }

  return (
    <div>
      <div className="mb-1 text-sm font-medium opacity-80 peer-disabled:opacity-50">
        Date
      </div>
      <button
        className="peer m-0 box-border h-[43px] w-full rounded-lg border border-solid border-gray-600 border-opacity-20  bg-white p-3 font-body shadow-sm placeholder:text-gray-400 disabled:opacity-60"
        onClick={() => setOpen(true)}
      >
        <div className="align-center flex flex-row justify-between gap-1">
          <div className="text-left min-w-[60%] text-ellipsis overflow-hidden whitespace-nowrap">
            {moment(state[0].startDate).format("YYYY-MM-DD")} -{" "}
            {moment(state[0].endDate).format("YYYY-MM-DD")}
          </div>

          <DateRangeOutlinedIcon style={{ height: "20px", color: "black" }} />
        </div>
      </button>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card className={getClassNameByWidth()}>
          <div className="mt-4">{renderDateRange()}</div>
          <div className="flex p-4 align-end flex flex-row justify-end">
            <div className=" flex w-full">
              <span className="text-sm flex items-center mr-auto">
                <strong>
                  Shows results from{" "}
                  {moment(
                    formatISO(startOfDay(toDate(state[0].startDate)))
                  ).format("DD MMMM YYYY")}{" "}
                  -{" "}
                  {moment(formatISO(endOfDay(toDate(state[0].endDate)))).format(
                    "DD MMMM YYYY"
                  )}
                </strong>
              </span>
              <Button
                className="mr-4"
                variant={"outlined"}
                onClick={() => setOpen(false)}
              >
                <Trans>Close</Trans>
              </Button>
              <Button onClick={() => setOpen(false)}>
                <Trans>Select</Trans>
              </Button>
            </div>
          </div>
        </Card>
      </Modal>
    </div>
  )
}

export default Datepicker
export { initStartDate, initEndDate, initStartDateToday }
