import clsx from "clsx"
import { FC } from "react"
import PayPalLogo from "../assets/paypal-logo.svg"

const PayPalBadge: FC<{
  paymentId?: string
  className?: string
}> = ({ className, paymentId }) => {
  return (
    <div
      className={clsx(
        "flex items-center justify-center rounded-lg bg-gray-100 px-4 py-2",
        className
      )}
    >
      <img src={PayPalLogo} alt={"PayPal"} className={"object-fit h-5 pl-1"} />
      {paymentId && (
        <p className={"m-0 p-0 text-sm leading-none text-gray-700"}>
          {paymentId}
        </p>
      )}
    </div>
  )
}

export default PayPalBadge
