import { SubscriptionPlan } from "../lib/models"
import useSubscriptionPlans from "./api/use-subscription-plans"

const useFindSubscriptionPlanNormalPrice = (plan: SubscriptionPlan) => {
  const { data: plans } = useSubscriptionPlans()
  const monthlyPlans = plans?.filter((p) => p.billingFrequency === "month")

  const planServices = plan.features
    .map((s) => s.id)
    .sort()
    .join("-")

  const equivalentMonthlyPlan = monthlyPlans?.find((mp) => {
    const includedServices = mp.features
      .map((s) => s.id)
      .sort()
      .join("-")
    return includedServices === planServices
  })

  let totalMonths = 1
  if (plan.billingFrequency === "year") totalMonths = 12
  else if (plan.billingFrequency === "every_2_year") totalMonths = 34

  return {
    localPrice: (equivalentMonthlyPlan?.localPrice || 0) * totalMonths,
    usdPrice: (equivalentMonthlyPlan?.price || 0) * totalMonths,
  }
}

export default useFindSubscriptionPlanNormalPrice
