import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { FC } from "react"

const Chart: FC<{
  options: Highcharts.Options
}> = ({ options }) => {
  return <HighchartsReact highcharts={Highcharts} options={options} />
}

export default Chart
