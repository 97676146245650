import React, {
  useState,
  createContext,
  useEffect,
  FC,
  useMemo,
  useCallback,
  useContext,
} from "react"
import { useHistory } from "react-router-dom"
import { useIntercom } from "react-use-intercom"
import apiProvider from "../../utils/apiProvider"
import { getUser } from "../../utils/authProvider"
import { defaultIntercomProps } from "../../lib/intercom"
import useMposSettings, {
  TMPOSSettings,
} from "../../hooks/api/use-mpos-settings"
import useBusiness from "../../hooks/api/use-business"
import useLocations from "../../hooks/api/use-locations"

/**
 * @deprecated please use the swr based use-api.ts and the other api hooks such
 * as use-products.ts instead of manually calling API using useEffect.
 *
 * useApi provides global caching, deduplication, automatic retries, automatic
 * revalidation, offline caching, and more. For more info see {@link https://swr.vercel.app/ | SWR docs}.
 *
 * */
export const ApiContext = createContext<any>(null)

export function ApiProvider({
  children,
}: {
  children: React.ReactElement
}): React.ReactElement {
  const history = useHistory()
  const { boot, shutdown } = useIntercom() as any

  const { data: business } = useBusiness()
  const { data: locations } = useLocations()
  const primaryLocation = locations?.find((el: any) => !!el.isPrimaryLocation)

  return (
    <ApiContext.Provider
      value={{
        businessProfile: business,
        businessLocations: locations,
        currLocation: primaryLocation,
      }}
    >
      {children}
    </ApiContext.Provider>
  )
}

interface IAppConfigValueContext {
  value: {
    mpostConfig?: TMPOSSettings
  }
  onUpdateConfig: (newConf: TMPOSSettings) => void
}

export const AppConfigContext = createContext<IAppConfigValueContext>({
  value: {},
  onUpdateConfig: () => {},
})

export const AppConfigProvider: FC<{
  children: React.ReactElement
}> = ({ children }) => {
  const { data: mposSettings } = useMposSettings()
  const config = mposSettings?.[0] ?? ({} as any)
  const [mpostConfig, setMpostConfig] = useState<TMPOSSettings>(config as any)

  const onUpdateConfig = (newConf: TMPOSSettings) => {
    if (!newConf) return

    const newMPostConfig = {
      ...config,
      ...(newConf ?? {}),
    }

    setMpostConfig(newMPostConfig)
  }

  return (
    <AppConfigContext.Provider
      value={{
        value: {
          mpostConfig,
        },
        onUpdateConfig,
      }}
    >
      {children}
    </AppConfigContext.Provider>
  )
}

export const useAppConfig = () => useContext(AppConfigContext)
