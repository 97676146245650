import ThermalPrinterEncoder, {
  ThermalPrinterEncoderOption,
} from "thermal-printer-encoder"
import { printBorder } from "./helper"

export const encodeBIRXReport = (
  report: {
    storeName: string
    operatedBy: string
    address: string
    vatRegTin: string
    min: string
    serialNo: string
    reportDate: string
    reportTime: string
    startDate: string
    endDate: string

    cashier: string

    begOR: string
    endOR: string

    openingFund: string

    // ---
    // Payment Received
    paymentReceivedBreakdown: Array<{
      paymentMethodCode: string
      sum: number
    }>
    totalPayments: string

    // ---

    void: string

    // ---

    refund: string

    // ---

    withdrawal: string

    // ---
    // Transaction Summary
    cashInDrawer: string
    transactionSummaryBreakdown: Array<{
      paymentMethodCode: string
      sum: number
    }>
    lessWithdrawal: string
    paymentReceived: string

    // ---
    shortOver: string
    // ---
    country?: string
    latestItemsSold?: any[]
    latestItemsRefunded?: any[]
  },
  options?: {
    customEncoder?: ThermalPrinterEncoder
    width?: number
    hideHeader?: boolean
  }
) => {
  const width = options?.width || 32

  let encoder =
    options?.customEncoder ||
    new ThermalPrinterEncoder({
      language: "esc-pos",
      width: options?.width,
    }).initialize()

  if (!options?.hideHeader) {
    encoder = encoder
      .bold(true)
      .align("center")
      .line(report.storeName)
      .line("Operated by: " + report.operatedBy)
      .line(report.address)

    if (report.country === "PH") {
      encoder = encoder
        .line(
          report.vatRegTin
            ? "VAT REG TIN" + report.vatRegTin
            : "NON VAT REG TIN"
        )
        .line("MIN: " + report.min)
        .line("S/N:" + report.serialNo)
    }
  }

  // header
  encoder = encoder
    .newline()
    .align("center")
    .bold(true)
    .line("X-READING REPORT")
    .bold(false)
    .align("left")
    .newline()

  encoder = encoder
    .table(
      [
        { width: width / 2 - 1, marginRight: 2, align: "left" },
        { width: width / 2 - 1, align: "right" },
      ],
      [
        ["Report Date:", report.reportDate],
        ["Report Time:", report.reportTime],
        ["", ""],
        ["Start Date & Time:", report.startDate],
        ["End Date & Time:", report.endDate],
        ["", ""],
        ["Cashier:", report.cashier],
        ["", ""],
        ["Beginning OR:", report.begOR],
        ["Ending OR:", report.endOR],
        ["", ""],
        ["Opening Fund:", report.openingFund],
        ["", ""],
      ]
    )
    .newline()

  encoder = printBorder(encoder, width, { noSpacing: true })
  encoder = encoder
    .line("Payment Received")
    .newline()
    .table(
      [
        { width: width / 2 - 1, marginRight: 2, align: "left" },
        { width: width / 2 - 1, align: "right" },
      ],
      [
        ...report.paymentReceivedBreakdown.map((tender) => [
          tender.paymentMethodCode + ":",
          tender.sum.toFixed(2),
        ]),
        ["Total Payments:", report.totalPayments],
      ]
    )

  encoder = printBorder(encoder, width, { noSpacing: true })
  encoder = encoder.table(
    [
      { width: width / 2 - 1, marginRight: 2, align: "left" },
      { width: width / 2 - 1, align: "right" },
    ],
    [["Void:", report.void]]
  )
  encoder = printBorder(encoder, width, { noSpacing: true })
  encoder = encoder.table(
    [
      { width: width / 2 - 1, marginRight: 2, align: "left" },
      { width: width / 2 - 1, align: "right" },
    ],
    [["Refund:", report.refund]]
  )
  encoder = printBorder(encoder, width, { noSpacing: true })
  encoder = encoder.table(
    [
      { width: width / 2 - 1, marginRight: 2, align: "left" },
      { width: width / 2 - 1, align: "right" },
    ],
    [["Withdrawal:", report.withdrawal]]
  )

  encoder = printBorder(encoder, width, { noSpacing: true })
  encoder = encoder
    .line("Transaction Summary")
    .newline()
    .table(
      [
        { width: width / 2 - 1, marginRight: 2, align: "left" },
        { width: width / 2 - 1, align: "right" },
      ],
      [
        ["Cash in Drawer:", report.cashInDrawer],
        ...report.transactionSummaryBreakdown.map((tender) => [
          tender.paymentMethodCode + ":",
          tender.sum.toFixed(2),
        ]),
        ["Opening Fund:", report.openingFund],
        ["Less Withdrawal:", report.lessWithdrawal],
        ["Payment Received:", report.paymentReceived],
        ["", ""],
      ]
    )
  encoder = printBorder(encoder, width, { noSpacing: true })
  encoder = encoder.table(
    [
      { width: width / 2 - 1, marginRight: 2, align: "left" },
      { width: width / 2 - 1, align: "right" },
    ],
    [["Short/Over:", report.shortOver]]
  )
  encoder = printBorder(encoder, width, { noSpacing: true })

  return encoder.newline().newline().newline()
}
