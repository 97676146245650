import PageHeader from "../../../../components/PageHeader"
import { t } from "i18next"
import useZReportReading from "../../../../hooks/api/use-z-report-reading"
import React, { useState } from "react"
import Datepicker, {
  initEndDate,
  initStartDateToday,
} from "../../../../components/atom/datepicker/datepicker"
import useCashRegister from "../../../../hooks/api/use-cash-register"
import CashRegisterSelect from "../../../../components/CashRegisterSelect"
import { format } from "date-fns"
import { useHistory } from "react-router-dom"

const BIRZReading = () => {
  const history = useHistory()
  const { data: cashRegisters } = useCashRegister()

  const [cashRegisterId, setCashRegisterId] = useState(
    cashRegisters?.[0].id || ""
  )
  const [startDate, setStartDate] = useState<string>(initStartDateToday)
  const [endDate, setEndDate] = useState<string>(initEndDate)

  const { data: report } = useZReportReading(cashRegisterId, startDate, endDate)

  return (
    <div>
      <PageHeader title={t`Z-Reading Reports`} hasGoBack />

      <div className={"flex gap-4 mt-8"}>
        <CashRegisterSelect
          value={cashRegisterId}
          onChange={(e) => setCashRegisterId(e)}
        />

        <Datepicker
          value={{ startDate, endDate }}
          onChange={(e) => {
            setStartDate(e.startDate)
            setEndDate(e.endDate)
          }}
        />
      </div>

      <div className={"bg-white border mt-4 rounded-lg"}>
        <table className={"w-full"}>
          <thead className={"bg-gray-50 border-b"}>
            <tr className={"td:text-left td:px-4 td:py-2"}>
              <td>{t("Close Time")}</td>
              <td>{t("Z-Count")}</td>
              <td>{t("Store")}</td>
              <td>{t("Register")}</td>
              <td>{t("Total Transactions")}</td>
              <td>{t("Gross Sales")}</td>
            </tr>
          </thead>

          <tbody>
            {report?.map((item) => {
              const closeTime = format(new Date(item.closedAt), "yyyy-MM-dd")

              return (
                <tr
                  className="hover:bg-primary-50 cursor-pointer transition-colors"
                  onClick={() => {
                    history.push(
                      `/dashboard/mpos/report/bir-z/details?type=z&cashRegisterId=${cashRegisterId}&logId=${item.id}&closedAt=${item.closedAt}`
                    )
                  }}
                >
                  <td className={"px-4 py-2 text-primary-900 font-bold"}>
                    {closeTime}
                  </td>
                  <td className={"px-4 py-2"}>{item.zCounter}</td>
                  <td className={"px-4 py-2"}>{item.locationName}</td>
                  <td className={"px-4 py-2"}>{item.name}</td>
                  <td className={"px-4 py-2"}>{item.totalTransactions}</td>
                  <td className={"px-4 py-2"}>{item.grossSales}</td>
                </tr>
              )
            })}
          </tbody>
        </table>

        {!report?.length && (
          <div className={"py-16 text-center text-gray-500"}>
            <p>{t`No data available`}</p>
          </div>
        )}
      </div>
    </div>
  )
}

export default BIRZReading
