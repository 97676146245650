import ThermalPrinterEncoder, {
  ThermalPrinterEncoderOption,
} from "thermal-printer-encoder"
import { printBorder } from "./helper"
import { GetBIRJournalResponse } from "../../hooks/api/use-bir-journal"
import { BIR_LABEL } from "../models"
import { encodeBIRZReport } from "./bir-z-report"
import { format, parseISO } from "date-fns"
import Big from "big.js"
import { encodeBIRXReport } from "./bir-x-report"

export const encodeBIRJournalHeader = (
  details: {
    storeName: string
    address: string
    currentDate: string
    startDate: string
    endDate: string
    employeeName: string
    customerName: string
    cashRegisterName: string
    transaction: string
    transactionType: string
    paymentMethod: string
    transactions: Array<
      GetBIRJournalResponse[number] & {
        orderStatus: string
        receiptType: string
        totalIncludedTaxes: number
        date: string
        vatSales: number
        vatExemptSales: number
        totalTax: number
        totalTaxExemption: number
        totalTaxApplied: number
        zeroRatesSales: number
      }
    >
  },
  encoderOptions: ThermalPrinterEncoderOption = {
    language: "esc-pos",
    width: 32,
  }
) => {
  const width = encoderOptions.width || 32

  // Initialize a single encoder instance
  let encoder = new ThermalPrinterEncoder(encoderOptions).initialize()

  // header
  encoder = encoder
    .bold(true)
    .align("center")
    .line("Account Journal")
    .newline()
    .line(details.storeName)
    .bold(false)
    .line(details.address)
    .align("left")

  // filters
  encoder = printBorder(encoder, width)
  encoder = encoder.table(
    [
      { width: width / 2 - 1, marginRight: 2, align: "left" },
      { width: width / 2 - 1, align: "left" },
    ],
    [
      [
        `Date: ${details.currentDate}`,
        `Cash Register: ${details.cashRegisterName}`,
      ],
      [
        `Period Covered: ${details.startDate} - ${details.endDate}`,
        `Transaction: ${details.transaction}`,
      ],
      [
        `Staff: ${details.employeeName}`,
        `Transaction Type: ${details.transactionType}`,
      ],
      [
        `Customer: ${details.customerName}`,
        `Payment Method: ${details.paymentMethod}`,
      ],
    ]
  )

  // invoices
  details.transactions.forEach((trx) => {
    if (trx.rowType === "TRANSACTION") {
      encoder = encodeTransactionItem(trx, encoder, width)
    } else if (trx.rowType === "BIR_Z_REPORT" && trx.birZReport) {
      const report = trx.birZReport
      const netSales = Big(report?.grossSales || 0)
        .minus(report?.discount || 0)
        .minus(report?.refunds || 0)
        .minus(report?.vatAmount || 0)
        .round(2)
        .toNumber()
      encoder = printBorder(encoder, width)
      encoder = encodeBIRZReport(
        {
          storeName: details.storeName || "",
          operatedBy:
            report.cashRegisterLog?.cashRegister?.location?.companyName || "",
          address: details.address || "",
          vatRegTin: report.cashRegisterLog?.cashRegister?.location?.tin || "",
          min: report.cashRegisterLog?.cashRegister?.min || "",
          serialNo: report.cashRegisterLog?.cashRegister?.serialNo || "",
          reportDate: format(parseISO(trx.created_at), "MMMM dd, yyyy"),
          reportTime: format(parseISO(trx.created_at), "hh:mm a"),
          startDate: format(parseISO(report?.openedAt), "dd/MM/yy hh:mm a"),
          endDate: format(parseISO(report?.closedAt), "dd/MM/yy hh:mm a"),

          begSI: report.beginningOR.toString(),
          endSI: report.endingOR.toString(),
          begVoid: report.beginningVoid,
          endVoid: report.endingVoid,
          resetCounterNo: "0",
          zCounterNo: report.zCounter,
          presentAccumulatedSales: "0",
          previousAccumulatedSales: "0",
          salesForTheDay: report.sales.toFixed(2),

          vatableSales: report.vatableSales.toFixed(2),
          vatAmount: report.vatAmount.toFixed(2),
          vatExemptSales: "0",
          zeroRatedSales: report.zeroRatedSales.toFixed(2),

          grossAmount: report.grossSales.toFixed(2),
          lessDiscount: report.discount.toFixed(2),
          lessReturn: report.lessReturn.toFixed(2),
          lessVoid: "0",
          lessVatAdjustment: report.lessVat.toFixed(2),
          netAmount: netSales.toFixed(2),

          scDisc: report.seniorCitizenDiscount.toFixed(2),
          pwdDisc: report.pwdDiscount.toFixed(2),
          naacDisc: report.nationalAthleteDiscount.toFixed(2),
          soloParentDisc: "0",
          otherDisc: report.discount.toFixed(2),

          void: (report.refunds + report.void).toFixed(2),

          scTrans: report.seniorCitizenTrans.toFixed(2),
          pwdTrans: report.pwdTrans.toFixed(2),
          regDiscTrans: report.regularDiscountTrans.toFixed(2),
          zeroRatedTrans: report.zeroRatedTrans.toFixed(2),
          vatOnReturn: report.vatOnReturn?.toFixed(2) || "0",
          otherVatAdjustments: report.otherVATAdjustment.toFixed(2),

          cashInDrawer: "0",
          tenderBreakdown: report.tenderBreakdown,
          openingFund: report.openingFund.toFixed(2),
          lessWithdrawal: report.lessWithdrawal?.toFixed(2) || "0",
          paymentReceived: report.paymentReceived.toFixed(2),

          shortOver: "0",
        },
        {
          width,
          customEncoder: encoder,
          hideHeader: true,
        }
      )
    } else if (trx.rowType === "BIR_X_REPORT" && trx.birXReport) {
      const report = trx.birXReport
      encoder = printBorder(encoder, width)
      encoder = encodeBIRXReport(
        {
          storeName: details.storeName || "",
          operatedBy:
            report.cashRegisterLog?.cashRegister?.location?.companyName || "",
          address: details.address || "",
          vatRegTin: report.cashRegisterLog?.cashRegister?.location?.tin || "",
          min: report.cashRegisterLog?.cashRegister?.min || "",
          serialNo: report.cashRegisterLog?.cashRegister?.serialNo || "",
          reportDate: format(parseISO(trx.created_at), "MMMM dd, yyyy"),
          reportTime: format(parseISO(trx.created_at), "hh:mm a"),
          startDate: format(parseISO(report.openedAt), "dd/MM/yy hh:mm a"),
          endDate: format(parseISO(report.closedAt), "dd/MM/yy hh:mm a"),

          cashier: report?.openedBy,

          begOR: report?.beginningOR.toString(),
          endOR: report?.endingOR.toString(),

          openingFund: "0",

          paymentReceivedBreakdown: report.tenderBreakdown,
          totalPayments: report.tenderBreakdown
            .reduce((acc, item) => acc + item.sum, 0)
            ?.toFixed(2),

          void: "0",

          refund: report.refundAmount?.toFixed(2) || "0",

          withdrawal: report.withdrawal?.toFixed(2) || "0",

          cashInDrawer: "0",
          transactionSummaryBreakdown: report.tenderBreakdown,
          lessWithdrawal: report.lessWithdrawal?.toFixed(2) || "0",
          paymentReceived: report.paymentReceived.toFixed(2),

          shortOver: "0",
        },
        {
          width,
          customEncoder: encoder,
          hideHeader: true,
        }
      )
    }
  })

  encoder = encoder
    .align("center")
    .line("---- END ----")
    .newline()
    .newline()
    .newline()

  return encoder.encode()
}

const encodeTransactionItem = (
  trx: GetBIRJournalResponse[number] & {
    orderStatus: string
    receiptType: string
    totalIncludedTaxes: number
    date: string
    vatSales: number
    vatExemptSales: number
    totalTax: number
    totalTaxExemption: number
    totalTaxApplied: number
    zeroRatesSales: number
  },
  encoder: ThermalPrinterEncoder,
  width: number
) => {
  encoder = printBorder(encoder, width)
  encoder = encoder
    .table(
      [
        { width: width / 2 - 1, marginRight: 2, align: "left" },
        { width: width / 2 - 1, align: "left" },
      ],
      [
        [`Invoice No. ${trx.ORNumber}`, `Trans No. ${trx.seriesNumber}`],
        [`Date: ${trx.date}`, `Cash Register: ${trx.cashRegister?.name}`],
        [`Staff: ${trx.cashierName}`, `Transaction: ${trx.receiptType}`],
        [
          `Customer: ${trx.customerName}`,
          `Transaction Type: ${trx.receiptType}`,
        ],
      ]
    )
    .newline()

  encoder = encoder
    .table(
      [
        { width: 20 - 1, marginRight: 2, align: "left" },
        { width: width - 20 - 1, align: "right" },
      ],
      [
        ["Item(s)", trx.orderedProducts.length.toString()],
        ...trx.orderedProducts.map((p) => {
          let name = `${p.name} - ${p.quantity}x @${p.sellingPrice}`

          return [name, p.sellingPrice.toString()]
        }),
      ]
    )
    .newline()

  let summaryDetails = [
    [`Subtotal`, (trx.totalCartAmount + trx.totalIncludedTaxes).toString()],
    [`Total Sales`, trx.totalCartAmount.toString()],
  ]
  if (trx.discount) {
    summaryDetails.push([
      (BIR_LABEL[trx.promotionDetails?.[0]?.code || ""] || "Discount") +
        `- ${trx.promotionDetails?.[0]?.discountPercentage}%`,
      trx.nonTaxDiscounts.toString(),
    ])
  }
  trx.taxes.forEach((t) => {
    summaryDetails.push([
      `- ${t.name} ${t.rate}% ${t.type === "INCLUDED" ? "(Included)" : ""}`,
      t.amount.toString(),
    ])
  })
  summaryDetails.push(["Total amount due", trx.grandTotal.toString()])
  encoder = encoder
    .table(
      [
        { width: 20 - 1, marginRight: 2, align: "left" },
        { width: width - 20 - 1, align: "right" },
      ],
      summaryDetails
    )
    .newline()
    .line(
      `Payment Method: ${
        trx.paymentMethodCode === "CUSTOM"
          ? trx.paymentChannel?.toLowerCase()
          : trx.paymentMethodCode.toLowerCase()
      }`
    )
    .newline()
    .table(
      [
        { width: width / 2 - 1, marginRight: 2, align: "left" },
        { width: width / 2 - 1, align: "right" },
      ],
      [
        ["Cash Received", trx.cashReceived.toString()],
        ["Cash Change", trx.cashChange.toString()],
      ]
    )
    .newline()

  encoder = encoder
    .table(
      [
        { width: width / 4, align: "left" },
        { width: width / 4, align: "right" },
        { width: width / 4, align: "right" },
        { width: width / 4, align: "right" },
      ],
      [
        ["", "Net.Amt", "VAT", "Amount"],
        [
          "VATABLE",
          trx.vatSales.toFixed(2),
          trx.totalTaxApplied.toFixed(2),
          (trx.vatSales + trx.totalTaxApplied).toFixed(2),
        ],
        [
          "EXEMPT",
          trx.vatExemptSales.toFixed(2),
          trx.totalTaxExemption.toFixed(2),
          (trx.vatExemptSales + trx.totalTaxExemption).toFixed(2),
        ],
        [
          "ZERO-R",
          trx.zeroRatesSales.toFixed(2),
          "0.00",
          trx.zeroRatesSales.toFixed(2),
        ],
      ]
    )
    .newline()

  return encoder
}
