import React, {
  ChangeEvent,
  ChangeEventHandler,
  FC,
  FormEventHandler,
  useState,
} from "react"
import useBusiness from "../hooks/api/use-business"
import usePaymentMethod from "../hooks/api/use-payment-method"
import { useShowSnackbar } from "./Snackbar"
import {
  postDiglogPaymentsMethod,
  putDiglogPaymentsMethod,
  uploadBusinessDocument,
} from "../lib/diglog-payments"
import { t } from "i18next"
import { alphaNumericNanoid } from "../lib/nanoid"
import { AxiosError } from "axios"
import { Trans } from "react-i18next"
import useAvailablePaymentMethods from "../hooks/api/use-available-payment-methods"
import Input from "./Input"
import IconButton from "./IconButton"
import { DeleteForever } from "@mui/icons-material"
import Button from "./Button"
import Select from "./Select"
import useFlutterwaveBanksBranches from "../hooks/api/use-flutterwave-banks-branches"
import useFlutterwaveBanks from "../hooks/api/use-flutterwave-banks"
import Skeleton from "./Skeleton"
import ToolTipComponent from "./ToolTipComponent"

const GATEWAY_CODE = {
  flutterwave: "FLUTTERWAVE",
  ipayafrica: "IPAY_AFRICA",
  razer: "RAZER",
  "ipay-id": "IPAY_ID",
  DPO: "DPO",
}

const FLUTTERWAVE_COUNTRY = [
  { value: "CI", label: "Ivory Coast" },
  { value: "EG", label: "Egypt" },
  { value: "GH", label: "Ghana" },
  { value: "RW", label: "Rwanda" },
  { value: "TZ", label: "Tanzania" },
  { value: "MA", label: "Morocco" },
  { value: "MW", label: "Malawi" },
  { value: "NG", label: "Nigeria" },
  { value: "CM", label: "Cameroon" },
  { value: "ZM", label: "Zambia" },
  { value: "ZA", label: "South Africa" },
]

const DiglogPaymentKYCForm: FC<{
  onSaved?: () => void
  onCreated?: () => Promise<void>
  enabledPaymentMethods?: Array<"bank" | "ewallet">
}> = ({ onSaved, onCreated, enabledPaymentMethods }) => {
  const showSnackbar = useShowSnackbar()

  const { data: paymentMethod, mutate } = usePaymentMethod()
  const { data: business } = useBusiness()
  const { data: pos } = useAvailablePaymentMethods("pos")
  const { data: online } = useAvailablePaymentMethods("onlineCheckout")
  const providers = pos && online ? [...pos, ...online] : []

  const [fileUploadLoading, setFileUploadLoading] = useState(false)
  const [registeredCompanyName, setRegisteredCompanyName] = useState(
    paymentMethod?.diglogPayment?.registeredCompanyName ?? ""
  )
  const [companyRegistrationNumber, setCompanyRegistrationNumber] = useState(
    paymentMethod?.diglogPayment?.companyRegistrationNumber ?? ""
  )
  const [monthlyRevenue, setMonthlyRevenue] = useState(
    paymentMethod?.diglogPayment?.monthlyRevenue ?? ""
  )
  const [businessDocumentUrl, setBusinessDocumentUrl] = useState(
    paymentMethod?.diglogPayment?.businessDocumentUrl ?? ""
  )
  const [bankAccountNumber, setBankAccountNumber] = useState(
    paymentMethod?.diglogPayment?.bankAccountNumber ?? ""
  )
  const [bankName, setBankName] = useState(
    paymentMethod?.diglogPayment?.bankName ?? ""
  )
  const [bankSwiftCode, setBankSwiftCode] = useState(
    paymentMethod?.diglogPayment?.bankSwiftCode ?? ""
  )
  const [bankBranchCode, setBankBranchCode] = useState(
    paymentMethod?.diglogPayment?.bankBranchCode ?? ""
  )
  const [bankRoutingNumber, setBankRoutingNumber] = useState(
    paymentMethod?.diglogPayment?.bankRoutingNumber ?? ""
  )

  const [withdrawalMethod, setWithdrawalMethod] = useState(
    paymentMethod?.diglogPayment?.paymentDetails ? "ewallet" : "bank"
  )
  const [walletName, setWalletName] = useState(
    paymentMethod?.diglogPayment?.paymentDetails?.[0]?.walletName ?? ""
  )
  const [walletEmail, setWalletEmail] = useState(
    paymentMethod?.diglogPayment?.paymentDetails?.[0]?.email ?? ""
  )
  const [walletPhone, setWalletPhone] = useState(
    paymentMethod?.diglogPayment?.paymentDetails?.[0]?.phone ?? ""
  )
  const [accountNumber, setAccountNumber] = useState(
    paymentMethod?.diglogPayment?.paymentDetails?.[0]?.accountNumber ?? ""
  )

  const hasFlutterwave = providers.find((m) => m.code.includes("flutterwave"))
  const hasIpayAfrica = providers.find((m) => m.code.includes("ipayafrica"))
  const hasIpayId = providers.find((m) => m.code.includes("ipay-id"))
  const hasRazer = providers.find((m) => m.code.includes("razer"))
  const hasDPO = providers.find((m) => m.code.includes("dpo"))
  let gateway = ""
  if (hasDPO) {
    gateway = GATEWAY_CODE.DPO
  } else if (hasFlutterwave) {
    gateway = GATEWAY_CODE.flutterwave
  } else if (hasIpayAfrica) {
    gateway = GATEWAY_CODE.ipayafrica
  } else if (hasIpayId) {
    gateway = GATEWAY_CODE["ipay-id"]
  } else if (hasRazer) {
    gateway = GATEWAY_CODE.razer
  }

  const validateFields = () => {
    let message = null
    // if (bankRoutingNumber == "") {
    //   message = "Routing number is required"
    // }
    // if (bankBranchCode == "") {
    //   message = "Branch code is required"
    // }
    // if (bankSwiftCode == "") {
    //   message = "Swift code is required"
    // }
    // if (bankAccountNumber == "") {
    //   message = "Bank Account Number is required"
    // }
    // if (bankName == "") {
    //   message = "Bank Name is required"
    // }
    if (!businessDocumentUrl) {
      message = "Supporting Document is required"
    }
    if (monthlyRevenue == "") {
      message = "Estimated Monthly Revenue is required"
    }
    if (companyRegistrationNumber == "") {
      message = "Business Registration Number is required"
    }
    if (registeredCompanyName == "") {
      message = "Registered Company Name is required"
    }
    if (message != null) {
      showSnackbar(message, "error")
      return
    }
  }

  const handleSubmit: FormEventHandler = async (e) => {
    e.preventDefault()
    validateFields()
    // if (
    //   !registeredCompanyName ||
    //   !companyRegistrationNumber ||
    //   !monthlyRevenue ||
    //   !businessDocumentUrl ||
    //   !bankAccountNumber ||
    //   !bankName
    // ) {
    //   showSnackbar(t("Please fill in all required fields"), "error")
    //   return
    // }

    if (gateway.length === 0) {
      showSnackbar(t("Please select at least one payment gateway"), "error")
      return
    }

    try {
      const payload = {
        ...paymentMethod?.diglogPayment,
        registeredCompanyName,
        companyRegistrationNumber,
        monthlyRevenue,
        businessDocumentUrl,
        bankAccountNumber,
        bankName,
        bankRoutingNumber,
        bankSwiftCode,
        bankBranchCode,
        externalId: undefined as string | undefined,
        paymentAccountMethods: [{ paymentGatewayType: gateway }],
        paymentDetails: !walletName
          ? null
          : [
              {
                walletName,
                email: walletEmail,
                phone: walletPhone,
                accountNumber,
              },
            ],
      }

      let result: any
      if (paymentMethod?.diglogPayment) {
        result = await putDiglogPaymentsMethod(paymentMethod.id, payload)
        await mutate()
      } else {
        if (business?.country.codeAlpha2 === "KE") {
          payload.externalId = alphaNumericNanoid()
        }
        result = await postDiglogPaymentsMethod(payload)
        if (onCreated) await onCreated()
        await mutate()
      }

      const errors = Object.keys(result.data.errors ?? {})
      if (errors.length > 0) {
        showSnackbar(
          t(
            result.data.errors?.[errors[0] as "FLUTTERWAVE"]?.message ??
              "Failed to update payment gateway"
          ),
          "error"
        )
      } else {
        showSnackbar(t("DizLog payments is now active."))
      }
      onSaved?.()
    } catch (e) {
      if (e instanceof AxiosError) {
        showSnackbar(e.response?.data.message, "error")
      } else {
        showSnackbar(t("Something went wrong, please try again."), "error")
      }
    }
  }

  const uploadKycDoc: FormEventHandler = async (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault()

    const file = e.target.files?.[0]
    if (!file) {
      showSnackbar(t("Please select a file"), "error")
    } else {
      setFileUploadLoading(true)
      try {
        const fileUpload = await uploadBusinessDocument(file)
        setBusinessDocumentUrl(fileUpload.data.url)
      } catch (e) {
        showSnackbar(t("Something went wrong, please try again."), "error")
      } finally {
        setFileUploadLoading(false)
      }
    }
  }

  return (
    <form
      className="h-full max-w-md border-y-0 border-l-0 border-r border-solid border-gray-200 bg-zinc-50 p-8"
      onSubmit={handleSubmit}
    >
      <div className={"prose mb-8"}>
        <h4 className={"text-gray-700"}>
          <Trans>Business Details</Trans>
        </h4>

        <p>
          <Trans>
            To start accepting payments from your customers, please provide
            basic information about your business and how the payment method you
            want to use to accept payment.
          </Trans>
        </p>
      </div>

      <Input
        required
        label={t("Business Name")}
        containerClassName={"mb-4"}
        value={registeredCompanyName}
        onChange={(e) => setRegisteredCompanyName(e.target.value)}
      />

      <Input
        required
        label={t("Registration Number")}
        containerClassName={"mb-4"}
        value={companyRegistrationNumber}
        onChange={(e) => setCompanyRegistrationNumber(e.target.value)}
        toolTipContent={`
        <p>If not available use individual ID</p>
        `}
      />

      <Input
        required
        label={t("Estimated Monthly Sales")}
        containerClassName={"mb-6"}
        value={monthlyRevenue}
        onChange={(e) => setMonthlyRevenue(e.target.value)}
        type={"number"}
        trailingElement={
          <p className={"font-medium text-gray-400"}>
            {business?.country.currency}
          </p>
        }
      />

      <div className={"mb-4"}>
        <label className="block text-sm font-medium text-gray-700">
          Supporting Document
          <ToolTipComponent
            toolTipContent={`<p>
                Attach at least one of these docs - business registration, tax
                returns, address proof, personal ID  (image/pdf/docs)
              </p>`}
          />
        </label>

        {businessDocumentUrl && (
          <div className="mt-2 flex items-center rounded-lg border border-solid border-gray-200 bg-white text-sm shadow-sm">
            <p className={"m-0 ml-4 p-0"}>Document</p>

            <IconButton
              className={"my-1 ml-auto mr-1 text-red-700"}
              onClick={() => setBusinessDocumentUrl("")}
            >
              <DeleteForever className={"!h-6 !w-6"} />
            </IconButton>
          </div>
        )}

        {!fileUploadLoading && !businessDocumentUrl && (
          <div className="mt-1 flex items-center">
            <label className="block">
              <span className="sr-only">Choose supporting document</span>
              <input
                required
                onChange={uploadKycDoc}
                accept="image/*, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword"
                type="file"
                className="
                m-0 -ml-1 block w-full px-0 text-sm text-slate-500
                file:mr-4 file:rounded-full file:border file:border-solid file:border-primary-200
                file:bg-primary-50 file:px-4
                file:py-2 file:text-sm
                file:font-semibold file:text-primary-900
                hover:file:bg-primary-100
                "
              />
            </label>
          </div>
        )}

        {fileUploadLoading && (
          <Button
            disabled
            variant={"outlined"}
            className="mt-3 !h-auto !border bg-primary-50 !px-4 !py-3 normal-case"
          >
            <Trans>Uploading...</Trans>
          </Button>
        )}
      </div>

      <div className={"prose mb-4 mt-10"}>
        <h4 className={"text-gray-700"}>
          <Trans>Payment Details</Trans>
        </h4>
        <p>
          <Trans>
            We use the information below to transfer money to your account
            received from customer – please ensure this is accurate information.
          </Trans>
        </p>
      </div>

      {gateway !== GATEWAY_CODE.flutterwave && !enabledPaymentMethods && (
        <>
          <Select
            label={"Payment method"}
            containerClassName={"mb-4"}
            value={withdrawalMethod}
            onChange={(e) => {
              setWithdrawalMethod(e.target.value)
            }}
          >
            <option value={"bank"}>Bank Transfer</option>
            <option value={"ewallet"}>eWallet</option>
          </Select>

          {withdrawalMethod === "bank" && (
            <>
              <Input
                required
                label={t("Bank Name")}
                containerClassName={"mb-4"}
                value={bankName}
                onChange={(e) => setBankName(e.target.value)}
              />

              <Input
                required
                label={t("Bank Account Number")}
                containerClassName={"mb-6"}
                value={bankAccountNumber}
                onChange={(e) => setBankAccountNumber(e.target.value)}
              />

              <Input
                label={t("Swift Code")}
                containerClassName={"mb-6"}
                value={bankSwiftCode}
                onChange={(e) => setBankSwiftCode(e.target.value)}
              />

              <Input
                label={t("Branch Code")}
                containerClassName={"mb-6"}
                value={bankBranchCode}
                onChange={(e) => setBankBranchCode(e.target.value)}
              />

              <Input
                label={t("Routing Number")}
                containerClassName={"mb-6"}
                value={bankRoutingNumber}
                onChange={(e) => setBankRoutingNumber(e.target.value)}
              />
            </>
          )}

          {withdrawalMethod === "ewallet" && (
            <>
              <Input
                required
                label={t("Wallet Name")}
                containerClassName={"mb-4"}
                value={walletName}
                onChange={(e) => setWalletName(e.target.value)}
              />

              <Input
                required
                label={t("Email")}
                containerClassName={"mb-6"}
                value={walletEmail}
                onChange={(e) => setWalletEmail(e.target.value)}
              />

              <Input
                label={t("Phone")}
                containerClassName={"mb-6"}
                value={walletPhone}
                onChange={(e) => setWalletPhone(e.target.value)}
              />

              <Input
                label={t("Account Number")}
                containerClassName={"mb-6"}
                value={accountNumber}
                onChange={(e) => setAccountNumber(e.target.value)}
              />
            </>
          )}
        </>
      )}

      {gateway !== GATEWAY_CODE.flutterwave && enabledPaymentMethods && (
        <>
          {enabledPaymentMethods.includes("bank") && (
            <>
              <div className={"prose mb-4 mt-10"}>
                <h4 className={"text-gray-700"}>
                  <Trans>Bank Transfer</Trans>
                </h4>
              </div>

              <>
                <Input
                  required
                  label={t("Bank Name")}
                  containerClassName={"mb-4"}
                  value={bankName}
                  onChange={(e) => setBankName(e.target.value)}
                />

                <Input
                  required
                  label={t("Bank Account Number")}
                  containerClassName={"mb-6"}
                  value={bankAccountNumber}
                  onChange={(e) => setBankAccountNumber(e.target.value)}
                />

                <Input
                  label={t("Swift Code")}
                  containerClassName={"mb-6"}
                  value={bankSwiftCode}
                  onChange={(e) => setBankSwiftCode(e.target.value)}
                />

                <Input
                  label={t("Branch Code")}
                  containerClassName={"mb-6"}
                  value={bankBranchCode}
                  onChange={(e) => setBankBranchCode(e.target.value)}
                />

                <Input
                  label={t("Routing Number")}
                  containerClassName={"mb-6"}
                  value={bankRoutingNumber}
                  onChange={(e) => setBankRoutingNumber(e.target.value)}
                />
              </>
            </>
          )}

          {enabledPaymentMethods.includes("ewallet") && (
            <>
              <div className={"prose mb-4 mt-10"}>
                <h4 className={"text-gray-700"}>
                  <Trans>E-Wallet</Trans>
                </h4>
              </div>

              <>
                <Input
                  required
                  label={t("Wallet Name")}
                  containerClassName={"mb-4"}
                  value={walletName}
                  onChange={(e) => setWalletName(e.target.value)}
                />

                <Input
                  required
                  label={t("Email")}
                  containerClassName={"mb-6"}
                  value={walletEmail}
                  onChange={(e) => setWalletEmail(e.target.value)}
                />

                <Input
                  label={t("Phone")}
                  containerClassName={"mb-6"}
                  value={walletPhone}
                  onChange={(e) => setWalletPhone(e.target.value)}
                />

                <Input
                  label={t("Account Number")}
                  containerClassName={"mb-6"}
                  value={accountNumber}
                  onChange={(e) => setAccountNumber(e.target.value)}
                />
              </>
            </>
          )}
        </>
      )}

      {business && gateway === GATEWAY_CODE.flutterwave && (
        <>
          <BankSelect
            country={business.country.codeAlpha2}
            value={bankName}
            onChange={(id) => {
              setBankName(id)
            }}
            containerClassName={"mb-6"}
          />

          {bankName && (
            <BankBranchesSelect
              bankId={bankName}
              value={bankBranchCode}
              onChange={(e) => setBankBranchCode(e.target.value)}
              containerClassName={"mb-6"}
            />
          )}

          <Input
            required
            label={t("Bank/Mobile Money Account Number")}
            containerClassName={"mb-6"}
            value={bankAccountNumber}
            onChange={(e) => setBankAccountNumber(e.target.value)}
            toolTipContent={`
            <p>Enter the Bank account or Mobile Money account</p>
            `}
          />
        </>
      )}

      <Button
        onClick={() => validateFields()}
        type="submit"
        className={"w-full"}
      >
        {paymentMethod?.diglogPayment ? t("Update") : t("Save")}
      </Button>
    </form>
  )
}

const BankSelect: FC<{
  country: string
  value?: string
  onChange?: (id: string) => void
  containerClassName?: string
}> = ({ country, value, onChange, containerClassName }) => {
  const { data: banks } = useFlutterwaveBanks(country)

  return (
    <Select
      label={"Bank/Mobile Money Name"}
      value={value}
      onChange={(e) => {
        if (onChange) onChange(e.target.value)
      }}
      containerClassName={containerClassName}
      required
      toolTipContent={`
        <p>Pick from list the bank name or Mobile Money</p>
      `}
    >
      <option>Select a bank</option>
      {banks?.map((bank) => (
        <option key={bank.id} value={bank.id}>
          {bank.name}
        </option>
      ))}
    </Select>
  )
}

const BankBranchesSelect: FC<{
  bankId: string
  value?: string
  onChange?: ChangeEventHandler<HTMLSelectElement>
  containerClassName?: string
}> = ({ bankId, value, onChange, containerClassName }) => {
  const {
    data: branches,
    error,
    isLoading,
  } = useFlutterwaveBanksBranches(bankId)

  if (error) return null

  if (isLoading)
    return (
      <div className="mb-6 h-[65px] text-primary-900">
        <Skeleton className={"mb-1 h-[18px] w-32"} />
        <Skeleton className={"h-[43px] w-full !rounded-lg"} />
      </div>
    )

  return (
    <Select
      label={"Branch Name"}
      value={value}
      onChange={(e) => {
        if (onChange) onChange(e)
      }}
      containerClassName={containerClassName}
      required
      toolTipContent={`
        <p>If you use Mobile money, select the Mobile money operator else leave blank</p>
      `}
    >
      <option>Select a bank</option>
      {branches?.map((branch) => (
        <option key={branch.id} value={branch.id}>
          {branch.branch_name}
        </option>
      ))}
    </Select>
  )
}

export default DiglogPaymentKYCForm
