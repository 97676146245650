import { deleteAPI, ErrorResponse, oldGetAPI, patchAPI, postAPI } from "./api"
import { PRODUCT_TYPE } from "./models"
import { patchInventory, postNewInventory } from "./inventory"
import { GetAllCategoryResponse } from "../hooks/api/use-all-product-categories"

interface PostNewInventoryCategoryResponse {
  id: string
  name: string
  description: any
  productType: string
  avatar: string
  code: number
  isDefault: boolean
  isLowestLevel: boolean
  createdAt: string
  updatedAt: string
  imageUrl: string
  useProduction: boolean
}

export const postNewInventoryCategory = async (data: {
  name: string
  productType: PRODUCT_TYPE
  isVisible: boolean
  isVisibleOnEstore: boolean
  imageUrl?: string
  icon?: string
  shape?: string
  color?: string
  parentCategory?: string | null
  useProduction?: boolean
}) => {
  let inventoryId = ""
  if (!data.parentCategory) {
    const inventory = await postNewInventory(data)
    if (inventory.status !== 201) return null

    inventoryId = inventory.data.id
  }

  const category = await postAPI<PostNewInventoryCategoryResponse>(
    `/product-category`,
    {
      name: data.name,
      productType: data.productType,
      imageUrl: data.imageUrl,
      inventory: { id: inventoryId },
      origin: "web",
      icon: data.icon,
      shape: data.shape,
      color: data.color,
      parentCategory: data.parentCategory,
      useProduction: data.useProduction,
    }
  )

  return {
    inventoryId: inventoryId,
    categoryId: category.data.id,
    productType: category.data.productType,
    name: category.data.name,
    useProduction: category.data.useProduction,
  }
}

/** @deprecated use patchCategory, since we now use category as the primary object */
export const patchInventoryCategory = async (
  id: string,
  data: {
    name: string
    productType?: PRODUCT_TYPE
    isVisible: boolean
    imageUrl?: string
    icon?: string
    shape?: string
    color?: string
    parentCategory?: string | null
    useProduction?: boolean
  }
) => {
  const category = await getCategoryByInventoryId(id)
  if (!category) return null

  const inventory = await patchInventory(id, data)
  if (inventory.status === 200) {
    return patchAPI(`/product-category`, category.id, data)
  }

  return inventory
}

export const patchCategory = async (
  categoryId: string,
  inventoryId: string,
  data: {
    name: string
    productType?: PRODUCT_TYPE
    isVisible: boolean
    isVisibleOnEstore: boolean
    imageUrl?: string
    icon?: string
    shape?: string
    color?: string
    parentCategory?: string | null
  }
) => {
  const [category] = await Promise.all([
    patchAPI(`/product-category`, categoryId, data),
    patchInventory(inventoryId, data),
  ])
  return category
}

export const deleteInventoryCategory = async (id: string) => {
  return deleteAPI<ErrorResponse>(`/inventory`, id)
}

export const deleteCategory = async (id: string) => {
  return deleteAPI<ErrorResponse>(`/product-category`, id)
}

export const getCategoryByInventoryId = async (
  inventoryId: string
): Promise<{ id: string } | null> => {
  const category: Array<{ id: string }> = await oldGetAPI(
    `/product-category?inventoryId=${inventoryId}`
  )

  if (category.length === 0) return null

  return category[0]
}

export const getCategoryById = async (
  id: string
): Promise<{ id: string } | null> => {
  const category: Array<{ id: string }> = await oldGetAPI(
    `/product-category/${id}`
  )

  if (category.length === 0) return null

  return category[0]
}

export const searchCategoryById = (
  id?: string,
  category: GetAllCategoryResponse = []
): GetAllCategoryResponse[number] | undefined => {
  if (!id) return undefined

  const result = category.find((item) => item.id === id)
  if (result) {
    return result
  }

  for (let i = 0; i < category.length; i++) {
    if ((category[i].childrenCategories?.length ?? 0) > 0) {
      const c = searchCategoryById(id, category[i].childrenCategories)
      if (c) {
        return c
      }
    }
  }

  return undefined
}
