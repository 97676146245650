import useAPI from "./use-api"

export type Employee = {
  id: string
  phone?: string
  pushToken: any
  targetArn: any
  employmentType: string
  avatar?: string
  fullName: string
  email?: string
  nationalId: string
  staffId: string
  dob?: string
  addressLine1: string
  addressLine2: string
  city: string
  state: any
  pin: any
  zip: string
  designation: any
  isManualAddTimesheet: boolean
  isManager: boolean
  isActive: boolean
  startDate?: string
  lastDate?: string
  lang: string
  deletedAt: any
  createdAt: string
  updatedAt: string
  userId?: string
  location?: Location
  workLocation?: Location
  shifts: Shift[]
  country: Country
  payroll?: Payroll
  earnings: any[]
  deductions: Deduction[]
  reimbursements: Reimbursement[]
  role: Role[]
  locations?: Location[]
  permissions?: any
}

type GetEmployeeAPIResponse = Array<Employee>

interface Location {
  id: string
  name: string
  addressLine1: string
  addressLine2: string
  city: string
  state: string
  zip: string
  isPrimaryLocation: boolean
  createdAt: string
  updatedAt: string
}

interface Shift {
  id: string
  shift: Shift2
}

interface Shift2 {
  id: string
  hours: number
  startAt: string
  endAt: string
  fromDate: string
  toDate: string
  label: string
  color: string
  createdAt: string
  updatedAt: string
}

interface Country {
  id: number
  name: string
  label: string
  code: string
  phoneCode: string
  currency: string
  codeAlpha2: string
  tax: number
  serviceCharge: number
  countryGroup: number
}

interface Payroll {
  id: string
  weekWorkdays: any
  paymentMethod: string
  organizationWorkingDays: number
  isAnnualSalary: boolean
  paySchedule: string
  currency: string
  value: string
  openDeposit: string
  bankAccount: string
  createdAt: string
  updatedAt: string
}

interface Deduction {
  id: string
  value: string
  payPeriod: string
  createdAt: string
  updatedAt: string
  deduction: Deduction2
}

interface Deduction2 {
  id: string
  name: string
  calculationBasis: string
  payPeriod: any
  percentageBasis: any
  value: string
  isMandatory: boolean
  showOnPaySlip: boolean
  default: boolean
  createdAt: string
  updatedAt: string
}

interface Reimbursement {
  id: string
  value: string
  payPeriod: any
  createdAt: string
  updatedAt: string
  reimbursement: any
}

interface Role {
  id: string
  roleName: string
  description?: string
  isDefault: boolean
  createdAt: string
  updatedAt: string
}

type Query = {
  location?: string
  isActive?: boolean
  manager?: string
  compact?: boolean
}

const useEmployees = (query?: Query) => {
  return useAPI<GetEmployeeAPIResponse>("/employee", query)
}

interface EmployeeComission {
  id: string
  name: string
  product: {
    id: string
    name: string
  }
  commissionRatePercentage: number
  commisionRateFixedAmount: number
}
export const useEmployeeComissions = (employeeId: string) => {
  return useAPI<EmployeeComission[]>(`/employee-commission/${employeeId}`)
}

export default useEmployees
