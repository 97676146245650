import React, { FC, FormEventHandler, useState } from "react"
import { Trans } from "react-i18next"
import {
  subscribeWithFlutterwaveOTP,
  subscribeWithFlutterwaveRecurring,
} from "../lib/subscriptions"
import Modal from "./Modal"
import { useShowSnackbar } from "./Snackbar"
import Button from "./Button"
import { t } from "i18next"
import clsx from "clsx"
import Select from "./Select"

const FlutterwaveSubscribeButton: FC<{
  flwPlanId?: string
  className?: string
  disableRecurring?: boolean
  disableOTP?: boolean
  buttonLabel?: string
}> = ({
  flwPlanId,
  className,
  disableRecurring,
  buttonLabel = "Subscribe",
  disableOTP,
}) => {
  const showSnackBar = useShowSnackbar()

  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)

  if (!flwPlanId) {
    return null
  }

  const handleSubscribeOTP = async () => {
    setLoading(true)
    try {
      const result = await subscribeWithFlutterwaveOTP(flwPlanId)
      if (result.data.data.link) {
        window.location.href = result.data.data.link
      }
    } catch (e: any) {
      if (e.response?.data?.message) {
        showSnackBar(e.response.data.message, "error")
      } else {
        showSnackBar(
          t("Oops! Something went wrong. Please try again."),
          "error"
        )
      }
      setLoading(false)
    }
  }

  const handleSubscribeRecurring = async () => {
    setLoading(true)
    try {
      const result = await subscribeWithFlutterwaveRecurring(flwPlanId)
      if (result.data.data.link) {
        window.location.href = result.data.data.link
      }
    } catch (e: any) {
      if (e.response?.data?.message) {
        showSnackBar(e.response.data.message, "error")
      } else {
        showSnackBar(
          t("Oops! Something went wrong. Please try again."),
          "error"
        )
      }
      setLoading(false)
    }
  }

  return (
    <>
      <button
        className={clsx(
          "w-xl mx-0 mt-2 block w-full rounded-xl border border-solid border-primary-800 bg-primary-800 py-3 text-center text-sm font-semibold text-white no-underline hover:bg-primary-900 disabled:cursor-not-allowed disabled:opacity-50",
          className
        )}
        onClick={async () => {
          if (disableRecurring) {
            await handleSubscribeOTP()
          } else if (disableOTP) {
            await handleSubscribeRecurring()
          } else {
            setShowModal(true)
          }
        }}
      >
        {loading ? <Trans>Loading</Trans> : buttonLabel}
      </button>
      {showModal && (
        <FlutterwaveSubscribeModal
          flwPlanId={flwPlanId}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  )
}

enum PaymentType {
  Recurring = "Recurring",
  OneTime = "OneTime",
}

const FlutterwaveSubscribeModal: FC<{
  flwPlanId: string
  onClose?: () => void
}> = ({ flwPlanId, onClose }) => {
  const showSnackBar = useShowSnackbar()

  const [loading, setLoading] = useState(false)
  const [paymentType, setPaymentType] = useState(PaymentType.OneTime)

  const handleCheckout: FormEventHandler = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      if (paymentType === PaymentType.OneTime) {
        const result = await subscribeWithFlutterwaveOTP(flwPlanId)
        if (result.data.data.link) {
          window.location.href = result.data.data.link
        }
      } else {
        const result = await subscribeWithFlutterwaveRecurring(flwPlanId)
        if (result.data.data.link) {
          window.location.href = result.data.data.link
        }
      }
    } catch (e: any) {
      if (e.response?.data?.message) {
        showSnackBar(e.response.data.message, "error")
      } else {
        showSnackBar(
          t("Oops! Something went wrong. Please try again."),
          "error"
        )
      }
      setLoading(false)
    }
  }

  return (
    <Modal
      open
      title={"Local Payment Checkout"}
      className={"max-w-lg !overflow-hidden !p-0"}
      titleClassName={"p-6"}
      onClose={() => {
        if (onClose) onClose()
      }}
    >
      <p className={"m-0 !max-w-sm p-0 px-6 pb-6 text-gray-700"}>
        <Trans>
          Use the form below to pay through local methods such as credit card
          and bank transfer.
        </Trans>
      </p>
      <form
        className="border-x-0 border-t border-b-0 border-solid border-gray-200 bg-gray-50 p-6"
        onSubmit={handleCheckout}
      >
        <Select
          label={"Type of Payment"}
          onChange={(e) => setPaymentType(e.target.value as PaymentType)}
          value={paymentType}
          containerClassName={"mb-4"}
        >
          <option value={PaymentType.Recurring}>
            Recurring with Credit Card
          </option>
          <option value={PaymentType.OneTime}>One time payment</option>
        </Select>

        <div className={"mt-6 flex"}>
          <Button variant={"outlined"} onClick={onClose}>
            <Trans>Cancel</Trans>
          </Button>
          <Button className={"ml-auto"} type={"submit"}>
            {loading ? <Trans>Loading...</Trans> : <Trans>Checkout</Trans>}
          </Button>
        </div>
      </form>
    </Modal>
  )
}

export default FlutterwaveSubscribeButton
