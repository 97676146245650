import { getUser } from "../../utils/authProvider"
import useSWR, { SWRConfiguration } from "swr"

interface Response {
  username?: string
  attributes?: {
    sub: string
    email_verified: boolean
    "custom:businessId": string
    "custom:lang": string
    "custom:role": string
    email: string
  }
}

const fetcher = async () => {
  const user = await getUser()
  if (!user.username) throw Error("User not found")

  return user
}

const useUser = (config?: SWRConfiguration) => {
  return useSWR<Response>("user", fetcher, { ...config })
}

export default useUser
