import useAPI, { UseAPIQueryParams } from "./use-api"
import useEmployeeMe from "./use-employee-me"

export interface Locations {
  id: string
  name: string
  addressLine1: string
  addressLine2: string
  city: string
  state: string
  zip: string
  isPrimaryLocation: boolean
  createdAt: string
  updatedAt: string
  latCoord: string
  longCoord: string
  tin: string
  isVatRegistered: string
  companyName: string
  birAccredited: boolean
  birIndustryType: "RETAIL" | "FOOD"
  birEnabled: boolean
  pickupLocation?: {
    name: string
    note: string
  }
}

interface Query extends UseAPIQueryParams<string> {
  join: Array<"pickupLocation">
}

const useLocations = (query?: Query, showAll?: boolean) => {
  const { data: me } = useEmployeeMe()

  const swr = useAPI<Locations[]>("/locations", query)

  // if no employee id, then it's the business owner that's logged in, so we enable all
  if (!me?.id) {
    return swr
  }

  // only show locations that the employee is assigned to
  const availableIds = me?.locations?.map((location) => location.id) ?? []
  let locations = swr.data ?? []

  if (!showAll) {
    locations = swr.data?.filter((l) => availableIds.includes(l.id)) ?? []
  }

  // set employee primary location as primary
  if (me.workLocation) {
    locations = locations.map((l) => {
      return {
        ...l,
        isPrimaryLocation: l.id === me.workLocation.id,
      }
    })
  }

  // if no primary location until now, we'll just set the first one as primary
  const hasPrimary = locations.some((l) => l.isPrimaryLocation)
  if (!hasPrimary && locations.length > 0) {
    locations[0].isPrimaryLocation = true
  }

  return {
    ...swr,
    data: locations,
  }
}

export default useLocations
